import React, { useState } from "react";
import {
	MDBRow,
	MDBCol,
	MDBCard,
} from "mdbreact";
import { makeStyles } from '@material-ui/core/styles';
import orderService from "../../Seating/Security/OrderService/orderService";

const useStyles = makeStyles({
    container: {
      //maxHeight: '35vh',
      background: '#5881c1',
      margin: '20px',
      color: 'white',
      padding: '10px',
    },
    separator:{
        marginTop: '4px',
        marginBottom: '4px',
        paddingTop: '4px',
        paddingBottom: '4px',
        color: '#fff',
    },
    avRow: {
        '&:hover':{
            background: 'lightblue',
            color: 'black',
        }
    },
    scrollContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});

export default function DeliveryAverageDays(props) {
    
    const style = useStyles();
    const [averages, setAverages] = useState([]);
    const [ctorRun, setCtorRun] = useState(false);

    const ctor = () => {
        if(ctorRun){
            return;
        }
        else{
            getAverages();
            setCtorRun(true);
        }
    }
    
    const renderAverages = () => {
        return averages.map(item => {
            return (<MDBRow key={item.locationName} className={style.avRow}>
                <MDBCol size='2'>
                    {item.locationName}
                </MDBCol>
                <MDBCol size='2' style={{textAlign:"center"}}>
                    {item.exchangeAverage || '-'}
                </MDBCol>
                <MDBCol size='2' style={{textAlign:"center"}}>
                    {item.standardAverage || '-'}
                </MDBCol>
                <MDBCol size='2' style={{textAlign:"center"}}>
                    {item.complexAverage || '-'}
                </MDBCol>
                <MDBCol size='2' style={{textAlign:"center"}}>
                    {item.pickupAverage || '-'}
                </MDBCol>
                <MDBCol size='2' style={{textAlign:"center"}}>
                    {item.serviceAverage || '-'}
                </MDBCol>
            </MDBRow>)
        })
    }

    const getAverages = () => {
        if(!averages || averages.length === 0){
            orderService.getDashboardDeliveryAverages().then(res => {
                //console.log(res);
                setAverages(res);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    ctor();
    return (
        <MDBRow>
            <MDBCol size='12'>
                <MDBCard className={style.container}>
                    <MDBRow>
                        <MDBCol size='2'>
                            Location
                        </MDBCol>
                        <MDBCol size='2' style={{textAlign:"center"}}>
                            Exchange Average
                        </MDBCol>
                        <MDBCol size='2' style={{textAlign:"center"}}>
                            Standard Average
                        </MDBCol>
                        <MDBCol size='2' style={{textAlign:"center"}}>
                            Complex Average
                        </MDBCol>
                        <MDBCol size='2' style={{textAlign:"center"}}>
                            Pickup Average
                        </MDBCol>
                        <MDBCol size='2' style={{textAlign:"center"}}>
                            Service Average
                        </MDBCol>
                        <MDBCol size='12'>
                            <hr className={style.separator}/>
                        </MDBCol>
                    </MDBRow>
                    <div className={style.scrollContainer}>
                        {averages && renderAverages()}
                    </div>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    )
}