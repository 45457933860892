import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";

export default class insurance extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Display Order",
					field: "displayorder",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			currInsurance: {
				id: -1,
				name: "",
				order: "",
			},
			currIndex: -1,
			errorMessage: "",
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveInsurance();
	}

	retrieveInsurance() {
		let t = this;
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((insurance, index) => {
					arr.push({
						id: insurance.id,
						name: insurance.name,
						displayorder:
							typeof insurance.order === "number"
								? insurance.order
								: "no order",
						edit: t.renderEditButton(insurance, index),
						remove: t.renderRemoveButton(insurance.id),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(insurance, index) {
		return (
			<Button
				floating
				size="sm"
				onClick={() => {
					this.handleEditClick(insurance, index);
				}}
			>
				<MDBIcon icon="edit" />
			</Button>
		);
	}

	renderRemoveButton(id) {
		return (
			<Button
				floating
				color={"red"}
				size="sm"
				onClick={() => this.handleRemoveClick(id)}
			>
				<MDBIcon icon="trash" />
			</Button>
		);
	}

	handleRemoveClick(id) {
		InsuranceService.deleteInsurance(id)
			.then((res) => {
				this.retrieveInsurance();
				toast.success("Payor Source Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Payor Source");
			});
	}

	handleEditClick(insurance, index) {
		let i = {
			id: insurance.id,
			name: insurance.name,
			order: insurance.order,
		};
		this.setState({
			editModal: !this.state.editModal,
			currInsurance: i,
			currIndex: index,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#90A4AE", color: "white" }}
					toggle={this.editToggle}
				>
					Payor Source
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Payor Source"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					label="Name"
					value={this.state.currInsurance.name}
					onChange={this.handleEditChange.bind(this, "name")}
				/>
				<Input
					label="Order"
					value={String(this.state.currInsurance.order)}
					onChange={this.handleEditChange.bind(this, "order")}
				/>
			</div>
		);
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data;
			let i = this.state.currIndex;
			return InsuranceService.updateInsurance(this.state.currInsurance)
				.then((insurance) => {
					d.rows[i].name = insurance.name;
					d.rows[i].displayorder =
						typeof insurance.order === "number" ? insurance.order : "no order";
					this.setState({
						data: d,
						editModal: !this.state.editModal,
					});
					toast.success("Payor Source Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateEdit() {
		let i = this.state.currInsurance;
		if (i.name === "") {
			this.setError("Please add a name");
			return false;
		} else if (i.order === "") {
			this.setError("Please add an order number");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		let i = {
			id: -1,
			name: "",
			order: "",
		};
		this.setState({
			currInsurance: i,
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	handleEditChange(property, e) {
		let i = this.state.currInsurance;
		i[property] = e.target.value;
		this.setState({
			currInsurance: i,
		});
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		return this.props.history.push({
			pathname: "insurance/new",
		});
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Payor Sources
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Payor Source"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
					<ReactTooltip />
				</Card>
				{this.renderEditModal()}
			</Container>
		);
	}
}
