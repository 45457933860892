import api from "../api";

class InsuranceService {
	async findAllInsurance() {
		return new Promise((res, reject) => {
			return api
				.get("/insurance")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAllSubInsurance() {
		return new Promise((res, reject) => {
			return api
				.get("/insuranceSubTypes")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateSubInsurance(insurance) {
		return new Promise((res, reject) => {
			api
				.put("/insuranceSubTypes/" + insurance.id, insurance)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateInsurance(insurance) {
		return new Promise((res, reject) => {
			api
				.put("/insurance/" + insurance.id, insurance)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createInsurance(insurance) {
		return new Promise((res, reject) => {
			api
				.post("/insurance", insurance)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createSubInsurance(insurance) {
		return new Promise((res, reject) => {
			api
				.post("/insuranceSubTypes", insurance)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteInsurance(id) {
		return new Promise((res, reject) => {
			api
				.delete("/insurance/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteInsuranceSubType(id) {
		return new Promise((res, reject) => {
			api
				.delete("/insuranceSubTypes/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const insuranceService = new InsuranceService();

export default insuranceService;
