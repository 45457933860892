import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ExpenseTypeService from "./expenseTypeService";
import Select from "react-select";
import CreditCardService from "./creditCardTypesService";
import ReactTooltip from "react-tooltip";

export default class expenseTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Requires Receipt",
					field: "requiresreceipt",
					sort: "asc",
				},
				{
					label: "Description",
					field: "description",
					sort: "asc",
				},
				{
					label: "Card Type",
					field: "cardtype",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currExpense: {},
			isChecked: false,
			creditCardsSelected: [],
			creditCards: [],
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveExpenseType();
		this.retrieveCCTypes();
	}

	retrieveCCTypes() {
		let t = this;
		return CreditCardService.getAllCreditCardTypes()
			.then((res) => {
				let arr = [];

				// Have to do ccVal b/c it will complain about matching keys
				// if you try to pass an obj as the value instead of a string
				res.forEach((cc) => {
					arr.push({
						value: cc.name,
						label: cc.name,
						ccVal: cc,
					});
				});

				t.setState({
					creditCards: arr,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	retrieveExpenseType() {
		let t = this;
		return ExpenseTypeService.getAllExpenseTypes()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((exp, index) => {
					arr.push({
						id: exp.id,
						name: exp.name,
						receipt: String(exp.receiptRequired),
						description: exp.description,
						card: t.renderCreditCard(exp),
						edit: t.renderEditButton(exp, index),
						remove: t.renderRemoveButton(exp.id),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderCreditCard(creditCardList) {
		let a = [];
		creditCardList.cardTypes.forEach((card) => {
			a.push(card.name);
		});
		return <i>{String(a)}</i>;
	}

	renderEditButton(exp, index) {
		return (
			<Button
				floating
				size="sm"
				onClick={() => this.handleEditClick(exp, index)}
			>
				<MDBIcon icon="edit" />
			</Button>
		);
	}

	renderRemoveButton(id) {
		return (
			<Button
				floating
				color={"red"}
				size="sm"
				onClick={() => this.handleRemoveClick(id)}
			>
				<MDBIcon icon="trash" />
			</Button>
		);
	}

	handleRemoveClick(id) {
		ExpenseTypeService.deleteExpenseType(id)
			.then((res) => {
				this.retrieveExpenseType();
				toast.success("Expense Type Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Expense Type");
			});
	}

	handleEditClick(exp, index) {
		let expense = JSON.parse(JSON.stringify(exp));
		let c = [];
		exp.cardTypes.forEach((card) => {
			c.push({
				label: card.name,
				value: card.name,
				ccVal: card,
			});
		});
		this.setState({
			currExpense: expense,
			editModal: !this.state.editModal,
			currIndex: index,
			isChecked: exp.receiptRequired,
			creditCardsSelected: c,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		if (this.state.editModal) {
			let exp = this.state.currExpense;
			return (
				<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
					<ModalHeader
						style={{ backgroundColor: "#90A4AE", color: "white" }}
						toggle={this.editToggle}
					>
						Edit Expense Type
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<div>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input
								label={"Expense Type Name"}
								onChange={this.handleChange.bind(this, "name")}
								value={exp.name}
							/>
							<div className="form-check my-4">
								<input
									className="form-check-input"
									checked={this.state.isChecked}
									type="checkbox"
									id="defaultCheck12"
									onChange={this.checkBoxChange.bind(this)}
								/>
								<label htmlFor="defaultCheck12" className="grey-text">
									Receipt Required
								</label>
							</div>
							<Input
								label={"Description"}
								onChange={this.handleChange.bind(this, "description")}
								value={exp.description}
							/>
							<Select
								closeMenuOnSelect={false}
								isMulti
								options={this.state.creditCards}
								onChange={this.handleSelectChange.bind(this)}
								value={this.state.creditCardsSelected}
							/>
						</div>
					</ModalBody>
					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"success"}
							data-tip={"Update Expense Type"}
							onClick={this.handleEditSave}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"red"}
							data-tip={"Close"}
							onClick={this.handleEditClose}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</ModalFooter>
				</Modal>
			);
		}
		return <div></div>;
	}

	handleChange = (property, e) => {
		let exp = this.state.currExpense;
		exp[property] = e.target.value;
		this.setState({
			currExpense: exp,
		});
	};

	checkBoxChange = (e) => {
		this.setState({
			isChecked: !this.state.isChecked,
		});
	};

	handleSelectChange = (e) => {
		this.setState({
			creditCardsSelected: e,
		});
	};

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data,
				i = this.state.currIndex,
				exp = this.state.currExpense,
				cc = this.state.creditCardsSelected,
				check = this.state.isChecked,
				t = this,
				ccList = [];

			cc.forEach((credit) => {
				ccList.push(credit.ccVal);
			});
			exp.cardTypes = ccList;
			exp.receiptRequired = check;
			return ExpenseTypeService.updateExpenseType(this.state.currExpense)
				.then((res) => {
					d.rows[i].name = res.name;
					d.rows[i].receipt = String(res.receiptRequired);
					d.rows[i].description = res.description;
					d.rows[i].card = t.renderCreditCard(res);
					d.rows[i].edit = t.renderEditButton(res, i);
					this.setState({
						data: d,
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					toast.success("Expense Type Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateEdit() {
		let exp = this.state.currExpense;
		if (exp.name === "") {
			this.setError("Please add an Expense Name");
			return false;
		} else if (exp.description === "") {
			this.setError("Please add a Description");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		return this.props.history.push({
			pathname: "expenseTypes/new",
		});
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Expense Types
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Expense Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
					<ReactTooltip />
				</Card>
				{this.renderEditModal()}
			</Container>
		);
	}
}
