import React from "react";
import {
	Button,
	Container,
	DataTable,
	MDBIcon,
	Spinner,
	toast,
} from "mdbreact";
import ReportsService from "../Security/ReportsService/reportsService";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class salesReport extends React.Component {
	constructor(props) {
		super(props);

		let filters = this.determineFilters(props.filters);
		let topFilters = props.topFilters;

		// table headers and columns that actually get displayed
		const salesDataMainColumns = [
			{
				label: "Setup Date",
				field: "setupDate",
				sort: "asc",
				width: 200,
			},
			{
				label: "Changed Date",
				field: "changeDate",
				sort: "asc",
				width: 200,
			},
			{
				label: "Approval Date",
				field: "approvalDate",
				sort: "asc",
				width: 200,
			},
			{
				label: "Sales Location",
				field: "salesLocation",
				sort: "asc",
				width: 200,
			},
			{
				label: "Sales Rep",
				field: "salesRep",
				sort: "asc",
				width: 200,
			},
			{
				label: "Patient",
				field: "patient",
				sort: "asc",
				width: 200,
			},
			{
				label: "Account",
				field: "account",
				sort: "asc",
				width: 200,
			},
			{
				label: "Payor Source",
				field: "payorSource",
				sort: "asc",
				width: 200,
			},
			{
				label: "Insurance",
				field: "insurance",
				sort: "asc",
				width: 200,
			},
			{
				label: "Category",
				field: "category",
				sort: "asc",
				width: 200,
			},
			{
				label: "Product",
				field: "product",
				sort: "asc",
				width: 200,
			},
			{
				label: "Points",
				field: "points",
				sort: "asc",
				width: 200,
			},
			{
				label: "Amount",
				field: "amount",
				sort: "asc",
				width: 200,
			},
		];

		// displayed columns in table format
		const mainSalesData = {
			columns: salesDataMainColumns,
			rows: []
		}

		// address columns for csv export
		const salesDataAddressColumns = [
			{
				label: "Address",
				field: "address",
				sort: "asc",
				width: 200,
			},
			{
				label: "City",
				field: "city",
				sort: "asc",
				width: 200,
			},
			{
				label: "State",
				field: "state",
				sort: "asc",
				width: 200,
			},
			{
				label: "ZIP",
				field: "zip",
				sort: "asc",
				width: 200,
			},
		];

		// combination of all columns
		const salesDataColumns = salesDataMainColumns.concat(salesDataAddressColumns);

		// combination of all columns in table format to be exported as csv
		const salesDataExport = {
			columns: salesDataColumns,
			rows: []
		};

		this.state = {
			allSales: [],
			mainSalesData: mainSalesData,
			salesDataExport: salesDataExport,
			startDate: new Date(filters.startDate),
			endDate: new Date(filters.endDate),
			pointsOnlyProducts: false,
			csvFunction: props.csvFunction,
			isLoaded: true,
			users: [],
			topFilters: topFilters,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		salesReps: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Get the most up-to-date filters passed down from parent...JK
		if (
			JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
		) {
			this.setState({
				topFilters: this.props.topFilters,
			});
		}
		// Checks if the generate reports button has been clicked...JK
		if (prevProps.generateClicked !== this.props.generateClicked) {
			this.getSalesReport();
		}
	}

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "2-digit",
		}).format(date);
	}

	determineFilters(userFilters) {
		let d = new Date(),
			filters = {};

		if (userFilters) {
			if (userFilters.startDate) {
				filters.startDate = new Date(userFilters.startDate);
			} else {
				filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			}

			if (userFilters.endDate) {
				filters.endDate = new Date(userFilters.endDate);
			} else {
				filters.endDate = d;
			}
		} else {
			filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			filters.endDate = d;
		}

		return filters;
	}

	getSalesReport() {
		const { currentUser } = this.context;
		let st = this.state,
			tp = st.topFilters;

		let filters = {
			startDate: tp.startDate,
			endDate: tp.endDate,
			pointsOnlyProducts: tp.pointsOnlyProducts || false,
		};

		try {
			filters = {
				startDate: new Date(tp.startDate).toLocaleDateString(),
				endDate: new Date(tp.endDate).toLocaleDateString(),
				pointsOnlyProducts: tp.pointsOnlyProducts,
			};
		} catch {
			//Only need one toast since all reports get generated at the same time...BC
			toast.error("Please check your dates.");
			return;
		}

		// Have to check len since showDatePicker defaults to false at startup...JK
		if (!tp.showDatePicker && tp.quickFilter.length > 0) {
			filters.startDate = tp.quickFilter[0].toLocaleDateString();
			filters.endDate = tp.quickFilter[1].toLocaleDateString();
		} else {
			// Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter...JK
			if (
				filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
			) {
				toast.error("Please check your dates");
				return;
			}
		}
		// Get users in the sales rep dropdown and add to the filter...JK
		if (tp.salesRepsSelected.length > 0) {
			filters.users = tp.salesRepsSelected;
		}
		// Only default to getting all users if they have the correct permissions....JK
		else if (
			currentUser.role === "OFFICE_MANAGER" ||
			currentUser.role === "OFFICE_SUPPORT" ||
			currentUser.role === "SALES" ||
			currentUser.role === "SALES_MANAGER" ||
			currentUser.role === "TECHNICIAN"
		) {
			return;
		}

		this.setState({ isLoaded: false });

		return ReportsService.getSalesReport(filters)
			.then((res) => {
				this.setState({ allSales: res });

				this.formatRows();
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	transformDate(date) {
		let result = "";
		if (date) {
			let parts = date.split("-");
			if (parts.length > 1) {
				result = `${parts[1]}/${parts[2]}/${parts[0]}`;
			}
			return result;
		}
	}

	formatRows() {
		let sales = this.state.allSales,
				dt = this.state.mainSalesData,
				ex = this.state.salesDataExport,
				ary = [];

		sales.forEach((sale) => {
			ary.push({
				setupDate: this.transformDate(sale.setupDate) || " ",
				changeDate: this.transformDate(sale.changeDate) || " ",
				approvalDate: this.transformDate(sale.approvalDate) || " ",
				salesLocation: sale.salesLocation,
				salesRep: sale.salesRep,
				patient: sale.patient ? sale.patient.replace(/,/g, " ") : "no patient",
				account: sale.account ? sale.account.replace(/,/g, " ") : "no account",
				payorSource: sale.payorSource
					? sale.payorSource.replace(/,/g, " ")
					: "no Payor Source",
				insurance: sale.insurance
					? sale.insurance.replace(/,/g, " ")
					: "No Insurance",
				category: sale.category
					? sale.category.replace(/,/g, " ")
					: "No Category",
				product: sale.product ? sale.product.replace(/,/g, " ") : "No Product",
				points: sale.points ? parseFloat(sale.points) : 0,
				amount: sale.amount ? parseFloat(sale.amount) : 0,
				// address data
				address: sale.address ? sale.address.replace(/,/g, " ") : "no address",
				city: sale.city ? sale.city.replace(/,/g, " ") : "no city",
				state: sale.state ? sale.state.replace(/,/g, " ") : "no state",
				zip: sale.zip ? sale.zip.replace(/,/g, " ") : "no zip",
			});
		});
		dt.rows = ary.slice(12);
		ex.rows = ary;

		this.setState({
			mainSalesData: dt,
			salesDataExport: ex,
			isLoaded: true,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//get the data in the table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<DataTable
					entriesOptions={[25, 50, 100]}
					pagesAmount={10}
					data={this.state.mainSalesData}
					responsive
					fixed
					striped
					bordered
				></DataTable>

				<Button
					style={{ float: "right" }}
					color={"blue"}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.state.csvFunction.bind(
						this,
						this.state.salesDataExport,
						"salesExport.csv"
					)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
