import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	CardHeader,
	Col,
	Container,
	DatePicker,
	Input,
	InputFile,
	Modal,
	ModalFooter,
	ModalBody,
	Row,
	toast,
	MDBIcon,
} from "mdbreact";
import "./activities.css";
import Select from "react-select";
import ActivityService from "../Security/ActivityService/activityService";
import NoteService from "../Security/NoteService/noteService";

import ReactTooltip from "react-tooltip";

export default class activitiesModal extends React.Component {
	constructor(props) {
		super(props);

		let account = props.account || null;
		this.activityFunction = props.activityFunction;
		this.cancelFunction = props.cancelFunction;

		this.state = {
			isOpen: props.isOpen,
			typeRadio: "Call",
			selectedExpense: {},
			myAccounts: [],
			nActivity: {
				account: account,
				expenseDate: new Date(),
				quality: false,
				contacts: [],
				type: "Appointment",
			},
			nCall: {
				account: account,
				quality: false,
				contacts: [],
				type: "Call",
				when: new Date(),
			},
			noteRadio: "Note",
			saveLoading: false,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		myAccounts: PropTypes.array,
		cardTypes: PropTypes.array,
		expenseTypes: PropTypes.array,
	};

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.isOpen != null) {
			this.setState({
				isOpen: nextProps.isOpen,
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { myAccounts } = this.context;
		let st = this.state;

		if (st.myAccounts.length !== myAccounts.length) {
			this.getAccountList();
		}
	}

	getCardList() {
		const { cardTypes } = this.context;

		let a = [];
		a.push({
			label: "Select Card Type...",
			value: "",
		});

		cardTypes.map((card) => {
			return a.push({
				label: card.name,
				value: card,
			});
		});

		return a;
	}

	getExpenseTypeList() {
		const { expenseTypes } = this.context;

		let act = this.state.nActivity;

		let a = [];
		a.push({
			label: "Select Expense Type...",
			value: "",
		});

		expenseTypes.map((exp) => {
			if (act.cardType != null) {
				if (exp.cardTypes) {
					let i = exp.cardTypes.findIndex((x) => x.id === act.cardType.id);
					if (i > -1) {
						return a.push({
							label: exp.name,
							value: exp,
						});
					}
				}
			} else {
				return a.push({
					label: exp.name,
					value: exp,
				});
			}

			return null;
		});

		return a;
	}

	getAccountList() {
		const { myAccounts } = this.context;

		let a = [];
		myAccounts.map((acc) => {
			return a.push({
				label: acc.name,
				value: acc,
			});
		});

		this.setState({
			myAccounts: a,
		});
	}

	handleAccountSelected = (e) => {
		let act = this.state.nActivity,
			call = this.state.nCall,
			account = { name: e.value.name, id: e.value.id };

		act.account = account;
		call.account = account;

		this.setState({
			nActivity: act,
			nCall: call,
		});
	};

	handleCardTypeSelected = (e) => {
		let act = this.state.nActivity;

		act.cardType = { name: e.value.name, id: e.value.id };

		this.setState({
			nActivity: act,
		});
	};

	handleExpenseTypeSelected = (e) => {
		let act = this.state.nActivity;

		act.expenseType = { name: e.value.name, id: e.value.id };

		this.setState({
			nActivity: act,
			selectedExpense: e.value,
		});
	};

	encodeImageFileAsURL(element) {
		let file = element[0],
			reader = new FileReader(),
			t = this;

		reader.onloadend = function () {
			t.state.nActivity.receipt = reader.result;
			t.setState({ nActivity: t.state.nActivity });
		};
		reader.readAsDataURL(file);
	}

	validateActivity(activity) {
		let shouldContinue = true;

		if (!activity.account) {
			toast.warn("Please pick an account");
			shouldContinue = false;
		}

		if (!activity.type) {
			toast.warn("Please select an activity type");
			shouldContinue = false;
		}

		if (activity.type === "Appointment") {
			if (!activity.expenseDescription) {
				toast.warn("Please provide a description");
				shouldContinue = false;
			}

			if (!activity.expenseDate) {
				toast.warn("Please set the date");
				shouldContinue = false;
			}

			if (activity.expenses < 0) {
				toast.warn("Amount spent should be no less than 0");
				shouldContinue = false;
			}

			if (!activity.vendor) {
				toast.warn("Please identify the vendor");
				shouldContinue = false;
			}

			if (!activity.peopleAttending) {
				toast.warn("Please identify how many people were attending");
				shouldContinue = false;
			}

			if (!activity.educationalTopics) {
				toast.warn("Please identify the topics discussed");
				shouldContinue = false;
			}

			if (activity.expenses > 0) {
				if (!activity.expenseType) {
					toast.warn("Please select an expense type");
					shouldContinue = false;
				}

				if (!activity.cardType) {
					toast.warn("Please select a card type");
					shouldContinue = false;
				}

				if (
					activity.expenseType &&
					this.state.selectedExpense.receiptRequired === true
				) {
					if (!activity.receipt) {
						toast.warn("Please upload a receipt");
						shouldContinue = false;
					}
				}
			}
		}

		return shouldContinue;
	}

	createActivity() {
		this.setState({ saveLoading: true });
		let act = this.state.nActivity;

		if (this.state.typeRadio === "Appointment") {
			let shouldCreate = this.validateActivity(act);

			if (!shouldCreate) {
				this.setState({ saveLoading: false });
				return;
			}

			//If we have a receipt, format it before save...BC
			if (act.receipt) {
				let rt = act.receipt;
				//get the index of the comma which comes after the part that declares the image type
				let idx = rt.indexOf(","),
					newStr = rt.slice(idx + 1);

				act.receipt = newStr;
			}

			if (act.expenses) {
				act.expenses = parseFloat(act.expenses);
			}

			act.when = new Date();
		} else {
			act = this.state.nCall;

			let shouldCreate = this.validateActivity(act);

			if (!shouldCreate) {
				this.setState({ saveLoading: false });
				return;
			}
		}

		return ActivityService.createActivity(act)
			.then((res) => {
				let nActivity = {
					expenseDate: new Date(),
					quality: false,
					contacts: [],
					type: "Appointment",
				};

				let nCall = {
					quality: false,
					contacts: [],
					type: "Call",
					when: new Date(),
				};

				this.setState({
					nActivity: nActivity,
					nCall: nCall,
				});

				this.createNoteTask(res);

				//this is the callback that will add the newly created activity to the list...BC
				this.activityFunction(res);
				this.setState({
					nActivity: nActivity,
					saveLoading: false,
				});
			})
			.catch((err) => {
				toast.warn("Oh! There was an error creating the Activity.");
				this.setState({ saveLoading: false });
			});
	}

	createNoteTask(activity) {
		const { currentUser } = this.context;

		let noteRadio = this.state.noteRadio,
			accountDTO = activity.account,
			cb = { id: currentUser.id, name: currentUser.username };

		let nText = document.getElementById("newNoteText").value;

		//we don't have any text, no note to create
		if (nText === "") {
			this.cancelFunction();
		} else {
			let note = {
				text: nText,
				account: accountDTO,
				completed: true,
				task: false,
				createdAt: new Date(),
				createdBy: cb,
			};

			if (noteRadio === "Task") {
				note.completed = false;
				note.task = true;
			}

			return NoteService.createActivityNote(activity.id, note)
				.then((res) => {
					toast.success("Task successfully created.");
					this.cancelFunction();
				})
				.catch((err) => {
					toast.warn("Oh! There was an error creating the Task.");
				});
		}
	}

	determineSection() {
		if (this.state.typeRadio === "Call") {
			return this.renderCallSection();
		} else if (this.state.typeRadio === "Appointment") {
			return this.renderAppointmentSection();
		}
		return <div />;
	}

	determineNoteTaskSection() {
		return (
			<div>
				<Row>
					<Col size="5">
						<Input
							onClick={() => this.setState({ noteRadio: "Note" })}
							checked={this.state.noteRadio === "Note"}
							label="Note"
							type="radio"
							id="noteRadio"
						/>
					</Col>
					<Col size="5">
						<Input
							onClick={() => this.setState({ noteRadio: "Task" })}
							checked={this.state.noteRadio === "Task"}
							label="Task"
							type="radio"
							id="taskRadio"
						/>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<textarea
							placeholder={"Enter Text"}
							id={"newNoteText"}
							style={{ width: "100%", height: "160px" }}
						></textarea>
					</Col>
				</Row>
			</div>
		);
	}

	renderCallSection() {
		return (
			<div>
				<Row>
					<Input
						onChange={(evt) => {
							let nCall = this.state.nCall;
							nCall.quality = evt.target.checked;
							this.setState({ nCall: nCall });
						}}
						filled
						label="Quality"
						type="checkbox"
						id="checkbox6"
					/>

					<DatePicker
						format="MM-DD-YYYY"
						hint={"01-01-1900"}
						keyboard
						getValue={(evt) => {
							let act = this.state.nCall;
							act.when = evt;
							this.setState({ nCall: act });
						}}
						mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
						label="Call Date"
					/>
				</Row>
			</div>
		);
	}

	renderReceipt() {
		if (!this.state.nActivity.receipt) {
			return <div></div>;
		} else {
			return (
				<img
					style={{ width: "200px", height: "300px" }}
					src={this.state.nActivity.receipt}
					alt="Invalid format"
				/>
			);
		}
	}

	renderAppointmentSection() {
		return (
			<div>
				<Row>
					<Col size="5">
						<div className={"datePickerOptions"}>
							<DatePicker
								format="MM-DD-YYYY"
								hint={"01-01-1900"}
								keyboard
								getValue={(evt) => {
									let act = this.state.nActivity;
									act.expenseDate = evt;
									this.setState({ nActivity: act });
								}}
								mask={[
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									/\d/,
									/\d/,
								]}
								label="Appointment Date"
							/>
						</div>
					</Col>
					<Col size="5">
						<Input
							label="Amount Spent"
							icon={"dollar"}
							value={this.state.nActivity.expenses}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.expenses = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col size="5">
						<Select
							placeholder={"Select Card Type"}
							options={this.getCardList()}
							onChange={this.handleCardTypeSelected.bind(this)}
						/>
					</Col>

					<Col size="5">
						<Select
							placeholder={"Select Expense Type"}
							options={this.getExpenseTypeList()}
							onChange={this.handleExpenseTypeSelected.bind(this)}
						/>
					</Col>
				</Row>

				<Row>
					<Col size="12">
						<Input
							label="Appointment Description"
							value={this.state.nActivity.expenseDescription}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.expenseDescription = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col size="5">
						<Input
							label="Vendor"
							value={this.state.nActivity.vendor}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.vendor = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>

					<Col size="5">
						<Input
							label="People Attending (amount)"
							value={this.state.nActivity.peopleAttending}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.peopleAttending = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col size="5">
						<Input
							label="Topics Discussed"
							value={this.state.nActivity.educationalTopics}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.educationalTopics = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>

					<Col size="5">
						<Input
							label="Physicians Attending (names)"
							value={this.state.nActivity.physiciansAttending}
							onChange={(evt) => {
								let act = this.state.nActivity;
								act.physiciansAttending = evt.target.value;
								this.setState({ nActivity: act });
							}}
						/>
					</Col>
				</Row>

				<InputFile
					type="file"
					textFieldTitle={"Upload receipt (.jpg or .png only)"}
					className="form-control-file"
					name="file"
					getValue={this.encodeImageFileAsURL.bind(this)}
				/>
				<Row style={{ justifyContent: "space-evenly" }}>
					{this.renderReceipt()}
				</Row>
			</div>
		);
	}

	render() {
		return (
			<Container>
				<Row>
					<Modal
						position={"top"}
						className=""
						isOpen={this.state.isOpen}
						toggle={() => {
							return;
						}}
					>
						<CardHeader color={"indigo"}>New Activity</CardHeader>
						<ModalBody>
							<Row>
								<Col size="5">
									<Input
										onClick={() => this.setState({ typeRadio: "Call" })}
										checked={this.state.typeRadio === "Call"}
										label="Call"
										type="radio"
										id="callRadio"
									/>
								</Col>
								<Col size="5">
									<Input
										onClick={() => this.setState({ typeRadio: "Appointment" })}
										checked={this.state.typeRadio === "Appointment"}
										label="Appointment"
										type="radio"
										id="serviceRadio"
									/>
								</Col>
							</Row>

							<Col size="12">
								<br />
							</Col>

							<Select
								placeholder={"Select Account...(Required)"}
								options={this.state.myAccounts}
								onChange={this.handleAccountSelected.bind(this)}
							/>
							<Col size="12">
								<br />
							</Col>

							{this.determineSection()}
							{this.determineNoteTaskSection()}
						</ModalBody>
						<ModalFooter>
							{this.state.saveLoading ? (
								<div className="spinner-border text-primary" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							) : (
								<Button
									floating
									size="sm"
									color={"success"}
									data-tip={"Create New Activity"}
									onClick={this.createActivity.bind(this)}
								>
									<MDBIcon icon="check" style={{ fontSize: "2em" }} />
								</Button>
							)}

							<Button
								floating
								size="sm"
								color={"red"}
								data-tip={"Cancel"}
								onClick={this.cancelFunction.bind(this)}
							>
								<MDBIcon icon="times" style={{ fontSize: "2em" }} />
							</Button>

							<ReactTooltip />
						</ModalFooter>
					</Modal>
				</Row>
			</Container>
		);
	}
}
