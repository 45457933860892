import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	DataTable,
	Input,
	MDBIcon,
	Row,
	Spinner,
} from "mdbreact";
import ContactService from "../Security/ContactService/contactService";
import UserService from "../Security/UserService/userService";
import GroupService from "../Security/UserService/groupService";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

export default class contactsList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "Name",
					field: "username",
					sort: "asc",
					width: 200,
				},
				{
					label: "Type",
					field: "contactType",
					sort: "asc",
					width: 200,
				},
				{
					label: "Title",
					field: "role",
					sort: "asc",
					width: 200,
				},
				{
					label: "Phone",
					field: "phone",
					sort: "asc",
					width: 200,
				},
				{
					label: "Email",
					field: "email",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 200,
				},
				{
					label: "Sales Rep",
					field: "owner",
					sort: "asc",
					width: 200,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			contacts: data,
			isLoaded: false,
			users: [],
			allLocs: [],
			allSalesReps: [],
			locationsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			salesRepsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			locationsSelected: [],
			salesRepsSelected: null,
			search: "",
		};
		this.getUsers();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		salesReps: PropTypes.array,
		allLocations: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { salesReps, currentUser, allLocations } = this.context;
		let st = this.state;
		if (
			st.allLocs.length !== allLocations.length &&
			st.currUser !== currentUser
		) {
			this.renderLocationsOptions();
		}
		if (st.allSalesReps.length !== salesReps.length) {
			this.renderSalesRepsOptions();
		}
	}

	renderSalesRepsOptions(e) {
		const { salesReps, currentUser } = this.context;
		let options = [];
		if (e === undefined || e.length === 0) {
			if (currentUser.role !== "SALES") {
				salesReps.map((rep) => {
					return options.push({
						label: rep.username,
						value: rep.id,
					});
				});
			}
			this.setState({
				salesRepsSelect: options,
				allSalesReps: salesReps,
			});
		} else {
			let salesList = [];
			let locs = [];
			e.forEach((location) => {
				locs.push(location.value);
			});
			return GroupService.getAllSalesRepsByLocations(locs)
				.then((res) => {
					res.forEach((t) => {
						if (salesList.indexOf(t.id) === -1) {
							options.push({
								label: t.username,
								value: t.id,
							});
						}
						salesList.push(t.id);
					});

					this.setState({
						salesRepsSelect: options,
					});
				})
				.catch((e) => {});
		}
	}

	renderLocationsOptions() {
		const { allLocations, currentUser } = this.context;
		let cu = currentUser;
		let select = [],
			locations = [];
		if (cu.role === "SALES") {
			locations = cu.locations;
		} else {
			locations = allLocations;
		}
		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === "Internal") {
				return select.push({
					label: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}

	getUsers() {
		return UserService.getAllUsers().then((res) => {
			let data = res,
				ary = [];
			data.forEach((user) => {
				ary.push(user.id);
			});

			this.setState({ users: ary });
			this.retrieveContacts();
		});
	}

	retrieveContacts() {
		let t = this,
			users = this.state.users,
			salesRepSelected = this.state.salesRepsSelected,
			q = this.state.search,
			arr = [];

		if (salesRepSelected) {
			arr.push(salesRepSelected.value);
			users = arr;
		}

		let filters = {
			users: users,
			q: q,
		};

		ContactService.getAllContacts(filters)
			.then((res) => {
				let ary = [],
					contacts = res.content || [],
					dt = this.state.contacts;

				contacts.forEach((contact, index) => {
					ary.push({
						username:
							(contact.firstname || "No First Name") +
							", " +
							(contact.lastname || "No Last Name"),
						contactType: contact.contactType
							? contact.contactType.name
							: "No Type",
						role: contact.role || "No Role",
						phone: contact.phone || "No Contact",
						email: contact.email || "No Email",
						account: contact.account ? contact.account.name : "No Account",
						owner: contact.owner ? contact.owner.name : "No Owner",
						button: t.renderRouteButton(contact, index),
					});
				});

				dt.rows = ary;

				t.setState({
					contacts: dt,
					currContacts: contacts,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	renderRouteButton(contact, index) {
		return (
			<MDBIcon
				icon="edit"
				key={index}
				aria-hidden="true"
				color="warning"
				onClick={() => {
					this.props.history.push({
						pathname: "/contact/" + contact.id,
						state: { contact: contact },
					});
				}}
			></MDBIcon>
		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.contacts}>
					{" "}
				</DataTable>
			);
		} else {
			return this.renderLoadingSpinner();
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	exportClicked = () => {
		let contacts = this.state.currContacts,
			s = "";
		contacts.map((c) => {
			return (s += this.generateContactString(c));
		});
		this.downloadBlob(s, "contacts.vcf");
	};

	downloadBlob(contactData, filename) {
		let blob = new Blob([contactData], {
			type: "application/vcf;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	generateContactString(contact) {
		let account = contact.account ? contact.account.name : "",
			fname = contact.firstname || "",
			lname = contact.lastname || "",
			role = contact.role || "",
			phone = contact.phone || "",
			email = contact.email || "";
		return (
			"BEGIN:VCARD\n" +
			"VERSION:2.1\n" +
			"N:" +
			lname +
			";" +
			fname +
			";\n" +
			"FN:" +
			fname +
			" " +
			lname +
			"\n" +
			"ORG:" +
			account +
			"\n" +
			"TITLE:" +
			role +
			"\n" +
			"TEL;WORK;VOICE:" +
			phone +
			"\n" +
			"EMAIL:" +
			email +
			"\n" +
			"END:VCARD\n"
		);
	}

	renderFilter() {
		let st = this.state;
		return (
			<Card style={{ marginBottom: "1%" }} color={" blue-grey lighten-2"}>
				<CardBody className={"filterSearchBoxCardBody"}>
					<Row>
						<Col md={3} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Select Location"
								closeMenuOnSelect={false}
								isMulti
								options={st.locationsSelect}
								onChange={this.handleLocationChange.bind(this)}
								value={st.locationsSelected}
							/>
						</Col>
						<Col md={3} className={"filterSearchBoxCol"}>
							<Select
								placeholder="Select Sales Rep"
								isClearable
								options={st.salesRepsSelect}
								onChange={this.handleSaleRepChange.bind(this)}
								value={st.salesRepsSelected}
							/>
						</Col>
						<Col md={5} className={"filterSearchBoxCol expenseDateOptions"}>
							<form
								onSubmit={(e) => {
									this.filterClicked(e);
								}}
							>
								<Input
									label="Search..."
									value={st.search}
									onChange={(e) => {
										this.setState({ search: e.target.value });
									}}
									size="sm"
								/>
							</form>
						</Col>
						<Col md={1} className={"filterSearchBoxCol"}>
							<Button
								floating
								size="sm"
								color={"indigo"}
								data-tip={"Search Contacts"}
								onClick={(e) => {
									this.filterClicked(e);
								}}
							>
								<MDBIcon icon="search" style={{ fontSize: "2em" }} />
							</Button>
						</Col>
						<ReactTooltip />
					</Row>
				</CardBody>
			</Card>
		);
	}

	handleLocationChange = (e) => {
		this.setState({
			locationsSelected: e,
		});
		this.renderSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			salesRepsSelected: e,
		});
	};

	handleSearchInput(e) {}

	filterClicked = (e) => {
		e.preventDefault();
		this.setState({
			isLoaded: false,
		});
		this.retrieveContacts();
	};

	render() {
		return (
			<div style={{ marginLeft: "5%", marginRight: "5%" }}>
				{this.renderFilter()}
				<Card style={{ marginBottom: "1%" }}>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<Button
					disabled={!this.state.isLoaded}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.exportClicked}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
