import api from "../api";

class ServiceOrderReasonService {
	//region patient issues
	async getAllServiceOrderReasons() {
		return new Promise((res, reject) => {
			api
				.get("/serviceOrderReason")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getSpecificServiceOrderReason(id) {
		return new Promise((res, reject) => {
			api
				.get("/serviceOrderReason/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createServiceOrderReason(issue) {
		return new Promise((res, reject) => {
			api
				.post("/serviceOrderReason", issue)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateServiceOrderReason(issue) {
		return new Promise((res, reject) => {
			api
				.put("/serviceOrderReason/" + issue.id, issue)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
	//endregion

	async getServiceOrderReasonViewNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/serviceOrderReasonNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const serviceOrderReasonService = new ServiceOrderReasonService();

export default serviceOrderReasonService;
