import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Fa,
	Input,
	MDBBtn,
	MDBIcon,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
	Spinner,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import "./deliveries.css";
import OrderService from "../Security/OrderService/orderService";
import PropTypes from "prop-types";
import {
	Grid,
	Table,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	TableColumnVisibility,
	TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import {
	FilteringState,
	GroupingState,
	IntegratedFiltering,
	IntegratedGrouping,
	IntegratedPaging,
	IntegratedSorting,
	PagingState,
	SortingState,
} from "@devexpress/dx-react-grid";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import { FormLabel } from "@material-ui/core";
import DateTimePicker from "material-ui-pickers/DateTimePicker";
import MomentUtils from "@date-io/moment";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import * as moment from "moment";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import Select from "react-select";

// region Constants

const ItemCounter = () => (
	<Plugin name="ItemCounter">
		<Template
			name="tableCell"
			predicate={({ tableRow }) => isGroupTableRow(tableRow)}
		>
			{(params) => (
				<TemplateConnector>
					{({ getCollapsedRows }) => {
						const updatedParams = {
							...params,
							tableRow: {
								...params.tableRow,
								row: {
									...params.tableRow.row,
									collapsedRows: getCollapsedRows(params.tableRow.row) || [],
								},
							},
						};
						return <TemplatePlaceholder params={updatedParams} />;
					}}
				</TemplateConnector>
			)}
		</Template>
	</Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

function getVal(row) {
	if (row.collapsedRows.length > 0) {
		return "Count: " + row.collapsedRows.length;
	} else {
		return "";
	}
}

const Content = ({ row, column }) => (
	<span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
	</span>
);

const compareDate = (a, b) => {
	const priorityA = new Date(a);
	const priorityB = new Date(b);
	if (priorityA === priorityB) {
		return 0;
	}
	return priorityA < priorityB ? -1 : 1;
};
// endregion

export default class ScheduledDeliveries extends React.Component {
	constructor(props) {
		super(props);

		let id = [];

		if (props.match && props.match.params) {
			id.push(props.match.params.id);
		}

		const data1 = {
			columns: [
				{
					title: "ID",
					name: "id",
				},
				{
					title: "Patient",
					name: "patient",
				},
				{
					title: "Order Type",
					name: "orderType",
				},
				{
					title: "Equipment",
					name: "equipment",
				},
				{
					title: "Address",
					name: "address",
				},
				{
					title: "City",
					name: "city",
				},
				{
					title: "Zip Code",
					name: "zipCode",
				},
				{
					title: "Phone",
					name: "phone",
				},
				{
					title: "Last Note Date",
					name: "lastNoteDate",
				},
				{
					title: "Delivery Date",
					name: "date",
				},
				{
					title: "Delivery Time",
					name: "deliveryTime",
				},
				{
					title: "Tech",
					name: "tech",
				},
			],
			groupSummaryItems: [{ columnName: "tech", type: "sum" }],
			integratedSortingColumnExtensions: [
				{ columnName: "date", compare: compareDate },
			],
			defaultHiddenColumnNames: ["id"],
			rows: [],
		};

		this.state = {
			isLoaded: false,
			rootData: [],
			dataSet1: data1,
			userLocations: [],
			selectedLocationIds: id,
			locationList: [],
			filterParams: [],
			editRow: {},
			toggleModal: false,
			errorMessage: "",
			techs: [],
			deliveryWindow: null,
			tooltipContent: "",
			noteModalOpen: false,
			taskCompleted: true,
			noteAssignedTo: null,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allLocations: PropTypes.array,
		productTypes: PropTypes.array,
		techs: PropTypes.array,
		allUsers: PropTypes.array,
	};

	componentDidMount() {
		this.getUserLocations();
		this.getLocationOptions();
		if (this.state.selectedLocationIds) {
			this.retrieveDeliveries();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { allLocations } = this.context;

		if (allLocations.length > 0 && this.state.locationList.length === 0) {
			this.getUserLocations();
			this.getLocationOptions();
		}
	}

	// region Locations
	getUserLocations() {
		const { currentUser, allLocations } = this.context;

		let locations = [];

		if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT"
		) {
			locations = allLocations;
		} else {
			locations = currentUser.locations;
		}

		this.setState({
			userLocations: locations,
		});
	}

	getLocationOptions() {
		let locations = this.state.userLocations,
			a = [];

		locations.map((loc) => {
			return a.push({
				disabled: false,
				text: loc.name,
				value: loc.id.toString(),
			});
		});

		this.setState({
			locationList: a,
		});
	}

	// endregion

	retrieveDeliveries() {
		let ids = this.state.selectedLocationIds;

		if (ids.length > 0) {
			this.setState({
				isLoaded: false,
			});

			let f = {
				statuses: "ReadyToDeliver",
				internalLocations: ids,
				pageSize: 1000,
			};

			return OrderService.getOrdersWithLocationsDeliveryDate(f)
				.then((res) => {
					this.buildDataSets(res.content);
				})
				.catch((err) => {
					console.log(err);
					this.setState({ isLoaded: true });
				});
		}
	}

	formatDate(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
		}).format(date);
	}

	formatTime(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			hour: "numeric",
			minute: "numeric",
			second: "2-digit",
		}).format(date);
	}

	getUserList() {
		const { allUsers } = this.context;
		let a = [];
		a.push({
			label: "Select User...",
			value: "",
		});
		allUsers.map((user) => {
			return a.push({
				label: user.username,
				value: user,
			});
		});
		return a;
	}

	buildDataSets(data) {
		let d1 = this.state.dataSet1;

		d1.rows = [];

		data
			.filter((order) => {
				return (
					order.scheduledDeliveryDate !== null && order.technician !== null
				);
			})
			.map((item) => {
				let equip = item.products.map((prod) => prod.productType.name);
				let temp = [...new Set(equip)].join(", ").toString();
				return d1.rows.push({
					id: item.id,
					patient:
						item.patient !== null
							? item.patient.firstName + " " + item.patient.lastName
							: "",
					orderType: item.type,
					equipment: temp,
					address: item.address + "\n" + item.city + ", " + item.state,
					city: item.city,
					zipCode: item.zipCode,
					phone: item.phone || "",
					lastNoteDate: this.formatDate(item.lastNoteDate),
					date: this.formatDate(item.scheduledDeliveryDate),
					deliveryTime: this.formatTime(item.scheduledDeliveryDate),
					tech: item.technician.name,
				});
			});

		this.setState({ dataSet1: d1, rootData: data, isLoaded: true });
	}

	handleDeliveryDate(e) {
		let row = this.state.editRow,
			date = parseInt(e.format("x"));
		row.scheduledDeliveryDate = date;
		this.setState({ editRow: row });
	}

	handleTechChange(e) {
		let row = this.state.editRow;

		row.technician = {
			id: e.id,
			name: e.username,
		};

		this.setState({ editRow: row });
	}

	updateOrder() {
		let row = this.state.editRow;
		if (row.id) {
			OrderService.updateOrder(row)
				.then(() => {
					this.toggleEditModal();
					toast.success("Saved Successfully!");
					this.fullSearch();
				})
				.catch((err) => {
					console.log(err);
					this.setState({ isLoaded: true, notScheduledLoaded: true });
				});
		}
	}

	handleNoteUserSelected = (e) => {
		let user = { name: e.value.username, id: e.value.id };
		this.setState({
			noteAssignedTo: user,
		});
	};

	addNewNote() {
		//get the current user context that can be passed into the note DTO...BC
		const { currentUser } = this.context;
		let cb = { id: currentUser.id, name: currentUser.username };

		let note = {
			task: this.state.taskCompleted,
			completed: this.state.taskCompleted,
			assignedTo: this.state.noteAssignedTo,
			createdAt: new Date(),
			createdBy: cb,
			account: this.state.editRow.account,
			patientName: this.state.editRow.patientName,
		};

		note.text = document.getElementById("newActivityNoteText").value;

		return NoteService.createActivityNote(this.state.editRow.id, note)
			.then((res) => {
				this.toggleNoteModalPopup();

				let rows = this.state.dataSet1;
				rows.rows.map((r) => {
					if (r.id === this.state.editRow.id) {
						r.lastNoteDate = this.formatDate(res.createdAt);
					}
					return null;
				});

				this.setState({ dataSet1: rows });
			})
			.catch((err) => {
				toast.error("There was an error saving the note.");
				console.log(err);
			});
	}

	toggleEditModal() {
		this.setState({ toggleModal: !this.state.toggleModal });
	}

	toggleNoteModalPopup() {
		let currentState = this.state.noteModalOpen;
		this.setState({ noteModalOpen: !currentState });
	}

	taskCompletedChange = (value) => {
		this.setState({ taskCompleted: value });
	};

	formatToolTip(result) {
		if (result == null) {
			return;
		}

		let strResult = "",
			count = result.length >= 2 ? 2 : result.length;
		if (result !== null && count > 0) {
			for (let i = 0; i < count; i++) {
				strResult +=
					"\nUser: " +
					result[i].createdBy.name +
					"\n" +
					"Date: " +
					this.formatDate(result[i].createdAt) +
					"\n" +
					"Note: " +
					result[i].text;

				if (i < count - 1) {
					strResult += "\n----------------------";
				}
			}
		}
		return strResult;
	}

	setTooltipContent(id) {
		NoteService.getNotes(id)
			.then((res) => {
				this.setState({ tooltipContent: res.content });
				return res.content;
			})
			.catch((err) => {
							console.log(err);
						});
	}

	renderEditOrderModal(id) {
		let row = this.state.rootData.find((item) => {
			return item.id === id;
		});
		if (row) {
			this.setState({ editRow: row, selectedTech: row.technician || {} });
			this.toggleEditModal();
		} else {
			toast.warn("Could not locate the order.");
		}
	}

	renderTechnicianDropdown() {
		const { techs } = this.context;

		let ids = this.state.selectedLocationIds;
		let tps = techs.filter((tech) => {
			return tech.locations
				.map((loc) => loc.id.toString())
				.includes(ids[0].toString());
		});

		return (
			<Dropdown className={"idDropdown idDropdownClinic"}>
				<DropdownToggle className={"idDropdownToggle"} nav>
					Technician
					<span style={{ float: "right" }}>
						{this.state.editRow.technician
							? this.state.editRow.technician.name
							: "No Technician"}
						&nbsp;
						<Fa icon={"angle-down"} />
					</span>
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={this.handleTechChange.bind(this, null)}>
						Choose Technician
					</DropdownItem>
					<DropdownItem divider />
					{tps.map((tech, index) => {
						return (
							<DropdownItem
								onClick={this.handleTechChange.bind(this, tech)}
								key={index}
							>
								{tech.username} &nbsp; -- {tech.firstname + " " + tech.lastname}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</Dropdown>
		);
	}

	renderModal() {
		let dataRow = this.state.editRow;
		if (dataRow.id) {
			return (
				<Modal
					size="lg"
					centered
					isOpen={this.state.toggleModal}
					toggle={() => {
						return;
					}}
				>
					<ReactTooltip />
					<ModalBody>
						<CardHeader className="form-header text-center font-weight-bold blue-gradient">
							<h3>Edit Order</h3>
						</CardHeader>
						<Row>
							<Col md={"12"}>
								<h6 style={{ color: "red" }}>{this.state.errorMessage}</h6>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<FormLabel style={{ color: "black" }}>
									Order #: {dataRow ? dataRow.id : ""}
								</FormLabel>
							</Col>
							<Col md="6">
								<FormLabel style={{ color: "black" }}>
									Patient:{" "}
									{dataRow && dataRow.patient
										? dataRow.patient.firstName + " " + dataRow.patient.lastName
										: ""}
								</FormLabel>
							</Col>
						</Row>
						<Row>
							<Col md={"5"}></Col>
							<Col md={"7"}>
								<FormLabel style={{ color: "black" }}>
									Phone: {dataRow && dataRow.phone ? dataRow.phone : "No Phone"}
								</FormLabel>
							</Col>
						</Row>
						<Row>
							<hr />
						</Row>
						<Row>
							<Col md={"12"}>
								<MuiPickersUtilsProvider utils={MomentUtils}>
									<DateTimePicker
										keyboard
										value={
											dataRow.scheduledDeliveryDate
												? new moment(dataRow.scheduledDeliveryDate)
												: null
										}
										onChange={this.handleDeliveryDate.bind(this)}
									/>
								</MuiPickersUtilsProvider>
							</Col>
						</Row>
						<Row>
							<hr />
						</Row>
						<Row>
							<Col md={"2"}>
								<MDBBtn
									floating
									size="sm"
									data-tip={"Add note"}
									color={"indigo"}
									onClick={() => this.toggleNoteModalPopup()}
								>
									<MDBIcon icon="plus" />
								</MDBBtn>
							</Col>
							<Col md={"10"} style={{ color: "#000" }}>
								{this.renderTechnicianDropdown()}
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<MDBBtn color="primary" onClick={this.updateOrder.bind(this)}>
							Save
						</MDBBtn>
						<MDBBtn color="secondary" onClick={() => this.toggleEditModal()}>
							Close
						</MDBBtn>
					</ModalFooter>
				</Modal>
			);
		} else {
			return <div></div>;
		}
	}

	renderNoteModal() {
		return (
			<Modal
				size="lg"
				centered
				isOpen={this.state.noteModalOpen}
				toggle={() => this.toggleNoteModalPopup()}
			>
				<ModalBody style={{ backgroundColor: "#FFFFFF" }} className="mx-3">
					<CardHeader color={"indigo"} className="form-header text-center">
						Add New Note
					</CardHeader>
					<form className=" mx-3 grey-text">
						<textarea
							placeholder={"Add note text here"}
							id={"newActivityNoteText"}
							style={{ width: "100%", height: "200px" }}
						></textarea>
						<Row>
							<Col size="6">
								<Input
									type="radio"
									gap
									label={"Note"}
									checked={this.state.taskCompleted}
									onClick={this.taskCompletedChange.bind(this, true)}
									id={"noteRadio"}
								/>
							</Col>

							<Col size="6">
								<Input
									type="radio"
									gap
									label={"Task"}
									checked={!this.state.taskCompleted}
									onClick={this.taskCompletedChange.bind(this, false)}
									id={"taskRadio"}
								/>
							</Col>
						</Row>
						<Row>
							<label> Assigned to (optional) </label>
						</Row>
						<Row>
							<Col size="12">
								<Select
									placeholder={
										this.state.noteAssignedTo
											? this.state.noteAssignedTo.name
											: "Select User..."
									}
									options={this.getUserList()}
									onChange={this.handleNoteUserSelected.bind(this)}
								/>
							</Col>
						</Row>
					</form>
				</ModalBody>
				<ModalFooter>
					<MDBBtn color="primary" onClick={this.addNewNote.bind(this)}>
						Add Note
					</MDBBtn>
					<MDBBtn color="secondary" onClick={() => this.toggleNoteModalPopup()}>
						Close
					</MDBBtn>
				</ModalFooter>
			</Modal>
		);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderScheduled() {
		let loc = this.state.userLocations.find(
			(item) =>
				item.id.toString() === this.state.selectedLocationIds[0].toString()
		);

		const CellWithTooltip = ({ value, style, ...restProps }) => {
			return (
				<Table.Cell
					data-tip
					data-event={"click focus"}
					data-for={"notes"}
					onMouseEnter={() => this.setTooltipContent(restProps.tableRow.row.id)}
					style={{
						cursor: "pointer",
					}}
				>
					{value}
					<ReactTooltip
						className={"note-tooltip"}
						id={"notes"}
						place={"left"}
						delayHide={0}
						globalEventOff={"click"}
						effect={"solid"}
						getContent={() => (
							<p> {this.formatToolTip(this.state.tooltipContent)} </p>
						)}
					/>
				</Table.Cell>
			);
		};

		const Cell = ({ row, column, ...props }) => {
			if (column.name === "lastNoteDate" && row.lastNoteDate) {
				return <CellWithTooltip {...props} />;
			} else {
				return (
					<Table.Cell
						{...props}
						onClick={() => {
							this.renderEditOrderModal(row.id);
						}}
						style={{
							cursor: "pointer",
						}}
					/>
				);
			}
		};

		return (
			<Card style={{ height: "100vh" }}>
				<CardBody>
					<div style={{ paddingTop: "6px", paddingBottom: "6px" }}>
						<Row>
							<Col md={"10"}>
								<h5>Scheduled\Assigned{loc ? " - " + loc.name : ""}:</h5>
							</Col>
							<Col md={"2"}>
								<Button
									color={"indigo"}
									floating
									size="sm"
									data-tip={"Refresh Deliveries"}
									onClick={this.retrieveDeliveries.bind(this)}
								>
									<Fa size="2x" icon="sync">
										{" "}
									</Fa>
								</Button>
								<ReactTooltip />
							</Col>
						</Row>
						<hr style={{ borderColor: "#555555", borderWidth: "3px" }} />
					</div>
					<div>
						{this.state.isLoaded ? (
							<Row className={"tableHost"}>
								<Grid
									style={{ maxWidth: "1800px !important", width: "99%" }}
									rows={this.state.dataSet1.rows}
									rootComponent={Root}
									columns={this.state.dataSet1.columns}
								>
									<DragDropProvider />
									<SortingState
										defaultSorting={[{ columnName: "date", direction: "asc" }]}
									/>
									<IntegratedSorting
										columnExtensions={
											this.state.dataSet1.integratedSortingColumnExtensions
										}
									/>
									<GroupingState defaultGrouping={[{ columnName: "tech" }]} />
									<IntegratedGrouping />
									<FilteringState />
									<IntegratedFiltering />
									<PagingState defaultCurrentPage={0} pageSize={10} />
									<IntegratedPaging />
									<Table cellComponent={Cell} />
									<TableHeaderRow showSortingControls />
									<TableFilterRow />
									<TableColumnVisibility
										defaultHiddenColumnNames={
											this.state.dataSet1.defaultHiddenColumnNames
										}
									/>
									<PagingPanel />
									<TableGroupRow
										contentComponent={Content}
										showColumnsWhenGrouped
									/>
									<ItemCounter />
									<Toolbar />
									<GroupingPanel />
								</Grid>
							</Row>
						) : this.state.selectedLocationIds.length > 0 ? (
							this.renderLoadingSpinner()
						) : (
							""
						)}
					</div>
				</CardBody>
			</Card>
		);
	}

	render() {
		return (
			<div>
				{this.renderModal()}
				{this.renderNoteModal()}
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<Row>
					<Col md="12">
						<div style={{ display: "flex", justifyContent: "space-around" }}>
							<div
								style={{ width: "92%", maxWidth: "92%", marginBottom: "1%" }}
								className="mt-2"
							>
								{this.renderScheduled()}
							</div>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}
