import React from "react";
import PropTypes from "prop-types";
import {
	Container,
	Spinner,
	Col,
	CardHeader,
	Button,
	MDBDatePicker,
	Row,
	toast,
	ToastContainer,
	MDBIcon,
	DataTable,
} from "mdbreact";
import Select from "react-select";
import QuickFilter from "../Filters/quickFilter";
import GroupService from "../Security/UserService/groupService";
import ReactTooltip from "react-tooltip";
import ReverseQualityService from "./reverseQualityService";

export default class ReverseQualityList extends React.Component {
	constructor(props) {
		super(props);

		const devData = {
			columns: [
				{
					label: "Order Id",
					field: "orderId",
					width: 150,
				},
				{
					label: "Reported By",
					field: "reportedBy",
					width: 200,
				},
				{
					label: "Reported On",
					field: "reportedOn",
					width: 150,
				},
				{
					label: "User Reported",
					field: "userReported",
					width: 200,
				},
				{
					label: "Reverse Quality Category",
					field: "reverseQualityCategory",
					width: 250,
				},
				{
					label: "Notes",
					field: "notes",
					width: 500,
				},
			],
			rows: [],
		};

		const columnHeaders = [
			"orderId",
			"reportedBy",
			"reportedOn",
			"userReported",
			"reverseQualityCategory",
			"notes",
		];

		this.state = {
			devData: devData,
			columnHeaders: columnHeaders,
			isLoaded: true,
			filters: {},
			addExpenseModal: false,
			denyModalOpen: false,
			errorMessage: [],
			allLocs: [],
			allUsers: [],
			locationsSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			allUsersSelect: [
				{
					label: "Loading...",
					value: "loading",
				},
			],
			allUsersSelected: [],
			locationsSelected: [],
			showDatePicker: true,
			quickFilter: [],
			quickFilterSelected: null,
			startDate: new Date(),
			endDate: new Date(),
			rawData: [],
			skip: 0,
			rowData: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allLocations: PropTypes.array,
		allUsers: PropTypes.array,
	};

	componentDidMount() {
		this.renderLocationsOptions();
	}

	componentDidUpdate() {
		const { currentUser, allLocations } = this.context;
		let st = this.state;
		if (
			st.allLocs.length !== allLocations.length &&
			st.currUser !== currentUser
		) {
			this.renderLocationsOptions();
		}
	}

	renderSalesRepsOptions(e) {
		const { allUsers, currentUser } = this.context;
		let options = [];
		if (e === undefined || e.length === 0) {
			if (
				currentUser.role === "ADMIN" ||
				currentUser.role === "SYSTEM_ADMIN" ||
				currentUser.role === "EXECUTIVE_MANAGEMENT"
			) {
				allUsers.map((rep) => {
					return options.push({
						label: rep.username,
						value: rep.id,
					});
				});
			}
			this.setState({
				allUsersSelect: options,
				allUsers: allUsers,
			});
		} else {
			let salesList = [];
			let locs = [];
			e.forEach((location) => {
				locs.push(location.value);
			});
			return GroupService.getAllUsersByLocations(locs)
				.then((res) => {
					res.forEach((t) => {
						if (salesList.indexOf(t.id) === -1) {
							options.push({
								label: t.username,
								value: t.id,
							});
						}
						salesList.push(t.id);
					});

					this.setState({
						allUsersSelect: options,
						allUsersSelected: options,
					});
				})
				.catch((e) => {});
		}
	}

	renderLocationsOptions() {
		const { allLocations, currentUser } = this.context;
		let cu = currentUser;
		let select = [],
			locations = [];
		if (
			cu.role === "ADMIN" ||
			cu.role === "SYSTEM_ADMIN" ||
			cu.role === "EXECUTIVE_MANAGEMENT" ||
			currentUser.role === "FINANCE"
		) {
			locations = allLocations;
		} else {
			locations = cu.locations;
		}
		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === "Internal") {
				return select.push({
					label: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}

	handleLocationChange = (e) => {
		this.setState({
			locationsSelected: e,
		});
		this.renderSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			allUsersSelected: e,
		});
	};

	handleQuickFilter = (e) => {
		let a = QuickFilter.getDates(e.value),
			startDate = "",
			endDate = "";

		if (a.length > 0) {
			startDate = a[0];
			endDate = a[1];
		}

		this.setState({
			quickFilter: a,
			quickFilterSelected: e,
			startDate: startDate,
			endDate: endDate,
		});
	};

	formatRows(rqLogs) {
		let ary = [],
			dt = this.state.devData;

		rqLogs.forEach((log) => {
			ary.push({
				orderId: log.orderId,
				reportedBy: log.reportedBy,
				reportedOn: log.reportedOn,
				userReported: log.userReported,
				reverseQualityCategory: log.reverseQualityCategory,
				notes: log.notes,
			});
		});

		dt.rows = ary;

		this.setState({
			devData: dt,
			isLoaded: true,
		});
	}

	getInternalLocations() {
		const { internalLocations, currentUser } = this.context;

		let ary = [],
			role = currentUser.role;

		ary.push({
			label: "Choose a Location...",
			value: "",
		});

		switch (role) {
			case "SYSTEM_ADMIN":
			case "ADMIN":
			case "EXECUTIVE_MANAGEMENT":
			case "FINANCE":
				internalLocations.map((location) => {
					return ary.push({
						label: location.name,
						value: location,
					});
				});
				break;
			case "SALES_MANAGER":
				if (currentUser.locations) {
					currentUser.locations.map((location) => {
						if (location.type === "Internal") {
							return ary.push({
								label: location.name,
								value: location,
							});
						}
						return null;
					});
				}
				break;
			default:
				break;
		}
		return ary;
	}

	selectLocation(location) {
		let nFilters = this.state.filters;
		nFilters.locationId = location.value.id;
		this.setState({ filters: nFilters });
	}

	filterOnLocation() {
		let st = this.state;
		if (
			st.startDate == null ||
			st.startDate === "" ||
			st.endDate == null ||
			st.endDate === ""
		) {
			toast.error("Please check your date filters");
			return;
		}

		this.setState({ isLoaded: false });

		this.getReverseQualityLogs();
	}

	getReverseQualityLogs() {
		let f = {
			startDate: new Date(this.state.startDate).toLocaleDateString(),
			endDate: new Date(this.state.endDate).toLocaleDateString(),
		};

		if (this.state.allUsersSelected.length > 0) {
			let ids = [];

			this.state.allUsersSelected.forEach((u) => {
				ids.push(u.value);
			});

			f.ids = ids;
		}

		ReverseQualityService.getAllLogsNative(f).then((r) => {
			this.formatRows(r);
		});
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div
				className={"expensesGrid orderList"}
				style={{ marginBottom: "1rem" }}
			>
				{this.renderDevTable()}
			</div>
		);
	}

	renderDevTable() {
		let filtersData = {
			users: this.state.allUsersSelected,
			locations: this.state.locationsSelected,
			currentPage: this.state.currentPage,
			totalCount: this.state.totalCount,
		};

		//build filters to send over
		if (this.state.showDatePicker) {
			filtersData.startDate = this.state.startDate;
			filtersData.endDate = this.state.endDate;
		} else {
			filtersData.quickFilter = this.state.quickFilter;
			filtersData.quickFilterSelected = this.state.quickFilterSelected;
		}

		return (
			<DataTable
				entriesOptions={[25, 50, 100]}
				pagesAmount={10}
				data={this.state.devData}
				responsive
				fixed
				striped
				bordered
			></DataTable>
		);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	handleDatePickerChange = (property, value) => {
		this.setState({
			[property]: value,
		});
	};

	renderLocationsFilter() {
		const { currentUser } = this.context;
		let role = currentUser.role;

		switch (role) {
			case "SALES":
			case "OFFICE_MANAGER":
			case "OFFICE_SUPPORT":
			case "CARE_COORDINATOR":
			case "CALL_CENTER":
			case "TECHNICIAN":
			case "DATA_EXPORT":
			case "VERIFICATION":
			case "FOLLOWUP":
			case "COLLECTIONS":
			case "BILLING":
			case "PURCHASING":
			case "FINANCE":
			case "SALES_MANAGER":
			default:
				return <div />;
			case "SYSTEM_ADMIN":
			case "ADMIN":
			case "EXECUTIVE_MANAGEMENT":
				return (
					<Row>
						<Col md={4} style={{ marginTop: 5 }}>
							<Select
								placeholder="Select Location"
								isMulti
								options={this.state.locationsSelect}
								onChange={this.handleLocationChange.bind(this)}
								value={this.state.locationsSelected}
							/>
						</Col>
						<Col md={8} style={{ marginTop: 5 }}>
							<Select
								placeholder="Select User"
								closeMenuOnSelect={false}
								isMulti
								options={this.state.allUsersSelect}
								onChange={this.handleSaleRepChange.bind(this)}
								value={this.state.allUsersSelected}
							/>
						</Col>
					</Row>
				);
		}
	}

	//call to download data
	clientCSV(stateData) {
		let result = "",
			ctr = 0,
			keys = this.state.columnHeaders,
			columnDelimiter = ",",
			lineDelimiter = "\n";

		if (stateData == null || !stateData.length) {
			return null;
		}

		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		stateData.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (key === "notes" && item[key] != null) {
					item[key] = item[key].replace(/,/g, " ");
				}

				result +=
					item[key] === null || item[key] === undefined ? "" : item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, "rqLogs.csv");
	}

	//csv downlaod data here
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	render() {
		return (
			<div style={{ marginLeft: "4%", marginRight: "4%" }}>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				<CardHeader style={{ backgroundColor: "#778899" }}>
					<Row>
						<Col md={10} style={{ color: "#000" }}>
							{this.renderLocationsFilter()}
						</Col>

						<Col md={2}>
							<Button
								floating
								size="sm"
								color={"indigo"}
								data-tip={"Search"}
								onClick={() => {
									this.filterOnLocation();
								}}
							>
								<MDBIcon icon="search-plus" style={{ fontSize: "2em" }} />
							</Button>
						</Col>

						<Col md={3} style={{ marginTop: 5, color: "#000" }}>
							<Select
								placeholder="Quick Filter"
								options={QuickFilter.getOptions()}
								onChange={this.handleQuickFilter.bind(this)}
								value={this.state.quickFilterSelected}
							/>
						</Col>

						<Row>
							<Col md={5} style={{ color: "#FFF" }}>
								<div className={"expenseDateOptions"}>
									<MDBDatePicker
										className={"openDatePicker"}
										label={"Start Date"}
										showTodayButton
										autoOk
										allowKeyboardControl
										keyboard={true}
										okLabel={false}
										value={this.state.startDate}
										getValue={this.handleDatePickerChange.bind(
											this,
											"startDate"
										)}
									/>
								</div>
							</Col>
							<Col md={5}>
								<div className={"expenseDateOptions"}>
									<MDBDatePicker
										className={"openDatePicker"}
										label={"End Date"}
										showTodayButton
										autoOk
										allowKeyboardControl
										keyboard={true}
										okLabel={false}
										value={this.state.endDate}
										getValue={this.handleDatePickerChange.bind(this, "endDate")}
									/>
								</div>
							</Col>
						</Row>
					</Row>
				</CardHeader>
				<Row style={{ zIndex: 0 }}>
					<Col style={{ zIndex: 0 }}>{this.renderTableOrSpinner()}</Col>
				</Row>
				<div className={"fixed-bottom downloadCsvDiv"}>
					<Button
						className={"downloadCsvButton"}
						floating
						size="sm"
						color={"indigo"}
						data-tip={"Download CSV"}
						onClick={() => this.clientCSV(this.state.devData.rows)}
					>
						<MDBIcon icon="download" style={{ fontSize: "2em" }} />
					</Button>

					<ReactTooltip />
				</div>
			</div>
		);
	}
}
