import api from "../api";

class UserService {
	async getAllUsers() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/users", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllTherapists() {
		return new Promise((res, reject) => {
			api
				.get("/findTherapists")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllSalesReps() {
		return new Promise((res, reject) => {
			api
				.get("/findSalesReps")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	getAllTechs() {
		return new Promise((res, reject) => {
			api
				.get("/findTechs")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateUser(user) {
		return new Promise((res, reject) => {
			api
				.put("/users/" + user.id, user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async clearTokens(user){
		return new Promise((res, reject) => {
			api.delete("/usersTokens/" + user.id).then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async changePassword(params) {
		return new Promise((res, reject) => {
			api
				.put("/changePassword", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateUserAndSendEmail(user) {
		return new Promise((res, reject) => {
			api
				.put("/usersUpdateAndEmail/" + user.id, user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getUser(id) {
		return new Promise((res, reject) => {
			api
				.get("/users/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getUserGroups() {
		return new Promise((res, reject) => {
			api
				.get("/users/groups")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createUser(user) {
		return new Promise((res, reject) => {
			api
				.post("/users", user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAllUsers() {
		let params = {
			page: 0,
			size: 1000,
			tpUsers: false,
		};
		return new Promise((res, reject) => {
			api
				.get("/users", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAllActiveUsers() {
		return new Promise((res, reject) => {
			api
				.get("/spActiveUsersList")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findThirdPartyUsers() {
		let params = {
			page: 0,
			size: 1000,
		};
		return new Promise((res, reject) => {
			api
				.get("/findThirdPartyUsers", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createThirdPartyUser(user) {
		return new Promise((res, reject) => {
			api
				.post("/thirdPartyUsers", user)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getThirdPartyUser(id) {
		return new Promise((res, reject) => {
			api
				.get("/thirdPartyUsers/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async checkThirdPartyUser(contactId) {
		return new Promise((res, reject) => {
			api
				.get("/checkThirdPartyUsers/" + contactId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getContactsByAccount(id) {
		return new Promise((res, reject) => {
			let params = {
					page: 0,
					size: 25,
				},
				url = "/sources/" + id + "/contacts";

			return api
				.get(url, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async moveAccounts(accounts, newOwner) {
		return new Promise((res, reject) => {
			let params = {
				accounts: accounts || [],
				newOwnerId: newOwner.id || null,
			};

			return api
				.post("/move_accounts", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAccountsForUsers(id) {
		console.log(id);
		return new Promise((res, reject) => {
			return api
				.get("/users/" + id + "/accounts")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findUsersByLocation(id) {
		return new Promise((res, reject) => {
			return api
				.get("/findUsersByLocation/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

    async findTechsByLocation(id) {
        return new Promise((res,reject) => {
            return api.get('/findTechsByLocation/' + id).then(data => {
                res(data);
            }).catch( err => {
                reject();
            })
        })
    }

    async addUserReportFilters(filters){
        return new Promise((res,rej) => {
            return api.post('/userReportFilters', filters).then(data => {
                res(data);
            }).catch(err => {
                rej();
            })
        })
    }

    async removeUserReportFilter(id){
        return new Promise((res,rej) => {
            return api.delete('/userReportFilters/' + id).then(data => {
                res();
            }).catch(err => {
                rej();
            })
        })
    }
}

const userService = new UserService();

export default userService;
