import React from "react";
import {
	Input,
	MDBBtn,
	MDBCloseIcon,
	MDBCol,
	MDBIcon,
	MDBInput,
	MDBRow,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOption,
	MDBSelectOptions,
	MDBSpinner,
	Modal,
	ModalBody
} from "mdbreact";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import googleService from "../../Security/GoogleService/GoogleService";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputMask from "react-input-mask";

export default class PatientInformationTab extends React.Component {
	constructor(props) {
		super(props);

		this.updateParentObject = props.updateParentObject;
		this.saveParentObject = props.saveParentObject;
		this.updateTableRows = props.updateTableRows;

		this.state = {
			eOrder: props.eOrder,
			searchResults: [],
			googleModal: false,
			insuranceTypes: [],
			secondaryInsuranceTypes: [],
			filteredPrimaryInsuranceSubTypes: [],
			filteredSecondaryInsuranceSubTypes: [],
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.eOrder !== prevState.eOrder) {
			return { eOrder: nextProps.eOrder };
		} else return null;
	}

	static contextTypes = {
		insuranceTypes: PropTypes.array,
		insuranceSubTypes: PropTypes.array,
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.setInsuranceTypeLists();
	}

	// might use the validation stuff later...BC
	// renderInformationCard() {
	//     let item = this.state.eOrder.patient,
	//         od = this.state.eOrder,
	//         dob = 'No Date',
	//         invalidRegex = /([!@#$%^&*()><,.;:'"])/g;
	//
	//     if (item.dateOfBirth) {
	//         dob = moment.utc(new Date(item.dateOfBirth), 'MM/DD/YYYY');
	//     }
	//
	//     function errorMessage(val){
	//         if(val.match(invalidRegex)){
	//             return( <div style={{fontSize: '80%', color: 'red'}}>
	//                 Names can only include letters
	//             </div>)
	//         }
	//     }
	// }

	// region Google address search

	clearSearch() {
		this.setState({ searchResults: [] });
	}

	addressTextChanged(e) {
		let val = e.target.value,
			o = Object.assign({}, this.state.eOrder),
			p = Object.assign({}, o.patient);
		p.address = val;
		o.patient = p;
		this.setState({
			eOrder: o,
		});

		// let geocoderResultNode = document.getElementById('geocoderResult');
		// if (val) {
		//     this.setTimer(val)
		// } else {
		//     this.setState({searchResults: []});
		//     if (geocoderResultNode) {
		//         geocoderResultNode.innerHTML = ''
		//     }
		// }
	}

	setTimer(val) {
		if (this.state.timer) {
			clearInterval(this.state.timer);
		}
		let t = setInterval(() => {
			this.geoGoogle(val);
		}, 1000);
		this.setState({
			timer: t,
		});
	}

	geoGoogle(val) {
		if (val) {
			googleService.searchAddress(val).then((res) => {
				this.setState({ searchResults: res.results });
			});
		}
	}

	renderGoogleSearchResult() {
		let results = this.state.searchResults;
		if (results.length > 0) {
			return (
				<div className="resultWrap">
					{/*<div style={{width: '100%'}}>*/}
					{/*    <button id="popup-closer" className="ol-popup-closer" onClick={this.clearSearch.bind(this)}/>*/}
					{/*</div>*/}
					{/*<br/>*/}
					<ul id="geocoderResult">
						{results.map((item, index) => {
							if (
								index < 4 &&
								item.formatted_address &&
								item.formatted_address.length > 0
							) {
								return (
									<li key={results.indexOf(item)}>
										<button
											style={{
												border: "0",
												backgroundColor: "transparent",
												color: "#3887BE",
											}}
											onClick={this.setGoogleAddress.bind(this, item)}
										>
											{" "}
											{item.formatted_address}{" "}
										</button>
									</li>
								);
							}
							return null;
						})}
					</ul>
					<div className="loading hidden">
						<img
							src="https://samples.thinkgeo.com/cloud/example/image/Spinner-1s-50px.gif"
							alt="loading"
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	toggleGoogleSearch = () => {
		this.setState((state, props) => ({
			googleModal: !state.googleModal,
			searchResults: [],
		}));
	};

	googleAddressTextChanged(e) {
		let val = e.target.value,
			geocoderResultNode = document.getElementById("geocoderResult");
		if (val) {
			this.setTimer(val);
		} else {
			this.setState({ searchResults: [] });
			if (geocoderResultNode) {
				geocoderResultNode.innerHTML = "";
			}
		}
	}

	setGoogleAddress = (location) => {
		let order = this.state.eOrder,
			patient = order.patient;

		let addr = location.address_components;
		let numComp = "",
			streetComp = "";
		addr.forEach((item) => {
			if (item.types.includes("street_number")) {
				numComp = item.long_name;
			} else if (item.types.includes("route")) {
				streetComp = item.long_name;
			} else if (
				item.types.includes("neighborhood") ||
				item.types.includes("locality")
			) {
				patient.city = item.long_name;
			} else if (item.types.includes("administrative_area_level_1")) {
				patient.state = item.long_name;
			} else if (item.types.includes("postal_code")) {
				patient.zipCode = item.long_name;
			}
		});

		patient.address = numComp + " " + streetComp;
		patient.latitude = location.geometry.location.lat;
		patient.longitude = location.geometry.location.lng;
		order.patient = patient;
		this.setState({ eOrder: order, searchResults: [] });
		this.toggleGoogleSearch();
	};

	// endregion

	approvalAndUpdate(prop, val) {
		let eOrder = this.state.eOrder;

		eOrder.approvals[0][prop] = val;
		this.setState({ eOrder: eOrder });
		this.updateParentObject(eOrder);
	}

	setAndUpdate(prop, val) {
		let eOrder = this.state.eOrder;

		if (eOrder.hasOwnProperty(prop)) {
			eOrder[prop] = val;
		}

		if (eOrder.patient.hasOwnProperty(prop)) {
			eOrder.patient[prop] = val;
		}

		this.setState({ eOrder: eOrder });
		this.updateParentObject(eOrder);
	}

	setInsuranceTypeLists() {
		const { insuranceTypes } = this.context;

		let pAry = tSort(),
			sAry = tSort();

		function tSort() {
			let a = [];

			insuranceTypes.map((t) => {
				let obj = {
					name: t.name,
					id: parseInt(t.id),
				};
				return a.push(obj);
			});

			return a;
		}

		this.setState({
			insuranceTypes: pAry,
			secondaryInsuranceTypes: sAry,
		});
	}

	filterInsuranceSubTypes(secondary) {
		const { insuranceSubTypes } = this.context;
		let eOrder = this.state.eOrder,
			ary = [];

		if (!eOrder.insuranceType) {
			return;
		}

		insuranceSubTypes.map((t) => {
			let einst = secondary
				? eOrder.secondaryInsuranceType
				: eOrder.insuranceType;

			if (t.insuranceType.id === einst.id) {
				let obj = {
					text: t.name,
					value: {
						name: t.name,
						id: parseInt(t.id),
					},
				};

				if (secondary) {
					if (
						eOrder.secondaryInsuranceSubType &&
						eOrder.secondaryInsuranceSubType.id === t.id
					) {
						obj.checked = true;
					}
				} else if (
					eOrder.insuranceSubType &&
					eOrder.insuranceSubType.id === t.id
				) {
					obj.checked = true;
				}
				ary.push(obj);
			}
			return null;
		});

		if (secondary) {
			this.setState({
				filteredSecondaryInsuranceSubTypes: ary,
			});
		} else {
			this.setState({
				filteredPrimaryInsuranceSubTypes: ary,
			});
		}
	}

	changeInsuranceType(type, secondary) {
		let eOrder = this.state.eOrder,
			nv = null;

		if (type) {
			nv = { name: type.name, id: type.id };
		}

		if (secondary === true) {
			eOrder.secondaryInsuranceType = nv;
			eOrder.secondaryInsuranceSubType = null;
		} else {
			eOrder.insuranceType = nv;
			eOrder.insuranceSubType = null;
		}

		this.updateTableRows("insuranceChange");
		this.setState({ eOrder: eOrder });
		this.updateParentObject(eOrder);
	}

	changeInsuranceSubType(type, secondary) {
		let eOrder = this.state.eOrder,
			nv = null;

		if (type) {
			nv = { name: type.name, id: type.id };
		}

		if (secondary === true) {
			eOrder.secondaryInsuranceSubType = nv;
		} else {
			eOrder.insuranceSubType = nv;
		}

		this.setState({ eOrder: eOrder });
		this.updateParentObject(eOrder);
	}

	renderInfoSection() {
		let eOrder = this.state.eOrder;

		return (
			<div style={{ border: "1px solid #7986cb", margin: 8, padding: 15 }}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<MDBRow>
						<label className={"demographicsLabel labelPosition"}>Basic Info</label>

						<MDBCol size={"3"}>
							<MDBInput
								label={"First Name"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.firstName}
								outline
								onBlur={(e) => this.setAndUpdate("firstName", e.target.value)}
							/>
						</MDBCol>

						<MDBCol size={"3"}>
							<MDBInput
								label={"Middle Name"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.middleName}
								outline
								onBlur={(e) => this.setAndUpdate("middleName", e.target.value)}
							/>
						</MDBCol>

						<MDBCol size={"4"}>
							<MDBInput
								label={"Last Name"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.lastName}
								outline
								onBlur={(e) => this.setAndUpdate("lastName", e.target.value)}
							/>
						</MDBCol>

						<MDBCol size={"2"}>
							<MDBInput
								label={"Suffix"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.suffix}
								outline
								onBlur={(e) => this.setAndUpdate("suffix", e.target.value)}
							/>
						</MDBCol>
					</MDBRow>

					<MDBRow>
						<MDBCol size="3">
							<MDBInput
								type={"number"}
								label={"Height"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.height}
								outline
								onBlur={(e) => this.setAndUpdate("height", e.target.value)}
							/>
						</MDBCol>

						<MDBCol size="3">
							<MDBInput
								label={"Weight"}
								type={"number"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.weight}
								outline
								onBlur={(e) => this.setAndUpdate("weight", e.target.value)}
							/>
						</MDBCol>

						<MDBCol size="3">
							<MDBInput
								label={"Gender"}
								containerClass={"smallMargin"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.patient.gender}
								outline
								onBlur={(e) => this.setAndUpdate("gender", e.target.value)}
							/>
						</MDBCol>

						<MDBCol size={"3"} className={"smallMargin uiDateOutline"}>
							<DatePicker
								format="MM/DD/YYYY"
								size={"small"}
								inputVariant="outlined"
								label={"Date of Birth"}
								emptyLabel={"Date of Birth"}
								value={eOrder.patient.dateOfBirth || null}
								onChange={(date) => this.setAndUpdate("dateOfBirth", date)}
							/>
						</MDBCol>
					</MDBRow>

					{this.renderAddressSection()}
				</MuiPickersUtilsProvider>
			</div>
		);
	}

	renderEmergencyRelationshipSelect() {
		let eOrder = this.state.eOrder,
				patient = eOrder.patient,
				types = [
					"Spouse/Partner",
					"Sibling",
					"Child",
					"Parent",
					"Aunt/Uncle",
					"Other",
				];

		return (
			<MDBCol size="12" className={"uiDateOutline smallMargin"}>
				<TextField
					id="outlined-Primary-Insurance"
					select
					size={"small"}
					fullWidth
					label="Contact Relationship"
					value={eOrder.emergencyRelationship || patient.emergencyRelationship}
					onChange={(e) =>
						this.setAndUpdate("emergencyRelationship", e.target.value)
					}
					variant="outlined"
				>
					{types.map((option, idx) => {
						return (
							<MenuItem key={idx} value={types[idx]}>
								{option}
							</MenuItem>
						);
					})}
				</TextField>
			</MDBCol>
		);
	}

	renderContactSection() {
		let eOrder = this.state.eOrder,
			patient = eOrder.patient;

		return (
			<div style={{ border: "1px solid #7986cb", margin: 8, padding: 15 }}>
				<label className={"tabContactInfoLabel labelPosition"}>Contact Info</label>
				<MDBRow>
					<MDBCol size={"12"}>
						<InputMask
							mask="(999)-999-9999"
							value={eOrder.phone || patient.phone}
							onChange={(e) => this.setAndUpdate("phone", e.target.value)}
						>
							{(inputProps) => (
								<MDBInput
									outline
									containerClass={"smallMargin"}
									{...inputProps}
									label="Phone"
									style={{ paddingTop: 8 }}
								/>
							)}
						</InputMask>
					</MDBCol>

					<MDBCol size={"12"}>
						<MDBInput
							outline
							label={"Alternative Phone"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={patient.alternativePhone}
							onBlur={(e) =>
								this.setAndUpdate("alternativePhone", e.target.value)
							}
						/>
					</MDBCol>

					<MDBCol size={"12"}>
						<MDBInput
							outline
							containerClass={"smallMargin"}
							label={"Email"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.email || patient.email}
							onBlur={(e) => this.setAndUpdate("email", e.target.value)}
						/>
					</MDBCol>

					<MDBCol size="12">
						<MDBInput
							label={"Emergency Contact"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.emergencyContact || patient.emergencyContact}
							outline
							onBlur={(e) =>
								this.setAndUpdate("emergencyContact", e.target.value)
							}
						/>
					</MDBCol>

					<MDBCol size="12">
						<MDBInput
							label={"Emergency Contact Phone"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={
								eOrder.emergencyContactPhone || patient.emergencyContactPhone
							}
							outline
							onBlur={(e) =>
								this.setAndUpdate("emergencyContactPhone", e.target.value)
							}
						/>
					</MDBCol>

					{this.renderEmergencyRelationshipSelect()}
				</MDBRow>
			</div>
		);
	}

	renderAddressSection() {
		let eOrder = this.state.eOrder,
			patient = eOrder.patient;

		return (
			<div>
				<MDBRow>
					<MDBCol size={"2"}>
						<MDBBtn
							floating
							data-tip={"Edit Address"}
							style={{ marginTop: 0, marginLeft: 20 }}
							size={"sm"}
							onClick={this.toggleGoogleSearch}
						>
							<MDBIcon icon="map-marked-alt" />
						</MDBBtn>
					</MDBCol>

					<MDBCol size={"10"}>
						<MDBInput
							label={"Address"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.address || patient.address}
							outline
							onBlur={(e) => this.setAndUpdate("address", e.target.value)}
						/>
					</MDBCol>

					<MDBCol size={"5"}>
						<MDBInput
							label={"City"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.city || patient.city}
							outline
							onBlur={(e) => this.setAndUpdate("city", e.target.value)}
						/>
					</MDBCol>

					<MDBCol size={"4"}>
						<MDBInput
							label={"State"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.state || patient.state}
							outline
							onBlur={(e) => this.setAndUpdate("state", e.target.value)}
						/>
					</MDBCol>

					<MDBCol size={"3"}>
						<MDBInput
							label={"ZIP Code"}
							containerClass={"smallMargin"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.zipCode || patient.zipCode}
							outline
							onBlur={(e) => this.setAndUpdate("zipCode", e.target.value)}
						/>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderDiseaseSection() {
		let eOrder = this.state.eOrder,
			patient = eOrder.patient;

		return (
			<div
				style={{
					border: "1px solid #7986cb",
					margin: 8,
					padding: 15,
					marginTop: 20,
				}}
			>
				<label className={"tabDiseaseInfoLabel labelPosition"}>Disease Info</label>

				<MDBRow>
					<MDBCol size="3">
						<MDBInput
							label={"Infectious Disease"}
							containerClass={"smallMargin"}
							value={
								eOrder.hasInfectiousDisease || patient.hasInfectiousDisease
							}
							checked={
								eOrder.hasInfectiousDisease || patient.hasInfectiousDisease
							}
							data-tip={"Priority"}
							type="checkbox"
							onChange={(e) =>
								this.setAndUpdate(
									"hasInfectiousDisease",
									e.target.checked ? 1 : 0
								)
							}
							filled
							id="diseaseCheckbox"
						/>
					</MDBCol>

					<MDBCol size="9">
						<MDBInput
							outline
							containerClass={"smallMargin"}
							label={"Disease Notes"}
							style={{ paddingTop: 8 }}
							valueDefault={eOrder.diseaseNotes || patient.diseaseNotes}
							onBlur={(e) => this.setAndUpdate("diseaseNotes", e.target.value)}
						/>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderPrimarySection() {
		const { insuranceTypes, insuranceSubTypes } = this.context;

		let t = this;

		let eOrder = this.state.eOrder,
			sAry = insuranceSubTypes,
			sIndex = null;

		let index = insuranceTypes.find((it) => {
			if (eOrder.insuranceType) {
				return it.id === eOrder.insuranceType.id;
			}
			return it;
		});

		if (eOrder.insuranceType) {
			sAry = insuranceSubTypes.filter((ist) => {
				return ist.insuranceType.id === eOrder.insuranceType.id;
			});

			if (eOrder.insuranceSubType) {
				sIndex = sAry.find((sit) => {
					return sit.id === eOrder.insuranceSubType.id;
				});
			}
		}

		function renderInsuranceType() {
			if (insuranceTypes.length < 1) {
				return <MDBSpinner small />;
			}

			return (
				<MDBCol size="12" className={"uiOutlines"}>
					<TextField
						id="outlined-Primary-Insurance"
						select
						size={"small"}
						fullWidth
						label="Payor Source"
						value={index}
						onChange={(e) => t.changeInsuranceType(e.target.value)}
						variant="outlined"
					>
						{insuranceTypes.map((option, idx) => {
							return (
								<MenuItem key={idx} value={insuranceTypes[idx]}>
									{option.name}
								</MenuItem>
							);
						})}
					</TextField>
				</MDBCol>
			);
		}

		function renderInsuranceSubType() {
			if (sAry.length < 1) {
				return <MDBSpinner small />;
			}

			return (
				<MDBCol size="12" className={"uiOutlines"}>
					<TextField
						id="outlined-Primary-Insurance"
						select
						size={"small"}
						fullWidth
						label="Insurance"
						value={sIndex}
						onChange={(e) => t.changeInsuranceSubType(e.target.value)}
						variant="outlined"
					>
						{sAry.map((option, idx) => {
							return (
								<MenuItem key={idx} value={sAry[idx]}>
									{option.name}
								</MenuItem>
							);
						})}
					</TextField>
				</MDBCol>
			);
		}

		return (
			<div>
				<MDBRow
					style={{
						border: "1px solid #7986cb",
						margin: 8,
						padding: 15,
						marginTop: 20,
					}}
				>
					<label className={"insuranceLabel labelPosition"}>
						Primary Insurance
					</label>

					{renderInsuranceType()}

					{renderInsuranceSubType()}

					<MDBCol size={"12"} className={"insuranceSelects"}>
						<MDBInput
							label={"Policy Number"}
							style={{ paddingTop: 8 }}
							outline
							valueDefault={eOrder.insurancePolicy}
							disabled={this.state.eOrder.status === "Setup"}
							onBlur={(e) =>
								this.setAndUpdate("insurancePolicy", e.target.value)
							}
						/>
					</MDBCol>

					<MDBCol size={"12"} className={"insuranceSelects"}>
						<MDBInput
							label={"Group Number"}
							style={{ paddingTop: 8 }}
							outline
							valueDefault={eOrder.insuranceGroup}
							disabled={this.state.eOrder.status === "Setup"}
							onBlur={(e) =>
								this.setAndUpdate("insuranceGroup", e.target.value)
							}
						/>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderSecondarySection() {
		const { insuranceTypes, insuranceSubTypes } = this.context;

		let t = this;

		let eOrder = this.state.eOrder,
			sAry = insuranceSubTypes,
			sIndex = null;

		let index = insuranceTypes.find((it) => {
			if (eOrder.secondaryInsuranceType) {
				return it.id === eOrder.secondaryInsuranceType.id;
			}
			return it;
		});

		if (eOrder.secondaryInsuranceType) {
			sAry = insuranceSubTypes.filter((ist) => {
				return ist.insuranceType.id === eOrder.secondaryInsuranceType.id;
			});

			if (eOrder.secondaryInsuranceSubType) {
				sIndex = sAry.find((sit) => {
					return sit.id === eOrder.secondaryInsuranceSubType.id;
				});
			}
		}

		function renderSecondaryInsurance() {
			if (insuranceTypes.length < 1) {
				return <MDBSpinner small />;
			}

			return (
				<MDBCol size="12" className={"uiOutlines"}>
					<TextField
						id="outlined-Primary-Insurance"
						select
						size={"small"}
						fullWidth
						label="Payor Source"
						value={index}
						onChange={(e) => t.changeInsuranceType(e.target.value, true)}
						variant="outlined"
					>
						{insuranceTypes.map((option, idx) => {
							return (
								<MenuItem key={idx} value={option}>
									{option.name}
								</MenuItem>
							);
						})}
					</TextField>
				</MDBCol>
			);
		}

		function renderSecondarySubInsurance() {
			if (sAry.length < 1) {
				return <MDBSpinner small />;
			}

			return (
				<MDBCol size="12" className={"uiOutlines"}>
					<TextField
						id="outlined-Primary-Insurance"
						select
						size={"small"}
						fullWidth
						label="Insurance"
						value={sIndex}
						onChange={(e) => t.changeInsuranceSubType(e.target.value, true)}
						variant="outlined"
					>
						{sAry.map((option, idx) => {
							return (
								<MenuItem key={idx} value={sAry[idx]}>
									{option.name}
								</MenuItem>
							);
						})}
					</TextField>
				</MDBCol>
			);
		}

		return (
			<div>
				<MDBRow
					style={{
						border: "1px solid #7986cb",
						margin: 8,
						padding: 15,
						marginTop: 20,
					}}
				>
					<label className={"insuranceLabel labelPosition"}>
						Secondary Insurance
					</label>

					{renderSecondaryInsurance()}

					{renderSecondarySubInsurance()}

					<MDBCol size={"12"} className={"insuranceSelects"}>
						<MDBInput
							label={"Policy Number"}
							style={{ paddingTop: 8 }}
							outline
							valueDefault={eOrder.secondaryInsurancePolicy}
							disabled={this.state.eOrder.status === "Setup"}
							onBlur={(e) =>
								this.setAndUpdate("secondaryInsurancePolicy", e.target.value)
							}
						/>
					</MDBCol>

					<MDBCol size={"12"} className={"insuranceSelects"}>
						<MDBInput
							label={"Group Number"}
							style={{ paddingTop: 8 }}
							outline
							valueDefault={eOrder.secondaryInsuranceGroup}
							disabled={this.state.eOrder.status === "Setup"}
							onBlur={(e) =>
								this.setAndUpdate("secondaryInsuranceGroup", e.target.value)
							}
						/>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderSameSimSelect() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
				<MDBSelect
					getValue={(e) => this.approvalAndUpdate("sameOrSimilar", e[0])}
					outline
					label={"Same or Similar"}
					style={{ marginTop: ".6em" }}
				>
					<MDBSelectInput />
					<MDBSelectOptions>
						<MDBSelectOption
							selected={appr.sameOrSimilar === "No Same or Similar on File"}
						>
							No Same or Similar on File
						</MDBSelectOption>

						<MDBSelectOption
							selected={appr.sameOrSimilar === "Change in Condition"}
						>
							Change in Condition
						</MDBSelectOption>

						<MDBSelectOption selected={appr.sameOrSimilar === "Damage"}>
							Damage
						</MDBSelectOption>

						<MDBSelectOption
							selected={appr.sameOrSimilar === "Identified Patient in SNF"}
						>
							Identified Patient in SNF
						</MDBSelectOption>

						<MDBSelectOption
							selected={appr.sameOrSimilar === "Identified Same/Sim on File"}
						>
							Identified Same/Sim on File
						</MDBSelectOption>
					</MDBSelectOptions>
				</MDBSelect>
			</MDBCol>
		);
	}

	renderRentalPurchaseSelect() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
				<MDBSelect
					getValue={(e) => this.approvalAndUpdate("rental", e[0] === "Rental")}
					outline
					label={"Rental or Purchase"}
					style={{ marginTop: ".6em" }}
				>
					<MDBSelectInput />
					<MDBSelectOptions>
						<MDBSelectOption selected={appr.rental === true}>
							Rental
						</MDBSelectOption>

						<MDBSelectOption selected={appr.rental === false}>
							Purchase
						</MDBSelectOption>
					</MDBSelectOptions>
				</MDBSelect>
			</MDBCol>
		);
	}

	renderFinancialHardshipSelect() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
				<MDBSelect
					getValue={(e) =>
						this.approvalAndUpdate(
							"financialHardship",
							e[0] === "Patient Has Financial Hardship"
						)
					}
					outline
					label={"Financial Hardship"}
					style={{ marginTop: ".6em" }}
				>
					<MDBSelectInput />
					<MDBSelectOptions>
						<MDBSelectOption selected={appr.financialHardship === true}>
							Patient Has Financial Hardship
						</MDBSelectOption>

						<MDBSelectOption selected={appr.financialHardship === false}>
							No Financial Hardship
						</MDBSelectOption>
					</MDBSelectOptions>
				</MDBSelect>
			</MDBCol>
		);
	}

	renderFacilitySelect() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
				<MDBSelect
					getValue={(e) =>
						this.approvalAndUpdate(
							"inFacility",
							e[0] === "Patient is in Facility"
						)
					}
					outline
					label={"Facility"}
					style={{ marginTop: ".6em" }}
				>
					<MDBSelectInput />
					<MDBSelectOptions>
						<MDBSelectOption selected={appr.inFacility === true}>
							Patient is in Facility
						</MDBSelectOption>

						<MDBSelectOption selected={appr.inFacility === false}>
							Patient NOT in Facility
						</MDBSelectOption>
					</MDBSelectOptions>
				</MDBSelect>
			</MDBCol>
		);
	}

	renderAuthInput() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
				<MDBInput
					outline
					style={{ paddingTop: 8 }}
					label={"Auth Number"}
					disabled={this.state.statusSetup}
					valueDefault={appr.authNumber}
					onBlur={(evt) =>
						this.approvalAndUpdate("authNumber", evt.target.value)
					}
				/>
			</MDBCol>
		);
	}

	renderPrescriptionInput() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBCol size="12" className={"insuranceSelects nestedNoPadding"}>
				<MDBInput
					outline
					type={"checkbox"}
					id="PrescriptionBox"
					style={{ paddingTop: 8 }}
					label={"Has Prescription"}
					filled
					disabled={this.state.statusSetup}
					value={appr.hasPrescription}
					checked={appr.hasPrescription}
					onChange={(evt) => {
						this.approvalAndUpdate("hasPrescription", evt.target.checked);
						this.approvalAndUpdate("stateSalesTax", 0);
					}}
				/>
			</MDBCol>
		);
	}

	renderGoogleModal() {
		let st = this.state;
		return (
			<Modal
				isOpen={st.googleModal}
				toggle={() => {
					return;
				}}
			>
				<div className={"topBorder"} />
				<ModalBody>
					<MDBCloseIcon
						onClick={this.toggleGoogleSearch}
						className={"closeBtn"}
					/>
					<div>
						<Input
							label="Address Search"
							onBlur={() => clearInterval(this.state.timer)}
							hint={"Address, City, State Zip Code"}
							onChange={this.googleAddressTextChanged.bind(this)}
						/>
						{this.renderGoogleSearchResult()}
					</div>
				</ModalBody>
				<div className={"bottomBorder"} />
			</Modal>
		);
	}

	render() {
		return (
			<div>
				<MDBRow>
					<MDBCol size="8">
						{this.renderInfoSection()}
						<div style={{ position: "relative" }}>
							{this.renderDiseaseSection()}
						</div>

						<MDBRow>
							<MDBCol size="6">{this.renderPrimarySection()}</MDBCol>

							<MDBCol size="6">{this.renderSecondarySection()}</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol size="4">
						<MDBRow>
							<MDBCol size="12">{this.renderContactSection()}</MDBCol>
						</MDBRow>

						<MDBRow>
							<MDBCol size="12">
								<div
									style={{
										border: "1px solid #7986cb",
										margin: 8,
										padding: 15,
										marginTop: 20,
									}}
								>
									<label className={"insuranceLabel labelPosition labelPosition"}>
										Approval Information
									</label>
									{this.renderSameSimSelect()}
									{this.renderRentalPurchaseSelect()}
									{this.renderFinancialHardshipSelect()}
									{this.renderFacilitySelect()}
									{this.renderAuthInput()}
									{this.renderPrescriptionInput()}
								</div>
							</MDBCol>
						</MDBRow>
					</MDBCol>
				</MDBRow>

				{this.renderGoogleModal()}
				<ReactTooltip />
				<hr />
			</div>
		);
	}
}
