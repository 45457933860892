import React from "react";
import PropTypes from "prop-types";
import {Route} from "react-router-dom";
import {
    MDBRow,
    MDBCol,
    ToastContainer,
    MDBIcon,
    MDBCard,
    MDBCardHeader,
    MDBCardBody, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBBtn, MDBBtnGroup,
} from "mdbreact";
import "./home.css";
import FindOrders from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import ReactTooltip from "react-tooltip";
import SalesPipelineView from "../Orders/salesPipelineView";
import DeliveryAverageDays from "../../Components/Deliveries/DeliveryAverageDays";

class Kiosk extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveOrders();
        this.getTasksForUser();
        this.getTasksAssignedOut();

        this.state = {
            orders: [],
            tasks: [],
            newOrders: 0,
            processOrders: 0,
            deliveryOrders: 0,
            priority: [],
            isLoaded: false,
            tasksAssignedOut: [],
            productionMonthlyToggle: false,
            currentView: "standardView"
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };

    formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    formatStatus(order) {
        if (order.status === "ReadyToDeliver") {
            return "Waiting for Delivery";
        } else if (order.status === "InProcess") {
            if (
                order.orderStatusReason &&
                order.orderStatusReason.name === "Verification"
            ) {
                return "Intake / Insurance Verification";
            } else {
                return "Insurance Review";
            }
        } else {
            return order.status;
        }
    }

    retrieveOrders() {
        let end = new Date(),
            start = new Date(),
            filters = {};

        start.setDate(end.getDate() - 14);

        filters.statuses = ["New", "InProcess", "ReadyToDeliver", "Setup"];
        filters.startDate = start;
        filters.endDate = end;

        FindOrders.getDashboardCounts()
            .then((orders) => {
                this.setState({
                    orders: orders,
                    isLoaded: true,
                });
                this.getOrderCount();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getTasksForUser() {
        NoteService.getOpenActivityNotesByOwner()
            .then((tasks) => {
                this.setState({
                    tasks: tasks,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getTasksAssignedOut() {
        NoteService.getOpenActivityNotesAssignedOut()
            .then((tasks) => {
                this.setState({
                    tasksAssignedOut: tasks,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    updateTask(note) {
        const {currentUser} = this.context;

        let tasks = this.state.tasks;
        note.completed = true;
        note.type = "ActivityNote";

        let name = currentUser ? currentUser.username : "user";

        note.text +=
            " --> Completed by " + name + " on " + this.formatDate(new Date());

        return NoteService.updateActivityNote(note)
            .then((res) => {
                this.setState({
                    tasks: tasks,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getOrderCount() {
        let newOrders = 0,
            processOrders = 0,
            deliveryOrders = 0;

        this.state.orders.forEach((order) => {
            if (order.status === "New") {
                newOrders += order.orderCount;
            } else if (order.status === "InProcess") {
                processOrders += order.orderCount;
            } else if (order.status === "Ready to Deliver") {
                deliveryOrders += order.orderCount;
            }
        });

        this.setState({
            newOrders: newOrders,
            processOrders: processOrders,
            deliveryOrders: deliveryOrders,
        });
    }

    generateTask(note, assignedOut) {
        let bgColor = "#FFE4F1",
            headerColor = "#FFCCE5",
            t = this;

        if (assignedOut) {
            bgColor = "#E4F9E0";
            headerColor = "#CBF1C4";
        }

        function routeToOrder(note) {
            if (note.activity && note.activity.id) {
                t.props.history.push({
                    pathname: "/order/" + note.activity.id,
                });
            }
        }

        function renderAssignedTo() {
            if (note.assignedTo) {
                return (
                    <span style={{fontSize: 14}}>
						{" "}
                        <MDBIcon
                            icon="long-arrow-alt-right"
                            data-tip={"Assigned To"}
                        />{" "}
                        {note.assignedTo.name}
					</span>
                );
            }
        }

        function renderAccountPatient(note) {
            return (
                <MDBRow
                    style={{fontSize: 13}}
                    onClick={() => {
                        routeToOrder(note);
                    }}
                >
                    <MDBCol size="6">
                        {note.account ? note.account.name : "No Account"}
                    </MDBCol>

                    <MDBCol size="6">{note.patientName || ""}</MDBCol>
                </MDBRow>
            );
        }

        return (
            <MDBCard style={{backgroundColor: bgColor, margin: 10}}>
                <MDBCardHeader
                    style={{backgroundColor: headerColor, cursor: "pointer"}}
                >
                    <MDBRow>
                        <MDBCol size="5" style={{fontSize: 14}}>
                            {this.formatDate(note.createdAt)}
                        </MDBCol>

                        <MDBCol size="5" style={{fontSize: 14}}>
                            {note.createdBy ? note.createdBy.name : ""}
                            {renderAssignedTo()}
                        </MDBCol>

                        <MDBCol size="2">
                            <MDBIcon
                                style={{
                                    color: "#7B626E",
                                    float: "right",
                                    padding: "4px 8px 4px 4px",
                                }}
                                onClick={this.updateTask.bind(this, note)}
                                data-tip={"Complete"}
                                icon="check"
                            />
                        </MDBCol>
                    </MDBRow>

                    {renderAccountPatient(note)}
                </MDBCardHeader>

                <MDBCardBody>{note.text}</MDBCardBody>
                <ReactTooltip/>
            </MDBCard>
        );
    }

    renderRouteButton(order) {
        return (
            <Route
                render={({history}) => (
                    <div style={{float: "right", fontSize: "16px", color: "#fff"}}>
                        <h4
                            className="fa fa-external-link"
                            aria-hidden="true"
                            onClick={() => {
                                history.push({
                                    pathname: "/order/" + order.id,
                                    state: {order: order},
                                });
                            }}
                        ></h4>
                    </div>
                )}
            />
        );
    }

    renderTaskList() {
        let tasks = this.state.tasks;
        return (
            <div>
                {tasks.map((task, idx) => {
                    return this.generateTask(task, idx, false);
                })}
            </div>
        );
    }

    renderTaskAssignedOutList() {
        let tasks = this.state.tasksAssignedOut;
        return (
            <div>
                {tasks.map((task, idx) => {
                    return this.generateTask(task, idx, true);
                })}
            </div>
        );
    }

    renderReasons(statusName, start, end) {
        let orders = this.state.orders;
        return (
            <div style={{textAlign: "right"}}>
                {orders.map((order) => {
                    if (order.status === statusName) {
                        return this.renderReasonLink(order, start, end);
                    }
                    return null;
                })}
            </div>
        );
    }

    renderReasonLink(order, start, end) {
        if (order.description !== "Blank Reason") {
            return (
                <Route
                    key={order.id}
                    render={({history}) => (
                        <div
                            className={"reasonHostLink"}
                            onClick={(e) => {
                                e.stopPropagation();

                                history.push({
                                    pathname: "/order",
                                    state: {
                                        shouldOverride: true,
                                        filters: {
                                            statuses: [
                                                order.status === "Ready to Deliver"
                                                    ? "ReadyToDeliver"
                                                    : order.status,
                                            ],
                                            reasonIds: [order.id],
                                            startDate: start.toLocaleDateString(),
                                            endDate: end.toLocaleDateString(),
                                            shouldOverride: true,
                                        },
                                    },
                                });
                            }}
                        >
							<span className={"dbStatusCardsText"}>
								<b>
									{order.description}: &emsp;{order.orderCount}
								</b>
							</span>
                        </div>
                    )}
                />
            );
        } else {
            return (
                <div key={order.id}>
					<span style={{color: "white"}}>
						<b>
							{order.description}: &emsp;{order.orderCount}
						</b>
					</span>
                </div>
            );
        }
    }

    renderStatusCards() {
        let end = new Date(),
            start = new Date();

        start.setDate(end.getDate() - 14);

        return (
            <MDBRow>
                <MDBCol size="4">
                    <Route
                        render={({history}) => (
                            <MDBCard
                                className={"statusNew"}
                                onClick={() => {
                                    history.push({
                                        pathname: "/order",
                                        state: {
                                            shouldOverride: true,
                                            filters: {
                                                statuses: ["New"],
                                                startDate: start,
                                                endDate: end,
                                            },
                                        },
                                    });
                                }}
                            >
                                <MDBRow style={{padding: 6}}>
                                    <MDBCol size="4">
                                        <h1 className={"dashboardStatusCardText dbTextLarge"}>
                                            {this.state.newOrders}
                                        </h1>
                                        <h4
                                            className={"dashboardStatusCardText"}
                                            style={{marginTop: "1rem"}}
                                        >
                                            New
                                        </h4>
                                    </MDBCol>

                                    <MDBCol size="8">
                                        {this.renderReasons("New", start, end)}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        )}
                    />
                </MDBCol>

                <MDBCol size="4">
                    <Route
                        render={({history}) => (
                            <MDBCard
                                className={"statusProcess"}
                                onClick={() => {
                                    history.push({
                                        pathname: "/order",
                                        state: {
                                            shouldOverride: true,
                                            filters: {
                                                statuses: ["InProcess"],
                                                startDate: start,
                                                endDate: end,
                                            },
                                        },
                                    });
                                }}
                            >
                                <MDBRow style={{padding: 6}}>
                                    <MDBCol size="5">
                                        <h1 className={"dashboardStatusCardText dbTextLarge"}>
                                            {this.state.processOrders}
                                        </h1>
                                        <h4
                                            className={"dashboardStatusCardText"}
                                            style={{marginTop: "1rem"}}
                                        >
                                            In Process
                                        </h4>
                                    </MDBCol>
                                    <MDBCol size="7">
                                        {this.renderReasons("InProcess", start, end)}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        )}
                    />
                </MDBCol>

                <MDBCol size="4">
                    <Route
                        render={({history}) => (
                            <MDBCard
                                className={"statusDelivery"}
                                onClick={() => {
                                    history.push({
                                        pathname: "/order",
                                        state: {
                                            shouldOverride: true,
                                            filters: {
                                                statuses: ["ReadyToDeliver"],
                                                startDate: start,
                                                endDate: end,
                                            },
                                        },
                                    });
                                }}
                            >
                                <MDBRow style={{padding: 6}}>
                                    <MDBCol size="5">
                                        <h1 className={"dashboardStatusCardText dbTextLarge"}>
                                            {this.state.deliveryOrders}
                                        </h1>
                                        <h4
                                            className={"dashboardStatusCardText"}
                                            style={{marginTop: "1rem"}}
                                        >
                                            Ready to Deliver
                                        </h4>
                                    </MDBCol>

                                    <MDBCol size="7">
                                        {this.renderReasons("Ready to Deliver", start, end)}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        )}
                    />
                </MDBCol>
            </MDBRow>
        );
    }

    renderTaskLists() {
        return (
            <MDBRow>
                <MDBCol size="6" style={{paddingLeft: 30, paddingRight: 30}}>
                    <h5 className={"dashboardText"} style={{paddingLeft: 8}}>
                        Open Tasks
                    </h5>
                    <hr className={"dashboardLine"}/>
                    <div style={{maxHeight: "45vh", overflowY: "auto"}}>
                        {this.renderTaskList()}
                    </div>
                </MDBCol>

                <MDBCol size="6" style={{paddingLeft: 30, paddingRight: 30}}>
                    <h5 className={"dashboardText"}>Tasks You've Assigned</h5>
                    <hr className={"dashboardLine"}/>
                    <div style={{maxHeight: "45vh", overflowY: "auto"}}>
                        {this.renderTaskAssignedOutList()}
                    </div>
                </MDBCol>
            </MDBRow>
        )
    }

    renderBasicView() {
        return (
            <div>
                <div>{this.renderStatusCards()}</div>
                {this.renderTaskLists()}
            </div>
        )
    }

    renderAdminView() {
        return (
            <div>
                <MDBRow style={{justifyContent: 'center', marginTop: 6, marginBottom: 6}}>
                    <MDBBtnGroup className="mr-2 m-auto">
                        <MDBBtn color={"primary"} style={{ margin: "10px", marginBottom: "-10px" }} onClick={() => this.setState({currentView: "standardView"})}>Current Statuses</MDBBtn>
                        <MDBBtn color={"primary"} style={{ margin: "10px", marginBottom: "-10px" }} onClick={() => this.setState({currentView: "salesPipeline"})}>Sales Pipeline</MDBBtn>
                        <MDBBtn color={"primary"} style={{ margin: "10px", marginBottom: "-10px" }} onClick={() => this.setState({currentView: "deliveryAverages"})}>Delivery Averages</MDBBtn>
                    </MDBBtnGroup>
                </MDBRow>
            </div>
        )
    }


    renderStandardView(){
        if(this.state.currentView === "standardView"){
            return(
                this.renderBasicView()
            )
        }
    }

    renderSalesPipeline(){
        if(this.state.currentView === "salesPipeline"){
            return(
                <SalesPipelineView/>
            )
        }
    }

    renderDeliveryAverages(){
        if(this.state.currentView === "deliveryAverages"){
            return(
                <DeliveryAverageDays/>
            )
        }
    }


    render() {
        return (
            <div style={{overflowY: "hidden", overflowX: "hidden"}}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderAdminView()}

                {this.renderStandardView()}
                {this.renderSalesPipeline()}
                {this.renderDeliveryAverages()}
            </div>
        );
    }
}

export default Kiosk;
