import api from "../api";

class ReportsService {
	//setups/sales report data
	async getSalesReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				pageSize: 10000,
				pointsOnlyProducts: filters.pointsOnlyProducts,
				startDate: filters.startDate,
				endDate: filters.endDate,
				users: filters.users || [],
			};
			api
				.get("/reports/salesNative", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//activity report data
	async getActivityReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				pageSize: 10000,
				startDate: filters.startDate,
				endDate: filters.endDate,
				users: filters.users || [],
				location: filters.location || [],
			};
			api
				.get("/reports/activity", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//approvals report data
	async getApprovalsReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				pageSize: 10000,
				pointsOnlyProducts: filters.pointsOnlyProducts,
				startDate: filters.startDate,
				endDate: filters.endDate,
				users: filters.users || [],
			};
			api
				.get("/reports/approvals", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//approvals report data
	async getApprovalsReportNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/reports/approvalsNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//service report data
	async getServiceReport(filters) {
		return new Promise((res, reject) => {
			api
				.get("/reports/servicesNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//delivery report data
	async getDeliveryReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				startDate: filters.startDate,
				endDate: filters.endDate,
				users: filters.users,
			};
			api
				.get("/reports/deliveryNative", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//expense report data
	async getExpensesReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				startDate: filters.startDate,
				endDate: filters.endDate,
				users: filters.users,
			};
			//exec spGetExpensesReport :ids, :from, :until
			api
				.get("/reports/expensesNative", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//accounts report data
	async getAccountsReport(filters) {
		return new Promise((res, reject) => {
			let params = {
				startDate: filters.startDate,
				endDate: filters.endDate,
				users: filters.users,
			};
			api
				.get("/reports/accountsNative", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getBonusReport(filters) {
		return new Promise((res, reject) => {
			api
				.get("/bonusNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getProductionReport(filters) {
		return new Promise((res, rej) => {
			api
				.get("/reports/productionReport", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej();
				});
		});
	}

	async getDailyOrderCounts(filters) {
		return new Promise((res, rej) => {
			api
				.get("/orderCountsByDayNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}

	async getDailyOrderCountsLocation(filters) {
		return new Promise((res, rej) => {
			api
				.get("/orderCountsByDayLocationNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej(err);
				});
		});
	}
}

const reportsService = new ReportsService();
export default reportsService;
