import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import AuthService from "../Security/authService";
import {
	Card,
	CardBody,
	Fa,
	Row,
	Col,
	MDBBtn,
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBInput,
} from "mdbreact";

import "./login.css";
import ClearCache from "react-clear-cache";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

const VERSION = 2.16,
    companies = [
        {name: 'Rehab Medical', value: 'rehabmedical'},
        {name: 'Cork Medical', value: 'corkmedical'},
        {name: 'OMS Rehab', value: 'omsrehab'},
        {name: 'RMS', value: 'rms'},
        {name: 'UAT', value: 'uat'},
    ];

export default class login extends React.Component {
	constructor() {
		super();

		let prevPage = window.sessionStorage.getItem("prevPage");
		this.state = {
			username: "",
			password: "",
			company: "",
			companyLabel: "",
			loggedIn: false,
			error: "",
			currentUser: {},
			needUpdate: false,
			currVersion: -1,
			prevPage: prevPage,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		userFunction: () => {},
		companyFunction: () => {},
	};

	checkVersion(evt) {
		evt.preventDefault();
		this.validateAndLogin();
		return;
		// TODO: commented for now -- may need to be re-initiated per Bart
		// let t = this;
		// evt.preventDefault();
		// VersionService.checkCurrentVersion(t.state.company)
		// 	.then((res) => {
		// 		let u = false,
		// 			err = "";
		// 		if (res.version > VERSION) {
		// 			u = true;
		// 			err = "Please Update Your SalesPilot Version";
		// 		} else {
		// 			t.validateAndLogin();
		// 		}
		// 		this.setState({
		// 			needUpdate: u,
		// 			error: err,
		// 			currVersion: res.version,
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		console.log(err);
		// 	});
	}

	//Attempt to log the user into the service
	validateAndLogin(evt) {
		const { userFunction, companyFunction } = this.context;
		// evt.preventDefault();
		AuthService.login(this.state)
			.then((res) => {
				//successfully logged in...BC

				AuthService.getMyUserDetails().then((user) => {
					if (user.role !== "THIRD_PARTY_USER") {
						this.setState({ loggedIn: true });
					} else {
						this.setState({ error: "You are not authorized to use this site" });
					}

					userFunction(user);
					companyFunction(this.state.companyLabel, this.state.company);
				});
			})
			.catch((err) => {
				//failed to login...BC
				this.setState({ error: err });
			});
	}

	renderIntro() {
		return (
			<video
				width="100%"
				height="100%"
				autoPlay
				muted
				style={{ height: 235, maxHeight: 235, border: ".1em solid #AAA" }}
			>
				<source
					src={require("../../images/Sales_pilot_logo_animation_1.0.mp4")}
					type={"video/mp4"}
				/>
				<img
					src={require("../../images/salesPilotLogo3.png")}
					style={{ marginBottom: 6 }}
					alt={"Sales Pilot Logo"}
				/>
				<h2 style={{ fontWeight: 500 }}>Sales Pilot</h2>
			</video>
		);
	}

	render() {
		let st = this.state;
		if (st.loggedIn === true) {
			if (st.prevPage === "null" || st.prevPage === null) {
				return <Redirect to="/dashboard" />;
			}
			window.sessionStorage.setItem("prevPage", null);
			return <Redirect to={st.prevPage} />;
		}

		let idx = "";
		if (this.state.company) {
			idx = companies.find((c) => {
				return c.value === this.state.company;
			});
		}

		return (
			<MDBContainer>
				<MDBRow center>
					<MDBCol size="5">
						<form onSubmit={this.checkVersion.bind(this)}>
							<Card>
								<CardBody
									style={{
										fontSmoothingEnabled: true,
										WebkitFontSmoothing: "antialiased",
									}}
								>
									<div className="text-center">{this.renderIntro()}</div>

									<div>
										<MDBInput
											style={{ marginBottom: 4 }}
											onChange={(evt) =>
												this.setState({ username: evt.target.value })
											}
											outline
											label="Username"
											icon="user"
											group
											validate
										/>

										<MDBInput
											style={{ marginBottom: 4 }}
											onChange={(evt) =>
												this.setState({ password: evt.target.value })
											}
											outline
											label="Password"
											icon="lock"
											group
											type="password"
											validate
										/>
										<Row>
											<Col size="1">
												<Fa size="lg" icon={"building"} />
											</Col>

											<Col size="11" className={"uiOutlines"}>
												<TextField
													id="outlined-select-status"
													select
													size="small"
													fullWidth
													label="Company"
													value={idx}
													onChange={(e) => {
														this.setState({
															company: e.target.value.value,
															companyLabel: e.target.value.name,
														});
													}}
													variant="outlined"
												>
													{companies.map((option, idx) => {
														return (
															<MenuItem key={idx} value={companies[idx]}>
																{option.name}
															</MenuItem>
														);
													})}
												</TextField>
											</Col>
										</Row>

										<br />
									</div>
									<div className="text-right">
										{st.error ? (
											<h1 style={errorStyle}>{st.error}</h1>
										) : (
											<div />
										)}
										<ClearCache>
											{({ isLatestVersion, emptyCacheStorage }) => (
												<div>
													{!isLatestVersion && (
														<p>
															<a
																href="/#"
																onClick={(e) => {
																	e.preventDefault();
																	emptyCacheStorage();
																}}
															>
																Update Version
															</a>
														</p>
													)}
												</div>
											)}
										</ClearCache>
										<MDBBtn
											type="submit"
											disabled={st.needUpdate}
											color={"indigo"}
											className="btn-block btn-rounded"
										>
											Login
										</MDBBtn>
										<div style={{ paddingTop: "4%" }}>
											{/*<span*/}
											{/*    style={{cursor:'pointer'}}*/}
											{/*    className={'float-left'}*/}
											{/*    onClick={() => { this.props.history.push(*/}
											{/*        {*/}
											{/*            pathname: '/forgotPassword',*/}
											{/*            state: st.company*/}
											{/*        })}}*/}
											{/*>Forgot Password*/}
											{/*</span>*/}
											<span style={{ marginTop: "10px" }}>
												Version {VERSION}
											</span>
										</div>
									</div>
								</CardBody>
							</Card>
						</form>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}

const errorStyle = {
	color: "red",
	fontSize: 16,
};
