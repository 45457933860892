import React, { Component } from "react";
import PropTypes from "prop-types";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import ReactTooltip from "react-tooltip";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

//region mdbReact imports
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Footer,
	FormInline,
	MDBBtn,
	MDBCol,
	MDBInput,
	MDBModal,
	MDBRow,
	ModalBody,
	Navbar,
	NavbarBrand,
	NavbarNav,
	NavItem,
	NavLink,
	toast,
} from "mdbreact";
//endregion

//region css imports
import "./index.css";
import "../node_modules/open-iconic/font/css/open-iconic-bootstrap.css";
//endregion

//region service imports
import Routes from "./Routes";
import InsuranceService from "./Seating/Security/InsuranceService/insuranceService";
import LocationService from "./Seating/Security/Locations/locationsService";
import UserService from "./Seating/Security/UserService/userService";
import AccountService from "./Seating/Security/AccountService/accountService";
import ContactService from "./Seating/Security/ContactService/contactService";
import ProductTypeService from "./Seating/Security/ProductService/productTypeService";
import ProductSubTypeService from "./Seating/Security/ProductService/productSubTypeService";
import ReasonService from "./Seating/Security/ReasonService/reasonService";
import ActivityService from "./Seating/Security/ActivityService/activityService";
import DocumentTypesService from "./Seating/Settings/documentTypesService";
import MarketingService from "./Seating/Security/MarketingService/marketingService";
import ComplaintService from "./Seating/Security/ComplaintService/ComplaintService";
import GroupService from "./Seating/Security/UserService/groupService";
import VendorService from "./Seating/Security/VendorService/vendorService";
import ProductMatrixService from "./Seating/Security/ProdcutMatrixEntryService/prodcutMatrixEntryService";
import ReverseQualityService from "./Seating/Settings/reverseQualityService";
import PatientCommunicationService from "./Seating/Settings/PatientCommunicationService";
//endregion

//region icon imports
import { AiOutlineBarChart, AiOutlineSearch } from "react-icons/ai";
import { GiGears, GiShakingHands } from "react-icons/gi";
import { FaDollarSign, FaBoxes } from "react-icons/fa";
import { MdPermMedia, MdSecurity, MdErrorOutline } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
//endregion
import API from "./Seating/Security/api";

//momentTimezone needs to be imported!!!...BC
import moment from "moment";
import momentTimezone from "moment-timezone";
import orderService from "./Seating/Security/OrderService/orderService";
import StateSalesTaxService from "./Seating/Settings/stateSalesTaxService";
import Select from "react-select";

class App extends Component {
	constructor(props) {
		super(props);

		let cu = window.sessionStorage.getItem("currentUser");

		//ensure clearing of the session storage...BC
		window.sessionStorage.clear();

		if (cu != null) {
			window.sessionStorage.setItem("currentUser", cu);
			cu = JSON.parse(cu);
		}

		this.state = {
			collapseID: "",
			allThemes: [
				"Gorgeous Contrast",
				"Alternate Contrast",
				"Corporate",
				"Bold",
				"Plum",
				"Indigo",
				"Umber",
			],
			currentUser: cu || {},
			company: "",
			companyName: "",
			inventoryRequestOpen: false,
			insuranceTypes: [],
			insuranceSubTypes: [],
			productTypes: [],
			productSubTypes: [],
			searchText: "",
			reasons: [],
			therapists: [],
			salesReps: [],
			accounts: [],
			myAccounts: [],
			allLocations: [],
			internalLocations: [],
			allUsers: [],
			allActiveUsers: [],
			allLiaisons: [],
			allAtp: [],
			thirdPartyUsers: [],
			thirdPartyUsersLoaded: false,
			cardTypes: [],
			expenseTypes: [],
			documentTypes: [],
			contactTypes: [],
			accountTypes: [],
			patientCommunicationTriggerDefs: [],
			techs: [],
			marketingCampaigns: [],
			complaintCategories: [],
			complaintReasons: [],
			allVendors: [],
			productOverrides: [],
			fullLiaisonKeys: [],
			orderModalOpen: false,
			orderErrorMessage: "",
			orderFound: false,
			orderId: "",
			stateSalesTax: [],
			reverseQualityCategories: [],
			reverseQualityCategoriesRefs: [],
			rqModalOpen: false,
			rqObject: {},
			rqOrderId: "",
		};
	}

	static childContextTypes = {
		userFunction: () => {},
		companyFunction: () => {},
		company: PropTypes.string,
		companyName: PropTypes.string,
		currentUser: PropTypes.object,
		insuranceTypes: PropTypes.array,
		insuranceSubTypes: PropTypes.array,
		productTypes: PropTypes.array,
		productSubTypes: PropTypes.array,
		reasons: PropTypes.array,
		therapists: PropTypes.array,
		salesReps: PropTypes.array,
		accounts: PropTypes.array,
		myAccounts: PropTypes.array,
		allLocations: PropTypes.array,
		internalLocations: PropTypes.array,
		allUsers: PropTypes.array,
		allActiveUsers: PropTypes.array,
		allLiaisons: PropTypes.array,
		allAtp: PropTypes.array,
		thirdPartyUsers: PropTypes.array,
		thirdPartyUsersLoaded: PropTypes.bool,
		patientCommunicationTriggerDefs: PropTypes.array,
		cardTypes: PropTypes.array,
		expenseTypes: PropTypes.array,
		documentTypes: PropTypes.array,
		contactTypes: PropTypes.array,
		accountTypes: PropTypes.array,
		techs: PropTypes.array,
		marketingCampaigns: PropTypes.array,
		complaintCategories: PropTypes.array,
		complaintReasons: PropTypes.array,
		allVendors: PropTypes.array,
		productOverrides: PropTypes.array,
		fullLiaisonKeys: PropTypes.array,
		timezoneVariation: PropTypes.number,
		stateSalesTax: PropTypes.array,
		reverseQualityCategories: PropTypes.array,
	};

	setUserFunction(user) {
		this.setState({ currentUser: user });
		if (user) {
			window.sessionStorage.setItem("currentUser", JSON.stringify(user));
			this.getAll();
		} else {
			window.sessionStorage.removeItem("currentUser");
			this.clearState();
		}
	}

	setCompanyFunction(friendlyCompany, company) {
		this.setState({
			companyName: company,
			company: friendlyCompany,
		});
	}

	getChildContext() {
		return {
			userFunction: this.setUserFunction.bind(this),
			companyFunction: this.setCompanyFunction.bind(this),
			company: this.state.company,
			companyName: this.state.companyName,
			currentUser: this.state.currentUser,
			insuranceTypes: this.state.insuranceTypes,
			insuranceSubTypes: this.state.insuranceSubTypes,
			productTypes: this.state.productTypes,
			productSubTypes: this.state.productSubTypes,
			reasons: this.state.reasons,
			therapists: this.state.therapists,
			salesReps: this.state.salesReps,
			accounts: this.state.accounts,
			myAccounts: this.state.myAccounts,
			allLocations: this.state.allLocations,
			internalLocations: this.state.internalLocations,
			allUsers: this.state.allUsers,
			allLiaisons: this.state.allLiaisons,
			allAtp: this.state.allAtp,
			thirdPartyUsers: this.state.thirdPartyUsers,
			thirdPartyUsersLoaded: this.state.thirdPartyUsersLoaded,
			patientCommunicationTriggerDefs: this.state
				.patientCommunicationTriggerDefs,
			cardTypes: this.state.cardTypes,
			expenseTypes: this.state.expenseTypes,
			documentTypes: this.state.documentTypes,
			contactTypes: this.state.contactTypes,
			accountTypes: this.state.accountTypes,
			techs: this.state.techs,
			marketingCampaigns: this.state.marketingCampaigns,
			complaintCategories: this.state.complaintCategories,
			complaintReasons: this.state.complaintReasons,
			allVendors: this.state.allVendors,
			productOverrides: this.state.productOverrides,
			fullLiaisonKeys: this.state.fullLiaisonKeys,
			timezoneVariation: this.state.timezoneVariation,
			stateSalesTax: this.state.stateSalesTax,
			reverseQualityCategories: this.state.reverseQualityCategories,
		};
	}

	//region context entities
	getInsuranceTypes() {
		let t = sessionStorage.getItem("insuranceTypes");
		if (t && t !== "undefined") {
			this.setState({ insuranceTypes: JSON.parse(t) });
			return;
		}

		InsuranceService.findAllInsurance()
			.then((types) => {
				sessionStorage.setItem("insuranceTypes", JSON.stringify(types));
				this.setState({ insuranceTypes: types });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getInsuranceSubTypes() {
		let t = sessionStorage.getItem("insuranceSubTypes");
		if (t && t !== "undefined") {
			this.setState({ insuranceSubTypes: JSON.parse(t) });
			return;
		}

		InsuranceService.findAllSubInsurance()
			.then((types) => {
				let res = types.sort((a, b) => {
					if (a.name.toLowerCase() < b.name.toLowerCase()) {
						return -1;
					} else if (a.name.toLowerCase() > b.name.toLowerCase()) {
						return 1;
					} else {
						return 0;
					}
				});

				sessionStorage.setItem("insuranceSubTypes", JSON.stringify(types));
				this.setState({ insuranceSubTypes: res });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getProductTypes() {
		let t = sessionStorage.getItem("productTypes");
		if (t && t !== "undefined") {
			this.setState({ productTypes: JSON.parse(t) });
			return;
		}

		ProductTypeService.getAllProducts()
			.then((types) => {
				sessionStorage.setItem("productTypes", JSON.stringify(types));
				this.setState({ productTypes: types });
			})
			.catch((err) => {
				// do something? ... RW
				console.log(err);
			});
	}

	getProductSubTypes() {
		let t = sessionStorage.getItem("productSubTypes");
		if (t && t !== "undefined") {
			this.setState({ productSubTypes: JSON.parse(t) });
			return;
		}

		ProductSubTypeService.getAllProductSubTypes()
			.then((types) => {
				sessionStorage.setItem("productSubTypes", JSON.stringify(types));
				this.setState({ productSubTypes: types });
			})
			.catch((err) => {
				// do something? ... RW
				console.log(err);
			});
	}

	getLocations() {
		let t = sessionStorage.getItem("allLocations");
		if (t && t !== "undefined") {
			this.setState({ allLocations: JSON.parse(t) });
		} else {
			LocationService.findAllLocations()
				.then((locations) => {
					sessionStorage.setItem("allLocations", JSON.stringify(locations));
					this.setState({ allLocations: locations });
				})
				.catch((err) => {
					console.log(err);
				});
		}

		let u = sessionStorage.getItem("internalLocations");
		if (u && u !== "undefined") {
			this.setState({ internalLocations: JSON.parse(u) });
		} else {
			LocationService.findAllInternalLocations()
				.then((locations) => {
					sessionStorage.setItem(
						"internalLocations",
						JSON.stringify(locations)
					);
					this.setState({ internalLocations: locations });
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	getTherapists() {
		UserService.getAllTherapists()
			.then((therapists) => {
				this.setState({ therapists: therapists });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getSalesReps() {
		let cu = this.state.currentUser;
		let locations = cu.locations || [];
		switch (cu.role) {
			case "SALES":
				this.setState({ salesReps: [cu] });
				break;
			case "SALES_MANAGER":
				let locs = [];
				locations.map((l) => {
					return locs.push(l.id);
				});
				GroupService.getAllSalesRepsByLocations(locs)
					.then((res) => {
						this.setState({ salesReps: res });
					})
					.catch((err) => {
						console.log(err);
					});
				break;
			default:
				UserService.getAllSalesReps()
					.then((reps) => {
						this.setState({ salesReps: reps.content });
					})
					.catch((err) => {
						console.log(err);
					});
				break;
		}
	}

	getTechs() {
		let u = sessionStorage.getItem("technicians");
		if (u && u !== "undefined") {
			this.setState({ techs: JSON.parse(u) });
			return;
		}

		UserService.getAllTechs()
			.then((techs) => {
				sessionStorage.setItem("technicians", JSON.stringify(techs));
				this.setState({ techs: techs });
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getAllUsers() {
		UserService.findAllUsers()
			.then((res) => {
				//reps.content
				let atp = res.content.filter((v) => {
					return v.isAtp === true;
				});

				let ary = [];
				res.content.forEach((u) => {
					ary.push({
						label: u.firstname + " " + u.lastname,
						value: { id: u.id, name: u.username },
					});
				});

				this.setState({
					allUsers: res.content,
					allAtp: atp,
					userRefs: ary,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getActiveUsers() {
		UserService.findAllActiveUsers()
			.then((res) => {
				let liaisons = res.filter((v) => {
					return (
						v.role === "SALES_LIAISON" || v.role === "SALES_LIAISON_MANAGER"
					);
				});

				this.setState({
					allActiveUsers: res,
					allLiaisons: liaisons,
				});

				this.populateLiaisonKeys(liaisons);
			})
			.catch((err) => {
							console.log(err);
						});
	}

	populateLiaisonKeys(allLiaisons) {
		let aryDates = [],
			liaisonsKeys = [],
			startDate = new Date();

		let currentTzo = new Date().getTimezoneOffset(),
			id = moment.tz(new Date(), "America/Indiana/Indianapolis"),
			idTzo = id._offset,
			variation = 0;

		//subtract indiana's timezone offset...BC (it is negative 300, so add negative)
		currentTzo += idTzo;

		//get the offset in hours...BC
		if (currentTzo !== 0) {
			variation = currentTzo / 60;
		}

		let start = 9,
			end = 16;

		//they will have a lot less choices to schedule a meeting based on their timezone...BC
		if (variation !== 0) {
			start = 9 - variation;
			end = 16 - variation;
		}

		for (let i = 0; i <= 5; i++) {
			let currentDate = new Date();
			currentDate.setDate(startDate.getDate() + i);

			if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
				continue;
			}

			let mString =
				(currentDate.getMonth() + 1).toString() +
				"/" +
				currentDate.getDate().toString() +
				"/" +
				currentDate.getFullYear().toString() +
				" | ";

			//this should generate a keystring of DDMMYYYYTTTT
			for (let i = start; i <= end; i++) {
				aryDates.push(mString + i + ":00:00--");
			}
		}

		aryDates.forEach((d) => {
			allLiaisons.forEach((l) => {
				liaisonsKeys.push(d + l.username);
			});
		});

		this.setState({
			fullLiaisonKeys: liaisonsKeys,
			timezoneVariation: variation,
		});
	}

	getThirdPartyUsers() {
		UserService.findThirdPartyUsers()
			.then((res) => {
				this.setState({
					thirdPartyUsers: res.content,
					thirdPartyUsersLoaded: true,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getAccounts() {
		AccountService.getAllAccounts()
			.then((accounts) => {
				this.setState({ accounts: accounts.content });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getContactTypes() {
		ContactService.findAllContactTypes()
			.then((types) => {
				this.setState({ contactTypes: types });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getMyAccounts(userId) {
		AccountService.getAccountsByUser(userId)
			.then((accounts) => {
				//accounts.content
				this.setState({ myAccounts: accounts });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	retrieveCreditCardTypes() {
		ActivityService.getCreditCardTypes()
			.then((types) => {
				this.setState({ cardTypes: types });
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	retrieveExpenseTypes() {
		ActivityService.getExpenseTypes()
			.then((types) => {
				this.setState({ expenseTypes: types });
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	getAccountTypes() {
		AccountService.getAllAccountTypes()
			.then((types) => {
				this.setState({ accountTypes: types });
			})
			.catch((err) => {
				//error
			});
	}

	getReasons() {
		let t = sessionStorage.getItem("statusReasons");
		if (t && t !== "undefined") {
			this.setState({ reasons: JSON.parse(t) });
			return;
		}

		ReasonService.findAllReasons()
			.then((reasons) => {
				sessionStorage.setItem("statusReasons", JSON.stringify(reasons));
				this.setState({ reasons: reasons });
			})
			.catch((err) => {
				//error
			});
	}

	getDocumentTypes() {
		let t = sessionStorage.getItem("documentTypes");
		if (t && t !== "undefined") {
			this.setState({ documentTypes: JSON.parse(t) });
			return;
		}

		DocumentTypesService.getAllDocumentTypes()
			.then((docs) => {
				sessionStorage.setItem("documentTypes", JSON.stringify(docs));
				this.setState({ documentTypes: docs });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getMarketingCampaigns() {
		let t = sessionStorage.getItem("marketingCampaigns");
		if (t && t !== "undefined") {
			this.setState({ marketingCampaigns: JSON.parse(t) });
			return;
		}

		MarketingService.getActiveCampaigns()
			.then((camps) => {
				sessionStorage.setItem("marketingCampaigns", JSON.stringify(camps));
				this.setState({ marketingCampaigns: camps });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getComplaintCategories() {
		ComplaintService.getAllIssueCategories()
			.then((cat) => {
				this.setState({ complaintCategories: cat });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getComplaintReasons() {
		ComplaintService.getAllIssueReasons()
			.then((res) => {
				this.setState({ complaintReasons: res });
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getVendors() {
		VendorService.getAllVendors()
			.then((res) => {
				this.setState({
					allVendors: res,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getProductOverrides() {
		ProductMatrixService.getAllProducts()
			.then((res) => {
				this.setState({
					productOverrides: res,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getStateSalesTax() {
		StateSalesTaxService.getAllStates().then((res) => {
			let arr = [];

			res.forEach((state, index) => {
				arr.push({
					id: state.id,
					name: state.name,
					abbr: state.abbr,
					salesTax: parseFloat(state.salesTax),
				});
			});
			this.setState({ stateSalesTax: arr });
		});
	}

	getReverseQualityCategories() {
		ReverseQualityService.getAllCategories().then((res) => {
			let arr = [],
				refAry = [];

			res.forEach((category) => {
				refAry.push({
					label: category.name,
					value: {
						id: category.id,
						name: category.name,
					},
				});

				arr.push({
					id: category.id,
					name: category.name,
				});
			});
			this.setState({
				reverseQualityCategories: arr,
				reverseQualityCategoriesRefs: refAry,
			});
		});
	}

	getPatientCommunicationTriggers() {
		PatientCommunicationService.getAllPatientCommunicationTriggerDefs()
			.then((defs) => {
				this.setState({
					patientCommunicationTriggerDefs: defs,
				});
			})
			.catch((e) => {});
	}
	//endregion

	componentDidMount() {
		let cu = window.sessionStorage.getItem("currentUser");

		if (cu != null && cu.length > 0) {
			this.getAll();

			//get the company name
			API.getToken()
				.then((res) => {
					this.setState({ companyName: res.company, company: res.company });
				})
				.catch((e) => {});
		}
	}

	getAll() {
		// this.getWhoAmI();
		this.getContactTypes();
		this.getInsuranceTypes();
		this.getInsuranceSubTypes();
		this.getProductTypes();
		this.getProductSubTypes();
		this.getLocations();
		this.getTherapists();
		this.getSalesReps();
		this.getAccounts();
		this.getReasons();
		this.getAllUsers();
		this.getActiveUsers();
		this.retrieveCreditCardTypes();
		this.retrieveExpenseTypes();
		this.getDocumentTypes();
		this.getMyAccounts(this.state.currentUser.id);
		this.getAccountTypes();
		this.getTechs();
		this.getMarketingCampaigns();
		this.getComplaintCategories();
		this.getComplaintReasons();
		this.getThirdPartyUsers();
		this.getVendors();
		this.getProductOverrides();
		this.getStateSalesTax();
		this.getReverseQualityCategories();
		this.getPatientCommunicationTriggers();
	}

	clearState() {
		this.setState({
			collapseID: "",
			currentUser: {},
			company: "",
			inventoryRequestOpen: false,
			insuranceTypes: [],
			insuranceSubTypes: [],
			productTypes: [],
			productSubTypes: [],
			searchText: "",
			reasons: [],
			therapists: [],
			salesReps: [],
			accounts: [],
			myAccounts: [],
			allLocations: [],
			internalLocations: [],
			allUsers: [],
			userRefs: [],
			allActiveUsers: [],
			allLiaisons: [],
			thirdPartyUsers: [],
			thirdPartyUsersLoaded: false,
			cardTypes: [],
			expenseTypes: [],
			documentTypes: [],
			contactTypes: [],
			accountTypes: [],
			techs: [],
			marketingCampaigns: [],
			complaintCategories: [],
			complaintReasons: [],
			allVendors: [],
			productOverrides: [],
			fullLiaisonKeys: [],
			reverseQualityCategories: [],
		});

		window.sessionStorage.clear();
	}

	closeInventoryRequest() {
		this.setState({
			inventoryRequestOpen: false,
		});
	}

	renderInventoryRequestModal() {
		return (
			<MDBModal
				size="lg"
				isOpen={this.state.inventoryRequestOpen}
				toggle={() => {
					this.setState({
						inventoryRequestOpen: false,
					});
				}}
			>
				<InventoryRequest onClose={this.closeInventoryRequest.bind(this)} />
			</MDBModal>
		);
	}

	renderOrderSearchModal() {
		return (
			<MDBModal
				isOpen={this.state.orderModalOpen}
				centered
				size="sm"
				toggle={() => {
					this.setState({
						orderModalOpen: false,
					});
				}}
			>
				<ModalBody style={{ backgroundColor: "#FFFFFF" }}>
					<MDBRow style={{ justifyContent: "space-around" }}>
						<MDBCol>
							<FormInline
								onSubmit={(e) => {
									this.searchForId(e);
								}}
							>
								<MDBInput
									outline
									size="lg"
									icon={"search"}
									label="Search By Order Id"
									value={this.state.orderId}
									onChange={(e) =>
										this.setState({
											orderId: e.target.value,
										})
									}
								/>
								<span>{this.state.orderErrorMessage}</span>
							</FormInline>
						</MDBCol>
					</MDBRow>
				</ModalBody>
			</MDBModal>
		);
	}

	handleRQUserSelected = (e) => {
		let obj = this.state.rqObject;

		obj.rqUser = e.value;
		this.setState({
			rqObject: obj,
		});
	};

	handleRqCategorySelected = (e) => {
		let obj = this.state.rqObject;

		obj.rqQualityCategory = e.value;
		this.setState({
			rqObject: obj,
		});
	};

	handleRqNote(e) {
		let obj = this.state.rqObject;

		obj.notes = e.target.value;

		this.setState({
			rqObject: obj,
		});
	}

	submitRqCategory() {
		let currentUser = this.state.currentUser,
			obj = this.state.rqObject;

		if (!obj.notes) {
			return toast.warn("You must enter some notes");
		} else if (!obj.orderId) {
			return toast.warn("You must enter an order id");
		} else if (!obj.rqUser) {
			return toast.warn("You must select a user");
		} else if (!obj.rqQualityCategory) {
			return toast.warn("You must select a category");
		}

		obj.rqReportedBy = { name: currentUser.username, id: currentUser.id };
		obj.rqReportedOn = new Date();

		ReverseQualityService.createReverseQualityLog(obj)
			.then((r) => {
				this.setState({
					rqModalOpen: false,
					rqObject: {},
				});
				toast.success("RQ Submitted!");
			})
			.catch((err) => {
				console.log(err);
			});
	}

	renderReverseQualityModal() {
		return (
			<MDBModal
				isOpen={this.state.rqModalOpen}
				centered
				size="lg"
				toggle={() => {
					this.setState({
						rqModalOpen: false,
						rqObject: {},
					});
				}}
			>
				<MDBRow style={{ justifyContent: "center" }}>
					<span
						style={{ fontSize: 20, padding: 10, textDecoration: "underline" }}
					>
						Report Reverse Quality
					</span>
				</MDBRow>

				<ModalBody style={{ backgroundColor: "#FFFFFF", padding: 20 }}>
					<MDBRow style={{ justifyContent: "center" }}>
						<MDBCol size="3">
							<label> Order Id </label>
							<MDBInput
								containerClass={"noMargin"}
								outline
								valueDefault={this.state.rqOrderId}
								onBlur={(e) => {
									let obj = this.state.rqObject;
									obj.orderId = e.target.value;

									this.setState({
										rqObject: obj,
										rqOrderId: e.target.value,
									});
								}}
							/>
						</MDBCol>

						<MDBCol size="4">
							<label> User Being Reported </label>
							<Select
								placeholder={
									this.state.rqObject.reportedOn
										? this.state.rqObject.reportedOn.name
										: "Select User..."
								}
								options={this.state.userRefs}
								onChange={this.handleRQUserSelected.bind(this)}
							/>
						</MDBCol>

						<MDBCol size="5">
							<label> RQ Category </label>
							<Select
								placeholder={
									this.state.rqObject.rqCategory
										? this.state.rqObject.rqCategory.name
										: "Select Category..."
								}
								options={this.state.reverseQualityCategoriesRefs}
								onChange={this.handleRqCategorySelected.bind(this)}
							/>
						</MDBCol>

						<MDBCol size="12">
							<MDBInput
								style={{ border: "1px solid black", textAlign: "left" }}
								type="textarea"
								label="Enter notes.."
								rows="4"
								cols="50"
								valueDefault={this.state.rqObject.notes}
								onBlur={this.handleRqNote.bind(this)}
							/>

							<MDBBtn
								color={"indigo"}
								className="float-right"
								onClick={() => this.submitRqCategory()}
							>
								Submit
							</MDBBtn>
						</MDBCol>
					</MDBRow>
				</ModalBody>

				<ModalBody style={{ backgroundColor: "#FFFFFF" }}></ModalBody>
			</MDBModal>
		);
	}

	searchForId(e) {
		e.preventDefault();
		let v = this.state.orderId;
		orderService
			.getActivity(v)
			.then((res) => {
				if (res && res.type === "Order") {
					this.setState({
						orderFound: true,
					});
				} else {
					this.setState({
						orderErrorMessage: "Order not found!",
					});
				}
			})
			.catch((e) => {
				this.setState({
					orderErrorMessage: "Order not found!",
				});
			});
	}

	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) return false;
		}
		return true;
	}

	hasAccess(type) {
		let cu = this.state.currentUser;

		if (cu.role === "ADMIN" || cu.role === "SYSTEM_ADMIN") {
			return true;
		}

		switch (type) {
			case "presidentsClub":
				if (
					["EXECUTIVE_MANAGEMENT", "SALES_MANAGER", "SALES"].includes(cu.role)
				) {
					return true;
				}
				break;
			case "salesBonus":
				if (["FINANCE"].includes(cu.role)) {
					return true;
				}
				break;
			case "liaisonMeetings":
				if (["SALES_LIAISON", "SALES_LIAISON_MANAGER"].includes(cu.role)) {
					return true;
				}
				break;
			case "purchasing":
				if (
					[
						"EXECUTIVE_MANAGEMENT",
						"PURCHASING",
						"AREA_MANAGER",
						"WAREHOUSE_TECHNICIAN",
						"OFFICE_MANAGER",
					].includes(cu.role)
				) {
					return true;
				}
				break;
			case "marketing":
				if (["EXECUTIVE_MANAGEMENT", "MARKETING"].includes(cu.role)) {
					return true;
				}
				break;
			case "admin":
				if (["EXECUTIVE_MANAGEMENT"].includes(cu.role)) {
					return true;
				}
				break;
			case "followup":
				if (["EXECUTIVE_MANAGEMENT", "FOLLOWUP"].includes(cu.role) || cu.canFollowUp) {
					return true;
				}
				break;
			default:
				break;
		}
	}

	renderRedirect() {
		if (this.state.searchedPatient === true) {
			this.setState({ searchedPatient: false });
			return <Redirect to={"/patientList/" + this.state.searchText} />;
		} else if (this.state.orderFound === true) {
			this.setState({
				orderFound: false,
				orderModalOpen: false,
			});
			return <Redirect to={"/order/" + this.state.orderId} />;
		} else {
			return <div />;
		}
	}

	renderOrderIdSearch() {
		return (
			<NavItem>
				<NavLink
					onClick={() => this.setState({ orderModalOpen: true })}
					to="#!"
				>
					<AiOutlineSearch
						size="2em"
						data-place={"bottom"}
						data-tip={"Search By Order Id"}
						style={{ color: "#5881C1" }}
					/>
				</NavLink>
			</NavItem>
		);
	}

	renderReverseQualityModalIcon() {
		let t = this;

		function showLogs() {
			if (t.hasAccess("admin") === true) {
				return (
					<DropdownItem>
						<NavLink style={{ padding: 0 }} to="/reverseQualityLogs">
							Reverse Quality Logs
						</NavLink>
					</DropdownItem>
				);
			}
		}

		return (
			<NavItem>
				<Dropdown size="sm">
					<DropdownToggle nav caret style={{ color: "#5881C1" }}>
						<MdErrorOutline
							size="2em"
							data-place={"bottom"}
							data-tip={"Reverse Quality"}
							style={{ color: "#5881C1" }}
						/>
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<NavItem>
								<NavLink
									style={{ padding: 0 }}
									onClick={() => this.setState({ rqModalOpen: true })}
									to="#!"
								>
									Report Reverse Quality
								</NavLink>
							</NavItem>
						</DropdownItem>
						{showLogs()}
					</DropdownMenu>
				</Dropdown>
			</NavItem>
		);
	}

	renderReportsNav() {
		return (
			<NavItem>
				<NavLink to="/reports">
					<AiOutlineBarChart
						size="2em"
						data-place={"bottom"}
						data-tip={"Reports"}
						style={{ color: "#5881C1" }}
					/>
				</NavLink>
			</NavItem>
		);
	}

	renderFollowUp() {
		if (this.hasAccess("followup")) {
			return (
				<DropdownItem>
					<NavLink style={{ padding: 0 }} to="/followup">
						Follow-Up
					</NavLink>
				</DropdownItem>
			);
		}
	}

	renderOperationsNav() {
		return (
			<NavItem>
				<Dropdown size="sm">
					<DropdownToggle nav caret style={{ color: "#5881C1" }}>
						<GiGears
							size="2em"
							data-place={"bottom"}
							data-tip={"Operations"}
							style={{ color: "#5881C1" }}
						/>
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/order">
								Orders
							</NavLink>
						</DropdownItem>

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/patientList">
								Patients
							</NavLink>
						</DropdownItem>

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/deliveries">
								Deliveries
							</NavLink>
						</DropdownItem>

						{this.renderFollowUp()}

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/billing">
								Billing
							</NavLink>
						</DropdownItem>

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/collections">
								Patient Collections
							</NavLink>
						</DropdownItem>

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/feedback">
								Customer Feedback
							</NavLink>
						</DropdownItem>
					</DropdownMenu>
				</Dropdown>
			</NavItem>
		);
	}

	renderSalesNav() {
		return (
			<NavItem>
				<Dropdown size="sm">
					<DropdownToggle nav caret style={{ color: "#5881C1" }}>
						<GiShakingHands
							size="2em"
							data-place={"bottom"}
							data-tip={"Sales"}
							style={{ color: "#5881C1" }}
						/>
					</DropdownToggle>
					<DropdownMenu>
						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/accounts">
								Accounts
							</NavLink>
						</DropdownItem>

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/activities">
								Activities
							</NavLink>
						</DropdownItem>

						<DropdownItem>
							<NavLink style={{ padding: 0 }} to="/contacts">
								Contacts
							</NavLink>
						</DropdownItem>

						{this.renderPresidentsClubNav()}
						{this.renderBonusNav()}
						{this.renderLiaisonMeetingsNav()}
					</DropdownMenu>
				</Dropdown>
			</NavItem>
		);
	}

	renderPresidentsClubNav() {
		if (this.hasAccess("presidentsClub")) {
			return (
				<DropdownItem>
					<NavLink style={{ padding: 0 }} to="/presidentsClub">
						Presidents Club
					</NavLink>
				</DropdownItem>
			);
		}
	}

	renderBonusNav() {
		if (this.hasAccess("salesBonus")) {
			return (
				<DropdownItem>
					<NavLink style={{ padding: 0 }} to="/bonus">
						Sales Bonus
					</NavLink>
				</DropdownItem>
			);
		}
	}

	renderLiaisonMeetingsNav() {
		if (this.hasAccess("liaisonMeetings")) {
			return (
				<DropdownItem>
					<NavLink style={{ padding: 0 }} to="/liaisonMeetings">
						Sales Liaison Meetings
					</NavLink>
				</DropdownItem>
			);
		}
	}

	renderExpensesNav() {
		return (
			<NavItem>
				<NavLink to="/expenses">
					<FaDollarSign
						size="2em"
						data-place={"bottom"}
						data-tip={"Expenses"}
						style={{ color: "#5881C1" }}
					/>
				</NavLink>
			</NavItem>
		);
	}

	renderPurchasingNav() {
		if (this.hasAccess("purchasing")) {
			return (
				<NavItem>
					<Dropdown size="sm">
						<DropdownToggle nav caret style={{ color: "#5881C1" }}>
							<FaBoxes
								size="2em"
								data-place={"bottom"}
								data-tip={"Purchasing"}
								style={{ color: "#5881C1" }}
							/>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/purchaseQueues">
									Purchase Queues
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink
									style={{ padding: 0 }}
									to="#!"
									onClick={() => {
										this.setState({ inventoryRequestOpen: true });
									}}
								>
									Inventory Request
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/inventoryProducts">
									Inventory Products
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/vendors">
									Vendors
								</NavLink>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</NavItem>
			);
		}
		return <div />;
	}

	renderMarketingNav() {
		if (this.hasAccess("marketing")) {
			return (
				<NavItem>
					<Dropdown size="sm">
						<DropdownToggle nav caret style={{ color: "#5881C1" }}>
							<MdPermMedia
								size="2em"
								data-place={"bottom"}
								data-tip={"Marketing"}
								style={{ color: "#5881C1" }}
							/>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/campaignList">
									Campaigns
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/campaignCategories">
									Categories
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/campaignCharts">
									Charts
								</NavLink>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</NavItem>
			);
		}
		return <div />;
	}

	renderAdminNav() {
		if (this.hasAccess("admin")) {
			return (
				<NavItem>
					<Dropdown size="sm">
						<DropdownToggle nav caret style={{ color: "#5881C1" }}>
							<MdSecurity
								size="2em"
								data-place={"bottom"}
								data-tip={"Admin"}
								style={{ color: "#5881C1" }}
							/>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/userList">
									Users
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/thirdPartyUserList">
									Third Party Users
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/products">
									Product Categories
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/productSizes">
									Product Sizes
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/insurance">
									Payor Sources
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/insuranceTypes">
									Insurance
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/accountTypes">
									Account Types
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/notificationTypes">
									Notification Types
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/contactTypes">
									Contact Types
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/creditCardTypes">
									Credit Card Types
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/expenseTypes">
									Expense Types
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/documentTypes">
									Document Types
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/deliveryAcknowledgements">
									Delivery Form Acknowledgments
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/reasons">
									Status Reasons
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/deployments">
									Deployment
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/locations">
									Locations
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/stateSalesTax">
									State Sales Tax
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/customerFeedback">
									Customer Feedback Settings
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/trainingLink">
									Patient Training Videos
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/manualDocumentBuilder">
									Manual Document Builder
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/version">
									Web Version
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/productMatrixEntry">
									Product Matrix Entries
								</NavLink>
							</DropdownItem>

							<DropdownItem>
								<NavLink style={{ padding: 0 }} to="/patientApprovals">
									Patient App Approvals
								</NavLink>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</NavItem>
			);
		}
		return <div />;
	}

	renderLogoutNav() {
		return (
			<NavItem>
				<NavLink
					onClick={() => {
						this.setUserFunction(null);
						this.setCompanyFunction("Rehab Medical", "rehabmedical");
					}}
					to={"/login"}
				>
					<FiLogOut
						size="2em"
						data-place={"bottom"}
						data-tip={"Logout"}
						style={{ color: "#5881C1" }}
					/>
				</NavLink>
			</NavItem>
		);
	}

	renderNavBarItems() {
		//TODO: remove this check...BC
		if (this.isEmpty(this.state.currentUser)) {
			//don't need a navbar here.  show nothing...BC
			return <div />;
		} else {
			return (
				<Navbar
					color={"white"}
					style={{ paddingTop: 0, paddingBottom: 0 }}
					expand="xs"
					fixed="top"
				>
					<ReactTooltip />
					<NavbarBrand
						style={{ fontWeight: 500, color: "#5881C1" }}
						href="/dashboard"
					>
						<img
							src={require("./images/salesPilotWebLogo.png")}
							alt={"Sales Pilot Logo"}
						/>
						&nbsp; Sales Pilot
					</NavbarBrand>

					<NavbarNav>
						{this.renderOrderIdSearch()}
						{this.renderReverseQualityModalIcon()}
						{this.renderReportsNav()}
						{this.renderOperationsNav()}
						{this.renderSalesNav()}
						{this.renderExpensesNav()}
						{this.renderPurchasingNav()}
						{this.renderMarketingNav()}
						{this.renderAdminNav()}
						{this.renderLogoutNav()}
					</NavbarNav>
				</Navbar>
			);
		}
	}

	render() {
		return (
			<Router>
				<div className="flyout App-background">
					<div>
						{this.renderOrderSearchModal()}
						{this.renderReverseQualityModal()}
						{this.renderInventoryRequestModal()}
						{this.renderRedirect()}
						{this.renderNavBarItems()}

						<main className={"App-main"}>
							<Routes />
						</main>
					</div>

					<div>
						<Footer className={"App-footer"}>
							<p className="text-center" style={{ margin: "2px" }}>
								&copy; {new Date().getFullYear()} Rehab Medical &nbsp;
								<span style={{ color: "#B2B2B2" }}>{this.state.company}</span>
							</p>
						</Footer>
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
