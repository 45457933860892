import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	CardHeader,
	Input,
	toast,
	MDBIcon,
} from "mdbreact";
import CreditCardService from "./creditCardTypesService";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";

export default class addCreditCardType extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			errorMessage: "",
		};
	}

	nameChange = (e) => {
		this.setState({
			name: e.target.value,
		});
	};

	renderAddCCButton() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					label={"Credit Card Type Name"}
					onChange={this.nameChange.bind(this)}
					value={this.state.name}
				/>
			</div>
		);
	}

	addSaveButtonClick = () => {
		if (this.validate()) {
			let obj = {
				name: this.state.name,
			};
			return CreditCardService.createCreditCardType(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						name: "",
					});
					toast.success(obj.name + " Credit Card Type has been Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred adding a new Credit Card Type");
				});
		}
	};

	validate() {
		let n = this.state.name;
		if (n === "") {
			this.setError("Please enter a Credit Card Name");
			return false;
		}
		return true;
	}
	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container className="mt-5">
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							<Button
								style={{ float: "left" }}
								floating
								size="sm"
								color={"warning"}
								data-tip={"Back to Credit Card Types"}
								onClick={() => this.props.history.push("/creditCardTypes")}
							>
								<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
							</Button>
							Add New Credit Card Type
							<Button
								style={{ float: "right" }}
								floating
								size="sm"
								color={"success"}
								data-tip={"Save New Credit Card Type"}
								onClick={this.addSaveButtonClick}
							>
								<MDBIcon icon="check" style={{ fontSize: "2em" }} />
							</Button>
						</CardHeader>
						<ReactTooltip />
						<CardBody>{this.renderAddCCButton()}</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}
