import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";

export default class insuranceSubType extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Payor Source",
					field: "payorsource",
					sort: "asc",
				},
				{
					label: "Display Order",
					field: "displayorder",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			currInsurance: {
				id: -1,
				name: "",
				order: "",
				insuranceType: {
					name: "",
				},
			},
			currIndex: -1,
			errorMessage: "",
			insuranceNames: [],
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveInsurance();
		this.retrieveInsuranceNames();
	}

	retrieveInsuranceNames() {
		let t = this;
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [];

				res.forEach((insurance, index) => {
					arr.push({
						label: insurance.name,
						value: insurance,
					});
				});

				t.setState({
					insuranceNames: arr,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	retrieveInsurance() {
		let t = this;
		return InsuranceService.findAllSubInsurance()
			.then((res) => {
				let arr = [],
					d = this.state.data;

				res.forEach((insurance, index) => {
					arr.push({
						id: insurance.id,
						name: insurance.name,
						payorsource: insurance.insuranceType.name,
						displayorder:
							typeof insurance.order === "number"
								? insurance.order
								: "no order",
						edit: t.renderEditButton(insurance, index),
						remove: t.renderRemoveButton(insurance.id),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(insurance, index) {
		return (
			<Button
				floating
				size="sm"
				onClick={() => {
					this.handleEditClick(insurance, index);
				}}
			>
				<MDBIcon icon="edit" />
			</Button>
		);
	}

	renderRemoveButton(id) {
		return (
			<Button
				floating
				color={"red"}
				size="sm"
				onClick={() => this.handleRemoveClick(id)}
			>
				<MDBIcon icon="trash" />
			</Button>
		);
	}

	handleRemoveClick(id) {
		InsuranceService.deleteInsuranceSubType(id)
			.then((res) => {
				this.retrieveInsurance();
				toast.success("Insurance Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Insurance");
			});
	}

	handleEditClick(insurance, index) {
		let i = JSON.parse(JSON.stringify(insurance));
		let names = this.state.insuranceNames;
		// Have to do this since its the insurance obj is missing the order number initially
		for (let e in names) {
			if (names[e].value.id === i.insuranceType.id) {
				i.insuranceType = names[e].value;
				break;
			}
		}

		this.setState({
			editModal: !this.state.editModal,
			currInsurance: i,
			currIndex: index,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#90A4AE", color: "white" }}
					toggle={this.editToggle}
				>
					Insurance
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Insurance"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderModalBody() {
		if (this.state.editModal) {
			let i = this.state.currInsurance.insuranceType.name;
			return (
				<div>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						label="Name"
						value={this.state.currInsurance.name}
						onChange={this.handleEditChange.bind(this, "name")}
					/>
					<Select
						placeholder={i}
						options={this.state.insuranceNames}
						onChange={this.handleEditChange.bind(this, "insurancetype")}
					/>
					<Input
						label="Order"
						value={String(this.state.currInsurance.order)}
						onChange={this.handleEditChange.bind(this, "order")}
					/>
				</div>
			);
		}
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let obj = this.state.currInsurance,
				d = this.state.data,
				i = this.state.currIndex;
			return InsuranceService.updateSubInsurance(obj)
				.then((res) => {
					d.rows[i].name = obj.name;
					d.rows[i].displayorder = obj.order;
					d.rows[i].payorsource = obj.insuranceType.name;
					this.setState({
						data: d,
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					toast.success("Insurance Edited!");
				})
				.catch((err) => {
					toast.warn(
						"An error occurred: Make sure order number is different from others"
					);
				});
		}
	};

	validateEdit() {
		let i = this.state.currInsurance;
		if (i.name === "") {
			this.setErrorMessage("Please enter a Name");
			return false;
		} else if (i.order === "") {
			this.setErrorMessage("Please enter an Order Number");
			return false;
		}
		return true;
	}

	setErrorMessage(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	handleEditChange(property, e) {
		let i = this.state.currInsurance;

		if (property !== "insurancetype") {
			i[property] = e.target.value;
		} else {
			i.insuranceType = e.value;
		}

		this.setState({
			currInsurance: i,
		});
	}

	addNewClick = () => {
		return this.props.history.push({
			pathname: "insuranceTypes/new",
		});
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Insurance
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Insurance Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				{this.renderEditModal()}
				<ReactTooltip />
			</Container>
		);
	}
}
