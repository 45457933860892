import React from "react";
import {
	MDBCard,
	MDBCardBody,
	MDBCardHeader,
	MDBCol,
	MDBIcon,
	MDBModal,
	MDBModalBody,
	MDBRow,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import NoteService from "../../Security/NoteService/noteService";

export default class NotesTab extends React.Component {
	constructor(props) {
		super(props);

		this.toggleNoteModal = props.toggleNoteModal;

		this.state = {
			allNotes: props.allNotes,
			editNoteOpen: false,
			taskCompleted: true,
			modalNote: {},
			noteAssignedTo: null,
			eOrder: props.eOrder,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allUsers: PropTypes.array,
		timezoneVariation: PropTypes.object,
		allLiaisons: PropTypes.array,
		fullLiaisonKeys: PropTypes.array,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.allNotes !== prevState.allNotes) {
			return { allNotes: nextProps.allNotes };
		} else if (nextProps.eOrder !== prevState.eOrder) {
			return { eOrder: nextProps.eOrder };
		} else return null;
	}

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
			hour: "numeric",
			minute: "numeric",
		}).format(date);
	}

	sortNotes(allNotes, task) {
		let t = [],
			n = [];

		allNotes.forEach((note) => {
			if (note.completed === false) {
				t.push(note);
			} else {
				n.push(note);
			}
		});

		if (task) {
			return t;
		}
		return n;
	}

	updateNoteModal(note) {
		const { currentUser } = this.context;

		let nText = document.getElementById("noteTextArea").value;

		let upDate = "Updated: " + this.formatDate(new Date()),
			by = " -- By: " + currentUser.username;
		note.text = nText + " -- " + upDate + by;

		this.updateNote(note, null);
	}

	updateNote(note, complete) {
		const { currentUser } = this.context;

		let notes = this.state.allNotes,
			noteToReplace = notes.findIndex((n) => {
				return n.id === note.id;
			});

		if (noteToReplace > -1) {
			notes.splice(noteToReplace, 1, note);
		}

		if (complete != null) {
			note.completed = complete;

			note.text +=
				" --> Completed by " +
				currentUser.username +
				" on " +
				this.formatDate(new Date());
		}

		return NoteService.updateActivityNote(note)
			.then((res) => {
				this.setState({
					allNotes: notes,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	toggleEditModalPopup(note) {
		const { currentUser } = this.context;

		let currentState = this.state.editNoteOpen,
			ncopy = {};

		if (note) {
			ncopy = JSON.parse(JSON.stringify(note));

			if (currentUser) {
				if (
					currentUser.role === "ADMIN" ||
					currentUser.role === "SYSTEM_ADMIN"
				) {
					//we are fine, continue
				} else if (currentUser.username !== note.createdBy.name) {
					//they do not own this note.  They shouldn't be able to edit it.
					return;
				} else {
					//determine if within 24 hours...BC
					let ca = note.createdAt,
						n = new Date().getTime();

					if (n - ca < 86400000) {
						//this note is within 24 hours, continue...BC
					} else {
						//This note is older than 24 hours.  do not continue...BC
						return;
					}
				}
			} else {
				return;
			}
		}

		this.setState({
			editNoteOpen: !currentState,
			modalNote: ncopy,
		});
	}

	generateNote(note) {
		return (
			<MDBCard style={{ backgroundColor: "#FFF7D1" }}>
				<MDBCardHeader style={{ backgroundColor: "#FFF2AB" }}>
					<MDBRow>
						<MDBCol size={"5"}>
							{this.formatDate(new Date(note.createdAt))}
						</MDBCol>

						<MDBCol size={"5"}>
							{note.createdBy ? note.createdBy.name : ""}
						</MDBCol>

						<MDBCol onClick={() => this.toggleEditModalPopup(note)} size={"2"}>
							<MDBIcon
								style={{ color: "#857E59", padding: 4, float: "right" }}
								data-tip={"Edit"}
								icon="ellipsis-v"
							/>
						</MDBCol>
					</MDBRow>
				</MDBCardHeader>

				<MDBCardBody>
					<p style={{ whiteSpace: "pre-line" }}>{note.text}</p>
				</MDBCardBody>
			</MDBCard>
		);
	}

	renderNotesSection() {
		let notes = this.sortNotes(this.state.allNotes);

		return (
			<div>
				{notes.map((n, idx) => {
					return (
						<div key={idx} style={{ paddingBottom: 6 }}>
							{this.generateNote(n)}
						</div>
					);
				})}
			</div>
		);
	}

	generateTask(note) {
		function renderAssignedTo() {
			if (note.assignedTo) {
				return (
					<span style={{ fontSize: 14 }}>
						{" "}
						<MDBIcon
							icon="long-arrow-alt-right"
							data-tip={"Assigned To"}
						/>{" "}
						{note.assignedTo.name}
					</span>
				);
			}
		}

		return (
			<MDBCard style={{ backgroundColor: "#FFE4F1" }}>
				<MDBCardHeader style={{ backgroundColor: "#FFCCE5" }}>
					<MDBRow>
						<MDBCol size={"5"} style={{ fontSize: 14 }}>
							{this.formatDate(note.createdAt)}
						</MDBCol>

						<MDBCol size={"5"} style={{ fontSize: 14 }}>
							{note.createdBy ? note.createdBy.name : ""}
							{renderAssignedTo()}
						</MDBCol>

						<MDBCol siz={"2"}>
							<MDBIcon
								onClick={() => this.toggleEditModalPopup(note)}
								style={{
									color: "#7B626E",
									float: "right",
									padding: "4px 4px 4px 8px",
								}}
								data-tip={"Edit"}
								icon="ellipsis-v"
							/>
							<MDBIcon
								style={{
									color: "#7B626E",
									float: "right",
									padding: "4px 8px 4px 4px",
								}}
								onClick={() => this.updateNote(note, true)}
								data-tip={"Complete"}
								icon="check"
							/>
						</MDBCol>
					</MDBRow>
				</MDBCardHeader>

				<MDBCardBody>
					<p style={{ whiteSpace: "pre-line" }}>{note.text}</p>
				</MDBCardBody>
				<ReactTooltip />
			</MDBCard>
		);
	}

	renderTasksSection() {
		let tasks = this.sortNotes(this.state.allNotes, true);

		return (
			<div>
				{tasks.map((n, idx) => {
					return (
						<div key={idx} style={{ paddingBottom: 6 }}>
							{this.generateTask(n)}
						</div>
					);
				})}
			</div>
		);
	}

	renderEditNoteModal() {
		let note = this.state.modalNote,
			isTask = note.completed === false;

		let bgClass = "orderNotesModal",
			bgColor = "#FFF7D1";

		if (isTask) {
			bgClass = "orderTasksModal";
			bgColor = "#FFE4F1";
		}
		return (
			<MDBModal
				className={bgClass}
				centered
				isOpen={this.state.editNoteOpen}
				toggle={() => this.toggleEditModalPopup()}
			>
				<MDBModalBody>
					<MDBRow>
						<MDBCol size="12">
							<textarea
								id={"noteTextArea"}
								style={{
									width: "100%",
									height: "200px",
									backgroundColor: bgColor,
								}}
							>
								{note.text}
							</textarea>
						</MDBCol>

						<MDBCol size="12">
							<span>Updated: {this.formatDate(new Date())}</span>

							<MDBIcon
								style={{ color: "#7B626E", float: "right", padding: 4 }}
								data-tip={"Update"}
								icon="check"
								onClick={() => {
									this.updateNoteModal(note);
									this.toggleEditModalPopup();
								}}
							/>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
				<ReactTooltip />
			</MDBModal>
		);
	}

	render() {
		return (
			<div>
				{this.renderEditNoteModal()}
				<MDBRow>
					<MDBCol className={"pvNotesGroupOuterContainer"} size={6}>
						<div className={"pvNotesGroupInnerContainer"}>
							{this.renderNotesSection()}
						</div>
					</MDBCol>

					<MDBCol className={"pvNotesGroupOuterContainer"} size={6}>
						<div className={"pvNotesGroupInnerContainer"}>
							{this.renderTasksSection()}
						</div>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}
}
