import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	Input,
	toast,
	CardHeader,
	MDBIcon,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";

export default class addInsuranceSubType extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			payorSource: {},
			order: "",
			isLoaded: false,
			insuranceNames: [],
		};
	}

	componentDidMount() {
		this.retrieveInsuranceNames();
	}

	retrieveInsuranceNames() {
		let t = this;
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [];

				res.forEach((insurance) => {
					arr.push({
						label: insurance.name,
						value: insurance,
					});
				});

				t.setState({
					insuranceNames: arr,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value,
		});
	};

	handleOrderChange = (e) => {
		this.setState({
			order: e.target.value,
		});
	};

	handleInsuranceNameChange(e) {
		this.setState({
			payorSource: e.value,
		});
	}

	renderAddProductButton() {
		let i = this.state.insuranceNames;
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					label={"Insurance Name"}
					onChange={this.handleNameChange.bind(this)}
					value={this.state.name}
				/>
				<Select
					placeholder={"Payor Source"}
					options={i}
					onChange={this.handleInsuranceNameChange.bind(this)}
				/>
				<Input
					label={"Order"}
					onChange={this.handleOrderChange.bind(this)}
					value={this.state.order}
				/>
			</div>
		);
	}

	saveButtonClick = () => {
		if (this.validate()) {
			let obj = {
				name: this.state.name,
				order: this.state.order,
				insuranceType: this.state.payorSource,
			};
			return InsuranceService.createSubInsurance(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						name: "",
						order: "",
						payorSource: {},
					});
					toast.success(obj.name + " Insurance has been Added!");
				})
				.catch((err) => {
					toast.warn(
						"An error occurred: Make sure order number is different from others"
					);
				});
		}
	};

	validate() {
		let n = this.state.name,
			o = this.state.order,
			i = this.state.payorSource;
		if (n === "") {
			this.setError("Please enter an Insurance name");
			return false;
		} else if (Object.keys(i).length === 0) {
			this.setError("Please choose a Payor Source");
			return false;
		} else if (o === "") {
			this.setError("Please enter an Order Number");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container className="mt-5">
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							<Button
								style={{ float: "left" }}
								floating
								size="sm"
								color={"warning"}
								data-tip={"Back to Insurance Types"}
								onClick={() => this.props.history.push("/insuranceTypes")}
							>
								<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
							</Button>
							Add New Insurance
							<Button
								style={{ float: "right" }}
								floating
								size="sm"
								color={"success"}
								data-tip={"Save New Insurance Type"}
								onClick={this.saveButtonClick}
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
						</CardHeader>

						<CardBody>{this.renderAddProductButton()}</CardBody>
					</Card>
					<ReactTooltip />
				</Container>
			</div>
		);
	}
}
