import api from "../Security/api";

class ManualDocumentBuilderService {
	async createEquipmentDeliveryDocument(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/equipmentDeliveryPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createSafetyAssessment(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/safetyAssessmentPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createHomeAssessment(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/homeAssessmentPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createABN(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/abnPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createPickup(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/pickUpPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createServiceTicket(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/serviceTicketPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createComplexPaperwork(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/complexPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createSalesAgreement(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/salesAgreementPDF", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

    async createDonationForm(values){
        return new Promise((res, reject) => {
            api.post('/deliveryPaperwork/donationFormPDF', values).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            })
        })
    };

    async createEmailDocument(values){
        return new Promise((res, reject) => {
            api.post('/deliveryPaperwork/emailPDF', values).then(data => {
                res(data);
            }).catch(err => {
                reject(err);
            })
        })
    };

	async mergePaperwork(values) {
		return new Promise((res, reject) => {
			api
				.post("/deliveryPaperwork/merge", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getDeliveryDocs(values) {
		return new Promise((res, reject) => {
			api
				.get("/getDeliveryDocs", values)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const manualDocumentBuilderService = new ManualDocumentBuilderService();

export default manualDocumentBuilderService;
