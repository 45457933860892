import React from "react";
import {
	Button,
	Container,
	Card,
	CardBody,
	CardHeader,
	Col,
	DataTable,
	Row,
	Spinner,
	toast,
	ToastContainer,
	DatePicker,
	Fa,
	MDBIcon,
} from "mdbreact";
import ReportsService from "../Security/ReportsService/reportsService";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class bonusReport extends React.Component {
	constructor(props) {
		super(props);

		//table headers & columns
		const bonusData = {
			columns: [
				{
					label: "Order Id",
					field: "orderID",
					sort: "asc",
					width: 200,
				},
				{
					label: "Order Type",
					field: "orderType",
					sort: "asc",
					width: 200,
				},
				{
					label: "Status",
					field: "status",
					sort: "asc",
					width: 200,
				},
				{
					label: "Reason",
					field: "reason",
					sort: "asc",
					width: 200,
				},
				{
					label: "Username",
					field: "username",
					sort: "asc",
					width: 200,
				},
				{
					label: "Patient Name",
					field: "patientName",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 200,
				},
				{
					label: "Setup Date",
					field: "setupDate",
					sort: "asc",
					width: 200,
				},
				{
					label: "Payor Source",
					field: "payorSource",
					sort: "asc",
					width: 200,
				},
				{
					label: "Insurance",
					field: "insurance",
					sort: "asc",
					width: 200,
				},
				{
					label: "Category",
					field: "category",
					sort: "asc",
					width: 200,
				},
				{
					label: "Product",
					field: "product",
					sort: "asc",
					width: 200,
				},
				{
					label: "Total Points",
					field: "totalPoints",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			allResults: [],
			bonusData: bonusData,
			startDate: "",
			endDate: "",
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	getBonusReport() {
		let st = this.state,
			filters = {};

		if (!st.startDate || !st.endDate) {
			toast.error("Please check your dates");
			return;
		}

		filters.startDate = st.startDate.toLocaleDateString();
		filters.endDate = st.endDate.toLocaleDateString();

		this.setState({ isLoaded: false });

		return ReportsService.getBonusReport(filters)
			.then((res) => {
				this.setState({ allResults: res });

				this.formatRows();
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	handleDatePickerChange = (property, value) => {
		//update the date property on the object that the user has edited in the order...BC
		this.setState({ [property]: value });
	};

	formatRows() {
		let setups = this.state.allResults,
			dt = this.state.bonusData,
			ary = [];

		setups.forEach((sale) => {
			ary.push({
				orderId: sale.id,
				orderType: sale.orderType,
				status: sale.status,
				reason: sale.reason,
				username: sale.username,
				patientName: sale.patientName
					? sale.patientName.replace(/,/g, " ")
					: "No Patient Name",
				account: sale.account ? sale.account.replace(/,/g, " ") : "No Account",
				setupDate: sale.setupDate,
				payorSource: sale.payorSource,
				insurance: sale.insurance || "No Insurance",
				category: sale.category
					? sale.category.replace(/,/g, " ")
					: "No Category",
				product: sale.product ? sale.product.replace(/,/g, " ") : "No Product",
				totalPoints: sale.totalPoints,
			});
		});
		dt.rows = ary;

		this.setState({
			bonusData: dt,
			isLoaded: true,
		});
	}

	//call to download data
	clientCSV(stateData, filename) {
		let result = "",
			ctr = 0,
			keys,
			columnDelimiter = ",",
			lineDelimiter = "\n";

		if (stateData == null || !stateData.rows.length) {
			toast.warn("No Data To Export");
			return;
		}

		keys = Object.keys(stateData.rows[0]);

		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		stateData.rows.forEach(function (item) {
			ctr = 0;

			keys.forEach(function (key) {
				if (ctr > 0) {
					result += columnDelimiter;
				}

				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//csv download data here
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//get the data in the table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		const { currentUser } = this.context;
		if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "FINANCE"
		) {
			return (
				<div style={{ paddingBottom: "4%" }}>
					<Card style={{ marginLeft: "4%", marginRight: "4%" }}>
						<ToastContainer
							hideProgressBar={false}
							newestOnTop={true}
							autoClose={5000}
						/>

						<CardHeader color={"indigo"}>
							<Row style={{ display: "flex", justifyContent: "space-around" }}>
								<Col size={"2"} style={{ color: "#FFF" }}>
									<div className="expenseDateOptions">
										<DatePicker
											format="MM/DD/YYYY"
											placeholder={"No Date"}
											valueDefault={"No Date"}
											invalidDateMessage={""}
											showTodayButton
											value={this.state.startDate || ""}
											getValue={this.handleDatePickerChange.bind(
												this,
												"startDate"
											)}
											mask={[
												/\d/,
												/\d/,
												"/",
												/\d/,
												/\d/,
												"/",
												/\d/,
												/\d/,
												/\d/,
												/\d/,
											]}
											label="Start Date"
										/>
									</div>
								</Col>

								<Col size={"2"} style={{ color: "#FFF" }}>
									<div className="expenseDateOptions">
										<DatePicker
											format="MM/DD/YYYY"
											placeholder={"No Date"}
											valueDefault={"No Date"}
											invalidDateMessage={""}
											showTodayButton
											value={this.state.endDate || ""}
											getValue={this.handleDatePickerChange.bind(
												this,
												"endDate"
											)}
											mask={[
												/\d/,
												/\d/,
												"/",
												/\d/,
												/\d/,
												"/",
												/\d/,
												/\d/,
												/\d/,
												/\d/,
											]}
											label="End Date"
										/>
									</div>
								</Col>

								<Col size={"5"} />

								<Col size={"1"} style={{ textAlign: "right" }}>
									<Button
										floating
										rounded
										size={"sm"}
										data-tip={"Search"}
										style={{ marginTop: "1.5rem" }}
										onClick={() => this.getBonusReport()}
										color={"warning"}
									>
										{/*<i className="fa fa-clock-o" style={{fontSize: "2em"}}/>*/}
										<Fa icon={"search"} />
									</Button>
								</Col>
							</Row>
						</CardHeader>

						<CardBody>
							<DataTable
								entriesOptions={[10, 50, 100]}
								entries={10}
								pagesAmount={10}
								data={this.state.bonusData}
								responsive
								fixed
								striped
								bordered
							></DataTable>

							<Button
								className={"downloadCsvButton"}
								floating
								size="sm"
								color={"indigo"}
								data-tip={"Download CSV"}
								onClick={this.clientCSV.bind(
									this,
									this.state.bonusData,
									"bonusExport.csv"
								)}
							>
								<MDBIcon icon="download" style={{ fontSize: "2em" }} />
							</Button>

							<ReactTooltip />
						</CardBody>
					</Card>
					<ReactTooltip />
				</div>
			);
		} else {
			return (
				<div>
					<h1>Not Authorized to view this page</h1>
				</div>
			);
		}
	}
}
