import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "./Seating/Login/login";
import PatientOrderView from "./Seating/Patient/patientOrderView";
import PatientInformationView from "./Seating/Patient/patientInformationView";
import Home from "./Seating/Home/home";
import UserList from "./Seating/Users/userList";
import UserEdit from "./Seating/Users/userEdit";
import UserAdd from "./Seating/Users/userAdd";
import ThirdPartyUserList from "./Seating/Users/thirdPartyUserList";
import Kiosk from "./Seating/Home/kiosk";
import Dashboard from "./Seating/Home/dashboard";
import TaskList from "./Seating/Home/taskList";
import OrderList from "./Seating/Orders/orderList";
import PatientList from "./Seating/Patient/patientList";
import Locations from "./Seating/Settings/locations";
import IssueCategoryList from "./Seating/Complaints/CustomerComplaintsSettings";
import ComplaintsList from "./Seating/Complaints/CustomerComplaintsList";
import DeliveryManifest from "./Seating/Patient/deliveryManifest";
import MapComponentWindow from "./Seating/Deliveries/mapComponentWindow";
import ForgotPassword from "./Seating/Login/forgotPassword";
import PurchaseQueueView from "./Seating/PurchaseQueue/purchaseQueueView";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import StateSalesTax from "./Seating/Settings/stateSalesTax";
import DailyOrderCounts from "./Seating/Reports/dailyOrderCounts";
import PatientApprovals from "./Seating/Settings/patientApprovals";
import ReverseQualityList from "./Seating/Settings/reverseQualityList";
//account files
import AccountList from "./Seating/Accounts/accountList";
import AccountEdit from "./Seating/Accounts/accountEdit";
import AccountAdd from "./Seating/Accounts/accountAdd";
import AccountDetail from "./Seating/Accounts/accountDetail";
//activity files
import ActivityList from "./Seating/Activities/activities-list";
import ActivityEdit from "./Seating/Activities/activities-edit";
import ActivityAdd from "./Seating/Activities/activities-add";
//settings files
import AddVersion from "./Seating/Settings/addVersion";
import ProductTypes from "./Seating/Settings/productTypes";
import EditProductSubType from "./Seating/Settings/editProductSubTypes";
import AddProductType from "./Seating/Settings/addProductType";
import AddProductSubType from "./Seating/Settings/addProductSubType";
import Insurance from "./Seating/Settings/insurance";
import InsuranceSubType from "./Seating/Settings/insuranceSubType";
import AddInsurance from "./Seating/Settings/addInsurance";
import AddInsuranceSubType from "./Seating/Settings/addInsuranceSubType";
import ProductSize from "./Seating/Settings/productSize";
import AddProductSize from "./Seating/Settings/addProductSize";
import AccountTypes from "./Seating/Settings/accountTypes";
import NotificationTypes from "./Seating/Settings/notificationTypes";
import AddAccountType from "./Seating/Settings/addAccountType";
import ContactTypes from "./Seating/Settings/contactTypes";
import AddContactType from "./Seating/Settings/addContactType";
import CreditCardTypes from "./Seating/Settings/creditCardTypes";
import AddCreditCardType from "./Seating/Settings/addCreditCardType";
import DocumentTypes from "./Seating/Settings/documentTypes";
import AddDocumentType from "./Seating/Settings/addDocumentType";
import ExpenseTypes from "./Seating/Settings/expenseTypes";
import AddExpenseType from "./Seating/Settings/addExpenseType";
import DeliveryAcknowledgements from "./Seating/Settings/deliveryAcknowledgements";
import AddDeliveryAcknowledgement from "./Seating/Settings/addDeliveryAcknowledgement";
import Reasons from "./Seating/Settings/reasons";
import Deployment from "./Seating/Settings/deployment";
import Deliveries from "./Seating/Deliveries/deliveries";
import ScheduledDeliveries from "./Seating/Deliveries/scheduledDeliveries";
import DeliveryList from "./Seating/Deliveries/deliveryList";
import CollectionsView from "./Seating/Orders/collectionsView";
import BillingView from "./Seating/Orders/billingView";
import FollowUpView from "./Seating/Orders/followUpView";
import ManualDocBuilder from "./Seating/Settings/manualDocumentBuilder";
import Vendor from "./Seating/Settings/vendor";
import ProductMatrixEntry from "./Seating/Settings/productMatrixEntry";
import InventoryProductView from "./Seating/InventoryProduct/inventoryProductView";

//report files
import Reports from "./Seating/Reports/reports";
import BonusReport from "./Seating/Reports/bonusReport";

//report files
import Expenses from "./Seating/Expenses/expenses-list";
import ExpenseEdit from "./Seating/Expenses/expenses-edit";
import ExpenseAdd from "./Seating/Expenses/expenses-add";

//contact files
import Contacts from "../src/Seating/Contacts/contact-list";
import ContactEdit from "../src/Seating/Contacts/contact-edit";

// marketing
import CampaignList from "./Seating/Marketing/campaignList";
import CategoryList from "./Seating/Marketing/categoryList";
import CampaignCharts from "./Seating/Marketing/campaignCharts";

// presidents club
import PresidentsClubView from "./Seating/PresidentsClub/presidentsClubView";

// sales liaison meetings
import LiaisonMeetingView from "./Seating/LiaisonMeetings/liaisonMeetingView";

import PatientCopy from "./Seating/Marketing/patientCopy";
import TrainingLink from "./Seating/Settings/trainingLink";

class Routes extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/forgotPassword" component={ForgotPassword} />
				<Route exact path="/" component={Login} />
				<Route exact path="/patients" component={Home} />
				<Route exact path="/order/:id" component={PatientOrderView} />
				<Route
					exact
					path="/patientInformation/:id"
					component={PatientInformationView}
				/>
				<Route exact path="/userList" component={UserList} />
				<Route exact path="/user/:id" component={UserEdit} />
				<Route
					exact
					path="/order/:id/deliveryManifest"
					component={DeliveryManifest}
				/>
				<Route
					exact
					path={"/reverseQualityLogs"}
					component={ReverseQualityList}
				/>

				<Route
					exact
					path="/thirdPartyUserList"
					component={ThirdPartyUserList}
				/>

				<Route exact path="/addUser/" component={UserAdd} />
				<Route exact path="/kiosk" component={Kiosk} />
				<Route exact path="/dashboard" component={Dashboard} />
				<Route exact path="/tasks" component={TaskList} />
				<Route exact path="/order" component={OrderList} />
				<Route exact path="/patientList/:patient" component={PatientList} />
				<Route exact path="/patientList/" component={PatientList} />

				{/* Account Route */}
				<Route exact path="/accounts" component={AccountList} />
				<Route exact path="/account/:id" component={AccountEdit} />
				<Route exact path="/addAccount" component={AccountAdd} />
				<Route exact path="/account/:id/detail" component={AccountDetail} />
				{/* Activity Route */}
				<Route exact path="/activities" component={ActivityList} />
				<Route exact path="/activity/:id" component={ActivityEdit} />
				<Route exact path="/addActivity" component={ActivityAdd} />
				{/*Settings Route */}
				<Route exact path="/version" component={AddVersion} />
				<Route exact path="/products" component={ProductTypes} />
				<Route exact path="/products/new" component={AddProductType} />
				<Route exact path="/products/:id" component={EditProductSubType} />
				<Route exact path="/productType/new" component={AddProductSubType} />
				<Route exact path="/insurance" component={Insurance} />
				<Route exact path="/insurance/new" component={AddInsurance} />
				<Route exact path="/insuranceTypes" component={InsuranceSubType} />
				<Route
					exact
					path="/insuranceTypes/new"
					component={AddInsuranceSubType}
				/>
				<Route exact path={"/stateSalesTax/"} component={StateSalesTax} />
				<Route exact path="/productSizes" component={ProductSize} />
				<Route exact path="/productSizes/new" component={AddProductSize} />
				<Route exact path="/accountTypes" component={AccountTypes} />
				<Route exact path="/NotificationTypes" component={NotificationTypes}/>
				<Route exact path="/accountTypes/new" component={AddAccountType} />
				<Route exact path="/contactTypes" component={ContactTypes} />
				<Route exact path="/contactTypes/new" component={AddContactType} />
				<Route exact path="/customerFeedback/" component={IssueCategoryList} />
				<Route exact path="/trainingLink/" component={TrainingLink} />
				<Route exact path="/creditCardTypes" component={CreditCardTypes} />
				<Route
					exact
					path="/creditCardTypes/new"
					component={AddCreditCardType}
				/>
				<Route exact path="/documentTypes" component={DocumentTypes} />
				<Route exact path="/documentTypes/new" component={AddDocumentType} />
				<Route exact path="/expenseTypes" component={ExpenseTypes} />
				<Route exact path="/expenseTypes/new" component={AddExpenseType} />
				<Route
					exact
					path="/deliveryAcknowledgements"
					component={DeliveryAcknowledgements}
				/>
				<Route
					exact
					path="/deliveryAcknowledgements/new"
					component={AddDeliveryAcknowledgement}
				/>
				<Route exact path="/reasons" component={Reasons} />
				<Route exact path="/deployments" component={Deployment} />
				<Route exact path="/deliveries" component={DeliveryList} />
				<Route exact path="/mapWindow/:id" component={MapComponentWindow} />
				<Route
					exact
					path="/deliveriesList/:id"
					component={ScheduledDeliveries}
				/>
				<Route exact path="/deliveries_old" component={Deliveries} />
				<Route exact path="/billing" component={BillingView} />
				<Route exact path="/collections" component={CollectionsView} />
				<Route exact path="/feedback" component={ComplaintsList} />
				<Route exact path="/locations" component={Locations} />
				<Route exact path="/followup" component={FollowUpView} />
				<Route
					exact
					path="/manualDocumentBuilder"
					component={ManualDocBuilder}
				/>
				<Route exact path="/vendors" component={Vendor} />
				<Route
					exact
					path="/productMatrixEntry"
					component={ProductMatrixEntry}
				/>
				<Route exact path="/patientApprovals" component={PatientApprovals} />

				{/*Report Routes*/}
				<Route exact path="/reports" component={Reports} />
				<Route exact path="/dailyOrderCounts" component={DailyOrderCounts} />

				{/*Expenses Routes*/}
				<Route exact path="/expenses" component={Expenses} />
				<Route exact path="/expenses/:id" component={ExpenseEdit} />
				<Route exact path="/addExpense" component={ExpenseAdd} />

				{/*Purchasing Routes*/}
				<Route exact path="/purchaseQueues" component={PurchaseQueueView} />
				<Route exact path="/inventoryRequest" component={InventoryRequest} />

				{/*Inventory Product Routes*/}
				<Route
					exact
					path="/inventoryProducts"
					component={InventoryProductView}
				/>

				{/*Contacts Routes*/}
				<Route exact path="/contacts" component={Contacts} />
				<Route exact path="/contact/:id" component={ContactEdit} />

				{/*Marketing Routes*/}
				<Route exact path="/campaignList" component={CampaignList} />
				<Route exact path="/campaignCategories" component={CategoryList} />
				<Route exact path="/campaignCharts" component={CampaignCharts} />
				<Route exact path="/patientCopy" component={PatientCopy} />

				{/*President's Club Routes*/}
				<Route exact path="/presidentsClub" component={PresidentsClubView} />

				{/*Liaison Meeting View*/}
				<Route exact path="/liaisonMeetings" component={LiaisonMeetingView} />

				{/*Bonus Points Routes*/}
				<Route exact path="/bonus" component={BonusReport} />

				<Route
					render={function () {
						return <h1>Not Found</h1>;
					}}
				/>
			</Switch>
		);
	}
}

export default Routes;
