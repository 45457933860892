import React from "react";
import {
	Button,
	Card,
	CardBody,
	Col,
	Fa,
	Row,
	MDBSelect,
	MDBIcon,
	toast,
	MDBInput,
	MDBRow,
	MDBCol,
} from "mdbreact";
import Moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./filterStyle.css";
import UserService from "../Security/UserService/userService";
import PropTypes from "prop-types";
import Select from "react-select";
import QuickFilter from "./quickFilter";
import GroupService from "../Security/UserService/groupService";
import ReactTooltip from "react-tooltip";

export default class UserFilters extends React.Component {
	constructor(props) {
		super(props);

		this.newSearchFunction = props.newSearchFunction;

		this.theme = props.theme;

		let f = props.userFilters,
			otherUsers = f.users ? f.users.slice(0) : [],
			startDate = Moment().subtract(11, "months").toDate(),
			endDate = new Date(),
			filterOptions = QuickFilter.getOptions(),
			filterSelected = null;

		//check for null and empty string because 0 could be a value...BC
		if (f.dateFilterType != null && f.dateFilterType !== "") {
			//9 is custom
			if (f.dateFilterType === 9) {
				if (f.startDate) {
					startDate = new Date(f.startDate);
				}

				if (f.endDate) {
					endDate = new Date(f.endDate);
				}
			} else {
				let a = QuickFilter.getDates(f.dateFilterType);

				startDate = a[0];
				endDate = a[1];
			}
		}

		if (f.dateFilterType != null) {
			let v = filterOptions.find((o) => {
				return o.value === f.dateFilterType;
			});

			if (v) {
				filterSelected = v;
			}
		}

		this.state = {
			userFilters: f,
			startDate: startDate,
			endDate: endDate,
			dateFilterType: f.dateFilterType || 0,
			searchText: f.q || "",
			equipmentIds: f.equipmentIds || [],
			statuses: f.statuses || [],
			reasonIds: f.reasonIds || [],
			internalLocations: f.internalLocations || [],
			clinicLocations: f.seatingClinics || [],
			users: f.users || [],
			groupingColumns: props.groupingColumns,
			defaultHiddenColumns: props.defaultHiddenColumns,
			dob: null,
			breakWidth: 1300,
			windowWidth: 0,
			locationsRadio: f.salesServiceClinic || "Sales",
			allEquipment: [],
			allInternalLocations: [],
			allStatuses: [],
			allReasons: [],
			availableReasons: [],
			allSalesReps: [],
			filteredSalesReps: [],
			orderModalOpen: false,

			selectedEquipmentIds: f.equipmentIds,
			selectedStatusNames: f.statuses,
			selectedReasonIds: f.reasonIds,
			selectedLocationIds: f.internalLocations,
			selectedSalesRepIds: f.users || [],

			showDatePicker: true,
			quickFilter: [],
			quickFilterOptions: filterOptions,
			quickFilterSelected: filterSelected,
			otherUsers: otherUsers,
			orderId: "",

			statusOptions: [
				{ label: "New", value: "New" },
				{ label: "In Process", value: "InProcess" },
				{ label: "Ready To Deliver", value: "ReadyToDeliver" },
				{ label: "Set Up", value: "Setup" },
				{ label: "Cancelled", value: "Cancelled" },
			],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		productTypes: PropTypes.array,
		reasons: PropTypes.array,
		salesReps: PropTypes.array,
		internalLocations: PropTypes.array,
		allLocations: PropTypes.array,
		userFunction: () => {},
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			salesReps,
			internalLocations,
			productTypes,
			reasons,
		} = this.context;
		let st = this.state;

		if (st.allInternalLocations.length !== internalLocations.length) {
			this.getLocationOptions();
		}

		if (st.allEquipment.length !== productTypes.length) {
			this.getEquipmentOptions();
		}
		if (st.allReasons.length < reasons.length) {
			this.getReasonOptions();
		}
		if (st.allStatuses.length !== 5) {
			this.getStatusOptions();
		}
		if (st.allSalesReps.length !== salesReps.length) {
			this.getSalesRepOptions();
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.groupingColumns !== this.state.groupingColumns) {
			this.setState({
				groupingColumns: nextProps.groupingColumns,
			});
		}

		if (nextProps.defaultHiddenColumns !== this.state.defaultHiddenColumns) {
			this.setState({
				defaultHiddenColumns: nextProps.defaultHiddenColumns,
			});
		}
	}

	componentDidMount() {
		this.getLocationOptions();
		this.newSearchPass();
	}

	handleResize = () =>
		this.setState({
			windowWidth: window.innerWidth,
		});

	newSearchPass() {
		const { currentUser } = this.context;

		let st = this.state,
			filters = {};

		if (st.quickFilter == null || st.quickFilter.length === 0) {
			if (
				st.startDate == null ||
				st.startDate === "" ||
				st.endDate == null ||
				st.endDate === ""
			) {
				toast.error(
					"You must select a quick date range or input custom start and end dates"
				);
				return;
			}
		}

		if (st.quickFilter && st.quickFilter[0] != null) {
			filters.startDate = st.quickFilter[0].toLocaleDateString();
		} else {
			filters.startDate = st.startDate.toLocaleDateString();
		}

		if (st.quickFilter && st.quickFilter[1] != null) {
			filters.endDate = st.quickFilter[1].toLocaleDateString();
		} else {
			filters.endDate = st.endDate.toLocaleDateString();
		}

		//This should run when we load the page for auto populating the list...BC
		if (st.selectedStatusNames && st.selectedStatusNames.length > 0) {
			filters.statuses = st.selectedStatusNames.toString();
		}

		if (st.selectedReasonIds && st.selectedReasonIds.length > 0) {
			filters.reasons = st.selectedReasonIds.toString();
		}

		filters.locationType = st.locationsRadio;

		if (st.selectedEquipmentIds && st.selectedEquipmentIds.length > 0) {
			filters.equipment = st.selectedEquipmentIds.toString();
		}

		//Don't let sales reps see anything except orders assigned to them...BC
		if (currentUser.role === "SALES") {
			delete filters.locations;
			filters.users = currentUser.id.toString();
		} else {
			if (st.selectedLocationIds && st.selectedLocationIds.length > 0) {
				filters.locations = st.selectedLocationIds.toString();
			}

			//This should only happen on load of the page from going back...BC
			if (st.users && st.users.length > 0) {
				filters.users = st.users.toString();
			}
		}

		this.newSearchFunction(filters);
	}

	saveUserFilters() {
		const { currentUser, userFunction } = this.context;

		let obj = {
			endDate: this.state.endDate,
			startDate: this.state.startDate,
			equipmentIds: this.state.selectedEquipmentIds,
			reasonIds: this.state.selectedReasonIds,
			statuses: this.state.selectedStatusNames,
			internalLocations: this.state.selectedLocationIds,
			seatingClinics: this.state.clinicLocations,
			users: this.state.users,
			groupColumns: this.state.groupingColumns,
			defaultHiddenColumns: this.state.defaultHiddenColumns,
			salesServiceClinic: this.state.locationsRadio,
			dateFilterType: this.state.dateFilterType,
		};

		currentUser.userPreferences = obj;
		let t = this;

		return UserService.updateUser(currentUser)
			.then((res) => {
				userFunction(res);
				toast.success("Saved Filters Successfully!");

				if (res.userPreferences) {
					t.setState({
						users: res.userPreferences.users,
					});
				}
			})
			.catch((err) => {
				toast.error("There was an error saving your filters :(");
			});
	}

	clearUserFilters() {
		this.state.allEquipment.forEach((eq) => {
			eq.checked = false;
		});

		this.state.availableReasons.forEach((r) => {
			r.checked = false;
		});

		this.state.allStatuses.forEach((s) => {
			s.checked = false;
		});

		this.state.filteredSalesReps.forEach((u) => {
			u.checked = false;
		});

		this.state.allInternalLocations.forEach((il) => {
			il.checked = false;
		});

		this.setState({
			equipmentIds: [],
			reasonIds: [],
			statuses: [],
			users: [],
			selectedLocationIds: [],
			startDate: null,
			endDate: null,
			selectedEquipmentIds: [],
			selectedReasonIds: [],
			selectedStatusNames: [],
			internalLocations: [],
			seatingClinics: [],
			groupingColumns: [],
			defaultHiddenColumns: [],
		});

		this.getStatusOptions();
		this.getReasonOptions();
		this.getEquipmentOptions();

		toast.success("Filters have been cleared (not saved)");
	}

	sortStatusReasons(reasons) {
		const groupedOptions = [
			{
				label: "New",
				options: [],
			},
			{
				label: "In Process",
				options: [],
			},
			{
				label: "Ready To Deliver",
				options: [],
			},
			{
				label: "Setup",
				options: [],
			},
			{
				label: "Cancelled",
				options: [],
			},
		];

		reasons.forEach((reason) => {
			let obj = {
				value: reason.id,
				label: reason.description,
			};

			switch (reason.orderStatus) {
				case "New":
					obj.color = "#FF0000";
					groupedOptions[0].options.push(obj);
					break;
				case "InProcess":
					obj.color = "#FF6A00";
					groupedOptions[1].options.push(obj);
					break;
				case "ReadyToDeliver":
					obj.color = "#F0F000";
					groupedOptions[2].options.push(obj);
					break;
				case "Setup":
					obj.color = "#00FF00";
					groupedOptions[3].options.push(obj);
					break;
				case "Cancelled":
					obj.color = "#0000FF";
					groupedOptions[4].options.push(obj);
					break;
				default:
					break;
			}
		});

		this.setState(() => ({
			reasonsReturned: true,
			reasonsSelect: groupedOptions,
		}));
	}

	onLocationsRadioClick = (val) => {
		this.setState({
			locationsRadio: val,
		});
	};

	getStatusOptions() {
		let oldNames = this.state.statuses,
			a = [];

		a.push({
			checked: oldNames.indexOf("New") > -1,
			disabled: false,
			text: "New",
			value: "New",
		});

		a.push({
			checked: oldNames.indexOf("InProcess") > -1,
			disabled: false,
			text: "In Process",
			value: "InProcess",
		});

		a.push({
			checked: oldNames.indexOf("ReadyToDeliver") > -1,
			disabled: false,
			text: "Ready To Deliver",
			value: "ReadyToDeliver",
		});

		a.push({
			checked: oldNames.indexOf("Setup") > -1,
			disabled: false,
			text: "Setup",
			value: "Setup",
		});

		a.push({
			checked: oldNames.indexOf("Cancelled") > -1,
			disabled: false,
			text: "Cancelled",
			value: "Cancelled",
		});

		this.setState({
			allStatuses: a,
		});
	}

	getReasonOptions() {
		const { reasons } = this.context;

		let a = [],
			oldIds = this.state.reasonIds || [],
			statuses = this.state.selectedStatusNames || [];

		// Declare a array that defines the order of the elements to be sorted.
		let sortOrder = [
			"New",
			"InProcess",
			"ReadyToDeliver",
			"Setup",
			"Cancelled",
		];

		reasons.sort(function (a, b) {
			// Pass a function to the sort that takes 2 elements to compare
			if (a.orderStatus === b.orderStatus) {
				// If the elements both have the same `type`,
				return a.description.localeCompare(b.description); // Compare the elements by `name`.
			} else {
				// Otherwise,
				return (
					sortOrder.indexOf(a.orderStatus) - sortOrder.indexOf(b.orderStatus)
				); // Subtract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
			}
		});

		reasons.forEach((reason, idx) => {
			let checked = false;

			if (statuses === null) {
				return;
			}
			if (statuses.indexOf(reason.orderStatus) === -1) {
				return;
			}

			//The first one always needs to be the disabled header...BC
			if (a.length === 0) {
				a.push({
					checked: false,
					disabled: true,
					text: reason.orderStatus,
					value: { orderStatus: reason.orderStatus },
				});
			}

			//if the previous reason orderStatus does not match the current, push the disabled group header...BC
			if (
				idx > 0 &&
				a.length > 0 &&
				a[a.length - 1].value.orderStatus !== reason.orderStatus
			) {
				a.push({
					checked: false,
					disabled: true,
					text: reason.orderStatus,
					value: { orderStatus: reason.orderStatus },
				});
			}

			if (oldIds.indexOf(reason.id) > -1) {
				checked = true;
			}

			a.push({
				checked: checked,
				disabled: false,
				text: reason.description,
				value: reason,
			});
		});

		this.setState({
			allReasons: reasons,
			availableReasons: a,
		});
	}

	getLocationOptions() {
		const { internalLocations } = this.context;

		let a = [],
			oldIds = this.state.internalLocations;

		//Must add the checked and disabled for it to remain 'uncontrolled'...BC
		internalLocations.map((loc) => {
			let checked = false;

			//If the user filter had old ids in it, set them to default be selected...BC
			if (oldIds.indexOf(loc.id) > -1) {
				checked = true;
			}

			return a.push({
				checked: checked,
				disabled: false,
				text: loc.name,
				value: loc.id.toString(),
			});
		});

		this.setState({
			allInternalLocations: a,
		});
	}

	getSalesRepOptions(e) {
		const { salesReps } = this.context;

		let options = [],
			oldIds = this.state.users;

		if (e === undefined || e.length === 0) {
			salesReps.map((rep) => {
				let checked = false;

				if (oldIds.indexOf(rep.id) > -1) {
					checked = true;
				}

				return options.push({
					disabled: false,
					checked: checked,
					text: rep.username,
					value: rep.id,
				});
			});

			if (salesReps.length !== 0) {
				this.setState({
					filteredSalesReps: options,
					allSalesReps: salesReps,
					updatedCheckedReps: true,
				});
			}
		} else {
			let salesList = [];

			return GroupService.getAllSalesRepsByLocations(e)
				.then((res) => {
					res.forEach((t) => {
						let checked = false;

						if (oldIds.indexOf(t.id) > -1) {
							checked = true;
						}

						if (salesList.indexOf(t.id) === -1) {
							options.push({
								checked: checked,
								disabled: false,
								text: t.username,
								value: t.id,
							});
						}
						salesList.push(t.id);
					});

					options.sort((a, b) => {
						return a.text > b.text ? 1 : -1;
					});
					this.setState({
						filteredSalesReps: options,
					});
				})
				.catch((e) => {});
		}
	}

	getEquipmentOptions() {
		const { productTypes } = this.context;

		let a = [],
			oldIds = this.state.equipmentIds;

		//Must add the checked and disabled for it to remain 'uncontrolled'...BC
		productTypes.map((prod) => {
			let checked = false;

			//If the user filter had old ids in it, set them to default be selected...BC
			if (oldIds.indexOf(prod.id) > -1) {
				checked = true;
			}

			return a.push({
				checked: checked,
				disabled: false,
				text: prod.name,
				value: prod.id.toString(),
			});
		});

		this.setState({
			allEquipment: a,
		});
	}

	renderDates() {
		let st = this.state;
		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<div>
					<MDBRow>
						<Col size="12" style={{ zIndex: "1000" }}>
							<Select
								placeholder="Quick Filter"
								options={st.quickFilterOptions}
								onChange={this.handleQuickFilter.bind(this)}
								value={st.quickFilterSelected}
							/>
						</Col>
					</MDBRow>

					{st.showDatePicker ? (
						<MDBRow style={{ marginTop: 10, marginBottom: 10 }}>
							<Col size="6" className={"inputDateWrapper uiDateOutline"}>
								<DatePicker
									size="small"
									inputVariant={"outlined"}
									format="MM-DD-YYYY"
									keyboard
									value={st.startDate || null}
									onChange={(evt) =>
										this.setState({ startDate: new Date(evt._d) })
									}
									mask={[
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										/\d/,
										/\d/,
									]}
									label="Start Date"
								/>
							</Col>

							<Col size="6" className={"inputDateWrapper uiDateOutline"}>
								<DatePicker
									size="small"
									inputVariant={"outlined"}
									format="MM-DD-YYYY"
									keyboard
									value={st.endDate || null}
									onChange={(evt) =>
										this.setState({ endDate: new Date(evt._d) })
									}
									mask={[
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										"-",
										/\d/,
										/\d/,
										/\d/,
										/\d/,
									]}
									label="End Date"
								/>
							</Col>
						</MDBRow>
					) : (
						<div style={{ margin: "68px" }}>
							<div
								style={{ marginLeft: "15px", marginRight: "55px" }}
								className="spinner-border text-info"
								role="status"
							>
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				</div>
			</MuiPickersUtilsProvider>
		);
	}

	handleQuickFilter = (e) => {
		let a = QuickFilter.getDates(e.value),
			st = this.state,
			t = this;

		if (a.length > 0) {
			st.startDate = a[0];
			st.endDate = a[1];
		} else {
			st.startDate = "";
			st.endDate = "";
		}

		this.setState({
			quickFilter: a,
			dateFilterType: e.value,
			startDate: st.startDate,
			endDate: st.endDate,
			showDatePicker: false,
			quickFilterSelected: e,
		});

		setTimeout(() => {
			t.setState({
				showDatePicker: true,
			});
		}, 500);
	};

	renderStatuses() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="danger"
						selected={"Choose Statuses"}
						style={{ maxHeight: "100px" }}
						options={this.state.allStatuses}
						getValue={this.getStatusValue.bind(this)}
					/>
				</Col>
				<Col size="2">
					<Fa
						style={{ paddingTop: 20 }}
						onClick={this.clearSelectedStatuses.bind(this)}
						icon={"times"}
					/>
				</Col>
			</Row>
		);
	}

	getStatusValue(e) {
		let t = this;
		this.setState({
			selectedStatusNames: e,
		});
		setTimeout(() => {
			t.getReasonOptions();
		}, 300);
	}

	clearSelectedStatuses() {
		this.setState({
			selectedStatusNames: [],
			statuses: [],
		});

		this.state.allStatuses.forEach((stat) => {
			stat.checked = false;
		});

		this.getStatusOptions();
	}

	renderReasons() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="warning"
						selected={"Choose Reasons"}
						style={{ maxHeight: "100px" }}
						options={this.state.availableReasons}
						getValue={this.getReasonValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedReasons.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getReasonValue(e) {
		let a = [];

		e.forEach((reason) => {
			a.push(reason.id);
		});

		this.setState({
			selectedReasonIds: a,
		});
	}

	clearSelectedReasons() {
		this.setState({
			selectedReasonIds: [],
			reasonIds: [],
		});

		this.state.availableReasons.forEach((res) => {
			res.checked = false;
		});

		this.getReasonOptions();
	}

	renderLocationType() {
		return (
			<Row>
				<MDBCol size="6">
					<MDBInput
						onClick={() => this.onLocationsRadioClick("Sales")}
						checked={this.state.locationsRadio === "Sales"}
						label="Sales"
						type="radio"
						id="salesLocations"
					/>
				</MDBCol>

				<MDBCol size="6">
					<MDBInput
						onClick={() => this.onLocationsRadioClick("Service")}
						checked={this.state.locationsRadio === "Service"}
						label="Service"
						type="radio"
						id="serviceLocations"
					/>
				</MDBCol>
			</Row>
		);
	}

	renderLocations() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="info"
						selected={"Choose Locations"}
						style={{ maxHeight: "100px" }}
						options={this.state.allInternalLocations}
						getValue={this.getLocationValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedLocations.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getLocationValue(e) {
		this.setState({
			selectedLocationIds: e,
		});
		this.getSalesRepOptions(e);
	}

	clearSelectedLocations() {
		this.setState({
			selectedLocationIds: [],
			internalLocations: [],
		});

		this.state.allInternalLocations.forEach((loc) => {
			loc.checked = false;
		});

		this.getLocationOptions();
	}

	renderSalesReps() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						search
						color="default"
						selected={"Choose Sales Reps"}
						className={"searchField"}
						style={{ maxHeight: "100px" }}
						options={this.state.filteredSalesReps}
						getValue={this.getSalesRepsValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedSalesReps.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getSalesRepsValue(e) {
		this.setState({
			users: e,
		});
	}

	clearSelectedSalesReps() {
		let ary = [];

		this.state.filteredSalesReps.forEach((rep) => {
			rep.checked = false;
			ary.push(rep);
		});

		this.getSalesRepOptions(this.state.selectedLocationIds);

		this.setState({
			users: [],
			filteredSalesReps: ary,
		});
	}

	renderEquipment() {
		return (
			<Row>
				<Col size="10" className={"insuranceSelects"}>
					<MDBSelect
						multiple
						outline
						color="secondary"
						selected={"Choose Equipment"}
						style={{ maxHeight: "100px" }}
						options={this.state.allEquipment}
						getValue={this.getEquipmentValue.bind(this)}
					/>
				</Col>
				<Col size="2" style={{ paddingTop: 20 }}>
					<Fa onClick={this.clearSelectedEquipment.bind(this)} icon={"times"} />
				</Col>
			</Row>
		);
	}

	getEquipmentValue(e) {
		this.setState({
			selectedEquipmentIds: e,
		});
	}

	clearSelectedEquipment() {
		this.setState({
			equipmentIds: [],
			selectedEquipmentIds: [],
		});

		this.state.allEquipment.forEach((eq) => {
			eq.checked = false;
		});

		this.getEquipmentOptions();
	}

	//method to hide/show the location filters based on if you are a sales rep or not...BC
	renderLocationUserFilters() {
		const { currentUser } = this.context;
		if (currentUser && currentUser.role !== "SALES") {
			return (
				<div>
					<Col size="12">{this.renderLocations()}</Col>

					<Col size="12">{this.renderSalesReps()}</Col>
				</div>
			);
		}
		return <div />;
	}

	render() {
		return (
			<Card>
				<CardBody>
					<Row>
						<Col size="12">{this.renderDates()}</Col>

						<Col size="12">{this.renderStatuses()}</Col>

						<Col size="12">{this.renderReasons()}</Col>

						<div style={{ width: "100%" }}>
							{this.renderLocationUserFilters()}
						</div>

						<Col size="12">{this.renderEquipment()}</Col>

						<Col size="12" style={{ paddingTop: 10, paddingBottom: 10 }}>
							{this.renderLocationType()}
						</Col>
					</Row>

					<Row style={{ justifyContent: "space-around" }}>
						<Button
							floating
							size="sm"
							color={"indigo"}
							data-tip={"Search"}
							onClick={this.newSearchPass.bind(this, this.state)}
						>
							<MDBIcon icon="search" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							floating
							size="sm"
							color={"success"}
							data-tip={"Save Filters"}
							onClick={this.saveUserFilters.bind(this)}
						>
							<MDBIcon icon="save" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							floating
							size="sm"
							color={"warning"}
							data-tip={"Clear Filters"}
							onClick={this.clearUserFilters.bind(this)}
						>
							<MDBIcon icon="eraser" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</Row>
				</CardBody>
			</Card>
		);
	}
}
