import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    toast, MDBModal, MDBModalBody, MDBInput, MDBBtn,
} from "mdbreact";
import {ToastContainer} from "react-toastify";
import ReactTooltip from "react-tooltip";
import NotificationTypesService from "../Security/NotificationService/NotificationTypesService";

export default class notificationTypes extends React.Component {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            currNotification: {},
            newNotification: {},
            newModalOpen: false
        };
    }

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveNotificationType();
    }

    createNewNotification() {
        //promise
        let nn = this.state.newNotification;

        if (!nn || !nn.name) {
            toast.warn("You must enter in a name!");
            return;
        }

        NotificationTypesService.createNotificationType(nn)
            .then(r => {
                this.retrieveNotificationType();
                this.closeModal();
            }).catch((e) => {
            toast.warn("There was an issue creating the Notification Type")
            this.closeModal();
        })

    }

    closeModal() {
        this.setState({
            newModalOpen: false,
            newNotification: {}
        })
    }

    retrieveNotificationType() {
        let t = this;
        return NotificationTypesService.getAllNotificationTypes()
            .then((res) => {
                let arr = [],
                    d = this.state.data;
                res.forEach((type, index) => {
                    arr.push({
                        id: type.id,
                        name: type.name,
                        edit: t.renderEditButton(type, index),
                        remove: t.renderRemoveButton(type.id),
                    });
                });


                arr.sort((a,b) => {
                    return a.id > b.id ? 1 : -1;
                });
                d.rows = arr;

                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }

    renderEditButton(type, index) {
        return (
            <Button
                floating
                size="sm"
                onClick={() => this.handleEditClick(type, index)}
            >
                <MDBIcon icon="edit"/>
            </Button>
        );
    }

    renderRemoveButton(id) {
        return (
            <Button
                floating
                size="sm"
                color={"red"}
                onClick={() => this.handleRemoveClick(id)}
            >
                <MDBIcon icon="trash"/>
            </Button>
        );
    }

    renderNewModal() {
        return (
            <MDBModal
                centered
                isOpen={this.state.newModalOpen}
                toggle={() => this.closeModal()}>
                <MDBModalBody>
                    <MDBInput outline label={"Notification Name/Description"} onBlur={(e) => this.setState({
                        newNotification: {name: e.target.value}
                    })}>
                    </MDBInput>

                    <MDBBtn color={'success'} onClick={() => this.createNewNotification()}>
                        Save
                    </MDBBtn>

                    <MDBBtn color={'danger'} onClick={() => this.setState({
                        newNotification: {},
                        newModalOpen: false
                    })}>
                        Cancel
                    </MDBBtn>
                </MDBModalBody>
            </MDBModal>
        )
    }

    handleRemoveClick(id) {
        NotificationTypesService.deleteNotificationType(id)
            .then((res) => {
                this.retrieveNotificationType();
                toast.success("Notification Type Removed");
            })
            .catch((err) => {
                toast.warn("Error occurred while removing Notification Type");
            });
    }

    handleEditClick(type, index) {
        let a = {
            id: type.id,
            name: type.name,
        };
        this.setState({
            currNotification: a,
            editModal: !this.state.editModal,
            currIndex: index,
        });
    }

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
        });
    };

    renderEditModal() {
        return (
            <Modal isOpen={this.state.editModal} toggle={this.editToggle}>
                <ModalHeader
                    style={{backgroundColor: "#90A4AE", color: "white"}}
                    toggle={this.editToggle}
                >
                    Edit Notification Type
                </ModalHeader>
                <ModalBody style={{backgroundColor: "white"}}>
                    <div>
                        <p style={{color: "red"}}>{this.state.errorMessage}</p>
                        <Input
                            label="Name"
                            value={this.state.currNotification.name}
                            onChange={this.handleEditChange.bind(this)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter style={{backgroundColor: "white"}}>
                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Update Notification Type"}
                        onClick={this.handleEditSave}
                    >
                        <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                    </Button>

                    <Button
                        style={{float: "right"}}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleEditClose}
                    >
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>
                </ModalFooter>
                <ReactTooltip/>
            </Modal>
        );
    }

    handleEditSave = () => {
        if (this.validateEdit()) {
            let d = this.state.data,
                i = this.state.currIndex,
                t = this;
            return NotificationTypesService.updateNotificationType(this.state.currNotification)
                .then((type) => {
                    d.rows[i].name = type.name;
                    d.rows[i].edit = t.renderEditButton(type, i);
                    this.setState({
                        data: d,
                        editModal: !this.state.editModal,
                    });
                    toast.success("Notification Type Edited!");
                })
                .catch((err) => {
                    toast.warn("An error occurred while saving.");
                });
        }
    };

    validateEdit() {
        let a = this.state.currNotification;
        if (a.name === "") {
            this.setError("Please add a Notification Name");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
            currNotification: {},
        });
    };

    handleEditChange(e) {
        let a = this.state.currNotification;
        a.name = e.target.value;
        this.setState({
            currNotification: a,
        });
    }

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable
                    small
                    striped
                    style={{textAlign: "center", fontSize: "16px"}}
                >
                    <MDBTableHead columns={this.state.data.columns}/>
                    <MDBTableBody rows={this.state.data.rows}/>
                </MDBTable>
            );
        } else {
            return <div></div>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    addNewClick = () => {
        this.setState({
            newModalOpen: true
        })
    };

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {this.renderNewModal()}
                <Card>
                    <CardHeader
                        color={"blue-grey lighten-2"}
                        style={{textAlign: "center"}}
                    >
                        Notification Types
                        <Button
                            style={{float: "right"}}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Add New Notification Type"}
                            onClick={this.addNewClick}
                        >
                            <MDBIcon icon="address-card" style={{fontSize: "2em"}}/>
                        </Button>
                    </CardHeader>
                    <CardBody>{this.renderTable()}</CardBody>
                </Card>
                {this.renderEditModal()}
                <ReactTooltip/>
            </Container>
        );
    }
}
