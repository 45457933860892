import React from "react";
import PropTypes from "prop-types";
import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import {
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import {
	Button,
	Container,
	Input,
	Spinner,
	MDBTable,
	MDBTableHead,
	MDBTableBody,
	toast,
	ToastContainer,
	MDBIcon,
	Modal,
	ModalFooter,
	ModalBody,
	MDBListGroupItem,
	CardHeader,
	MDBInput,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import "./purchaseGrid.css";

export default class PurchaseQueueTable extends React.Component {
	constructor(props) {
		super(props);

		const approvalData = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Qty",
					field: "qty",
					sort: "asc",
				},
				{
					label: "Description",
					field: "desc",
					sort: "asc",
				},
				{
					label: "Cost",
					field: "cost",
					sort: "asc",
				},
			],
			rows: [],
		};

		const status = this.props.status;

		const groupColumns = [
			{ columnName: "serviceLocation" },
			{ columnName: "patient" },
			{ columnName: "vendor" },
		];

		const data = this.buildData(status);

		let defaultColumnWidths = [{ columnName: "itemId", width: 120 }];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: groupColumns,
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
			status: status,
			approvalModal: false,
			nPurchaseQueues: [],
			brightreeId: "",
			errorMessage: "",
			approvalData: approvalData,
		};

		this.changeGrouping = (grouping) => {
			let ary = [];

			grouping.forEach((group) => {
				ary.push({ columnName: group.columnName });
			});
			this.setState({
				groupingColumns: ary,
			});
		};

		this.getResults(status);
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	buildData(status) {
		let d = {
			columns: [
				{
					title: "Id",
					name: "id",
				},
				{
					title: "Patient",
					name: "patient",
				},
				{
					title: "Order Id",
					name: "orderId",
				},
				{
					title: "Item Id",
					name: "itemId",
				},
				{
					title: "Quantity",
					name: "quantity",
				},
				{
					title: "Requested On",
					name: "requestedOn",
				},
				{
					title: "Status",
					name: "status",
				},
				{
					title: "Service Location",
					name: "serviceLocation",
				},
				{
					title: "Product",
					name: "product",
				},
				{
					title: "Vendor",
					name: "vendor",
				},
				{
					title: "Status Changed On",
					name: "statusChangedOn",
				},
			],
			rows: [],
		};

		switch (status) {
			case "APPROVED":
				d.columns = [
					...d.columns,
					{ title: "View Purchase Order", name: "purchaseOrder" },
					{
						title: "Cancel",
						name: "cancel",
					},
				];
				break;
			case "DENIED":
				d.columns = [
					...d.columns,
					{ title: "View Quote", name: "quote" },
					{ title: "Pending", name: "pending" },
				];
				break;
			case "PENDING":
				d.columns = [
					...d.columns,
					{ title: "Approve / Deny", name: "approve" },
					{ title: "View Quote", name: "quote" },
				];
				break;
			default:
				break;
		}
		return d;
	}

	Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

	CellContent = ({ row, column, expanded, ...props }) => {
		let cell = (
			<TableGroupRow.Cell
				{...props}
				row={row}
				column={column}
				expanded={expanded}
			/>
		);
		return cell;
	};

	async handleApproveAll(e, grid) {
		const { currentUser } = this.context;
		e.stopPropagation();

		let purchaseIds = [];

		grid.rows.forEach((r) => {
			purchaseIds.push(r.id);
		});

		let objs = await PurchaseQueueService.findPurchaseQueuesByIds({
			ids: purchaseIds,
		});

		if (objs && objs.length > 0) {
			objs.map((p) => {
				p.statusChangedBy = { id: currentUser.id, name: currentUser.username };
				p.statusChangedOn = new Date();
				p.status = "APPROVED";
				return p;
			});

			this.setState({
				nPurchaseQueues: objs,
				approvalModal: true,
			});
		}
	}

	createOrderNotes(orders) {
		orders.forEach((o) => {
			let vendor = o.vendor ? o.vendor.name : "(No vendor)";

			let text =
				vendor + " quote approved . Brightree ID:" + o.brightreeId;

			let note = {
				type: "ActivityNote",
				text: text,
				createdAt: new Date(),
				task: false,
				completed: false,
				createdBy: o.statusChangedBy,
			};

			OrderService.getActivity(o.orderId).then((r) => {
				note.account = r.account;

				NoteService.createActivityNote(o.orderId, note)
					.then((res) => {})
					.catch((err) => {
						console.log(err);
					});
			});
		});
	}

	approvePurchaseQueues = () => {
		let st = this.state,
			purchaseQueues = st.nPurchaseQueues,
			btId = st.brightreeId;

		if (this.validateApproval(btId)) {
			purchaseQueues.map((p) => {
				p.brightreeId = btId;
				return p;
			});

			PurchaseQueueService.approveAll(purchaseQueues)
				.then((res) => {
					let data = this.state.data,
						rows = data.rows.slice();

					purchaseQueues.map((result) => {
						let i = rows.findIndex((row) => {
							return result.id === row.id;
						});
						return rows.splice(i, 1);
					});

					data.rows = rows;
					this.setState({
						data: data,
						approvalModal: false,
						errorMessage: "",
						brightreeId: "",
					});

					this.genrateDownload(res);

					this.createOrderNotes(purchaseQueues);

					toast.success("Equipment Approved");
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	genrateDownload(file) {
		let pdf = new File([file], "purchaseOrder.pdf");
		let url = window.URL.createObjectURL(pdf);
		let temp = document.createElement("a");
		temp.href = url;
		temp.setAttribute("download", "purchaseOrder.pdf");
		temp.click();
		window.URL.revokeObjectURL(url);
	}

	validateApproval(btId) {
		if (!btId) {
			this.setState({
				errorMessage: "Please enter a Brightree P.O.",
			});
			return false;
		}
		return true;
	}

	Content = ({ row, column }) => (
		<span>
			<span>
				{column.title} : {row.value || "(Blank)"}
			</span>
			<span style={{ fontWeight: "bold" }}>&nbsp; {this.getVal(row)}</span>
		</span>
	);

	getVal(row) {
		if (row.collapsedRows.length > 0) {
			return "Count: " + row.collapsedRows.length;
		} else {
			return "";
		}
	}

	getResults(status) {
		let gc = [
			{ columnName: "serviceLocation" },
			{ columnName: "patient" },
			{ columnName: "vendor" },
		];

		if (status === "APPROVED" || status === "DENIED") {
			gc = [{ columnName: "serviceLocation" }, { columnName: "vendor" }];
		}

		return PurchaseQueueService.getPurchaseQueueListing(status)
			.then((res) => {
				this.setState({
					groupingColumns: gc,
				});
				this.buildNewResults(res);
			})
			.catch((err) => {
				this.setState({
					isLoaded: true,
				});
			});
	}

	buildNewResults(res) {
		let ary = [],
			dt = this.state.data,
			status = this.state.status;

		res.forEach((val) => {
			let row = {
				id: val.id,
				patient: val.patientName,
				orderId: val.orderId ? this.createBtn(val.orderId, 1) : "",
				itemId: val.itemId || "",
				quantity: val.quantity || "",
				requestedOn: this.convertDate(val.requestedOn),
				status: val.status,
				serviceLocation: val.inventoryLocation || val.serviceLocation,
				product: val.product || "From Quote",
				vendor: val.vendor || "",
				statusChangedOn: this.convertDate(val.statusChangedOn),
			};
			switch (status) {
				case "APPROVED":
					row = {
						...row,
						purchaseOrder: this.createBtn(val, 3),
						cancel: this.createBtn(val.id, 2),
					};
					break;
				case "DENIED":
					row = {
						...row,
						quote: val.quoteId ? this.createBtn(val.quoteId, 3) : "",
						pending: this.createBtn(val.id, 4),
					};
					break;
				case "PENDING":
					row = {
						...row,
						approve: this.createMultiBtn(val.id),
						quote: val.quoteId ? this.createBtn(val, 3) : "",
					};
					break;
				default:
					break;
			}
			ary.push(row);
		});

		dt.rows = ary;

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	createMultiBtn(id) {
		return (
			<div style={{ backgroundColor: "transparent" }}>
				<Button
					floating
					size="sm"
					color="green"
					disabled={!this.canApprove()}
					onClick={() => this.handleEdit(id, "APPROVED")}
				>
					<MDBIcon icon="check" style={{ fontSize: "1.2em" }} />
				</Button>

				<Button
					floating
					size="sm"
					color="red"
					disabled={!this.canApprove()}
					onClick={() => this.handleEdit(id, "DENIED")}
				>
					<MDBIcon icon="times" style={{ fontSize: "1.2em" }} />
				</Button>
			</div>
		);
	}

	createBtn(id, num) {
		switch (num) {
			case 0:
				return (
					<Button
						floating
						size="sm"
						color="green"
						disabled={!this.canApprove()}
						onClick={() => this.handleEdit(id, "APPROVED")}
					>
						<MDBIcon icon="check" style={{ fontSize: "1.2em" }} />
					</Button>
				);
			case 1:
				return (
					<a
						className={"btn-rounded btn btn-primary btn-sm Ripple-parent"}
						href={"/order/" + id}
						target={"_blank"}
					>
						<span>{id}</span>
						{/*<MDBIcon icon="edit" style={{fontSize: "1.2em"}}/>*/}
					</a>
				);
			case 2:
				return (
					<Button
						floating
						size="sm"
						color="red"
						disabled={!this.canApprove()}
						onClick={() => this.handleEdit(id, "DENIED")}
					>
						<MDBIcon icon="times" style={{ fontSize: "1.2em" }} />
					</Button>
				);
			case 3:
				return (
					<Button
						floating
						size="sm"
						color="secondary"
						onClick={() => this.fileDownloadHandler(id)}
					>
						<MDBIcon icon="file-pdf" style={{ fontSize: "1.2em" }} />
					</Button>
				);
			case 4:
				return (
					<Button
						floating
						size="sm"
						color="yellow"
						onClick={() => this.handleEdit(id, "PENDING")}
					>
						<MDBIcon icon="retweet" style={{ fontSize: "2em" }} />
					</Button>
				);
			default:
				return;
		}
	}

	fileDownloadHandler(row) {
		if (row.inventoryFile) {
			return OrderService.downloadDoc(row.inventoryFile)
				.then((res) => {
					this.genrateNewTab(res);
				})
				.catch((err) => {
							console.log(err);
						});
		} else if (row.fileId) {
			return OrderService.downloadDoc(row.fileId)
				.then((res) => {
					this.genrateNewTab(res);
				})
				.catch((err) => {
							console.log(err);
						});
		} else {
			return OrderService.downloadDocument(row.quoteId)
				.then((res) => {
					this.genrateNewTab(res);
				})
				.catch((err) => {
							console.log(err);
						});
		}
	}

	genrateNewTab(file) {
		let pdf = new Blob([file], { type: "application/pdf" });
		let url = window.URL.createObjectURL(pdf);
		let w = window.open();
		w.location.href = url;
		window.URL.revokeObjectURL(url);
	}

	saveEdit(purchaseQueue) {
		PurchaseQueueService.editPurchaseQueue(purchaseQueue)
			.then((res) => {
				let data = this.state.data,
					rows = data.rows.slice(),
					i = rows.findIndex((p) => {
						return p.id === res.id;
					});

				rows.splice(i, 1);
				data.rows = rows;
				this.setState({
					data: data,
				});

				let vendor = purchaseQueue.vendor
					? purchaseQueue.vendor.name
					: "(No vendor)";

				let text = vendor + " quote denied ";

				let note = {
					type: "ActivityNote",
					text: text,
					createdAt: new Date(),
					task: false,
					completed: false,
					createdBy: purchaseQueue.statusChangedBy,
				};

				OrderService.getActivity(purchaseQueue.orderId).then((r) => {
					note.account = r.account;

					NoteService.createActivityNote(purchaseQueue.orderId, note)
						.then((res) => {})
						.catch((err) => {
							console.log(err);
						});
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	async handleEdit(id, status) {
		const { currentUser } = this.context;

		let purchaseQueue = await PurchaseQueueService.findPurchaseQueuesById(id);

		purchaseQueue.status = status;
		purchaseQueue.statusChangedOn = new Date();
		purchaseQueue.statusChangedBy = {
			id: currentUser.id,
			name: currentUser.username,
		};

		if (status === "APPROVED") {
			this.setState({
				nPurchaseQueues: [purchaseQueue],
				approvalModal: true,
			});
			return;
		}

		this.saveEdit(purchaseQueue);
	}

	convertDate(date) {
		let arr = date.split("-");
		return arr[1] + "-" + arr[2] + "-" + arr[0];
	}

	renderTable() {
		let st = this.state;
		return (
			<Grid
				rootComponent={this.Root}
				rows={st.data.rows}
				columns={st.data.columns}
			>
				<PagingState defaultCurrentPage={0} pageSize={25} />
				<DragDropProvider />
				<SortingState
					defaultSorting={[{ columnName: "requestedOn", direction: "desc" }]}
				/>

				<GroupingState
					columnGroupingEnabled={false}
					defaultGrouping={st.groupingColumns}
					// columnExtensions={st.disabledGroupings}
				/>
				<IntegratedGrouping />
				<IntegratedPaging />
				<IntegratedSorting />
				<VirtualTable height={"auto"} />
				<PagingPanel />
				<TableHeaderRow showSortingControls />
				<TableGroupRow
					cellComponent={this.CellContent}
					contentComponent={this.Content}
					showColumnsWhenGrouped={false}
				/>
				{this.ItemCounter()}
				<Toolbar />
				<GroupingPanel />
			</Grid>
		);
	}

	ItemCounter = () => (
		<Plugin name="ItemCounter">
			<Template
				name="tableCell"
				predicate={({ tableRow }) => isGroupTableRow(tableRow)}
			>
				{(params) => (
					<TemplateConnector>
						{({ getCollapsedRows, rows }) => {
							const updatedParams = {
								...params,
								tableRow: {
									...params.tableRow,
									row: {
										...params.tableRow.row,
										collapsedRows: getCollapsedRows(params.tableRow.row) || [],
										rows: rows.filter((r) => {
											return r.groupedBy === undefined;
										}),
									},
								},
							};
							return <TemplatePlaceholder params={updatedParams} />;
						}}
					</TemplateConnector>
				)}
			</Template>
		</Plugin>
	);

	canApprove() {
		const { currentUser } = this.context;

		switch (currentUser.role) {
			case "PURCHASING":
			case "SYSTEM_ADMIN":
			case "ADMIN":
				return true;
			default:
				return false;
		}
	}

	renderApproveModal() {
		let st = this.state;
		return (
			<Modal
				centered
				isOpen={st.approvalModal}
				toggle={() => {
					return;
				}}
			>
				<CardHeader className="form-header text-center font-weight-bold blue-gradient">
					<h3>Approve Equipment</h3>
				</CardHeader>
				<ModalBody>
					<p style={{ color: "red" }}>{st.errorMessage}</p>
					<Input
						outline
						label={"Brightree P.O."}
						onBlur={(evt) =>
							this.setState({
								brightreeId: evt.target.value,
							})
						}
					/>
					<div
						style={{
							maxHeight: "400px",
							overflowX: "hidden",
							overflowY: "auto",
						}}
					>
						<MDBTable striped>
							<MDBTableHead columns={this.state.approvalData.columns} />
							<MDBTableBody rows={this.renderApprovalTable()} />
						</MDBTable>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color={"red"}
						floating
						data-tip={"Cancel"}
						size={"sm"}
						label={"Close"}
						onClick={() => {
							this.setState({ approvalModal: false, errorMessage: "" });
						}}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
					<Button
						color={"green"}
						floating
						data-tip={"Save"}
						size={"sm"}
						onClick={this.approvePurchaseQueues}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderQueues() {
		return this.state.nPurchaseQueues.map((p, index) => {
			return (
				<MDBListGroupItem key={index}>
					<div className="d-flex w-100 justify-content-between">
						{!p.quoteId ? (
							<Input
								label={"Cost"}
								onChange={this.handleCost.bind(this, index)}
							/>
						) : (
							<div />
						)}
						<h5 className="mb-1">ID: {p.id}</h5>
					</div>
					{p.quoteId ? (
						<p className="mb-1">Ordered from quote</p>
					) : (
						<div>
							<p className="mb-1">{p.productSubType.name}</p>
							<small className="text-muted">{"Quantity: " + p.quantity}</small>
						</div>
					)}
				</MDBListGroupItem>
			);
		});
	}

	renderApprovalTable() {
		let st = this.state,
				arr = [];
		st.nPurchaseQueues.map((p, index) => {
			return arr.push({
				id: p.id,
				qty: (
					<MDBInput
						type={"number"}
						containerClass={"tableInputContainer"}
						outline
						valueDefault={p.quantity}
						onBlur={this.handleQty.bind(this, index)}
					/>
				),
				desc: p.quoteId
					? "Ordered from quote"
					: p.productSubType
					? p.productSubType.name
					: "No Sub Type",
				cost: (
					<MDBInput
						type={"number"}
						containerClass={"tableInputContainer"}
						outline
						onBlur={this.handleCost.bind(this, index)}
					/>
				),
			});
		});
		return arr;
	}

	handleCost(index, evt) {
		let pq = this.state.nPurchaseQueues;
		pq[index].cost = evt.target.value;
		this.setState({
			nPurchaseQueues: pq,
		});
	}

	handleQty(index, evt) {
		let pq = this.state.nPurchaseQueues;
		pq[index].quantity = evt.target.value;
		this.setState({
			nPurchaseQueues: pq,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div className={"purchasingGrid"} style={{ height: "100%" }}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		let h = window.innerHeight;
		return (
			<div style={{ overflowY: "scroll", height: h * 0.7, maxHeight: h * 0.7 }}>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>
				{this.renderTableOrSpinner()}
				{this.renderApproveModal()}
			</div>
		);
	}
}
