import api from "../Security/api";

class ReverseQualityService {
	async getAllCategories() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/reverseQualityCategories", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllLogs(params) {
		return new Promise((res, reject) => {
			api
				.get("/reverseQualityLogs", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllLogsNative(params) {
		return new Promise((res, reject) => {
			api
				.get("/reverseQualityLogsNative", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createReverseQualityLog(log) {
		return new Promise((res, reject) => {
			api
				.post("/reverseQualityLogs", log)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const reverseQualityService = new ReverseQualityService();

export default reverseQualityService;
