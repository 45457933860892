import React from "react";
import {
	Button,
	CardHeader,
	Col,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Fa,
	MDBBtn,
	MDBCard,
	MDBCardBody,
	MDBCol,
	MDBCollapse,
	MDBCollapseHeader,
	MDBIcon,
	MDBInput,
	MDBRow,
	Modal,
	ModalBody,
	Row,
} from "mdbreact";
import { Route } from "react-router-dom";

import PropTypes from "prop-types";
import AccountService from "../../Security/AccountService/accountService";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import MomentUtils from "@date-io/moment";
import DateTimePicker from "material-ui-pickers/DateTimePicker";
import moment from "moment";
import DatePicker from "material-ui-pickers/DatePicker";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ReactTooltip from "react-tooltip";
import NoteService from "../../Security/NoteService/noteService";
import EquipmentTrainingLinkService from "../../Settings/EquipmentTrainingLinkService";
import { toast } from "react-toastify";

export default class TypeStatusContainer extends React.Component {
	constructor(props) {
		super(props);

		this.updateParent = props.updateParent;
		this.updateTableRows = props.updateTableRows;
		this.saveOrder = props.saveOrder;
		this.validateSave = props.validateSave;
		this.updateNotes = props.updateNotes;

		this.state = {
			eOrder: props.eOrder,
			order: props.order,
			collapseID: "collapse1",
			goto: props.goto,
			statuses: [
				{ label: "New", value: "New" },
				{ label: "In Process", value: "InProcess" },
				{ label: "Ready To Deliver", value: "ReadyToDeliver" },
				{ label: "Set Up", value: "Setup" },
				{ label: "Cancelled", value: "Cancelled" },
			],
			orderTypes: ["New", "Pickup", "Service", "Exchange"],
			filteredReasons: [],
			currSalesRepAccounts: [],
			accountSearch: "",
			scheduleModalOpen: false,
			paymentModalOpen: false,
			ssAcknowledge: false,
			customerSuccessDisabled: false,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		reasons: PropTypes.array,
		salesReps: PropTypes.array,
		allUsers: PropTypes.array,
		allAtp: PropTypes.array,
		allLocations: PropTypes.array,
		internalLocations: PropTypes.array,
		marketingCampaigns: PropTypes.array,
		techs: PropTypes.array,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.eOrder !== prevState.eOrder) {
			return { eOrder: nextProps.eOrder };
		} else return null;
	}

	componentDidMount() {
		this.getSalesRepsAccounts();
	}

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
			hour: "numeric",
			minute: "numeric",
		}).format(date);
	}

	saveNote(noteText) {
		let eOrder = this.state.eOrder;

		const { currentUser } = this.context;
		let note = {
			type: "ActivityNote",
			text: noteText,
			createdAt: new Date(),
			account: eOrder.account,
			task: false,
			completed: false,
			createdBy: { id: currentUser.id, name: currentUser.username },
		};

		NoteService.createActivityNote(eOrder.id, note)
			.then((res) => {
				toast.success("Note saved");
			})
			.catch((err) => {
				toast.error("Error: Note not saved");
			});
	}

	getSalesRepsAccounts() {
		let rep = this.state.eOrder.owner.id;
		AccountService.getAccountsByUser(rep)
			.then((accounts) => {
				this.setState({
					currSalesRepAccounts: accounts,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	toggleCollapse(collapseID) {
		this.setState({
			collapseID: collapseID,
		});
	}

	togglePaymentModalPopup = () => {
		let currentState = this.state.paymentModalOpen;
		this.setState({ paymentModalOpen: !currentState });
	};

	toggleScheduleModalPopup = () => {
		let currentState = this.state.scheduleModalOpen;
		this.setState({
			scheduleModalOpen: !currentState,
			ssAcknowledge: false,
		});
	};

	paymentPlanApprovedChange = (event) => {
		this.setState({ paymentPlanApproved: event.target.checked });
	};

	confirmCustomerSuccessDate() {
		let eOrder = this.state.eOrder,
			approvals = eOrder.approvals ? eOrder.approvals[0] : [];

		if (!this.validateSave(eOrder)) {
			return;
		} else {
			this.setState({
				customerSuccessDisabled: true,
			});
		}

		approvals.customerSuccessDate = new Date().toLocaleDateString();

		eOrder.approvals[0] = approvals;
		this.updateParent(eOrder);
		this.generateCustomerSuccessNote();
	}

	confirmScheduleDelivery() {
		const { currentUser } = this.context;

		let eOrder = this.state.eOrder,
			order = this.state.order;

		let noteDate = new Date(eOrder.scheduledDeliveryDate).toLocaleString();

		//this has a technician and date and is being scheduled...BC
		if (eOrder.technician && eOrder.scheduledDeliveryDate) {
			if (this.state.ssAcknowledge) {
				let noteText =
					currentUser.username +
					" acknowledged that there is a same/sim on file and scheduled the order to be delivered on " +
					noteDate;

				if (eOrder.technician && eOrder.technician.name) {
					noteText += " with technician " + eOrder.technician.name;
				}

				this.saveNote(noteText);
			}

			if (
				eOrder.orderStatusReason &&
				eOrder.orderStatusReason.name === "Scheduled"
			) {
				let text =
					"Order was scheduled by " +
					currentUser.username +
					" for " +
					moment(eOrder.scheduledDeliveryDate).format("MMMM Do YYYY, h:mm a") +
					" with technician " +
					eOrder.technician.name;

				this.saveNote(text);
			}

			//if the old date does not match the new date, send the video...BC
			if (
				new Date(order.scheduledDeliveryDate) !==
				new Date(eOrder.scheduledDeliveryDate)
			) {
				EquipmentTrainingLinkService.fireEquipmentVideoProcess({
					orderId: eOrder.id,
				})
					.then((r) => {
						console.log(r);
					})
					.catch((e) => {
						console.log(e);
					});

				//save after
				this.saveOrder(this.state.eOrder);
			}
		} else {
			//save even if it doesn't have both parts...BC
			this.saveOrder(eOrder);
		}
		//close the modal...BC
		this.toggleScheduleModalPopup();
	}

	generateCustomerSuccessNote() {
		const { currentUser } = this.context;

		let st = this.state,
			noteText =
				currentUser.username +
				" contacted patient regarding customer success on " +
				new Date().toLocaleString(),
			cb = { id: currentUser.id, name: currentUser.username },
			eOrder = st.eOrder;

		let note = {
			type: "ActivityNote",
			text: noteText,
			createdAt: new Date(),
			account: eOrder.account,
			task: false,
			completed: true,
			createdBy: cb,
		};

		NoteService.createActivityNote(eOrder.id, note)
			.then((res) => {
				this.updateNotes(note);
				this.saveOrder(eOrder);
				this.setState({
					customerSuccessDisabled: false,
				});
			})
			.catch((err) => {
				//Maybe send an error to the user?...BC
			});
	}

	confirmPaymentPlanProcessed() {
		const { currentUser } = this.context;

		let eOrder = this.state.eOrder,
			approvals = eOrder.approvals ? eOrder.approvals[0] : [];

		approvals.paymentProcessedBy = {
			id: currentUser.id,
			name: currentUser.username,
		};
		approvals.paymentProcessedOn = new Date();

		eOrder.approvals[0] = approvals;

		this.setState({ eOrder: eOrder });

		this.updateParent(eOrder);
		this.togglePaymentModalPopup();
	}

	setAndUpdate(prop, val) {
		let eOrder = this.state.eOrder;

		if (eOrder.hasOwnProperty(prop)) {
			eOrder[prop] = val;
		}

		if (eOrder.patient.hasOwnProperty(prop)) {
			eOrder.patient[prop] = val;
		}

		this.setState({ eOrder: eOrder });
		this.updateParent(eOrder);
	}

	approvalAndUpdate(prop, val) {
		let eOrder = this.state.eOrder;

		eOrder.approvals[0][prop] = val;
		this.setState({ eOrder: eOrder });
		this.updateParent(eOrder);
	}

	statusChanged(v) {
		let eOrder = this.state.eOrder;

		eOrder.orderStatusReason = null;

		this.setAndUpdate("status", v);
		this.updateTableRows();
	}

	updateReason(v) {
		const { reasons } = this.context;

		if (v) {
			let r = reasons.find((o) => {
				return v === o.description;
			});

			if (r) {
				let q = {
					id: r.id,
					name: r.description,
				};
				this.setAndUpdate("orderStatusReason", q);
			}
		} else {
			this.setAndUpdate("orderStatusReason", null);
		}
	}

	changeByRef(prop, val) {
		let o = null;

		if (val) {
			o = {
				name: val.name,
				id: val.id,
			};
		}

		this.setAndUpdate(prop, o);
	}

	//region internal details functions
	changeSalesRep(rep) {
		let eOrder = this.state.eOrder;

		eOrder.owner = {
			id: rep.id,
			name: rep.username,
		};

		if (rep.locations) {
			let newLoc = rep.locations.find((loc) => {
				return loc.type === "Internal";
			});

			//if we have a new sales location use it...BC
			if (newLoc) {
				eOrder.salesLocationRef = { id: newLoc.id, name: newLoc.name };
				eOrder.serviceLocationRef = { id: newLoc.id, name: newLoc.name };
			}
		}

		this.setState({ eOrder: eOrder });
		this.updateParent(eOrder);
		this.getSalesRepsAccounts();
	}

	changeSeatingClinic(clinic) {
		let eOrder = this.state.eOrder;

		if (clinic == null) {
			eOrder.seatingClinicLocationRef = null;
		} else {
			eOrder.seatingClinicLocationRef = {
				id: clinic.id,
				name: clinic.name,
			};
		}

		this.setState({ eOrder: eOrder });
	}

	changeTherapist(therapist) {
		let eOrder = this.state.eOrder;

		if (therapist == null) {
			eOrder.therapist = null;
		} else {
			eOrder.therapist = {
				id: therapist.id,
				name: therapist.name,
			};
		}

		this.setState({ eOrder: eOrder });
	}

	//endregion

	handleDate(prop, e) {
		let v;

		e ? (v = parseInt(e.format("x"))) : (v = e);

		this.setAndUpdate(prop, v);
	}

	//region type and status

	renderPatientInformationRoute() {
		let pt = this.state.eOrder.patient;
		return (
			<Route
				render={({ history }) => (
					<div
						aria-hidden="true"
						data-tip="Return to Patient"
						style={{ fontSize: "1.2em" }}
						onClick={() => {
							history.push({
								pathname: "/patientInformation/" + pt.id,
								state: { patient: pt },
							});
						}}
					>
						{pt.firstName + " " + pt.lastName}
					</div>
				)}
			/>
		);
	}

	renderOrderTypeDropdown() {
		let eOrder = this.state.eOrder,
			types = this.state.orderTypes;

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="outlined-select-status"
					select
					size={"small"}
					fullWidth
					label="Order Type"
					value={eOrder.orderType}
					onChange={(e) => {
						this.setAndUpdate("orderType", e.target.value);
						this.updateTableRows();
					}}
					variant="outlined"
				>
					{types.map((option, idx) => {
						return (
							<MenuItem key={idx} value={option}>
								{option}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderOrderStatusDropdown() {
		const { currentUser } = this.context;

		let eOrder = this.state.eOrder,
			statuses = this.state.statuses,
			shouldDisable = false;

		if (this.state.order.status === "Setup") {
			let r = currentUser.role;
			if (
				r !== "ADMIN" &&
				r !== "SYSTEM_ADMIN" &&
				r !== "EXECUTIVE_MANAGEMENT"
			) {
				shouldDisable = true;
			}
		}

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="outlined-select-status"
					select
					disabled={shouldDisable}
					size={"small"}
					fullWidth
					label="Order Status"
					value={eOrder.status}
					onChange={(e) => this.statusChanged(e.target.value)}
					variant="outlined"
				>
					{statuses.map((option, idx) => {
						return (
							<MenuItem
								disabled={option.value === "ReadyToDeliver"}
								key={idx}
								value={option.value}
							>
								{option.label}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderOrderReasonDropdown() {
		const { reasons } = this.context;
		let eOrder = this.state.eOrder;

		let arr = [];

		reasons.map((r) => {
			return arr.push({
				name: r.description,
				id: r.id,
			});
		});

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="outlined-select-statusReason"
					select
					size={"small"}
					fullWidth
					label="Status Reason"
					value={
						eOrder.orderStatusReason ? eOrder.orderStatusReason.name : "None"
					}
					onChange={(e) => this.updateReason(e.target.value)}
					variant="outlined"
				>
					<MenuItem key={-1} value={null}>
						{"(Remove Reason)"}
					</MenuItem>
					{reasons.map((r, idx) => {
						if (r.orderStatus === eOrder.status) {
							if (eOrder.status === "ReadyToDeliver") {
								if (r.description === "Scheduled") {
									if (!eOrder.technician || !eOrder.scheduledDeliveryDate) {
										return (
											<MenuItem disabled key={idx} value={r.description}>
												{r.description}
											</MenuItem>
										);
									}
								}
							}

							return (
								<MenuItem key={idx} value={r.description}>
									{r.description}
								</MenuItem>
							);
						} else {
							return null;
						}
					})}
				</TextField>
			</div>
		);
	}

	renderStatusSection() {
		let collapseID = this.state.collapseID,
			eOrder = this.state.eOrder;

		return (
			<div>
				<MDBCollapseHeader
					className={"patientStatusHeaders"}
					tagClassName="d-flex justify-content-between"
					onClick={() => this.toggleCollapse("collapse1")}
				>
					<span style={{ fontSize: "1em" }}>Type and Status</span>
					<MDBIcon
						icon={collapseID === "collapse1" ? "angle-up" : "angle-down"}
					/>
				</MDBCollapseHeader>

				<MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
					<MDBCardBody>
						<MDBRow>
							<MDBCol size={"2"}>
								<MDBInput
									label={"Priority"}
									value={eOrder.priority === 1}
									checked={eOrder.priority === 1}
									data-tip={"Priority"}
									type="checkbox"
									onChange={(e) =>
										this.setAndUpdate("priority", e.target.checked ? 1 : 0)
									}
									filled
									id="priorityCheckbox"
								/>
							</MDBCol>
						</MDBRow>

						{this.renderOrderTypeDropdown()}
						{this.renderOrderStatusDropdown()}
						{this.renderOrderReasonDropdown()}
					</MDBCardBody>
				</MDBCollapse>
			</div>
		);
	}

	//endregion

	//region internal details

	renderSalesRepDropdown() {
		let eOrder = this.state.eOrder;
		const { salesReps, allUsers } = this.context;

		let copy = salesReps;

		let index = salesReps.find((rep) => {
			return rep.id === eOrder.owner.id;
		});

		//if we didn't find them in the rep list, try all users...BC
		if (!index) {
			index = allUsers.find((rep) => {
				return rep.id === eOrder.owner.id;
			});

			//if we find them, push that user to the list so it will populate properly...BC
			if (index) {
				copy.push(index);
			}
		}

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="Sales-Rep"
					select
					size={"small"}
					fullWidth
					label="Sales Rep"
					value={index ? index : ""}
					onChange={(e) => this.changeSalesRep(e.target.value)}
					variant="outlined"
				>
					{copy.map((option, idx) => {
						return (
							<MenuItem key={idx} value={copy[idx]}>
								{option.username}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderATPDropdown() {
		let eOrder = this.state.eOrder,
			index = null;
		const { allAtp } = this.context;

		if (eOrder.atp) {
			index = allAtp.find((rep) => {
				return rep.id === eOrder.atp.id;
			});
		}

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="ATP"
					select
					size={"small"}
					fullWidth
					label="ATP"
					value={index ? index : ""}
					onChange={(e) => this.changeByRef("atp", e.target.value)}
					variant="outlined"
				>
					{allAtp.map((option, idx) => {
						return (
							<MenuItem key={idx} value={allAtp[idx]}>
								{option.username}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderAccountDropdown() {
		let eOrder = this.state.eOrder,
			currSalesRepAccounts = this.state.currSalesRepAccounts,
			currAccount = eOrder.account,
			arr = [currAccount],
			index = eOrder.account;

		currSalesRepAccounts.forEach((acc) => {
			let o = {
				name: acc.name,
				id: acc.id,
			};

			if (o.id === currAccount.id) {
				index = o;
			}

			arr.push(o);
		});

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="Rep-Accounts"
					select
					size={"small"}
					fullWidth
					label="Account"
					value={index ? index : currAccount}
					onChange={(e) => this.changeByRef("account", e.target.value)}
					variant="outlined"
				>
					{arr.map((option, idx) => {
						return (
							<MenuItem key={idx} value={option}>
								{option.name}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderSalesLocationDropdown() {
		const { internalLocations, salesReps } = this.context;

		let eOrder = this.state.eOrder,
			arr = internalLocations;

		let rep = salesReps.find((r) => {
			return r.id === eOrder.owner.id;
		});

		if (rep && rep.locations && rep.locations.length > 0) {
			arr = rep.locations;
		}

		let index = arr.find((loc) => {
			if (eOrder.salesLocationRef) {
				return loc.id === eOrder.salesLocationRef.id;
			} else {
				return null;
			}
		});

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="Sales-Location"
					select
					size={"small"}
					fullWidth
					label="Sales Location"
					value={index ? index : ""}
					onChange={(e) => this.changeByRef("salesLocationRef", e.target.value)}
					variant="outlined"
				>
					{arr.map((option, idx) => {
						return (
							<MenuItem key={idx} value={arr[idx]}>
								{option.name}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderServiceLocationDropdown() {
		let eOrder = this.state.eOrder;

		const { internalLocations } = this.context;

		let index = internalLocations.find((loc) => {
			if (eOrder.serviceLocationRef) {
				return loc.id === eOrder.serviceLocationRef.id;
			} else {
				return null;
			}
		});

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="Service-Location"
					select
					size={"small"}
					fullWidth
					label="Service Location"
					value={index ? index : ""}
					onChange={(e) =>
						this.changeByRef("serviceLocationRef", e.target.value)
					}
					variant="outlined"
				>
					{internalLocations.map((option, idx) => {
						return (
							<MenuItem key={idx} value={option}>
								{option.name}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderSeatingClinicDropdown() {
		let t = this;
		const { allLocations } = this.context;

		let fLoc = allLocations.filter((loc) => {
			return loc.type === "SeatingClinic";
		});

		return (
			<Dropdown dropright className={"idDropdown idDropdownClinic"}>
				<DropdownToggle className={"idDropdownToggle"} nav>
					Seating Clinic
					<span style={{ float: "right" }}>
						{this.state.eOrder.seatingClinicLocationRef
							? this.state.eOrder.seatingClinicLocationRef.name
							: "No Seating Clinic"}
						&nbsp;
						<Fa icon={"angle-down"} />
					</span>
				</DropdownToggle>
				<DropdownMenu color={"ins"}>
					{fLoc.map((loc, index) => {
						return (
							<DropdownItem
								onClick={t.changeSeatingClinic.bind(t, loc)}
								active={
									this.state.eOrder.seatingClinicLocationRef
										? this.state.eOrder.seatingClinicLocationRef.name ===
										  loc.name
										: false
								}
								key={index}
							>
								{loc.name}{" "}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</Dropdown>
		);
	}

	renderTherapistDropdown() {
		let t = this,
			eOrder = this.state.eOrder,
			disabled = true;
		const { therapists } = this.context;

		let tps = therapists;

		if (eOrder.seatingClinicLocationRef) {
			disabled = false;
			tps = [];

			let clinic = {};

			therapists.map((ts) => {
				if (ts.locations) {
					clinic = ts.locations.find((loc) => {
						return loc.type === "SeatingClinic";
					});
				}

				if (clinic && clinic.id === eOrder.seatingClinicLocationRef.id) {
					return tps.push({
						id: ts.id,
						name: ts.username,
					});
				}
				return null;
			});
		}

		return (
			<Dropdown
				dropright
				disabled={disabled}
				className={"idDropdown idDropdownClinic"}
			>
				<DropdownToggle className={"idDropdownToggle"} nav>
					Therapist
					<span style={{ float: "right" }}>
						{this.state.eOrder.therapist
							? this.state.eOrder.therapist.name
							: "No Therapist"}
						&nbsp;
						<Fa icon={"angle-down"} />
					</span>
				</DropdownToggle>
				<DropdownMenu color={"ins"}>
					{tps.map((thera, index) => {
						return (
							<DropdownItem
								onClick={t.changeTherapist.bind(t, thera)}
								active={
									this.state.eOrder.therapist
										? this.state.eOrder.therapist.name === thera.name
										: false
								}
								key={index}
							>
								{thera.name}{" "}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</Dropdown>
		);
	}

	renderMarketingCampaignDropdown() {
		const { marketingCampaigns } = this.context;
		let eOrder = this.state.eOrder;

		let index = marketingCampaigns.find((cp) => {
			if (eOrder.marketingCampaign) {
				return cp.id === eOrder.marketingCampaign.id;
			} else {
				return null;
			}
		});

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="Marketing-campaign"
					select
					size={"small"}
					fullWidth
					label="Marketing Campaign"
					value={index ? index : ""}
					onChange={(e) =>
						this.changeByRef("marketingCampaign", e.target.value)
					}
					variant="outlined"
				>
					{marketingCampaigns.map((option, idx) => {
						if (option.isActive) {
							return (
								<MenuItem key={idx} value={option}>
									{option.name}
								</MenuItem>
							);
						} else {
							return null;
						}
					})}
				</TextField>
			</div>
		);
	}

	renderRepSection() {
		let collapseID = this.state.collapseID;

		return (
			<div>
				<MDBCollapseHeader
					className={"patientStatusHeaders"}
					tagClassName="d-flex justify-content-between"
					onClick={() => this.toggleCollapse("collapse2")}
				>
					<span style={{ fontSize: "1em" }}>Sales Information</span>
					<MDBIcon
						icon={collapseID === "collapse2" ? "angle-up" : "angle-down"}
					/>
				</MDBCollapseHeader>
				<MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
					<MDBCardBody>
						{this.renderSalesRepDropdown()}
						{this.renderATPDropdown()}
						{this.renderAccountDropdown()}
						{this.renderSalesLocationDropdown()}
						{this.renderServiceLocationDropdown()}
						{this.renderMarketingCampaignDropdown()}
					</MDBCardBody>
				</MDBCollapse>
			</div>
		);
	}

	//endregion

	renderTechnicianDropdown() {
		let eOrder = this.state.eOrder;
		const { techs } = this.context;

		let index = techs.find((rep) => {
			if (eOrder.technician) {
				return rep.id === eOrder.technician.id;
			} else {
				return null;
			}
		});

		return (
			<div className={"uiOutlines"}>
				<TextField
					id="Technicians"
					disabled={
						eOrder.status !== "ReadyToDeliver" && eOrder.status !== "Setup"
					}
					select
					size={"small"}
					fullWidth
					label="Technician"
					value={index ? index : ""}
					onChange={(e) => this.changeByRef("technician", e.target.value)}
					variant="outlined"
				>
					<MenuItem key={-1} value={null}>
						{"(Remove Technician)"}
					</MenuItem>
					{techs.map((option, idx) => {
						return (
							<MenuItem key={idx} value={techs[idx]}>
								{option.username}
							</MenuItem>
						);
					})}
				</TextField>
			</div>
		);
	}

	renderScheduledDate() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		const { currentUser } = this.context;

		let dateDisabled = true;
		if (currentUser.role === "ADMIN" || currentUser.role === "SYSTEM_ADMIN") {
			dateDisabled = false;
		} else if (
			eOrder.status === "ReadyToDeliver" ||
			eOrder.status === "Setup"
		) {
			dateDisabled = false;
		}

		return (
			<MDBRow>
				<MDBCol size="12">
					<div className={"uiOutlines"}>
						<DateTimePicker
							style={{ width: "100%" }}
							format={"MMMM DD, YYYY - hh:mm a"}
							size={"small"}
							clearable
							disabled={dateDisabled}
							variant={"outlined"}
							maxDate={
								appr && appr.deliverByDate
									? moment(appr.deliverByDate)
									: "01-01-2999"
							}
							label={"Scheduled Delivery Date"}
							value={eOrder.scheduledDeliveryDate || null}
							onChange={(e) => this.handleDate("scheduledDeliveryDate", e)}
						/>
					</div>
				</MDBCol>
			</MDBRow>
		);
	}

	renderDeliverByDate() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<MDBRow>
				<MDBCol size="12">
					<div className={"uiOutlines"}>
						<DatePicker
							format="MM/DD/YYYY"
							size={"small"}
							clearable
							style={{ width: "100%" }}
							variant={"outlined"}
							label={"Deliver By Date"}
							emptyLabel={"Not Specified"}
							value={appr.deliverByDate || null}
							onChange={(date) => this.approvalAndUpdate("deliverByDate", date)}
						/>
					</div>
				</MDBCol>
			</MDBRow>
		);
	}

	renderSetupDate() {
		let eOrder = this.state.eOrder;

		if (eOrder.status === "Setup") {
			return (
				<div>
					<hr />
					<MDBRow>
						<MDBCol size="12">
							<div className={"uiOutlines"}>
								<DatePicker
									style={{ width: "100%" }}
									format={"MMMM DD, YYYY"}
									size={"small"}
									clearable
									variant={"outlined"}
									label={"Setup Date"}
									value={eOrder.setupDate || null}
									onChange={(e) => this.handleDate("setupDate", e)}
								/>
							</div>
						</MDBCol>
					</MDBRow>
				</div>
			);
		}
	}

	renderCurrentModelSection() {
		let eOrder = this.state.eOrder,
			statusSetup = this.state.eOrder.status === "Setup";

		if (eOrder.orderType === "Service" || eOrder.orderType === "Pickup") {
			return (
				<Row style={{ display: "flex", justifyContent: "center" }}>
					<Col size={"6"}>
						<MDBInput
							label={"Current Model"}
							outline
							containerClass={"smallMargin"}
							disabled={statusSetup}
							valueDefault={eOrder.currentModel || ""}
							onBlur={(e) => this.setAndUpdate("currentModel", e.target.value)}
						/>
					</Col>
					<Col size={"6"}>
						<MDBInput
							label={"Serial Number"}
							outline
							containerClass={"smallMargin"}
							disabled={statusSetup}
							valueDefault={eOrder.serialNumber || ""}
							onBlur={(e) => this.setAndUpdate("serialNumber", e.target.value)}
						/>
					</Col>

					<Col size={"12"}>
						<MDBInput
							label={"Service Reason"}
							type={"textarea"}
							outline
							containerClass={"smallMargin"}
							disabled={statusSetup}
							valueDefault={eOrder.deliveryReason || ""}
							onBlur={(e) =>
								this.setAndUpdate("deliveryReason", e.target.value)
							}
						/>
					</Col>
				</Row>
			);
		}
	}

	renderSetupDeliverySection() {
		let collapseID = this.state.collapseID,
			eOrder = this.state.eOrder,
			ss = false,
			onFile = false,
			t = this;

		if (eOrder.approvals[0]) {
			ss = eOrder.approvals[0].sameOrSimilar;
			onFile =
				ss === "Change in Condition" ||
				ss === "Damage" ||
				ss === "Identified Same/Sim on File" ||
				ss === "Identified Patient in SNF";
		}

		function renderSSSection() {
			if (onFile === true) {
				return (
					<MDBCol size="12">
						<MDBInput
							type={"checkbox"}
							value={t.state.ssAcknowledge}
							id={"sameSimCheckbox"}
							onChange={(e) =>
								t.setState({
									ssAcknowledge: e.target.checked,
								})
							}
							label={"I acknowledge there is a same or similar on file."}
						/>
					</MDBCol>
				);
			}
		}

		return (
			<div>
				<MDBCollapseHeader
					className={"patientStatusHeaders"}
					tagClassName="d-flex justify-content-between"
					onClick={() => this.toggleCollapse("collapse3")}
				>
					<span style={{ fontSize: "1em" }}>Delivery Information</span>
					<MDBIcon
						icon={collapseID === "collapse3" ? "angle-up" : "angle-down"}
					/>
				</MDBCollapseHeader>
				<MDBCollapse id="collapse3" isOpen={this.state.collapseID}>
					<MDBCardBody>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							{this.renderCurrentModelSection()}
							<MDBInput
								outline
								type={"textarea"}
								label={"Delivery Notes"}
								style={{ paddingTop: 8 }}
								valueDefault={eOrder.deliveryNotes}
								onBlur={(e) =>
									this.setAndUpdate("deliveryNotes", e.target.value)
								}
							/>

							{this.renderDeliverByDate()}
							{this.renderScheduledDate()}
							{this.renderTechnicianDropdown()}

							{renderSSSection()}

							<MDBRow style={{ justifyContent: "center" }}>
								<MDBCol size={"12"}>
									<MDBBtn
										disabled={
											(onFile === true && !this.state.ssAcknowledge) ||
											!eOrder.scheduledDeliveryDate ||
											!eOrder.technician
										}
										outline
										onClick={() => this.confirmScheduleDelivery()}
									>
										Confirm, Save, and Send SMS
									</MDBBtn>
								</MDBCol>
							</MDBRow>

							{this.renderSetupDate()}
						</MuiPickersUtilsProvider>
					</MDBCardBody>
				</MDBCollapse>
			</div>
		);
	}

	renderPaymentProcessedSection() {
		let eOrder = this.state.eOrder,
			appr = eOrder.approvals[0],
			t = this;

		function renderButton() {
			if (!appr.paymentProcessedBy) {
				return (
					<MDBCol size={"12"}>
						<Button
							color={"secondary"}
							outline
							disabled={appr.paymentProcessedBy}
							onClick={() => t.togglePaymentModalPopup()}
						>
							{" "}
							Confirm Payment Processed
						</Button>
					</MDBCol>
				);
			}
		}

		function renderProcessed() {
			if (appr.paymentProcessedBy) {
				return (
					<MDBCol size={"12"}>
						<MDBBtn color={"secondary"} outline>
							<MDBRow>Processed By: {appr.paymentProcessedBy.name}</MDBRow>

							<MDBRow>On: {t.formatDate(appr.paymentProcessedOn)}</MDBRow>
						</MDBBtn>
					</MDBCol>
				);
			}
		}

		if (appr.patientResponsibility && appr.patientResponsibility > 0) {
			return (
				<MDBRow>
					{renderButton()}
					{renderProcessed()}
				</MDBRow>
			);
		}
	}

	renderPaymentProcessedModal() {
		let eOrder = this.state.eOrder;

		return (
			<Modal
				centered
				isOpen={this.state.paymentModalOpen}
				toggle={() => this.togglePaymentModalPopup()}
			>
				<ModalBody>
					<ReactTooltip />
					<CardHeader
						className="form-header text-center"
						style={{
							backgroundColor: "#1e88e5",
							margin: "-40px 30px 10px 30px",
							fontSize: 24,
						}}
					>
						<h4>Payment Plan Processed</h4>
						<h4>{eOrder.patient.firstName + " " + eOrder.patient.lastName}</h4>
					</CardHeader>

					<div align="left">
						<MDBInput
							key={eOrder.id}
							onChange={this.paymentPlanApprovedChange.bind(this)}
							value={this.state.paymentPlanApproved}
							label="I verify that a payment/ payment plan has been put in place for this patient."
							type="checkbox"
							id="approvePayment"
						/>
					</div>
				</ModalBody>
				<MDBRow style={{ justifyContent: "flex-end" }}>
					<MDBCol size={"4"}>
						<MDBBtn
							size={"sm"}
							floating
							data-tip={"Cancel"}
							color="danger"
							onClick={() => this.togglePaymentModalPopup()}
						>
							<MDBIcon icon="times" />
						</MDBBtn>

						<MDBBtn
							size={"sm"}
							floating
							data-tip={"Approve"}
							disabled={!this.state.paymentPlanApproved}
							color="success"
							onClick={() => this.confirmPaymentPlanProcessed()}
						>
							<MDBIcon icon="check" />
						</MDBBtn>
					</MDBCol>
				</MDBRow>
			</Modal>
		);
	}

	renderPaymentInfoSection() {
		let collapseID = this.state.collapseID,
			eOrder = this.state.eOrder,
			appr = eOrder.approvals[0];

		return (
			<div>
				<MDBCollapseHeader
					className={"patientStatusHeaders"}
					tagClassName="d-flex justify-content-between"
					onClick={() => this.toggleCollapse("collapse4")}
				>
					<span style={{ fontSize: "1em" }}>Payment Information</span>
					<MDBIcon
						icon={collapseID === "collapse4" ? "angle-up" : "angle-down"}
					/>
				</MDBCollapseHeader>
				<MDBCollapse id="collapse4" isOpen={this.state.collapseID}>
					<MDBCardBody>
						<MDBRow>
							<MDBCol
								size={"6"}
								className={"insuranceSelects insuranceSelectsText"}
							>
								<MDBInput
									outline
									style={{ paddingTop: 8 }}
									label={"Deductible"}
									disabled={this.state.statusSetup}
									valueDefault={appr.deductible}
									onBlur={(evt) =>
										this.approvalAndUpdate("deductible", evt.target.value)
									}
								/>
							</MDBCol>

							<MDBCol
								size={"6"}
								className={"insuranceSelects insuranceSelectsText"}
							>
								<MDBInput
									outline
									style={{ paddingTop: 8 }}
									label={"Co-Insurance"}
									disabled={this.state.statusSetup}
									valueDefault={appr.coInsurance}
									onBlur={(evt) =>
										this.approvalAndUpdate("coInsurance", evt.target.value)
									}
								/>
							</MDBCol>

							<MDBCol
								size={"6"}
								className={"insuranceSelects insuranceSelectsText"}
							>
								<MDBInput
									outline
									style={{ paddingTop: 8 }}
									label={"Pt. Resp"}
									disabled={this.state.statusSetup}
									valueDefault={appr.patientResponsibility}
									onBlur={(evt) =>
										this.approvalAndUpdate(
											"patientResponsibility",
											evt.target.value
										)
									}
								/>
							</MDBCol>

							<MDBCol
								size={"6"}
								className={"insuranceSelects insuranceSelectsText"}
							>
								<MDBInput
									outline
									style={{ paddingTop: 8 }}
									label={"State Sales Tax"}
									disabled={true}
									value={appr.stateSalesTax}
									onBlur={(evt) =>
										this.approvalAndUpdate("stateSalesTax", evt.target.value)
									}
								/>
							</MDBCol>

							<MDBCol
								size={"12"}
								className={"insuranceSelects insuranceSelectsText"}
							>
								<MDBInput
									outline
									style={{ paddingTop: 8 }}
									label={"Payment On Delivery"}
									disabled={this.state.statusSetup}
									valueDefault={appr.paymentOnDelivery}
									onBlur={(evt) =>
										this.approvalAndUpdate(
											"paymentOnDelivery",
											evt.target.value
										)
									}
								/>
							</MDBCol>

							<MDBCol size={"12"}>
								<MDBBtn
									disabled={this.state.customerSuccessDisabled}
									outline
									onClick={() => this.confirmCustomerSuccessDate()}
								>
									Customer Care Rep Called Patient{" "}
									{appr.customerSuccessDate
										? "on " + appr.customerSuccessDate
										: ""}
								</MDBBtn>
							</MDBCol>
						</MDBRow>

						{this.renderPaymentProcessedSection()}
					</MDBCardBody>
				</MDBCollapse>
			</div>
		);
	}

	render() {
		return (
			<MDBCard>
				{this.renderPaymentProcessedModal()}
				{this.renderStatusSection()}
				{this.renderRepSection()}
				{this.renderPaymentInfoSection()}
				{this.renderSetupDeliverySection()}
			</MDBCard>
		);
	}
}
