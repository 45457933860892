import React from "react";
import {
	CardHeader,
	Col,
	Fa,
	Input,
	MDBContainer,
	MDBRow,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
} from "mdbreact";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import NoteService from "../../Security/NoteService/noteService";

export default class CreateNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.toggleNoteModal = props.toggleNoteModal;
		this.addNoteUpdateOrderCloseModal = props.addNoteUpdateOrderCloseModal;

		this.state = {
			eOrder: props.eOrder,
			usedLiaisonKeys: [],
			resultingLiaisonKeys: [],
			existingMeeting: {},
			allNotes: props.allNotes,
			noteModalOpen: false,
			taskCompleted: true,
			noteAssignedTo: props.eOrder.owner || null,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allUsers: PropTypes.array,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.noteModalOpen !== prevState.noteModalOpen) {
			return {
				noteModalOpen: nextProps.noteModalOpen,
				eOrder: nextProps.eOrder,
			};
		} else return null;
	}

	getUserList() {
		const { allUsers } = this.context;
		let a = [];
		a.push({
			label: "Select User...",
			value: "",
		});
		allUsers.map((user) => {
			return a.push({
				label: user.username,
				value: user,
			});
		});
		return a;
	}

	taskCompletedChange = (value) => {
		this.setState({ taskCompleted: value });
	};

	handleNoteUserSelected = (e) => {
		let user = { name: e.value.username, id: e.value.id };
		this.setState({
			noteAssignedTo: user,
		});
	};

	addNewNote() {
		//get the current user context that can be passed into the note DTO...BC
		const { currentUser } = this.context;

		let cb = { id: currentUser.id, name: currentUser.username };

		let note = {
			task: this.state.taskCompleted,
			completed: this.state.taskCompleted,
			assignedTo: this.state.noteAssignedTo,
			createdAt: new Date(),
			createdBy: cb,
			account: this.state.eOrder.account,
			patientName: this.state.eOrder.patientName,
		};

		let nText = document.getElementById("newActivityNoteText").value;
		note.text = nText;

		return NoteService.createActivityNote(this.state.eOrder.id, note)
			.then((res) => {
				//need to set the name since the res doesnt currently have it...BC
				res.createdBy.name = note.createdBy.name;

				if (note.assignedTo) {
					res.assignedTo.name = note.assignedTo.name;
				}

				//need to set the last note date because otherwise the save overrides it...BC
				let ord = this.state.eOrder;
				ord.lastNoteDate = new Date();

				this.addNoteUpdateOrderCloseModal(res, ord);
			})
			.catch((err) => {
				//Maybe send an error to the user?...BC
			});
	}

	renderNoteModal() {
		return (
			<MDBContainer>
				<MDBRow>
					<Modal
						centered
						isOpen={this.state.noteModalOpen}
						toggle={() => this.toggleNoteModal()}
					>
						<ModalBody style={{ backgroundColor: "#FFFFFF" }} className="mx-3">
							<CardHeader color={"indigo"} className="form-header text-center">
								Add New Note
							</CardHeader>
							<form className=" mx-3 grey-text">
								<textarea
									placeholder={"Add note text here."}
									autoFocus
									id={"newActivityNoteText"}
									style={{ width: "100%", height: "200px" }}
								></textarea>
								<Row>
									<Col size={"6"}>
										<Input
											type="radio"
											gap
											label={"Note"}
											checked={this.state.taskCompleted}
											onClick={this.taskCompletedChange.bind(this, true)}
											id={"noteRadio"}
										/>
									</Col>

									<Col size={"6"}>
										<Input
											type="radio"
											gap
											label={"Task"}
											checked={!this.state.taskCompleted}
											onClick={this.taskCompletedChange.bind(this, false)}
											id={"taskRadio"}
										/>
									</Col>
								</Row>

								<br />
								<label> Assigned to (optional) </label>
								<Select
									placeholder={
										this.state.noteAssignedTo
											? this.state.noteAssignedTo.name
											: "Select User..."
									}
									options={this.getUserList()}
									onChange={this.handleNoteUserSelected.bind(this)}
								/>
								<br />

								<ModalFooter>
									<span
										className={"ptBtn saveBtn"}
										data-tip={"Add Note"}
										onClick={this.addNewNote.bind(this)}
									>
										<Fa size={"2x"} icon={"check"}>
											{" "}
										</Fa>
									</span>
									<span
										className={"ptBtn cancelBtn"}
										data-tip={"Cancel"}
										onClick={() => this.toggleNoteModal()}
									>
										<Fa size={"2x"} icon={"times"}>
											{" "}
										</Fa>
									</span>
								</ModalFooter>
								<ReactTooltip />
							</form>
						</ModalBody>
					</Modal>
				</MDBRow>
			</MDBContainer>
		);
	}

	render() {
		return <div>{this.renderNoteModal()}</div>;
	}
}
