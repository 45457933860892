import api from "../api";

class PresidentsClubService {
	async getPresidentsClub(year) {
		return new Promise((res, reject) => {
			api
				.get("/presidentsClub", year)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const presidentsClubService = new PresidentsClubService();

export default presidentsClubService;
