import api from "../api";

class OrderService {
	formatDate(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "2-digit",
			month: "2-digit",
			day: "2-digit",
		}).format(date);
	}

	GetFormattedDate(date) {
		if (date == null || date === "") {
			return "";
		}

		let month = date.getMonth() + 1,
			day = date.getDate(),
			year = date.getFullYear();

		return month + "/" + day + "/" + year;
	}

	async getAllOrders(filters) {
		return new Promise((res, reject) => {
			if (filters) {
				filters.startDate = this.GetFormattedDate(filters.startDate);
				filters.endDate = this.GetFormattedDate(filters.endDate);
			}

			api
				.get("/findOrdersByStatuses", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrdersWithLocations(filters) {
		return new Promise((res, reject) => {
			let params = {
				statuses: filters.statuses || [],
				reasonIds: filters.reasonIds || [],
				equipmentIds: filters.equipmentIds || [],
				internalLocations: filters.internalLocations || [],
				clinicLocations: filters.seatingClinics || [],
				salesOrService: filters.salesOrService || "Sales",
				startDate: this.GetFormattedDate(filters.startDate) || "",
				endDate: this.GetFormattedDate(filters.endDate) || "",
				DOB: this.GetFormattedDate(filters.dob) || "",
				q: filters.searchText || "",
				pageSize: filters.pageSize || 150,
			};
			api
				.get("/findOrdersWithLocations", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrderActivity(id) {
		return new Promise((res, rej) => {
			api
				.get("/getOrderActivity/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej();
				});
		});
	}

	async syncToBrightTree(id) {
		return new Promise((res, rej) => {
			api
				.post("/syncToBrightTree/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					rej();
				});
		});
	}

	//getTechTimeline
	async getDeliveriesForLocation(locationId) {
		return new Promise((res, reject) => {
			api
				.get("/deliveries/" + locationId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateDelivery(order) {
		return new Promise((res, reject) => {
			api
				.post("/deliveries", order)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getTechDeliveryTimeline(locationId, startDate, endDate) {
		return new Promise((res, reject) => {
			let params = {
				startDate: startDate,
				endDate: endDate,
			};
			api
				.get("/getTechTimeline/" + locationId, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrdersWithLocationsNoDeliveryDate(filters) {
		return new Promise((res, reject) => {
			let params = {
				statuses: filters.statuses || "",
				internalLocations: filters.internalLocations || [],
				startDate: this.GetFormattedDate(filters.startDate) || "",
				endDate: this.GetFormattedDate(filters.endDate) || "",
				noScheduledDate: "true",
				q: filters.q,
				pageSize: filters.pageSize || 100,
			};
			api
				.get("/findOrdersWithLocationsNoScheduledDate", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrdersWithLocationsDeliveryDate(filters) {
		return new Promise((res, reject) => {
			let params = {
				statuses: filters.statuses || "",
				internalLocations: filters.internalLocations || [],
				startDate: filters.deliveryDateStart || "",
				endDate: filters.deliveryDateEnd || "",
				noScheduledDate: "true",
				users: filters.users || [],
				pageSize: filters.pageSize || 150,
			};
			api
				.get("/findDeliveries", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async searchOrders(filters) {
		return new Promise((res, reject) => {
			if (filters) {
				filters.startDate = this.GetFormattedDate(filters.startDate);
				filters.endDate = this.GetFormattedDate(filters.endDate);
			}

			api
				.get("/searchOrdersByStatuses", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getDashboardCounts() {
		return new Promise((res, reject) => {
			api
				.get("/dashboard", null)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllOrdersNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/ordersNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrdersByIdNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/orderByIdNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getBillingViewNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/billingNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getFollowUpViewNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/followUpViewNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getCollectionsViewNative(filters) {
		return new Promise((res, reject) => {
			api
				.get("/patientCollectionsNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getActivity(id) {
		return new Promise((res, reject) => {
			api
				.get("/activities/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createOrder(order) {
		return new Promise((res, reject) => {
			api
				.post("/activities", order)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//could be {data: order}
	async updateOrder(order) {
		return new Promise((res, reject) => {
			return api
				.put("/activities/" + order.id, order)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async uploadDocument(order, file, fileName) {
		return new Promise((res, reject) => {
			return api
				.downloadPost("/activities/" + order.id + "/files/" + fileName, file)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async uploadDocumentWithStamp(order, file, fileName) {
		return new Promise((res, reject) => {
			return api
				.downloadPost(
					"/activities/" + order.id + "/fileswithstamp/" + fileName,
					file
				)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getDocumentThumbnails(docId) {
		return new Promise((res, reject) => {
			return api
				.get("/deliveryPaperwork/image/" + docId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async downloadDocument(id) {
		return new Promise((res, reject) => {
			return api
				.getFile("/files/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async downloadDoc(id) {
		return new Promise((res, reject) => {
			return api
				.getFile("/downloadFile/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteDocument(id) {
		return new Promise((res, reject) => {
			return api
				.delete("/files/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrderTimeline(order) {
		return new Promise((res, reject) => {
			return api
				.get("/orderHistory/" + order.id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getOrdersByAccount(id) {
		return new Promise((res, reject) => {
			let params = {
					page: 0,
					size: 25,
				},
				url = "/accounts/" + id + "/orders";

			return api
				.get(url, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getPatients() {
		return new Promise((res, reject) => {
			return api
				.get("/patients")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getPatientById(patientId) {
		return new Promise((res, reject) => {
			return api
				.get("/patients/" + patientId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getPatientByIdFromOtherCompany(patientId, params, credentials) {
		return new Promise((res, reject) => {
			return api
				.getFromOtherCompany("/patients/" + patientId, params, credentials)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getPatientOrdersById(patientId) {
		return new Promise((res, reject) => {
			return api
				.get("/patients/" + patientId + "/orders")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getPatientsByInformation(fName, lName, dob) {
		let params = {
			fName: fName || "",
			lName: lName || "",
			dateOfBirth: dob || "",
		};
		return new Promise((res, reject) => {
			return api
				.get("/patients/findByInformation", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updatePatient(data) {
		return new Promise((res, reject) => {
			return api
				.put("/patients/" + data.id, data)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createPatient(data) {
		return new Promise((res, reject) => {
			return api
				.post("/patients", data)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async mergePatients(params) {
		return new Promise((res, reject) => {
			return api
				.get("/patients/mergePatients", params)
				.then(() => {
					res();
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async mergeDocuments(order, fileIds) {
		return new Promise((res, reject) => {
			return api
				.post("/activities/" + order.id + "/merge", fileIds)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async mergePagedDocuments(order, files, name) {
		return new Promise((res, reject) => {
			return api
				.post("/activities/" + order.id + "/mergepages/" + name, files)
				.then((data) => {
					res(data.json());
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async getDashboardDeliveryAverages() {
		return new Promise((res, reject) => {
			api
				.get("/dashboard/averages", null)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const orderService = new OrderService();

export default orderService;
