import React from "react";
import {
	Button,
	Card,
	CardFooter,
	CardBody,
	Container,
	Spinner,
	Input,
	toast,
	CardHeader,
	MDBIcon,
	MDBRow,
	MDBCol,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ProductService from "../Security/ProductService/productTypeService";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";

export default class addProductType extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			insurance: [],
			insuranceAdd: {},
			name: "",
			errorMessage: "",
			insuranceList: [],
			currProduct: {
				insuranceMatrixEntries: [],
				requiresSerialNumber: false,
			},
		};
	}

	componentDidMount() {
		this.getInsuranceTypes();
	}

	getInsuranceTypes() {
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [],
					l = [],
					cp = this.state.currProduct;
				res.forEach((insurance) => {
					arr.push({
						id: insurance.id,
						name: insurance.name,
						order:
							typeof insurance.order === "number"
								? insurance.order
								: "no order",
					});
					l.push(insurance.name);
					cp.insuranceMatrixEntries.push({
						insuranceType: insurance,
					});
				});
				this.setState({
					insurance: arr,
					insuranceList: l,
					currProduct: cp,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderForm() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					label={"Name"}
					value={this.state.currProduct.name}
					onChange={this.handleNameChange.bind(this)}
				/>
				<Input
					filled
					type="checkbox"
					id="requiresSerialNumber"
					value="Requires Serial Number"
					label="Requires Serial Number"
					checked={this.state.currProduct.requiresSerialNumber}
					onChange={this.handleCheckChange.bind(this)}
				/>
				<MDBRow>{this.renderInputs()}</MDBRow>
			</div>
		);
	}

	handleCheckChange = (event) => {
		var p = this.state.currProduct;
		p.requiresSerialNumber = !p.requiresSerialNumber;
		this.setState({ currProduct: p });
	};

	renderInputs() {
		let l = this.state.insuranceList;
		let cp = this.state.currProduct.insuranceMatrixEntries;

		return l.map((name, index) => {
			return (
				<MDBCol size="4">
					<Input
						key={index}
						value={cp[index] ? cp[index].points : ""}
						onChange={this.handleInsuranceChange.bind(this, index)}
						label={name}
					/>
				</MDBCol>
			);
		});
	}

	handleInsuranceChange(property, e) {
		let p = this.state.currProduct;
		p.insuranceMatrixEntries[property].points = e.target.value;
		this.setState({
			currProduct: p,
		});
	}

	handleNameChange(e) {
		let cp = this.state.currProduct;
		cp.name = e.target.value;
		this.setState({
			currProduct: cp,
		});
	}

	saveButtonClick = () => {
		if (this.validate()) {
			let cp = this.state.currProduct;
			cp.insuranceMatrixEntries.forEach((insurance) => {
				parseInt(insurance.points, 10);
			});

			return ProductService.createProduct(cp)
				.then(() => {
					toast.success(cp.name + " Product Type Created Has Been Created");
					this.getInsuranceTypes();
					this.setState({
						currProduct: {
							name: "",
							insuranceMatrixEntries: [],
							requiresSerialNumber: false,
						},
						errorMessage: "",
					});
				})
				.catch((err) => {
					toast.warn("An error occurred while saving");
				});
		}
	};

	validate() {
		let cp = this.state.currProduct,
			cc = this.state.currProduct.insuranceMatrixEntries,
			i = 0;
		cc.forEach((insurance) => {
			if (insurance.points === undefined || insurance.points === "") {
				this.setError(
					"Please enter points for " + insurance.insuranceType.name
				);
				i += 1;
			}
		});
		if (cp.name === undefined || cp.name === "") {
			this.setError("Please enter a Name");
			i += 1;
		}
		return i === 0;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				<Container style={{ width: "30%" }}>
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							Add New Product Category
						</CardHeader>
						<CardBody>{this.renderForm()}</CardBody>
						<CardFooter>
							<Button
								style={{ float: "left" }}
								floating
								size="sm"
								color={"warning"}
								data-tip={"Back to Categories"}
								onClick={() => this.props.history.push("/products")}
							>
								<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
							</Button>

							<Button
								style={{ float: "right" }}
								floating
								size="sm"
								color={"success"}
								data-tip={"Save New Product Category"}
								onClick={this.saveButtonClick}
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
							<ReactTooltip />
						</CardFooter>
					</Card>
				</Container>
			</div>
		);
	}
}
