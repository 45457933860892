import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ContactTypeService from "./contactTypesService";
import ReactTooltip from "react-tooltip";

export default class contactTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currContact: {},
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveContactType();
	}

	retrieveContactType() {
		let t = this;
		return ContactTypeService.getAllContactTypes()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((contact, index) => {
					arr.push({
						id: contact.id,
						name: contact.name,
						edit: t.renderEditButton(contact, index),
						remove: t.renderRemoveButton(contact.id),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(contact, index) {
		// let a = JSON.parse(JSON.stringify(account));
		return (
			<Button
				floating
				size="sm"
				onClick={() => this.handleEditClick(contact, index)}
			>
				<MDBIcon icon="edit" />
			</Button>
		);
	}

	renderRemoveButton(id) {
		return (
			<Button
				floating
				color={"red"}
				size="sm"
				onClick={() => this.handleRemoveClick(id)}
			>
				<MDBIcon icon="trash" />
			</Button>
		);
	}

	handleRemoveClick(id) {
		ContactTypeService.deleteContact(id)
			.then((res) => {
				this.retrieveContactType();
				toast.success("Contact Type Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Contact Type");
			});
	}

	handleEditClick(account, index) {
		let a = {
			id: account.id,
			name: account.name,
		};
		this.setState({
			currContact: a,
			editModal: !this.state.editModal,
			currIndex: index,
		});
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#90A4AE", color: "white" }}
					toggle={this.editToggle}
				>
					Edit Contact Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							label="Name"
							value={this.state.currContact.name}
							onChange={this.handleEditChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Contact Type"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Close"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>

					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data,
				i = this.state.currIndex,
				t = this;
			return ContactTypeService.updateContactType(this.state.currContact)
				.then((contact) => {
					d.rows[i].name = contact.name;
					d.rows[i].edit = t.renderEditButton(contact, i);
					this.setState({
						data: d,
						editModal: !this.state.editModal,
					});
					toast.success("Contact Type Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateEdit() {
		let a = this.state.currContact;
		if (a.name === "") {
			this.setError("Please add a Contact Name");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currContact: {},
		});
	};

	handleEditChange(e) {
		let c = this.state.currContact;
		c.name = e.target.value;
		this.setState({
			currContact: c,
		});
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		return this.props.history.push({
			pathname: "contactTypes/new",
		});
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Contact Types
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Contact Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="address-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
			</Container>
		);
	}
}
