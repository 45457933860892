import React from "react";
import { Container, Spinner, MDBIcon } from "mdbreact";
import ReactTooltip from "react-tooltip";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "devextreme-react";
import {
	ColumnChooser,
	Column,
	FilterRow,
	Grouping,
	GroupPanel,
	Paging,
	Sorting,
	Summary,
	GroupItem,
} from "devextreme-react/data-grid";
import NoteService from "../Security/NoteService/noteService";

import "devextreme/dist/css/dx.common.css";
//import 'devextreme/dist/css/dx.light.compact.css';
import "devextreme/dist/css/dx.material.blue.light.compact.css";

export default class ReadyToScheduleGrid extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tooltipContent: "",
			renderEditOrderModal: props.renderEditOrderModal,
			gotoMap: props.gotoMap,
		};
	}

	formatDate(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
		}).format(date);
	}

	formatTime(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			hour: "numeric",
			minute: "numeric",
			second: "2-digit",
		}).format(date);
	}

	formatToolTip(result) {
		if (result == null) {
			return;
		}

		let strResult = "",
			count = result.length >= 2 ? 2 : result.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				strResult +=
					"\nUser: " +
					result[i].createdBy.name +
					"\n" +
					"Date: " +
					this.formatDate(result[i].createdAt) +
					"\n" +
					"Note: " +
					result[i].text;

				if (i < count - 1) {
					strResult += "\n----------------------";
				}
			}
		}
		return strResult;
	}

	setTooltipContent(id) {
		NoteService.getNotes(id)
			.then((res) => {
				this.setState({ tooltipContent: res.content });
				return res.content;
			})
			.catch((err) => {
							console.log(err);
						});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	cellWithTooltip(cellData) {
		return (
			<div>
				<div
					data-tip
					data-event={"click focus"}
					data-for={"notes"}
					onMouseEnter={() => {
						this.setTooltipContent(cellData.data.order);
					}}
					style={{
						cursor: "pointer",
					}}
				>
					{cellData.data.lastNoteDate}
				</div>
				<ReactTooltip
					className={"note-tooltip"}
					id={"notes"}
					place={"left"}
					delayHide={0}
					globalEventOff={"click"}
					effect={"solid"}
					getContent={() => (
						<p> {this.formatToolTip(this.state.tooltipContent)} </p>
					)}
				/>
			</div>
		);
	}

	toggleCell(cellData) {
		return (
			<div>
				<a
					href="#void"
					data-tip={"Toggle this order on the map"}
					onClick={() => this.state.gotoMap(cellData.data.order)}
				>
					<MDBIcon icon="map-marker" />
				</a>
				<ReactTooltip />
			</div>
		);
	}

	linkingCell(cellData) {
		return (
			<div style={{ cursor: "pointer" }}>
				<span
					style={{ color: "blue" }}
					onClick={() => {
						this.state.gotoMap(cellData.data.order);
						window.open("/order/" + cellData.value, "_blank");
					}}
				>
					{cellData.value}
				</span>
				{/*<a href={'/order/' + cellData.value} target={'_blank'}></a>*/}
			</div>
		);
	}

	scheduleCell(cellData) {
		return (
			<div>
				<a
					href="#void"
					data-tip={"Schedule Delivery"}
					onClick={() => {
						this.state.renderEditOrderModal(cellData.data.order);
					}}
				>
					<MDBIcon icon="calendar" />
				</a>
				<ReactTooltip />
			</div>
		);
	}

	// standardCell(cellData) {
	//     return (
	//         <div style={{cursor: 'pointer'}}  onClick={() => {
	//             this.state.renderEditOrderModal(cellData.data.order)
	//         }}>
	//             {cellData.value}
	//         </div>
	//     )
	// }

	render() {
		let h = window.innerHeight;

		if (!this.props.isLoaded && this.props.selectedLocationIds.length > 0) {
			return this.renderLoadingSpinner();
		}

		return (
			<div
				style={{ overflowY: "scroll", height: h * 0.85, maxHeight: h * 0.85 }}
			>
				<Paper>
					<DataGrid
						className={"rtsGrid"}
						style={{ maxWidth: "1800px !important" }}
						dataSource={this.props.dataNotScheduled}
						allowColumnResizing={true}
						allowColumnReordering={true}
						showBorders={true}
					>
						<ColumnChooser enabled={true} mode="select" />

						<Paging enabled={false} />
						<Sorting mode={"single"} />

						<GroupPanel visible={true} />
						<Grouping autoExpandAll={false} />

						<FilterRow visible={true} />

						<Column cellRender={this.toggleCell.bind(this)} width={40} />

						<Column cellRender={this.scheduleCell.bind(this)} width={40} />

						<Column
							dataField={"order"}
							width={80}
							cellRender={this.linkingCell.bind(this)}
							alignment={"left"}
						/>

						<Column dataField={"type"} groupIndex={2} />
						<Column dataField={"reason"} groupIndex={1} />
						<Column dataField={"name"} />
						<Column dataField={"address"} />
						<Column dataField={"city"} />
						<Column dataField={"state"} />
						<Column dataField={"zip"} alignment={"left"} />
						<Column dataField={"product"} />
						<Column dataField={"pts"} alignment={"left"} />

						<Column dataField={"tech"} />
						<Column dataField={"scheduledDate"} />
						<Column dataField={"approvalDate"} />
						<Column dataField={"equipmentOrderedDate"} />
						<Column
							dataField={"lastNoteDate"}
							cellRender={this.cellWithTooltip.bind(this)}
						/>

						<Summary>
							<GroupItem
								column="order"
								summaryType="count"
								displayFormat={"{0} orders"}
							/>
							{/*<GroupItem*/}
							{/*    column="SaleAmount"*/}
							{/*    summaryType="max"*/}
							{/*    valueFormat="currency"*/}
							{/*    showInGroupFooter={false}*/}
							{/*    alignByColumn={true} />*/}
							{/*<GroupItem*/}
							{/*    column="TotalAmount"*/}
							{/*    summaryType="max"*/}
							{/*    valueFormat="currency"*/}
							{/*    showInGroupFooter={false}*/}
							{/*    alignByColumn={true} />*/}
							{/*<GroupItem*/}
							{/*    column="TotalAmount"*/}
							{/*    summaryType="sum"*/}
							{/*    valueFormat="currency"*/}
							{/*    displayFormat={'Total: {0}'}*/}
							{/*    showInGroupFooter={true} />*/}
						</Summary>
					</DataGrid>
				</Paper>
				<ReactTooltip />
			</div>
		);
	}
}
