import React from "react";
import {Route} from "react-router-dom";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBListGroup,
    MDBListGroupItem,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBRow,
    MDBTabContent,
    MDBTabPane,
    MDBModal,
    MDBModalHeader,
    MDBModalBody,
    MDBModalFooter,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
    TableBody,
    TableHead,
    Timeline,
    TimelineStep,
    toast,
    ToastContainer,
    MDBInput,
    MDBTable,
    MDBCardHeader,
} from "mdbreact";
import moment from "moment";
import classnames from "classnames";
import OrderService from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import "./patientView.css";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import "react-dual-listbox/lib/react-dual-listbox.css";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import PqQuoteSelector from "./pqQuoteSelector";
import LiaisonMeetingService from "../Security/LiaisonMeetingService/LiaisonMeetingService";
import ApprovalTab from "./PatientViewComponents/approvalTab";
import PatientInformationTab from "./PatientViewComponents/patientInformationTab";
import NotesTab from "./PatientViewComponents/notesTab";
import AttachmentsTab from "./PatientViewComponents/attachmentsTab";
import TypeStatusContainer from "./PatientViewComponents/typeStatusContainer";
import LiaisonModal from "./PatientViewComponents/liaisonModal";
import CreateNoteModal from "./PatientViewComponents/createNoteModal";
import PatientCommunicationService from "../Settings/PatientCommunicationService";

export default class PatientOrderView extends React.Component {
    constructor(props) {
        super(props);

        let id,
            isLoaded = false,
            order = {},
            goto = "order";

        if (props.match && props.match.params) {
            id = props.match.params.id;
        }

        if (props.location && props.location.state && props.location.state.goto) {
            goto = props.location.state.goto;
        }

        // leaving this just in case it's needed later
        // let dt = new Date(),
        // 		mo =
        // 			dt.getMonth() < 10
        // 				? "0" + (dt.getMonth() + 1).toString()
        // 				: dt.getMonth() + 1,
        // 		dy = dt.getDate() < 10 ? "0" + dt.getDate().toString() : dt.getDate(),
        // 		ho = dt.getHours() < 9 ? "0" + dt.getHours().toString() : dt.getHours(),
        // 		mi =
        // 			dt.getMinutes() < 9
        // 				? "0" + dt.getMinutes().toString()
        // 				: dt.getMinutes(),
        // 		se =
        // 			dt.getSeconds() < 9
        // 				? "0" + dt.getSeconds().toString()
        // 				: dt.getSeconds(),
        // 		name = `${id}_${dt.getFullYear()}${mo}${dy}_${ho}${mi}${se}_MergedDocuments`;

        let primaryColumns = [
            {
                label: "Category",
                field: "category",
                minimal: "sm",
            },
            {
                label: "Product",
                field: "product",
                minimal: "lg",
            },
            {
                label: "HCPCS",
                field: "hcpcs",
                minimal: "sm",
            },
            {
                label: "Points",
                field: "points",
                minimal: "sm",
            },
            {
                label: "Quantity",
                field: "quantity",
                minimal: "sm",
            },
            {
                label: "Serial Number",
                field: "serialnumber",
                minimal: "lg",
            },
        ];

        let secondaryColumns = [
            {
                label: "Approved",
                field: "approved",
                minimal: "lg",
            },
            {
                label: "ABN Required",
                field: "abnrequired",
                minimal: "lg",
            },
            {
                label: "Remove",
                field: "Remove",
                minimal: "lg",
            },
        ];

        const data = {
            columns: primaryColumns.concat(secondaryColumns),
            rows: [],
        };

        if (order.approvals == null || order.approvals.length === 0) {
            order.approvals = [{}];
        }

        this.state = {
            id: id,
            notes: [],
            clinicNotes: [],
            tasks: [],
            order: order,
            goto: goto,
            eOrder: JSON.parse(JSON.stringify(order)),
            isLoaded: isLoaded,
            noteModalOpen: false,
            timelineModalOpen: false,
            approvalModalOpen: false,
            followupModalOpen: false,
            approvalErrorsModal: false,
            approvalErrors: [],
            noteText: "",
            noteAssignedTo: order.owner || null,
            taskCompleted: true,
            clinicTaskCompleted: true,
            chosenCategory: {},
            statuses: [
                {label: "New", value: "New"},
                {label: "In Process", value: "InProcess"},
                {label: "Set Up", value: "Setup"},
                {label: "Cancelled", value: "Cancelled"},
            ],
            orderTypes: ["New", "Pickup", "Service", "Exchange"],
            data: data,
            abnModal: false,
            approveAgreement: false,
            allApproved: false,
            formActivePanel1: 1,
            formActivePanel1Changed: false,
            totalPoints: 0,
            productOverrides: [],
            purchaseQueueArr: [],
            pqModal: false,
            showOrderProduct: false,
            purchaseQueues: [],
            selectedTime: "",
            primaryColumns: primaryColumns,
            secondaryColumns: secondaryColumns,
            usedLiaisonKeys: [],
            resultingLiaisonKeys: [],
            stateSalesTax: [],
        };

        this.state.TabsOneActiveItem = "1";
        this.state.TabsTwoActiveItem = "1";

        this.retrieveInitialInfo(id);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let id = nextProps.match.params.id;

        if (nextProps.match.params.id !== this.state.id) {
            this.setState({
                id: id,
                isLoaded: false,
            });
            this.retrieveInitialInfo(id);
        }
    }

    retrieveInitialInfo(id) {
        this.retrieveOrder(id);
        this.retrieveNotes(id);
        this.retrieveClinicNotes(id);
        this.retrievePurchaseQueues(id);
        this.formatStatus();
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        companyName: PropTypes.string,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        reasons: PropTypes.array,
        therapists: PropTypes.array,
        salesReps: PropTypes.array,
        accounts: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        allUsers: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
        documentTypes: PropTypes.array,
        techs: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        productOverrides: PropTypes.array,
        timezoneVariation: PropTypes.object,
        stateSalesTax: PropTypes.array,
        patientCommunicationTriggerDefs: PropTypes.array,
    };

    componentDidMount() {
        this.triggerSerialsPopup();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {productOverrides} = this.context;
        if (
            productOverrides &&
            productOverrides.length !== prevState.productOverrides.length
        ) {
            this.setState({
                productOverrides: productOverrides,
            });
        }
    }

    toggleNoteModalPopup() {
        let currentState = this.state.noteModalOpen;

        this.setState({noteModalOpen: !currentState});

        if (currentState === false) {
            setTimeout(() => {
                let p = document.getElementById("newActivityNoteText");
                if (p) {
                    p.focus();
                }
            }, 600);
        }
    }

    //region liaison meeting stuff
    getLiaisonMeetings() {
        LiaisonMeetingService.findAllMeetingsNative()
            .then((r) => {
                if (r) {
                    this.populateTakenMeetingsArray(r);
                }
            })
            .catch((e) => {
            });
    }

    populateTakenMeetingsArray(meetings) {
        const {fullLiaisonKeys, currentUser} = this.context;

        let ary = [],
            existingMeeting = null,
            oid = this.state.eOrder.id,
            today = new Date().toLocaleDateString(),
            hour = new Date().getHours();

        meetings.forEach((m) => {
            let sd = new Date(m.scheduledDate);

            //create the keys from the meetings from the server
            let dstr =
                (sd.getMonth() + 1).toString() +
                "/" +
                sd.getDate().toString() +
                "/" +
                sd.getFullYear().toString() +
                " | ";

            let tstr = sd.getHours() + ":00:00--" + m.salesLiaison;

            ary.push(dstr + tstr);

            //find out if a meeting already exists for this order and sales rep...BC
            if (m.orderId === oid && m.salesRep === currentUser.username) {
                existingMeeting = m;
            }
        });

        let difference = fullLiaisonKeys.filter((x) => !ary.includes(x));

        difference = difference.filter((v) => {
            //split the entry and separate the hour from it...BC
            let entry = v.split("|"),
                entryDate = new Date(entry[0]).toLocaleDateString(),
                time = entry[1].split(":"),
                h = parseInt(time[0]);

            if (entryDate === today) {
                return h > hour;
            }
            return true;
        });

        this.setState({
            usedLiaisonKeys: ary,
            resultingLiaisonKeys: difference,
            existingMeeting: existingMeeting,
            helpModalOpen: true,
        });
    }

    formatTimelineStatus(timelineEntry) {
        if (timelineEntry.status === "ReadyToDeliver") {
            return "Waiting for Delivery";
        } else if (timelineEntry.status === "InProcess") {
            if (timelineEntry.reason === "Verification") {
                return "Intake / Insurance Verification";
            } else {
                return "Insurance Review";
            }
        } else {
            return timelineEntry.status;
        }
    }

    formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    formatStatus = (status) => {
        const statuses = this.state.statuses;
        let i = statuses.findIndex((x) => x.value === status);
        if (statuses[i] !== undefined) {
            return statuses[i].label;
        }
    };

    addNoteUpdateOrderCloseModal(note, order) {
        let notes = this.state.notes;

        notes.unshift(note);

        this.setState({
            notes: notes,
            eOrder: order,
            noteModalOpen: false,
        });
    }

    //region notes download
    noteAdded(nNote) {
        let notes = this.state.notes;

        notes.unshift(nNote);

        this.setState({
            notes: notes,
        });
    }

    downloadNotes() {
        let exportName = this.state.eOrder.id + "_notes_.csv";

        this.clientCSV(this.state.notes, exportName);
    }

    clientCSV(stateData, filename) {
        let result,
            headers = ["Created At", "Created By", "Text"],
            data;

        data = stateData || null;
        if (data == null || !data.length) {
            return null;
        }

        result = "";
        result += headers.join(",");
        result += "\n";

        data.forEach(function (item) {
            let date = new Intl.DateTimeFormat("en-US").format(item.createdAt);
            // this is a slight pain because of complex objects, otherwise we could use keys -CD
            result += date + "," + item.createdBy.name + ',"' + item.text + '"\n';
        });

        this.downloadBlob(result, filename);
    }

    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a");

            link.href = URL.createObjectURL(blob);
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    //endregion

    checkBoxApprovedChange = (event) => {
        this.setState({approveAgreement: event.target.checked});
    };

    //region primary order functions
    retrieveNotes(id) {
        NoteService.getNotes(id).then((notes) => {
            if (notes === null || notes.length === 0) {
                //we have no notes, return...BC
                return;
            }
            //copy the notes array...BC
            let con = notes.content.slice();

            //sort the notes...BC
            con.sort((a, b) => {
                if (a.createdAt > b.createdAt) {
                    return -1;
                } else {
                    return 1;
                }
            });

            //set the state for notes...BC
            this.setState({notes: con});
            // this.triggerTaskPopup();
        });
    }

    async retrievePurchaseQueues(id) {
        return PurchaseQueueService.findPurchaseQueuesByOrderId(id)
            .then((res) => {
                this.setState({
                    purchaseQueues: res,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    retrieveClinicNotes(id) {
        NoteService.getTherapistNotes(id).then((notes) => {
            if (notes === null || notes.length === 0) {
                //we have no notes, return...BC
                return;
            }
            //copy the notes array...BC
            let con = notes.slice();

            //sort the notes...BC
            con.sort((a, b) => {
                if (a.createdAt > b.createdAt) {
                    return -1;
                } else {
                    return 1;
                }
            });

            //set the state for notes...BC
            this.setState({clinicNotes: con});
            // this.triggerTaskPopup(true);
        });
    }

    retrieveOrder(id) {
        return OrderService.getActivity(id).then((data) => {
            if (data.approvals == null || data.approvals.length === 0) {
                data.approvals = [{}];
            }

            this.setState({
                order: data,
                eOrder: JSON.parse(JSON.stringify(data)),
                isLoaded: true,
                noteAssignedTo: data.owner,
            });
            this.updateTableRows();
        });
    }

    updateOrderProperties(order, patient) {
        //update address properties
        order.address = patient.address;
        order.city = patient.city;
        order.state = patient.state;
        order.zipCode = patient.zipCode;
        order.phone = patient.phone;

        order.patientName = patient.firstName + " " + patient.lastName;
    }

    generateScheduledNote(order, edit) {
        const {currentUser} = this.context;

        let cb = {id: currentUser.id, name: currentUser.username},
            currentNotes = this.state.notes;

        if (edit.orderStatusReason && edit.orderStatusReason.name === "Scheduled") {
            if (edit.technician && edit.scheduledDeliveryDate) {
                let createNote = false;

                if (!order.technician) {
                    createNote = true;
                } else if (
                    edit.technician.id !== order.technician.id ||
                    edit.scheduledDeliveryDate !== order.scheduledDeliveryDate
                ) {
                    createNote = true;
                }

                if (createNote === true) {
                    let text =
                        "Order was scheduled by " +
                        currentUser.username +
                        " for " +
                        moment(edit.scheduledDeliveryDate).format("MMMM Do YYYY, h:mm a") +
                        " with technician " +
                        edit.technician.name;

                    let note = {
                        type: "ActivityNote",
                        text: text,
                        createdAt: new Date(),
                        account: edit.account,
                        task: false,
                        completed: true,
                        createdBy: cb,
                    };

                    NoteService.createActivityNote(edit.id, note)
                        .then((res) => {
                            currentNotes.unshift(note);
                        })
                        .catch((err) => {
                            //Maybe send an error to the user?...BC
                        });
                }
            }
        }
    }

    syncToBrightTree(orderId) {
        return OrderService.syncToBrightTree(orderId)
            .then((data) => {
                toast.success("Patient Created in Brighttree");
                return true;
            })
            .catch((err) => {
                toast.error(
                    "Oh no! An error occurred while creating the patient in Brighttree."
                );
                return false;
            });
    }

    async determineStatusTrigger(order) {
        const {patientCommunicationTriggerDefs} = this.context;

        function convertStatus(status) {
            switch (status) {
                case "New":
                    return 0;
                case "InProcess":
                    return 1;
                case "ReadyToDeliver":
                    return 2;
                case "Setup":
                    return 3;
                case "Cancelled":
                    return 4;
                default:
                    break;
            }
        }

        let sid = convertStatus(order.status),
            rid = order.orderStatusReason ? order.orderStatusReason.id : "";

        let trigger = patientCommunicationTriggerDefs.find((v) => {
            return v.statusID === sid && v.reasonID === rid;
        });

        if (trigger) {
            let p = {
                orderId: order.id,
                patientId: order.patient.id,
                dateToSend: "",
                sentOn: "",
                type: order.patient.preferredContactMethod,
                statusId: sid,
                reasonId: rid,
                sent: false,
            };

            PatientCommunicationService.insertPatientCommunication(p);
        }
    }

    async saveOrder(order) {
        const {currentUser} = this.context;
        const regex = /((|\()\d{3}(|\))-\d{3}-\d{4})/g;

        let st = this.state,
            eO = order || st.eOrder,
            ePatient = st.eOrder.patient,
            originalOrder = st.order,
            shouldSave = true;
        // In case the approvals array is empty put in try/catch so I dont error out trying to access ABN information...JK
        // If the statement is false then I don't have to worry about it anyway...JK
        try {
            if (eO.approvals[0].abnRequired === true && !eO.abnProductOther) {
                eO.approvals[0].abnRequired = false;
            }

            if (eO.approvals[0].deliverByDate) {
                eO.approvals[0].deliverByDate = new Date(eO.approvals[0].deliverByDate);
            }
            if (
                eO.abnProductOther &&
                (eO.abnAmount === "" ||
                    eO.abnAmount === null ||
                    eO.approvals[0].abnRequiredNote === "")
            ) {
                shouldSave = false;
                toast.error(
                    "One or more products have ABN required so ABN Amount and Reason are required"
                );
            }
        } catch (e) {
            if (eO.abnProductOther) {
                shouldSave = false;
                toast.error(
                    "One or more products have ABN required so ABN Amount and Reason are required"
                );
            }
        }

        if (ePatient.phone) {
            if (!regex.test(ePatient.phone)) {
                toast.warning(
                    "Phone number is not valid.  Try re-entering and saving again."
                );
            }
        }

        eO.type = "Order";

        if (shouldSave && this.validateSave(eO)) {
            eO.lastUpdatedOn = new Date();
            eO.lastUpdatedBy = {
                id: currentUser.id,
                name: currentUser.username,
            };

            if (st.order.status !== eO.status) {
                //If we have changed the status we need to update that field...BC
                eO.lastStatusChange = new Date();
            }
            if (eO.orderStatusReason && eO.orderStatusReason.id === 8) {
                if (st.order.orderStatusReason && st.order.orderStatusReason.id === 8) {
                    // dont do anything is status isn't changing...JK
                } else {
                    eO.equipmentOrderedDate = new Date().toLocaleString();
                }
            }

            //need to make sure the dateOfBirth is in milliseconds otherwise it wont work..BC
            if (st.order.dateOfBirth !== eO.dateOfBirth) {
                eO.dateOfBirth = moment(eO.dateOfBirth).format("MM/DD/YYYY");
                eO.patient.dateOfBirth = moment(eO.dateOfBirth).format("MM/DD/YYYY");
            }

            // Remove the purchase queue entry if the product is remoeved from the order...JK
            if (eO.products.length < originalOrder.products.length) {
                originalOrder.products.filter((p) => {
                    if (
                        p.purchaseQueueId &&
                        eO.products.findIndex((pr) => {
                            return pr.id === p.id;
                        }) === -1
                    ) {
                        this.removePurchaseQueue(p.purchaseQueueId);
                    }
                    return p;
                });
            }
            if (st.purchaseQueueArr.length > 0) {
                eO = await this.savePurchaseQueue();
            }

            if (eO.approvals[0] != null && eO.approvals[0].hasPrescription !== true) {
                eO.approvals[0].stateSalesTax = await this.calculateSalesTax(eO);
            }

            // wait for purchase queue to save so we can update the products with the newly created purchaseQueueId...JK
            this.updateOrderProperties(eO, ePatient);
            this.generateScheduledNote(originalOrder, eO);
            this.saveOrderServiceCall(eO);
            this.determineStatusTrigger(eO);
        }
    }

    removePurchaseQueue(pqId) {
        PurchaseQueueService.deletePurchaseQueue(pqId)
            .then((res) => {
                this.retrievePurchaseQueues(this.state.eOrder.id);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    savePurchaseQueue(obj) {
        const {currentUser} = this.context;
        let st = this.state,
            eOrder = st.eOrder,
            products = eOrder.products,
            nPurchaseQueues = [];

        if (obj) {
            obj.forEach((f) => {
                nPurchaseQueues.push({
                    orderId: eOrder.id,
                    requestedOn: new Date(),
                    requestedBy: {id: currentUser.id, name: currentUser.username},
                    quoteId: f.fileId,
                    statusChangedOn: new Date(),
                    statusChangedBy: {id: currentUser.id, name: currentUser.username},
                    status: "PENDING",
                    vendor: {name: f.vendor.label, id: f.vendor.value},
                });
            });
        } else {
            let pqArr = st.purchaseQueueArr;

            pqArr.map((pq) => {
                let currP = products.find((p) => {
                        return p.id === pq;
                    }),
                    currPst = currP.productSubType;

                return nPurchaseQueues.push({
                    itemId: currPst.itemId || null,
                    orderId: eOrder.id,
                    quantity: currP.qty,
                    requestedOn: new Date(),
                    productSubType: {id: currPst.id, name: currPst.name},
                    requestedBy: {id: currentUser.id, name: currentUser.username},
                    vendor: currPst.vendor
                        ? {id: currPst.vendor.id, name: currPst.vendor.name}
                        : null,
                    productId: currP.id,
                    statusChangedOn: new Date(),
                    statusChangedBy: {id: currentUser.id, name: currentUser.username},
                    status: "PENDING",
                });
            });
        }
        return PurchaseQueueService.createPurchaseQueues(nPurchaseQueues)
            .then((res) => {
                toast.success("Product(s) ordered!");
                if (!obj) {
                    res.map((item) => {
                        let pIndx = products.findIndex((p) => {
                            return p.id === item.productId;
                        });
                        products[pIndx].purchaseQueueId = item.id;
                        return item;
                    });
                    eOrder.products = products;
                }
                this.setState({
                    purchaseQueueArr: [],
                    pqModal: false,
                });
                this.retrievePurchaseQueues(eOrder.id);
                eOrder.equipmentOrderedDate = new Date().toLocaleDateString();
                return eOrder;
            })
            .catch((err) => {
                toast.error("Oh no! An error occurred while saving.");
                console.log(err);
                return eOrder;
            });
    }

    saveOrderServiceCall(order) {
        let t = this;
        return OrderService.updateOrder(order)
            .then((data) => {
                toast.success("Saved Successfully!");

                t.setState({
                    order: data,
                    eOrder: JSON.parse(JSON.stringify(data)),
                });
                return true;
            })
            .catch((err) => {
                toast.error("Oh no! An error occurred while saving.");
                return false;
            });
    }

    validateSave(order) {
        let errors = [];
        const regex = /(\W(?:\s))/g;

        if (!order.patient.firstName) {
            errors.push("Please fill out patient's first name");
        }
        if (!order.patient.lastName) {
            errors.push("Please fill out patient's last name");
        }

        if (regex.test(order.patient.firstName)) {
            errors.push("Remove punctuation and numbers from the first name");
        }

        if (regex.test(order.patient.lastName)) {
            errors.push("Remove punctuation and numbers from the last name");
        }

        if (order.products.length === 0) {
            errors.push("Order must have at least one product");
        }
        if (
            order.status === "ReadyToDeliver" &&
            (!order.patient.address ||
                !order.patient.city ||
                !order.patient.state ||
                !order.patient.zipCode)
        ) {
            errors.push(
                "Order must have an address for status to be set to Ready to Deliver"
            );
        }
        if (order.status === "Setup" && !order.setupDate) {
            errors.push("Must set a setup date to change status to Setup");
        }
        if (order.hasInfectiousDisease && !order.diseaseNotes) {
            errors.push(
                "Must fill out disease note if infections disease if checked"
            );
        }
        if (
            order.status === "InProcess" &&
            this.state.order.status === "New" &&
            order.account.name === "Intake - " + order.owner.name
        ) {
            errors.push("Account must be changed from intake account");
        }

        // if (!order.patient.latitude && !order.patient.longitude) {
        //     toast.warn('The address is not valid for this patient.');
        // }

        if (errors.length > 0) {
            errors.map((e) => {
                return toast.error(e);
            });
            return false;
        } else {
            return true;
        }
    }

    triggerSerialsPopup() {
        const {productTypes} = this.context;
        let prods = this.state.order.products;
        if (prods) {
            let serials = 0;
            prods.forEach((prod) => {
                if (
                    !prod.serialNumber &&
                    productTypes.find((typ) => typ.id === prod.productType.id)
                        .requiresSerialNumber
                ) {
                    serials++;
                }
            });
            if (serials > 0) {
                toast.warn(
                    "There are " +
                    serials +
                    " order(s) included that require a serial number."
                );
            }
        }
    }

    //endregion

    toggle = (tabset, tab) => {
        if (tabset === "1") {
            if (this.state.TabsOneActiveItem !== tab) {
                this.setState({
                    TabsOneActiveItem: tab,
                });
            }
        } else {
            if (this.state.TabsTwoActiveItem !== tab) {
                this.setState({
                    TabsTwoActiveItem: tab,
                });
            }
        }
    };

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC

        const nOrder = this.state.eOrder;
        if (property === "status") {
            nOrder.orderStatusReason = null;
        }

        nOrder[property] = event.target.value;

        this.setState({eOrder: nOrder});
    };

    getPointsFromInsurance(change, productAdded, fromInsurance) {
        const {productTypes, productOverrides} = this.context;

        if (productTypes.length === 0) {
            return productAdded;
        }
        let o = this.state.eOrder,
            insuranceID = o.insuranceType.id;

        let correctProduct = {},
            insuranceMatrix = [],
            points = -1;

        productAdded.forEach((p, index) => {
            let productAddedID = p.productType.id;

            for (let p of productTypes) {
                if (p.id === productAddedID) {
                    correctProduct = p;
                    break;
                }
            }

            if (p.points === -1 || fromInsurance) {
                //no points set or change is triggered from insurance change...BC

                if (p.overridePoints) {
                    let f = productOverrides.filter((pro) => {
                        return (
                            pro.insuranceTypeId === o.insuranceType.id &&
                            pro.productSubTypeId === p.id
                        );
                    });
                    if (f.length > 0) {
                        points = f[0].points;
                    }
                } else if (correctProduct) {
                    insuranceMatrix = correctProduct.insuranceMatrixEntries;
                    for (let i of insuranceMatrix) {
                        if (i.insuranceType.id === insuranceID) {
                            points = i.points;
                            break;
                        }
                    }
                }
                p.points = points;
            }
        });

        if (change === "add") {
            delete productAdded[0].points;
            return points;
        } else if (change === "edit") {
            return productAdded;
        }
    }

    addNewProduct = (productAdded) => {
        let o = this.state.eOrder,
            points = -1,
            np = {
                abnRequired: false,
                approved: false,
                hcpcs: productAdded.hcpcs || "",
                // points: ,
                productSubType: productAdded,
                productType: {
                    id: productAdded.productType.id,
                    name: productAdded.productType.name,
                },
                qty: 1,
                serial: "",
                amount: 0,
            };

        np.points = points;
        o.products.push(np);

        this.setState({
            eOrder: o,
        });

        setTimeout(() => {
            this.updateTableRows();
        }, 50);
    };

    toggleTimelineModalPopup() {
        let newState = !this.state.timelineModalOpen,
            order = this.state.order;

        let records = [];

        records.push({
            status: "New",
            reason: "",
            updatedOn: new Date(),
        });

        records.push({
            status: "In Process",
            reason: "Verification",
            updatedOn: new Date(),
        });

        records.push({
            status: "Ready To Deliver",
            reason: "Scheduled",
            updatedOn: new Date(),
        });

        //We don't want to call the endpoint if the modal is being closed...BC
        if (newState === false) {
            this.setState({
                timelineModalOpen: newState,
            });
        } else {
            OrderService.getOrderTimeline(order).then((records) => {
                this.setState({
                    timelineModalOpen: newState,
                    timelineModalOrder: order,
                    timelineModalEntries: records,
                });
            });
        }
    }

    renderLoadingSpinner() {
        return (
            <MDBContainer>
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </MDBContainer>
        );
    }

    closeHelpModal() {
        this.setState({
            helpModalOpen: false,
        });
    }

    setExistingEmpty() {
        this.setState({
            existingMeeting: null,
        });
    }

    renderCurrentStatus() {
        let eOrder = this.state.eOrder;
        if (eOrder.status) {
            if (eOrder.status === "ReadyToDeliver") {
                return "Ready To Deliver";
            } else {
                return this.formatStatus(eOrder.status);
            }
        }
        return "";
    }

    toggleApprovalModalPopup = () => {
        let currentState = this.state.approvalModalOpen;
        this.setState({approvalModalOpen: !currentState});
    };

    toggleApprovalErrorsModal = () => {
        this.setState({
            approvalErrorsModal: !this.state.approvalErrorsModal,
        });
    };

    toggleFollowupNoteModal = () => {
        this.setState({
            followupModalOpen: !this.state.followupModalOpen
        })
    }

    canApproveOrder = () => {
        let eOrder = this.state.eOrder,
            patient = eOrder.patient,
            errors = [];

        const regex = /((|\()\d{3}(|\))-\d{3}-\d{4})/g;

        let approved = eOrder.products.some((prod) => {
            return prod.approved === true;
        });

        if (approved === false) {
            errors.push("At least one product needs to be approved");
        }

        if (!patient.dateOfBirth) {
            errors.push("Add Date of Birth");
        }
        if (!patient.phone) {
            errors.push("Add Phone Number");
        }
        if (!regex.test(patient.phone)) {
            errors.push("Phone number is invalid.  Try re-entering phone Number");
        }
        if (!patient.address) {
            errors.push("Add Address");
        }
        if (!patient.city) {
            errors.push("Add City");
        }
        if (!patient.state) {
            errors.push("Add State");
        }
        if (!patient.zipCode) {
            errors.push("Add Zip Code");
        }
        if (eOrder.status === "ReadyToDeliver") {
            errors.push("Order Cannot be in Ready to Deliver");
        }
        if (eOrder.status === "Setup") {
            errors.push("Order Cannot be in Setup");
        }
        if (eOrder.status === "Cancelled") {
            errors.push("Order Cannot be Cancelled");
        }
        if (!eOrder.insuranceSubType) {
            errors.push("Please add an Insurance type");
        }

        if (errors.length > 0) {
            this.setState({
                approvalErrors: errors,
            });
            this.toggleApprovalErrorsModal();
        } else {
            this.toggleApprovalModalPopup();
        }
    };

    async calculateSalesTax(eOrder) {
        if (
            eOrder.insuranceType &&
            (eOrder.insuranceType.name === "Cash" ||
                eOrder.insuranceType.name === "Dealer")
        ) {
            const {stateSalesTax} = this.context;
            if (!eOrder.patient.state) {
                return 0;
            }
            let cs = eOrder.patient.state.toLowerCase();

            let tr = stateSalesTax.find((v) => {
                return v.abbr.toLowerCase() === cs || v.name.toLowerCase() === cs;
            });

            if (tr) {
                let t = 0;
                eOrder.products.forEach((p) => {
                    let c = parseFloat(p.amount);
                    if (p.approved === true && c > 0) {
                        t += c;
                    }
                });
                return ((t / 100) * tr.salesTax).toFixed(2);
            }
        }
        return 0;
    }

    async generateApprovalNote() {
        const {currentUser, companyName} = this.context;

        let st = this.state,
            noteText = "",
            list = st.eOrder.products.filter((p) => {
                return p.approved;
            }),
            cb = {id: currentUser.id, name: currentUser.username},
            currentNotes = st.notes,
            eOrder = st.eOrder,
            totalPoints = 0;

        noteText =
            currentUser.username +
            " approved order " +
            this.state.order.id +
            " on " +
            moment().format("MMMM Do YYYY, h:mm a") +
            " for the following products : ";

        list.forEach((product) => {
            totalPoints += parseFloat(product.points);
            noteText +=
                product.qty +
                " " +
                (product.productType ? product.productType.name : "") +
                " " +
                (product.productSubType ? product.productSubType.name : "") +
                " | ";
        });

        let note = {
            type: "ActivityNote",
            text: noteText,
            createdAt: new Date(),
            account: eOrder.account,
            task: false,
            completed: true,
            createdBy: cb,
        };

        NoteService.createActivityNote(eOrder.id, note)
            .then((res) => {
                currentNotes.unshift(note);
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
            });

        if (eOrder.approvalDate === null) {
            eOrder.approvalDate = new Date();

            if (companyName === 'rehabmedical' || companyName === 'uat') {
                this.syncToBrightTree(eOrder.id);
            }
        }

        eOrder.status = "ReadyToDeliver";
        eOrder.orderStatusReason = null;
        eOrder.points = totalPoints;

        this.saveOrder(eOrder);
        this.toggleApprovalModalPopup();
    }

    renderApprovalErrorsModal() {
        let errors = this.state.approvalErrors;
        return (
            <Modal
                isOpen={this.state.approvalErrorsModal}
                toggle={this.toggleApprovalErrorsModal}
            >
                <ModalHeader toggle={this.toggleApprovalErrorsModal}>
                    Approval Errors
                </ModalHeader>
                <ModalBody>
                    <MDBListGroup className="text-left">
                        {errors.map((e, index) => {
                            return (
                                <MDBListGroupItem
                                    key={"errorList" + index}
                                    className="red-gradient white-text"
                                >
                                    {e}
                                </MDBListGroupItem>
                            );
                        })}
                    </MDBListGroup>
                </ModalBody>
            </Modal>
        );
    }

    renderApprovalModal() {
        let st = this.state,
            eOrder = st.eOrder;
        return (
            <Modal
                centered
                isOpen={st.approvalModalOpen}
                toggle={() => this.toggleApprovalModalPopup()}
            >
                <ModalBody>
                    <CardHeader
                        style={{marginBottom: "1rem"}}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>
                            Approve Order :{" "}
                            {eOrder.patient.firstName + " " + eOrder.patient.lastName}
                        </h3>
                    </CardHeader>
                    <Table
                        responsive
                        fixed
                        scrollY
                        striped
                        bordered
                        autoWidth
                        maxHeight="400px"
                    >
                        <TableHead>
                            <tr>
                                <th>Category</th>
                                <th>Product</th>
                                <th>Quantity</th>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {eOrder.products.map((product, index) => {
                                if (product.approved) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {product.productType ? product.productType.name : ""}
                                            </td>
                                            <td>
                                                {product.productSubType
                                                    ? product.productSubType.name
                                                    : ""}
                                            </td>
                                            <td>{product.qty}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </TableBody>
                    </Table>
                    <div align="left">
                        <Input
                            key={eOrder.id}
                            onChange={this.checkBoxApprovedChange.bind(this)}
                            value={this.state.approveAgreement}
                            label="I hereby approve the order for the above products."
                            type="checkbox"
                            id="approveOrder"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <MDBBtn
                        color="secondary"
                        onClick={() => this.toggleApprovalModalPopup()}
                    >
                        Close
                    </MDBBtn>
                    <MDBBtn
                        disabled={!this.state.approveAgreement}
                        color="primary"
                        onClick={() => this.generateApprovalNote()}
                    >
                        Approve
                    </MDBBtn>
                </ModalFooter>
            </Modal>
        );
    }

    timeSelectPlaceholder(date) {
        if (date) {
            return new Date(date).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
            });
        } else {
            return "Select a Time";
        }
    }

    renderTimeOptions() {
        let h = [],
            ampm = [],
            hour = 0,
            times = [],
            minutes = ["00", "15", "30", "45"];
        for (let i = 6; i < 22; i++) {
            hour = i % 12 === 0 ? 12 : i % 12;
            h.push(hour.toString());
            i % 24 < 12 ? ampm.push("AM") : ampm.push("PM");
        }
        h.map((nh, k) => {
            return minutes.map((m) => {
                return times.push({
                    label: nh + ":" + m + " " + ampm[k],
                    value: [nh, m],
                });
            });
        });
        return times;
    }

    handleOpenOrderTimeChange(e) {
        let eo = this.state.eOrder;

        if (eo.scheduledDeliveryDate) {
            let d = new Date(eo.scheduledDeliveryDate);

            d.setHours(e.value[0]);
            d.setMinutes(e.value[1]);

            eo.scheduledDeliveryDate = d;
        }

        this.setState({
            eOrder: eo,
            openOrderTime: e.value,
        });
    }

    //endregion

    //region clinic notes
    addNewClinicNote() {
        //get the current user context that can be passed into the note DTO...BC
        const {currentUser} = this.context;

        let cb = {id: currentUser.id, name: currentUser.username},
            currentNotes = this.state.clinicNotes,
            therapist = this.state.eOrder.therapist;

        let note = {
            createdAt: new Date(),
            createdBy: cb,
            assignedTo: therapist,
            completed: this.state.completed,
            orderId: this.state.order.id,
            patientName: this.state.order.patientName,
        };

        let nText = document.getElementById("newClinicNoteText").value;
        note.text = nText;

        return NoteService.createTherapistNote(note)
            .then((res) => {
                this.toggleNoteModalPopup();
                currentNotes.unshift(note);
                this.setState({clinicNotes: currentNotes});
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
            });
    }

    updateClinicNote(note) {
        let clinicNotes = this.state.clinicNotes;
        note.completed = true;
        return NoteService.updateTherapistNote(note)
            .then((res) => {
                this.setState({clinicNotes: clinicNotes});
                //success, don't need to do anything...BC
            })
            .catch((err) => {
                //maybe throw an error if it fails...BC
            });
    }

    renderSeatingClinicTabLink() {
        if (this.state.eOrder.seatingClinicLocationRef != null) {
            return (
                <MDBNavItem>
                    <MDBNavLink
                        to="#"
                        role="tab"
                        className={classnames({
                            active: this.state.TabsOneActiveItem === "7",
                        })}
                        onClick={() => {
                            this.toggle("1", "7");
                        }}
                    >
                        Seating Clinic Notes
                    </MDBNavLink>
                </MDBNavItem>
            );
        }
        return <div/>;
    }

    renderSeatingClinicTabPane(theme) {
        if (this.state.eOrder.seatingClinicLocationRef != null) {
            return (
                <MDBTabPane
                    tabId="7"
                    role="tabpanel"
                    style={{backgroundColor: theme.cardBackground}}
                >
                    {this.renderSeatingClinicNotesTab(theme)}
                </MDBTabPane>
            );
        }
        return <div></div>;
    }

    renderSeatingClinicNotesTab(theme) {
        let notes = this.state.clinicNotes;

        return (
            <MDBRow>
                <MDBCol size="12">
                    {this.renderClinicNoteModal(theme)}

                    <div className={"pvNotesGroupOuterContainer"}>
                        <div className={"pvNotesGroupInnerContainer"}>
                            {notes.map((note, idx) => {
                                return this.generateClinicNote(note, idx, theme);
                            })}
                        </div>
                    </div>
                    <hr/>
                </MDBCol>
                <MDBCol style={{textAlign: "right"}}>
                    <MDBBtn
                        color={"teal darken-2"}
                        disabled={!this.state.eOrder.therapist}
                        rounded
                        size="md"
                        onClick={() => this.toggleNoteModalPopup()}
                    >
                        <MDBIcon icon="plus"/>
                        &nbsp;&nbsp; Add Clinic Note
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        );
    }

    generateClinicNote(note, index) {
        let t = this;

        function renderCompleteButton(note) {
            if (note.completed === false) {
                return (
                    <Button
                        floating
                        className={"pvButtonFloat"}
                        data-tip={"Complete Note"}
                        color={"red darken-2"}
                        onClick={() => t.updateClinicNote(note)}
                        size="sm"
                    >
                        <MDBIcon icon={"check-square"}/>
                    </Button>
                );
            }
        }

        return (
            <div className={"pvNoteContainer"} key={index}>
                <Card>
                    <CardHeader color={"teal darken-2"}>
                        {note.createdBy.name} &nbsp;&nbsp;&nbsp;
                        <span style={{float: "right"}}>
							{t.formatDate(note.createdAt)}
						</span>
                    </CardHeader>
                    <CardBody className={"pvNoteText"}>
                        {note.text}
                        <br/>
                        {renderCompleteButton(note)}
                    </CardBody>
                </Card>
                <br/>
            </div>
        );
    }

    renderClinicNoteModal() {
        return (
            <MDBContainer>
                <MDBRow>
                    <Modal
                        centered
                        className="patientOrderView form-elegant"
                        isOpen={this.state.noteModalOpen}
                        toggle={() => this.toggleNoteModalPopup()}
                    >
                        <ModalBody style={{backgroundColor: "#FFFFFF"}} className="mx-3">
                            <CardHeader className="blue-grey form-header text-center">
                                Add New Note
                            </CardHeader>
                            <form className=" mx-3 grey-text">
								<textarea
                                    placeholder={"Add note text here"}
                                    id={"newClinicNoteText"}
                                    style={{width: "100%", height: "200px"}}
                                ></textarea>
                                <br/>
                                <Row>
                                    <Col size="6">
                                        <Input
                                            type="radio"
                                            gap
                                            label={"Note"}
                                            checked={this.state.taskCompleted}
                                            onClick={this.taskCompletedChange.bind(this, true)}
                                            id={"clinicNoteRadio"}
                                        />
                                    </Col>

                                    <Col size="6">
                                        <Input
                                            type="radio"
                                            gap
                                            label={"Task"}
                                            checked={!this.state.taskCompleted}
                                            onClick={this.taskCompletedChange.bind(this, false)}
                                            id={"clinicTaskRadio"}
                                        />
                                    </Col>
                                </Row>

                                <br/>
                                <Button
                                    disabled={!this.state.eOrder.therapist}
                                    onClick={this.addNewClinicNote.bind(this)}
                                >
                                    Add Clinic Note
                                </Button>
                            </form>
                        </ModalBody>
                    </Modal>
                </MDBRow>
            </MDBContainer>
        );
    }

    //endregion

    noteChange = (property, event) => {
        this.setState({[property]: event.target.value});
    };

    getCategoryList() {
        const {productTypes} = this.context;
        let a = [];
        a.push({
            label: "Category...",
            value: "",
        });
        productTypes.map((productType) => {
            return a.push({
                label: productType.name,
                value: productType,
            });
        });
        return a;
    }

    getProductList() {
        // let subTypes = this.state.productSubTypes;
        const {productSubTypes} = this.context;
        let a = [];
        let cc = this.state.chosenCategory;
        if (Object.keys(cc).length !== 0) {
            productSubTypes.map((product) => {
                if (product.productType.name === cc.name) {
                    let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                    let l = product.name + h;
                    return a.push({
                        label: l,
                        value: product,
                    });
                }
                return null;
            });
        } else {
            productSubTypes.map((product) => {
                let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
                let l = product.productType.name + " | " + product.name + h;
                return a.push({
                    label: l,
                    value: product,
                });
            });
        }
        return a;
    }

    handleCategoryChange = (e) => {
        this.setState({
            chosenCategory: e.value,
        });
    };

    renderCategoryDropdown() {
        let statusSetup = this.state.eOrder.status === "Setup";
        return (
            <div>
                <Select
                    isDisabled={statusSetup}
                    placeholder={"Category..."}
                    options={this.getCategoryList()}
                    onChange={this.handleCategoryChange.bind(this)}
                />
            </div>
        );
    }

    handleProductSelectChange = (e) => {
        this.addNewProduct(e.value);
    };

    renderProductDropdown() {
        let statusSetup = this.state.eOrder.status === "Setup";
        return (
            <div>
                <Select
                    isDisabled={statusSetup}
                    placeholder={"Product..."}
                    options={this.getProductList()}
                    onChange={this.handleProductSelectChange.bind(this)}
                />
            </div>
        );
    }

    renderEquipmentCard(theme) {
        return (
            <div>
                <MDBRow>
                    <MDBCol size="12">
                        {this.renderTableToolbar()}
                        {this.renderProductTable()}
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }

    viewABNClicked = () => {
        let o = this.state.eOrder;
        if (o.abnProductOther) {
            if (o.approvals.length === 0) {
                o.approvals.push({
                    abnRequired: true,
                    hasPrescription: false,
                    abnRequiredNote: "",
                    authNumber: null,
                    coInsurance: null,
                    deductible: null,
                    deliverByDate: null,
                    fieldNotes: null,
                    financialHardship: null,
                    hcpcs: null,
                    inFacility: null,
                    patientResponsibility: null,
                    paymentOnDelivery: null,
                    rental: null,
                    sameOrSimilar: null,
                });
                this.setState({
                    eOrder: o,
                });
            }
            this.abnToggle();
        } else {
            toast.error("No products require ABN");
        }
    };

    abnToggle = () => {
        this.setState({
            abnModal: !this.state.abnModal,
        });
    };

    renderAbnModal() {
        let o = this.state.eOrder;

        return (
            <Modal isOpen={this.state.abnModal} toggle={() => this.abnToggle()}>
                <ModalHeader style={{backgroundColor: "white"}}>ABN</ModalHeader>
                <ModalBody style={{backgroundColor: "white"}}>
                    <div>
                        <MDBListGroup style={{borderRadius: "5px"}}>
                            {this.renderABNProductList()}
                        </MDBListGroup>
                        <MDBInput
                            label="ABN Amount"
                            outline
                            value={o.abnAmount === null ? "" : o.abnAmount.toString()}
                            onChange={this.handleABNModalChange.bind(this, "abnAmount")}
                        />
                        <label className="grey-text">ABN Required Reason</label>
                        <textarea
                            id="abnreason"
                            className="form-control"
                            rows="3"
                            value={o.approvals[0].abnRequiredNote || ""}
                            onChange={this.handleABNModalChange.bind(this, "abnRequiredNote")}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    // ADD ABN Required reason *******************

    handleABNModalChange(property, e) {
        let o = this.state.eOrder;
        if (property === "abnAmount") {
            o[property] = e.target.value;
        } else {
            o.approvals[0][property] = e.target.value;
        }
        this.setState({
            eOrder: o,
        });
    }

    renderABNProductList() {
        if (this.state.abnModal) {
            let o = this.state.eOrder;
            let arr = o.abnProductOther.split(",");
            return arr.map((p) => {
                return (
                    <MDBListGroupItem className="winter-neva-gradient">
                        {p}
                    </MDBListGroupItem>
                );
            });
        }
    }

    updateTableRows(fromInsurance) {
        let item = this.state.eOrder,
            arr = [],
            d = {},
            products = item.products.sort((a, b) => {
                return b.points - a.points;
            });

        if (item.insuranceType) {
            //only get points from insurance if we have an insurance type...BC
            products = this.getPointsFromInsurance("edit", products, fromInsurance);
        }

        let pc = this.state.primaryColumns.slice(0),
            sc = this.state.secondaryColumns.slice(0),
            inst = item.insuranceType ? item.insuranceType.name : "None";

        if (inst === "Cash" || inst === "Dealer") {
            pc.push({
                label: "Cost",
                field: "cost",
            });
        }

        if (item.orderType === "Service") {
            pc.push({
                label: "Warranty",
                field: "warranty",
            });
        }

        pc = pc.concat(sc);

        products.map((product, index) => {
            let o = {
                category: this.renderFlatRow(product.productType.name, index),
                productSub: this.renderFlatRow(
                    product.productSubType === null
                        ? "no product"
                        : product.productSubType.name,
                    index
                ),
                hcpcs: this.renderFlatRow(
                    product.hcpcs || product.productSubType
                        ? product.productSubType.hcpcs
                        : "",
                    index
                ),
                points: this.renderRow("points", product.points, index, product),
                qty: this.renderRow("qty", product.qty, index, product),
                serial: this.renderRow("serial", product.serial, index, product),
            };

            let oTwo = {
                approved: this.renderRow("approved", product.approved, index, product),
                abnRequired: this.renderRow(
                    "abnRequired",
                    product.abnRequired,
                    index,
                    product
                ),
                remove: this.renderRemove(product, index),
            };

            if (inst === "Cash" || inst === "Dealer") {
                o.amount = this.renderRow("amount", product.amount, index, product);
            }

            if (item.orderType === "Service") {
                o.warranty = this.renderRow(
                    "warranty",
                    product.warranty,
                    index,
                    product
                );
            }

            let oThree = {...o, ...oTwo};

            return arr.push(oThree);
        });

        d.rows = arr;
        d.columns = pc;
        this.setState({
            data: d,
        });
    }

    renderRemove(product, index) {
        return (
            <div>
                <MDBIcon
                    icon={"trash"}
                    size="2x"
                    style={{color: "red", marginLeft: "1em"}}
                    data-tip={"Remove this product"}
                    onClick={() => this.handleProductRemove(product, index)}
                />
                <ReactTooltip/>
            </div>
        );
    }

    renderTableToolbar() {
        let eOrder = this.state.eOrder;

        function renderOrderedDate(eOrder) {
            if (eOrder.equipmentOrderedDate) {
                return (
                    <p style={{textAlign: "left"}}>
                        Ordered Date: {eOrder.equipmentOrderedDate}
                    </p>
                );
            }
        }

        return (
            <MDBCardHeader style={{paddingBottom: 0, backgroundColor: "#5c6bc0"}}>
                <MDBRow>
                    <MDBCol size="3">{this.renderCategoryDropdown()}</MDBCol>
                    <MDBCol size="3">{this.renderProductDropdown()}</MDBCol>

                    <MDBCol
                        size="3"
                        style={{textAlign: "center", margin: "auto", color: "white"}}
                    >
                        <p>Total Approved Pts: {eOrder.points}</p>
                    </MDBCol>

                    <MDBCol
                        size="3"
                        style={{textAlign: "center", margin: "auto", color: "white"}}
                    >
                        {renderOrderedDate(eOrder)}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
        );
    }

    renderFlatRow(val, idx) {
        return (
            <div id={val} key={val} style={{paddingTop: 1, paddingBottom: 1}}>
                <span>{val}</span>
            </div>
        );
    }

    renderRow(property, productAttribute, index, product) {
        let statusSetup = this.state.eOrder.status === "Setup";

        if (property === "points") {
            let cu = window.sessionStorage.getItem("currentUser");
            if (cu !== null || cu !== undefined) {
                cu = JSON.parse(cu);
            }
            let p = productAttribute.toString();
            return (
                <MDBInput
                    disabled={cu.role !== "SYSTEM_ADMIN" && cu.canEditPoints !== true}
                    style={{textAlign: "center", width: 80, margin: 0}}
                    containerClass={"tableInputContainer"}
                    outline
                    step={0.1}
                    type={"number"}
                    value={p === "" ? "" : p}
                    onChange={this.handleProductChange.bind(
                        this,
                        "points",
                        index,
                        product
                    )}
                />
            );
        } else if (property === "qty") {
            let q = productAttribute;
            return (
                <MDBInput
                    style={{textAlign: "center", width: 80, margin: 0}}
                    type={"number"}
                    containerClass={"tableInputContainer"}
                    outline
                    disabled={statusSetup}
                    value={q === null ? "1" : q.toString()}
                    onChange={this.handleProductChange.bind(this, "qty", index, product)}
                />
            );
        } else if (property === "serial") {
            return (
                <MDBInput
                    style={{textAlign: "center", width: 160, margin: 0}}
                    outline
                    containerClass={"tableInputContainer"}
                    valueDefault={productAttribute || ""}
                    disabled={statusSetup}
                    onBlur={this.handleProductChange.bind(this, "serial", index, product)}
                />
            );
        } else if (property === "approved") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxAPR" + index}
                    filled
                    disabled={statusSetup}
                    containerClass={"checkbox-approved-filled"}
                    label="approve"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "approved",
                        index,
                        product,
                        a
                    )}
                />
            );
        } else if (property === "abnRequired") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxABN" + index}
                    disabled={statusSetup}
                    filled
                    label="abn"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "abnRequired",
                        index,
                        product,
                        a
                    )}
                />
            );
        } else if (property === "amount") {
            let c = productAttribute.toString();
            return (
                <MDBInput
                    style={{textAlign: "center", width: 100, margin: 0}}
                    outline
                    containerClass={"tableInputContainer"}
                    type={"number"}
                    disabled={statusSetup}
                    valueDefault={c === "" ? "" : c}
                    onBlur={this.handleProductChange.bind(this, "amount", index, product)}
                />
            );
        } else if (property === "warranty") {
            let a = productAttribute || false;
            return (
                <Input
                    id={"checkboxWARR" + index}
                    containerClass={"checkbox-warranty-filled"}
                    disabled={statusSetup}
                    filled
                    label="warranty"
                    type="checkbox"
                    checked={a}
                    onChange={this.handleCheckBoxChange.bind(
                        this,
                        "warranty",
                        index,
                        product,
                        a
                    )}
                />
            );
        }
    }

    handleProductRemove(product, index) {
        let o = this.state.eOrder,
            status = o.status;

        if (status === "Setup") {
            return;
        }

        if (o.products.length <= 1) {
            toast.error("Cannot remove if there is only one product");
        } else {
            let d = this.state.data;

            let op = o.products.findIndex((prod) => {
                return prod.id === product.id;
            });

            d.rows.splice(index, 1);

            if (op > -1) {
                o.products.splice(index, 1);
            }

            this.setState({
                eOrder: o,
                data: d,
            });

            if (o.abnProductOther) {
                this.removeABN(product);
            }

            this.updateTableRows();
        }
    }

    handleProductChange = (property, index, product, e) => {
        let o = JSON.parse(JSON.stringify(this.state.eOrder)),
            d = this.state.data;

        let op = o.products.find((prod) => {
            if (product.id) {
                return prod.id === product.id;
            } else {
                return prod.productSubType.id === product.productSubType.id;
            }
        });

        if (op) {
            op[property] = e.target.value;
            d.rows[index][property] = this.renderRow(
                property,
                e.target.value,
                index,
                op
            );
        }

        this.setState({
            data: d,
            eOrder: o,
        });
    };

    handleCheckBoxChange = (property, index, product, value, e) => {
        let o = JSON.parse(JSON.stringify(this.state.eOrder)),
            d = this.state.data;

        let op = o.products.find((prod) => {
            if (product.id) {
                return prod.id === product.id;
            } else {
                return prod.productSubType.id === product.productSubType.id;
            }
        });

        if (op) {
            op[property] = !value;
            d.rows[index][property] = this.renderRow(property, !value, index, op);
        }
        this.setState({
            data: d,
            eOrder: o,
        });
        if (property === "abnRequired") {
            this.addABNProduct(index, o);
        }
    };

    addABNProduct(index, o) {
        let d = this.state.data,
            r = d.rows[index];

        let abn = o.abnProductOther,
            abnArr = abn ? abn.split(",") : "";

        let cat = r.category.key.replace(/,/g, ""),
            sub = r.productSub.key.replace(/,/g, "");

        let exIdx = abnArr.indexOf(cat + " " + sub);
        // in try/catch since abnProductOther can be null from the order obj...JK
        try {
            // if len is zero then you can just added product to property
            if (abn.length === 0) {
                o.abnProductOther = cat + " " + sub;
                // if the product is present in abnProductOther property already then remove it
                // since that means they unchecked the ABN checkbox...JK
            } else if (exIdx > -1) {
                abnArr.splice(exIdx, 1);
                o.abnProductOther = abnArr.toString();
                // product isn't present in abnProductOther yet so it's safe to add
                // and that means the user checked the ABN checkbox...JK
            } else {
                abnArr.push(cat + " " + sub);
                o.abnProductOther = abnArr.toString();
            }
            // abnProductOther was null so just add the product to the property...JK
        } catch (e) {
            o.abnProductOther = cat + " " + sub;
        }
        this.setState({
            eOrder: o,
        });
    }

    removeABN(product) {
        let o = this.state.eOrder;
        let abnArr = o.abnProductOther.split(",");

        if (
            abnArr.includes(
                product.productType.name + " " + product.productSubType.name
            )
        ) {
            let i = abnArr.indexOf(
                product.productType.name + " " + product.productSubType.name
            );
            abnArr.splice(i, 1);
            o.abnProductOther = abnArr.toString();
            this.setState({
                eOrder: o,
            });
        }
    }

    approveAllProducts() {
        let eOrder = this.state.eOrder,
            d = this.state.data,
            a = this.state.allApproved;

        eOrder.products.forEach((prod, idx) => {
            prod.approved = !a;
            d.rows[idx]["approved"] = this.renderRow("approved", !a, idx, prod);
        });

        this.setState({
            eOrder: eOrder,
            data: d,
            allApproved: !a,
        });
    }

    renderProductTable() {
        let d = this.state.data;
        return (
            <MDBTable
                small
                hover
                className="rounded mb-0 table-box-shadow smallTable"
            >
                <TableHead color="indigo" textWhite>
                    <tr>
                        {d.columns.map((col, idx) => {
                            if (col.label === "Approved") {
                                return (
                                    <th
                                        key={idx}
                                        style={{padding: 8, cursor: "pointer"}}
                                        onClick={this.approveAllProducts.bind(this)}
                                        data-tip={"Approve All"}
                                    >
                                        Approved
                                    </th>
                                );
                            }

                            if (col.label === "ABN Required") {
                                return (
                                    <th
                                        key={idx}
                                        style={{padding: 8, cursor: "pointer"}}
                                        data-tip={"View ABN Information"}
                                        onClick={this.viewABNClicked}
                                    >
                                        ABN
                                    </th>
                                );
                            }

                            return (
                                <th style={{padding: 8}} key={idx}>
                                    {col.label}
                                </th>
                            );
                        })}
                    </tr>
                </TableHead>
                <TableBody rows={d.rows}/>
            </MDBTable>
        );
    }

    updateParentObject(obj) {
        this.setState({
            eOrder: obj,
        });
    }

    updateOrderNotes(note) {
        let notes = this.state.notes;

        notes.unshift(note);
        this.setState({
            notes: notes,
        });
    }

    renderPQModal() {
        let st = this.state;
        if (!st.pqModal) {
            return;
        }
        return (
            <MDBModal
                size="lg"
                isOpen={st.pqModal}
                toggle={() => {
                    return;
                }}
            >
                <MDBModalHeader>Quotes</MDBModalHeader>
                <ModalBody
                    style={{backgroundColor: "#FFFFFF", padding: 2, margin: 2}}
                    className="mx-3"
                >
                    <PqQuoteSelector
                        files={st.eOrder.files}
                        currQueue={st.purchaseQueues}
                        closeModal={() => this.setState({pqModal: false})}
                        quoteChosen={(obj) => {
                            this.savePurchaseQueue(obj);
                        }}
                    />
                </ModalBody>
            </MDBModal>
        );
    }

    generateTimelineEntry() {
        if (this.state.timelineModalEntries == null) {
            return;
        }
        return this.state.timelineModalEntries.map((att, idx) => {
            let v = true;
            if (idx === 0 || idx % 2 === 0) {
                v = false;
            }
            return (
                <TimelineStep
                    color="red-gradient darken-4"
                    href="#void"
                    inverted={v}
                    key={idx}
                    icon="calendar-check-o"
                >
					<span style={{fontSize: "0.88rem"}}>
						{this.formatDate(att.updatedOn)}
                        <br/>
                        {att.updatedBy}
					</span>
                    <br/>
                    <label style={{fontWeight: 400}}>
                        {this.formatTimelineStatus(att)}
                    </label>
                    <hr/>
                    <span>{att.reason}</span>
                </TimelineStep>
            );
        });
    }

    createFollowupNote() {
        const {currentUser} = this.context;

        let cb = {id: currentUser.id, name: currentUser.username};

        let note = {
            task: false,
            completed: true,
            assignedTo: this.state.eOrder.owner,
            createdAt: new Date(),
            createdBy: cb,
            account: this.state.eOrder.account,
            patientName: this.state.eOrder.patientName,
        };

        let nText = document.getElementById("newFollowupNoteText").value;
        note.text = nText;

        return NoteService.createActivityNote(this.state.eOrder.id, note)
            .then((res) => {
                //need to set the name since the res doesnt currently have it...BC
                res.createdBy.name = note.createdBy.name;

                if (note.assignedTo) {
                    res.assignedTo.name = note.assignedTo.name;
                }

                //need to set the last note date because otherwise the save overrides it...BC
                let ord = this.state.eOrder;
                ord.lastNoteDate = new Date();

                this.addNoteUpdateOrderCloseModal(res, ord);

                let followup = {
                    name: this.state.eOrder.patientName,
                    orderActivityId: this.state.eOrder.id,
                    followUpOn: new Date(),
                    followUpBy: cb,
                    followUpNoteId: res.id
                };

                NoteService.createOrderFollowup(followup)
                    .then(result => {
                        //console.log(result);
                        let o = this.state.eOrder;
                        let dt = new Date(o.lastNoteDate);
                        o.followUpDate = dt.setDate(dt.getDate() + 5);
                        this.setState({
                            followupModalOpen: false,
                            eOrder: o
                        })

                        this.saveOrder(o);
                    }).catch(errRes => {
                    console.log(errRes);
                });
            })
            .catch((err) => {
                //Maybe send an error to the user?...BC
            });
    }

    renderOrderTimeline() {
        return (
            <Timeline className="TimelineStep">
                {this.generateTimelineEntry()}
            </Timeline>
        );
    }

    renderTimelineModal() {
        return (
            <div>
                <Modal
                    className={"extraWidth"}
                    isOpen={this.state.timelineModalOpen}
                    toggle={() => this.toggleTimelineModalPopup()}
                    fullHeight
                    position="left"
                >
                    <ModalHeader>
                        <h5>
                            {this.formatStatus(this.state.eOrder.status)} -- Updated:{" "}
                            {this.formatDate(this.state.order.lastUpdatedOn || new Date())}
                        </h5>
                    </ModalHeader>
                    <ModalBody
                        className={"stepContainer"}
                        style={{backgroundColor: "transparent"}}
                    >
                        {this.renderOrderTimeline()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderFollowupNoteModal() {
        return (
            <div>
                <MDBModal
                    size={"lg"}
                    isOpen={this.state.followupModalOpen}
                    toggle={() => this.toggleFollowupNoteModal()}>
                    <MDBModalHeader>Add Followup Note</MDBModalHeader>
                    <MDBModalBody>
						<textarea
                            placeholder={"Add note text here."}
                            autoFocus
                            id={"newFollowupNoteText"}
                            style={{width: "100%", height: "200px"}}
                        ></textarea>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn
                            size="sm"
                            color="red"
                            data-tip="Close"
                            floating
                            onClick={() => this.toggleFollowupNoteModal()}
                        >
                            <MDBIcon icon="times"/>
                        </MDBBtn>
                        <MDBBtn
                            size="sm"
                            data-tip="Add Followup Note"
                            floating
                            color="green"
                            onClick={() => this.createFollowupNote()}
                        >
                            <MDBIcon icon="check"/>
                        </MDBBtn>
                    </MDBModalFooter>
                    <ReactTooltip/>
                </MDBModal>
            </div>
        )
    }

    renderApprovalTab() {
        if (this.state.eOrder.approvalDate) {
            return (
                <MDBNavItem>
                    <MDBNavLink
                        link
                        to="#"
                        role="tab"
                        active={this.state.TabsOneActiveItem === "4"}
                        onClick={() => {
                            this.toggle("1", "4");
                        }}
                    >
                        Approval
                    </MDBNavLink>
                </MDBNavItem>
            );
        }
    }

    renderApprovalTabPane() {
        let eOrder = this.state.eOrder;

        if (eOrder.approvalDate) {
            return (
                <MDBTabPane tabId="4" role="tabpanel">
                    <ApprovalTab order={this.state.order}/>
                </MDBTabPane>
            );
        }
    }

    renderTabSection() {
        return (
            <Row>
                <Col size="12">
                    <MDBNav tabs className="nav-justified" color={"blue"}>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "1"}
                                onClick={() => {
                                    this.toggle("1", "1");
                                }}
                            >
                                Patient Information
                            </MDBNavLink>
                        </MDBNavItem>

                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "3"}
                                onClick={() => {
                                    this.toggle("1", "3");
                                }}
                            >
                                Equipment
                            </MDBNavLink>
                        </MDBNavItem>

                        {this.renderApprovalTab()}

                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "5"}
                                onClick={() => {
                                    this.toggle("1", "5");
                                }}
                            >
                                Notes
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                role="tab"
                                active={this.state.TabsOneActiveItem === "6"}
                                onClick={() => {
                                    this.toggle("1", "6");
                                }}
                            >
                                Attachments
                            </MDBNavLink>
                        </MDBNavItem>
                        {this.renderSeatingClinicTabLink()}
                    </MDBNav>
                    <MDBTabContent
                        className="card"
                        activeItem={this.state.TabsOneActiveItem}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            <PatientInformationTab
                                eOrder={this.state.eOrder}
                                updateTableRows={this.updateTableRows.bind(this)}
                                updateParentObject={this.updateParentObject.bind(this)}
                                saveParentObject={this.saveOrder.bind(this)}
                            />
                        </MDBTabPane>

                        <MDBTabPane tabId="3" role="tabpanel">
                            {this.renderEquipmentCard()}
                        </MDBTabPane>
                        {this.renderApprovalTabPane()}
                        <MDBTabPane tabId="5" role="tabpanel">
                            <NotesTab
                                allNotes={this.state.notes}
                                eOrder={this.state.eOrder}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="6" role="tabpanel">
                            <AttachmentsTab
                                eOrder={this.state.eOrder}
                                updateParent={this.updateParentObject.bind(this)}
                            />
                        </MDBTabPane>
                        {/*{this.renderSeatingClinicTabPane(theme)}*/}
                    </MDBTabContent>
                </Col>
            </Row>
        );
    }

    renderDMWindow = () => {
        window.open(
            this.state.order.id.toString() + "/deliveryManifest",
            "_blank",
            "toolbar=yes,scrollbars=yes,resizable=yes,top=700,left=700,width=1000,height=800"
        );
    };

    renderViewToolbar() {
        const {currentUser} = this.context;

        let eOrder = this.state.eOrder,
            patient = eOrder.patient,
            readyToPurchase = !!eOrder.approvalDate,
            equipOrdered = this.state.purchaseQueues.length > 0,
            dataTip = readyToPurchase
                ? "Purchase Equipment".concat(
                    equipOrdered ? " / View Equipment Status" : ""
                )
                : "Order must be approved first";

        let ur = currentUser.role,
            requestDisabled = true,
            canFollowUp = false;

        if (
            ur.indexOf("SALES") > -1 ||
            ur.indexOf("ADMIN") > -1 ||
            ur === "PATIENT_CARE_REP"
        ) {
            requestDisabled = false;
        }

        if (ur.indexOf("ADMIN") > -1 ||
            ur === 'EXECUTIVE_MANAGEMENT'
            || ur === 'FOLLOWUP'
            || currentUser.canFollowUp) {
            if (eOrder.orderStatusReason && eOrder.orderStatusReason.name === "Follow Up Task") {
                canFollowUp = true;
            }
        }

        return (
            <MDBCardHeader
                style={{
                    paddingTop: 12,
                    paddingBottom: 8,
                    marginBottom: 10,
                    color: "white",
                    fontSize: 18,
                    backgroundColor: "#1976D2",
                }}
            >
                <MDBRow>
                    <MDBCol size="3">
                        <Route
                            render={({history}) => (
                                <MDBBtn
                                    className="px-2 toolbarButton"
                                    data-tip="Return to Order Listing"
                                    data-place={"right"}
                                    outline
                                    rounded
                                    size="sm"
                                    color={"warning"}
                                    onClick={() => {
                                        if (this.state.goto === "billing") {
                                            history.push({
                                                pathname: "/billing/",
                                            });
                                        } else if (this.state.goto === "collections") {
                                            history.push({
                                                pathname: "/collections/",
                                            });
                                        } else {
                                            history.push({
                                                pathname: "/order/",
                                            });
                                        }
                                    }}
                                >
                                    <MDBIcon icon="long-arrow-alt-left"/>
                                </MDBBtn>

                            )}
                        />

                        <span
                            style={{
                                fontSize: 20,
                                color: "white",
                                textAlign: "center",
                                margin: "auto",
                            }}
                        >
                            {"Order # " + eOrder.id}
                        </span>
                    </MDBCol>

                    <MDBCol size="4">
                        <Route
                            render={({history}) => (
                                <h4
                                    aria-hidden="true"
                                    data-tip="Return to Patient"
                                    onClick={() => {
                                        history.push({
                                            pathname: "/patientInformation/" + patient.id,
                                            state: {patient: patient},
                                        });
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: 24,
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                            color: "white",
                                            textAlign: "center",
                                            margin: "auto",
                                        }}
                                    >
                                        {patient.firstName + " " + patient.lastName}
                                    </p>
                                </h4>
                            )}
                        />
                    </MDBCol>

                    <MDBCol size="4">
                        {/*region add note*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="secondary"
                            className="px-2 toolbarButton"
                            data-tip={"Add note"}
                            data-place={"bottom"}
                            onClick={() => this.toggleNoteModalPopup()}
                        >
                            <MDBIcon icon="notes-medical"/>
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region download notes*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="default"
                            className="px-2 toolbarButton"
                            data-tip={"Export Notes"}
                            data-place={"bottom"}
                            onClick={() => this.downloadNotes()}
                        >
                            <MDBIcon icon="download"/>
                        </MDBBtn>
                        {/*endregion*/}

                        {/*//region timelinemodal*/}
                        <MDBBtn
                            onClick={() => this.toggleTimelineModalPopup()}
                            data-tip={"Toggle Order Timeline"}
                            size="sm"
                            data-place={"bottom"}
                            outline
                            rounded
                            color="primary"
                            className="px-2 toolbarButton"
                        >
                            <MDBIcon icon="clock"/>
                        </MDBBtn>
                        {/*//endregion*/}

                        {/*region request help*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="info"
                            className="px-2 toolbarButton"
                            disabled={requestDisabled}
                            data-tip={"Request Paperwork Help"}
                            data-place={"bottom"}
                            onClick={() => this.getLiaisonMeetings()}
                        >
                            <MDBIcon icon="question"/>
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region purchase*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color={"red"}
                            className="px-2 toolbarButton"
                            data-tip={dataTip}
                            data-place={"bottom"}
                            onClick={() => {
                                if (readyToPurchase) {
                                    this.setState({
                                        pqModal: !this.state.pqModal,
                                    });
                                }
                            }}
                        >
                            <MDBIcon icon="cart-plus"/>
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region delivery manifest*/}
                        <MDBBtn
                            outline
                            rounded
                            size="sm"
                            color="amber"
                            className="px-2 toolbarButton"
                            data-tip={"Delivery Manifest"}
                            data-place={"bottom"}
                            onClick={this.renderDMWindow}
                        >
                            <MDBIcon icon="truck"/>
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region approve*/}
                        <MDBBtn
                            onClick={() => this.canApproveOrder()}
                            data-tip={"Approve Order"}
                            data-place={"bottom"}
                            outline
                            rounded
                            size="sm"
                            color="orange"
                            className="px-2 toolbarButton"
                        >
                            <MDBIcon icon="check"/>
                        </MDBBtn>
                        {/*endregion*/}

                        {/*region add follow up note*/}
                        {canFollowUp ?
                            <MDBBtn
                                outline
                                rounded
                                size="sm"
                                color="light grey"
                                className="px-2 toolbarButton"
                                data-tip={"Add followup note"}
                                data-place={"bottom"}
                                onClick={() => this.toggleFollowupNoteModal()}
                            >
                                <MDBIcon icon="pen"/>
                            </MDBBtn>
                            : null}
                        {/*endregion*/}

                        {/*region save*/}
                        <MDBBtn
                            onClick={() => this.saveOrder()}
                            data-tip={"Save Order"}
                            data-place={"bottom"}
                            outline
                            rounded
                            size="sm"
                            color="success"
                            className="px-2 toolbarButton"
                        >
                            <MDBIcon icon="save"/>
                        </MDBBtn>
                        {/*endregion*/}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
        );
    }

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                    style={{marginTop: "75px"}}
                />
                <LiaisonModal
                    closeModal={this.closeHelpModal.bind(this)}
                    setExistingEmpty={this.setExistingEmpty.bind(this)}
                    existingMeeting={this.state.existingMeeting}
                    eOrder={this.state.eOrder}
                    resultingLiaisonKeys={this.state.resultingLiaisonKeys}
                    helpModalOpen={this.state.helpModalOpen}
                />

                <CreateNoteModal
                    eOrder={this.state.eOrder}
                    allNotes={this.state.notes}
                    toggleNoteModal={this.toggleNoteModalPopup.bind(this)}
                    addNoteUpdateOrderCloseModal={this.addNoteUpdateOrderCloseModal.bind(
                        this
                    )}
                    noteModalOpen={this.state.noteModalOpen}
                    noteAssignedTo={null}
                />
                {this.renderTimelineModal()}
                {this.renderPQModal()}
                {this.renderApprovalModal()}
                {this.renderApprovalErrorsModal()}
                {this.renderViewToolbar()}
                {this.renderAbnModal()}
                {this.renderFollowupNoteModal()}
                <div className="container-fluid" style={{paddingRight: "25px"}}>
                    <Row style={{paddingBottom: "1em"}}>
                        <Col size="3">
                            <Col size="12">
                                <TypeStatusContainer
                                    goto={this.state.goto}
                                    eOrder={this.state.eOrder}
                                    order={this.state.order}
                                    updateNotes={this.updateOrderNotes.bind(this)}
                                    saveOrder={this.saveOrder.bind(this)}
                                    validateSave={this.validateSave.bind(this)}
                                    updateParent={this.updateParentObject.bind(this)}
                                    updateTableRows={this.updateTableRows.bind(this)}
                                />
                            </Col>
                        </Col>

                        <Col size="9">{this.renderTabSection()}</Col>
                    </Row>
                </div>
                <ReactTooltip className={"topMost"}/>
            </div>
        );
    }
}
