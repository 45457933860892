import React from "react";
import {
	Col,
	MDBIcon,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
	ToastContainer,
	Button,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBCol,
	MDBModalFooter,
	toast,
	Container,
	Spinner,
	MDBCardHeader,
	MDBRow,
    MDBSelect,
    MDBInput,
} from "mdbreact";
import classnames from "classnames";
import { GiCarWheel } from "react-icons/gi";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ActivitiesReport from "./activityReport";
import SalesReport from "./salesReport";
import ApprovalReport from "./approvalReport";
import ServicesReport from "./servicesReport";
import DeliveryReport from "./deliveryReport";
import ExpensesReport from "./expensesReport";
import ComplaintReport from "./complaintReport";
import ServiceOrderReasonReport from "./serviceOrderReasonReport";
import AccountReport from "./accountReport";
import PropTypes from "prop-types";
import QuickFilter from "../Filters/quickFilter";
import GroupService from "../Security/UserService/groupService";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import api from "../Security/api";
import ReportsService from "../Security/ReportsService/reportsService";
import userService from "../Security/UserService/userService";
import authService from "../Security/authService";

export default class reports extends React.Component {
    constructor(props) {
        super(props);

        const roles = [
            {label: '---NONE---', value: null},
            {label: 'ADMIN', value: 'ADMIN'},
            {label: 'AREA_MANAGER', value: 'AREA_MANAGER'},
            {label: 'BILLING', value: 'BILLING'},
            {label: 'CASE_WORKER', value: 'CASE_WORKER'},
            {label: 'COLLECTIONS', value: 'COLLECTIONS'},
            {label: 'CUSTOMER_EXPERIENCE', value: 'CUSTOMER_EXPERIENCE'},
            {label: 'DATA_EXPORT', value: 'DATA_EXPORT'},
            {label: 'DIRECTOR', value: 'DIRECTOR'},
            {label: 'EXECUTIVE_MANAGEMENT', value: 'EXECUTIVE_MANAGEMENT'},
            {label: 'FINANCE', value: 'FINANCE'},
            {label: 'FOLLOWUP', value: 'FOLLOWUP'},
            {label: 'MARKETING', value: 'MARKETING'},
            {label: 'OFFICE_SUPPORT', value: 'OFFICE_SUPPORT'},
            {label: 'CARE_COORDINATOR', value: 'CARE_COORDINATOR'},
            {label: 'CALL_CENTER', value: 'CALL_CENTER'},
            {label: 'OFFICE_MANAGER', value: 'OFFICE_MANAGER'},
            {label: 'PURCHASING', value: 'PURCHASING'},
            {label: 'SALES', value: 'SALES'},
            {label: 'SALES_LIAISON', value: 'SALES_LIAISON'},
            {label: 'SALES_MANAGER', value: 'SALES_MANAGER'},
            {label: 'SERVICE_TEAM', value: 'SERVICE_TEAM'},
            {label: 'SYSTEM_ADMIN', value: 'SYSTEM_ADMIN'},
            {label: 'TECHNICIAN', value: 'TECHNICIAN'},
            {label: 'THERAPIST', value: 'THERAPIST'},
            {label: 'THIRD_PARTY_USER', value: 'THIRD_PARTY_USER'},
            {label: 'VERIFICATION', value: 'VERIFICATION'},
            {label: 'WAREHOUSE_TECHNICIAN', value: 'WAREHOUSE_TECHNICIAN'},
        ];

        const months = [
            {label: 'January', value: 1},
            {label: 'February', value: 2},
            {label: 'March', value: 3},
            {label: 'April', value: 4},
            {label: 'May', value: 5},
            {label: 'June', value: 6},
            {label: 'July', value: 7},
            {label: 'August', value: 8},
            {label: 'September', value: 9},
            {label: 'October', value: 10},
            {label: 'November', value: 11},
            {label: 'December', value: 12},
        ];

        let years = [];
        let thisYear = new Date().getFullYear();

        for (var i = 2019; i <= thisYear; i++) {
            years.push({label: i, value: i});
        }

        this.state = {
            activeItem: "1",
            isSetupLoaded: false,
            isOpen: true,
            users: [],
            currUser: {},
            allLocs: [],
            allSalesReps: [],
            filteredSalesReps: [],
            locationsSelect: [{
                label: 'Loading...',
                value: 'loading'
            }],
            salesRepsSelect: [{
                label: 'Loading...',
                value: 'loading'
            }],
            locationsSelected: [],
            salesRepsSelected: [],
            showDatePicker: false,
            quickFilter: [],
            quickFilterSelected: null,
            salesRadio: 1,
            allProducts: true,
            pointsOnlyProducts: false,
            startDate: null,
            endDate: null,
            techsSelect: [{
                label: 'Loading...',
                value: 'loading'
            }],
            techsSelected: [],
            allTechs: [],
            filteredTechs: [],
            generateClicked: 0,
            monthList: months,
            yearList: years,
            roleList: roles,
            selectedMonth: null,
            selectedYear: null,
            selectedRole: null,
            processing: false,
            internalLocations: [],
            allInternalLocations: [],

            saveFiltersToggle: false,
            userReportFilters:[],
            myFilterName:'',
            listFiltersToggle: false,

            selectedLocationNames: "Choose Locations"
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        productTypes: PropTypes.array,
        locations: PropTypes.array,
        userFunction: () => {
        },
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        salesReps: PropTypes.array,
        techs: PropTypes.array,
    };

    componentDidMount() {
        this.getLocationOptions();
        this.getFilterOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {techs, salesReps, internalLocations} = this.context;
        let st = this.state;
        if (st.allInternalLocations.length !== internalLocations.length) {
            this.getLocationOptions();
        }
        if (st.allSalesReps.length !== salesReps.length) {
            this.getSalesRepOptions();
        }
        if (st.allTechs.length !== techs.length) {
            this.getTechOptions();
        }
    }

    handleQuickFilter = (e) => {
        let a = QuickFilter.getDates(e.value);

        let startD = null,
            endD = null;

        if (a && a.length > 0) {
            startD = a[0];
            endD = a[1];
        }

        this.setState({
            quickFilter: a,
            startDate: startD,
            endDate: endD,
            quickFilterSelected: e
        });
    };

    getFilterOptions(){
        const {currentUser} = this.context;
        let res = [];
        
        if(currentUser.userReportFilters && currentUser.userReportFilters.length > 0){
            // push into res here
            currentUser.userReportFilters.forEach((val,idx)=> {
                res.push({
                    label: val.name,
                    value: val
                })
            })
        }
        this.setState({
            userReportFilters: res
        });
    }

    getLocationOptions() {
        const {internalLocations} = this.context;

        let a = [],
            oldIds = this.state.internalLocations;

        //Must add the checked and disabled for it to remain 'uncontrolled'...BC
        internalLocations.map(loc => {
            let checked = false;

            //If the user filter had old ids in it, set them to default be selected...BC
            if (oldIds.indexOf(loc.id) > -1) {
                checked = true;
            }

            return a.push(
                {
                    checked: checked,
                    disabled: false,
                    text: loc.name,
                    value: loc.id.toString()
                }
            )
        });

        this.setState({
            allInternalLocations: a
        });
    }

    getSalesRepOptions(e) {
        const {salesReps} = this.context;
        
        let options = [],
            selected = [],
            oldIds = this.state.users;

        if (e === undefined || e.length === 0) {
            salesReps.map(rep => {
                if(rep.active === false || rep.deleted === true)
                    return null;

                let checked = false;

                if (oldIds.indexOf(rep.id) > -1) {
                    checked = true;
                }
                selected.push(rep.id);

                return options.push({
                    disabled: false,
                    checked: checked,
                    text: rep.username,
                    value: rep.id,
                })
            });

            if (salesReps.length !== 0) {
                this.setState({
                    filteredSalesReps: options,
                    allSalesReps: salesReps,
                    salesRepsSelected: selected,
                    updatedCheckedReps: true,
                });
            }

        } else {
            let salesList = [];

            return GroupService.getAllSalesRepsByLocations(e).then(res => {
                res.forEach(t => {
                    if (salesList.indexOf(t.id) === -1) {
                        options.push({
                            checked: true,
                            disabled: false,
                            text: t.username,
                            value: t.id,
                        })
                    }
                    salesList.push(t.id);
                });

                this.setState({
                    filteredSalesReps: options,
                    salesRepsSelected: salesList,
                })
            }).catch(e => {

            });
        }
    }

    getTechOptions(e) {
        const {techs} = this.context;

        let options = [],
            selected = [];

        if (e === undefined || e.length === 0) {
            techs.map(rep => {
                if(rep.active === false || rep.deleted === true)
                    return null;
                selected.push(rep.id);
                return options.push({
                    disabled: false,
                    checked: true,
                    text: rep.username,
                    value: rep.id,
                })
            });
            
            if (techs.length !== 0) {
                this.setState({
                    filteredTechs: options,
                    allTechs: techs,
                    techsSelected: selected,
                    updatedCheckedTechs: true,
                });
            }

        } else {
            let techList = [];

            return GroupService.getAllTechniciansByLocations(e).then(res => {
                res.forEach(t => {

                    if (techList.indexOf(t.id) === -1) {
                        options.push({
                            checked: true,
                            disabled: false,
                            text: t.username,
                            value: t.id,
                        })
                    }
                    techList.push(t.id);
                });

                this.setState({
                    filteredTechs: options,
                    techsSelected: techList
                })
            }).catch(e => {

            });
        }
    }

    renderLocationSelect() {
        let st = this.state;

        if (st.activeItem !== '8') {
            return (
                <MDBSelect
                    multiple
                    outline
                    color="info"
                    selected={this.state.selectedLocationNames}
                    style={{maxHeight: '100px', backgroundColor: 'white', margin: 0}}
                    options={this.state.allInternalLocations}
                    getValue={this.getLocationValue.bind(this)}
                />
            );
        }
    }

    getLocationValue(e) {
        if(e.length === 0){
            this.setState({
                selectedLocationNames: "Choose Locations"
            })
        }
        this.setState({
            locationsSelected: e,
        });
        this.getTechOptions(e)
        this.getSalesRepOptions(e)
    }

    renderRepTechSelect() {
        let st = this.state;

        if (st.activeItem === '6') {
            return;
        }

        if (st.activeItem === '7') {
            return (
                <MDBSelect
                    selectAll
                    multiple
                    outline
                    search
                    className={'searchField'}
                    color="default"
                    selected={"Choose Techs"}
                    style={{maxHeight: '100px', backgroundColor: 'white', margin: 0}}
                    options={this.state.filteredTechs}
                    getValue={this.handleTechChange.bind(this)}
                />
            )
        }

        if (st.activeItem !== '8') {
            return (
                <MDBSelect
                    selectAll
                    multiple
                    outline
                    search
                    className={'searchField'}
                    color="default"
                    selected={"Choose Sales Reps"}
                    style={{maxHeight: '100px', backgroundColor: 'white', margin: 0}}
                    options={this.state.filteredSalesReps}
                    getValue={this.getSalesRepsValue.bind(this)}
                />
            )
        }
    }

    getSalesRepsValue(e) {
        this.setState({
            salesRepsSelected: e
        })
    }

    handleTechChange(e) {
        this.setState({
            techsSelected: e
        });
    };

    renderDateRow() {
        let st = this.state;

        return (
            <MDBRow>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MDBCol size={'6'}>
                        <Select
                            placeholder='Quick Filter'
                            options={QuickFilter.getOptions()}
                            onChange={this.handleQuickFilter.bind(this)}
                            value={st.quickFilterSelected}
                        />
                    </MDBCol>

                    <MDBCol size={'3'} className={'inputDateWrapper reportsDateOutline'}>
                        <DatePicker
                            size={'small'}
                            inputVariant={'outlined'}
                            format='MM-DD-YYYY'
                            value={st.startDate}
                            onChange={(evt) => this.setState({startDate: evt})}
                            mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            label="Start Date"
                        />
                    </MDBCol>

                    <MDBCol size={'3'} className={'inputDateWrapper reportsDateOutline'}>
                        <DatePicker
                            size={'small'}
                            inputVariant={'outlined'}
                            format='MM-DD-YYYY'
                            value={st.endDate || null}
                            onChange={(evt) => this.setState({endDate: evt})}
                            mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            label="End Date"
                        />
                    </MDBCol>

                </MuiPickersUtilsProvider>
            </MDBRow>
        )
    }

    //This will convert all the data in the grid to a csv file
    clientCSV(stateData, filename) {
        let result,
            ctr,
            keys,
            columnDelimiter = ',',
            lineDelimiter = '\n',
            data;

        data = stateData.rows || null;
        if (data == null || !data.length) {
            return null;
        }

        keys = Object.keys(stateData.rows[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //get Dashboard Information
    getDashboardInformation() {
        let t = this;

        function getVerificationDashboardRankings() {
            return new Promise((res, reject) => {
                api.get('/dashboardMonthlyVerificationRank').then(data => {
                    t.setState({verificationRankingsData: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        function getBillingDashboardRankings() {
            return new Promise((res, reject) => {
                api.get('/dashboardMonthlyBillingRank').then(data => {
                    t.setState({billingRankingsData: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        function getDailyBilling() {
            return new Promise((res, reject) => {
                api.get('/dashboardDailyBilling', {reason: 'Billed'}).then(data => {
                    t.setState({dailyBillingData: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        function getDailySalesPaperworkIssues() {
            return new Promise((res, reject) => {
                api.get('/dashboardDailyBilling', {reason: 'Sales PPW Issue'}).then(data => {
                    t.setState({salesPPWIssues: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        function getDailyDeliveryPaperworkIssues() {
            return new Promise((res, reject) => {
                api.get('/dashboardDailyBilling', {reason: 'Delivery PPW Issue'}).then(data => {
                    t.setState({deliveryPPWIssues: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        function getDailyPreAuth() {
            return new Promise((res, reject) => {
                api.get('/dashboardDailyBilling', {reason: 'Pre-Auth Issue'}).then(data => {
                    t.setState({preAuthIssues: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        function getMonthlyInsuranceMatrix() {
            return new Promise((res, reject) => {
                api.get('/dashboardMonthlyInsuranceMetrics').then(data => {
                    t.setState({insuranceMetrics: data});
                    res();
                }).catch(err => {
                    reject();
                });
            })
        }

        Promise.all([
            getVerificationDashboardRankings(),
            getBillingDashboardRankings(),
            getDailyBilling(),
            getDailySalesPaperworkIssues(),
            getDailyDeliveryPaperworkIssues(),
            getDailyPreAuth(),
            getMonthlyInsuranceMatrix()

        ]).then(() => {
            this.createClientCSV("", "dashboardData.csv")
        }).catch(e => {
            toast.error("There was an error getting the dashboard data.");
        })
    }

    getProductionReport = () => {
        let month = this.state.selectedMonth,
            year = this.state.selectedYear,
            role = this.state.selectedRole || '';
        this.setState({processing: true});
        let filters = {
            month,
            year,
            role,
        };

        ReportsService.getProductionReport(filters).then(res => {
            this.toggleProductionModal();
            this.createProductionCsv(res.productionMonthly, 'productionReport.csv');
            this.createProductionCsv(res.productionMonthlyNotations, 'productionReportNotedOrders.csv');
        }).catch(err => {
            console.log(err);

            this.setState({processing: false});
            toast.error('An error occurred when getting the production report.');
        })
    }

    createProductionCsv(reportData, filename) {
        let result = '',
            columnDelimiter = ',',
            lineDelimiter = '\n';

        if(filename === 'productionReport.csv') {

            result += 'Monthly Production Report';
            result += lineDelimiter;

            result += 'Updated By,Role,Updated On,Status From,Reason From,Status,Reason,Orders Worked\n';

            reportData.forEach(col => {
                result += (col.updatedBy) + columnDelimiter;
                result += (col.role) + columnDelimiter;
                result += (col.updatedOn) + columnDelimiter;
                result += (col.statusFrom) + columnDelimiter;
                result += (col.reasonFrom) + columnDelimiter;
                result += (col.status) + columnDelimiter;
                result += (col.reason) + columnDelimiter;
                result += (col.ordersWorked) + lineDelimiter;
            })
        }
        else{

            result += 'Monthly Production Report Noted Orders';
            result += lineDelimiter;

            result += 'Username,Role,Updated On,Order Id,Text,Activity Id\n';

            reportData.forEach(col => {
                result += (col.username) + columnDelimiter;
                result += (col.role) + columnDelimiter;
                result += (col.dateUpdated) + columnDelimiter;
                result += (col.orderId) + columnDelimiter;
                result += '"' + (col.text) + '"' + columnDelimiter;
                result += (col.activityId) + lineDelimiter;
            })
        }

        this.downloadBlob(result, filename);

        this.setState({processing: false});
    }

    //This will convert all the data in the grid to a csv file
    createClientCSV(stateData, filename) {
        let result = '',
            columnDelimiter = ',',
            lineDelimiter = '\n',
            st = this.state;

        //#region monthly verification orders
        result += 'Monthly Orders Moved From Verification';
        result += lineDelimiter;

        st.verificationRankingsData.forEach(col => {
            result += (col.updatedBy);
            result += (columnDelimiter);

            result += (col.ordersWorked);
            result += (lineDelimiter);
        });
        //#endregion

        result += lineDelimiter;
        result += lineDelimiter;

        //#region monthly billing orders
        result += 'Monthly Orders Moved From Billing';
        result += lineDelimiter;

        st.billingRankingsData.forEach(col => {
            result += (col.updatedBy);
            result += (columnDelimiter);

            result += (col.ordersBilled);
            result += (lineDelimiter);
        });
        //#endregion

        result += lineDelimiter;
        result += lineDelimiter;

        //#region Daily Billing orders
        result += 'Daily Orders Moved Into Billed';
        result += lineDelimiter;

        st.dailyBillingData.forEach(col => {
            result += (col.updatedBy);
            result += (columnDelimiter);

            result += (col.ordersBilled);
            result += (lineDelimiter);
        });
        //#endregion

        result += lineDelimiter;
        result += lineDelimiter;

        //#region Daily Sales PPW orders
        result += 'Daily Orders Moved Into Sales PPW Issue';
        result += lineDelimiter;

        st.salesPPWIssues.forEach(col => {
            result += (col.updatedBy);
            result += (columnDelimiter);

            result += (col.ordersBilled);
            result += (lineDelimiter);
        });
        //#endregion

        result += lineDelimiter;
        result += lineDelimiter;

        //#region Daily Delivery PPW orders
        result += 'Daily Orders Moved Into Delivery PPW Issue';
        result += lineDelimiter;

        st.deliveryPPWIssues.forEach(col => {
            result += (col.updatedBy);
            result += (columnDelimiter);

            result += (col.ordersBilled);
            result += (lineDelimiter);
        });
        //#endregion

        result += lineDelimiter;
        result += lineDelimiter;

        //#region Daily Pre Auth orders
        result += 'Daily Orders Moved Into Pre Auth Issue';
        result += lineDelimiter;

        st.preAuthIssues.forEach(col => {
            result += (col.updatedBy);
            result += (columnDelimiter);

            result += (col.ordersBilled);
            result += (lineDelimiter);
        });
        //#endregion

        result += lineDelimiter;
        result += lineDelimiter;

        //#region Insurance Metrics
        result += 'Insurance Metrics';
        result += lineDelimiter;

        st.insuranceMetrics.forEach(col => {
            result += (col.ndxKey);
            result += (columnDelimiter);

            result += (col.ndxValue);
            result += (lineDelimiter);
        });
        //#endregion

        this.downloadBlob(result, filename);
    }

    //this actually prompts the download
    downloadBlob(csvData, filename) {

        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;"
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    handleMonthChange(val) {
        this.setState({selectedMonth: val.value});
    }

    handleYearChange(val) {
        this.setState({selectedYear: val.value});
    }

    handleRoleChange(val) {
        this.setState({selectedRole: val.value});
    }

    formatDate(date) {
        return (new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: '2-digit'
        }).format(date))
    }

    formatCurrency(amount) {
        return (
            new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD', minimumFractionDigits: 2
            }).format(amount))
    }

    //toggle tabs
    toggle = (tab) => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    toggleSaveFiltersModal(){
        let res = !this.state.saveFiltersToggle;
        this.setState({
            saveFiltersToggle: res
        })
    }

    toggleListFiltersModal(){
        let res = !this.state.listFiltersToggle;
        this.setState({
            listFiltersToggle: res
        })
    }

    saveMyFilters(){
        let name = this.state.myFilterName,
            locs = this.state.locationsSelected,
            qf = this.state.quickFilterSelected;
        const {currentUser, userFunction} = this.context;
        let filters = {
            name: name,
            locationIds: locs.join(','),
            userId: currentUser.id,
            dateQuickFilter: qf.label
        }
        userService.addUserReportFilters(filters).then(res => {
            authService.getMyUserDetails().then(res2 => {
                userFunction(res2);
                let fil = [];
                
                if(res2.userReportFilters && res2.userReportFilters.length > 0){
                    res2.userReportFilters.forEach((val,idx)=> {
                        fil.push({
                            label: val.name,
                            value: val
                        })
                    })
                }
                this.setState({
                    userReportFilters: fil
                })
            })
            this.setState({
                saveFiltersToggle: false
            })
        }).catch(err => {
            console.log(err);
        })
    }

    deleteMyFilter(id){
        const {userFunction} = this.context;
        userService.removeUserReportFilter(id).then(res => {
            authService.getMyUserDetails().then(res2 => {
                userFunction(res2);
                let fil = [];
                
                if(res2.userReportFilters && res2.userReportFilters.length > 0){
                    res2.userReportFilters.forEach((val,idx)=> {
                        fil.push({
                            label: val.name,
                            value: val
                        })
                    })
                }
                this.setState({
                    userReportFilters: fil
                })
            })
        }).catch(err => {
            console.log(err);
        })
    }

    loadSelectedFilter(obj){
        let opts = QuickFilter.getOptions();
        let allLocs = this.state.allInternalLocations;
        let locs = obj.locationIds.split(','),
            qf = obj.dateQuickFilter,
            selQf = opts.filter(x => x.label === qf)[0];
        let locNames = '';
        
        this.handleQuickFilter(selQf);
        allLocs.forEach((item) => {
            if(locs.includes(item.value)){
                item.checked = true;
                locNames += locNames.length === 0 ? item.text : ', ' + item.text;
            }
            else{
                item.checked = false;
            }
        })
        if(locNames.length === 0){
            locNames = "Choose Locations";
        }
        this.setState({
            allInternalLocations: allLocs,
            selectedLocationNames: locNames
        })
        this.getLocationValue(locs);
    }

    renderProductionMonthlyModal() {
        return (
            <MDBModal
                centered
                size="lg"
                toggle={() => this.toggleProductionModal()}
                isOpen={this.state.productionMonthlyToggle}>
                <MDBModalHeader style={{backgroundColor: '#FFFFFF'}}>
                    Production Report
                </MDBModalHeader>
                <MDBModalBody>
                    {this.state.processing ? this.renderLoadingSpinner() :
                        <div>
                            <Row style={{margin: '1rem'}}>
                                <MDBCol md={"12"}>
                                    <Select
                                        placeholder={"Select Month"}
                                        style={{margin: '1rem'}}
                                        options={this.state.monthList}
                                        onChange={this.handleMonthChange.bind(this)}/>
                                </MDBCol>
                            </Row>
                            <Row style={{margin: '1rem'}}>
                                <MDBCol md={"12"}>
                                    <Select
                                        placeholder={"Select Year"}
                                        style={{margin: '1rem'}}
                                        options={this.state.yearList}
                                        onChange={this.handleYearChange.bind(this)}>
                                    </Select>
                                </MDBCol>
                            </Row>
                            <Row style={{margin: '1rem'}}>
                                <MDBCol md={"12"}>
                                    <Select
                                        placeholder={"Select Role"}
                                        style={{margin: '1rem'}}
                                        options={this.state.roleList}
                                        onChange={this.handleRoleChange.bind(this)}>
                                    </Select>
                                </MDBCol>
                            </Row>
                        </div>
                    }
                </MDBModalBody>
                <MDBModalFooter color={'indigo'}>
                    <Button color={'success'}
                            floating
                            size={'sm'}
                            onClick={this.getProductionReport}
                            disabled={this.state.processing}
                            data-tip={"Run Report"}>
                        <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                    </Button>
                    <Button color={'red'}
                            floating
                            size={'sm'}
                            data-tip={"Cancel"}
                            onClick={this.toggleProductionModal}>
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>
                    <ReactTooltip/>
                </MDBModalFooter>
            </MDBModal>
        );
    }

    renderSaveFiltersModal(){
        let locs = this.state.locationsSelected,
            allLocs=this.state.allInternalLocations,
            qf = this.state.quickFilterSelected,
            cannotSave = true;
        let colRes = [];
        var resLocs = allLocs.filter(x=> {
            return locs.includes(x.value)
        });

        if(resLocs.length === 1){
            colRes.push(resLocs[0]);
        }
        else if(resLocs.length > 1){
            let rowsCt = 0, ctr = 1;
            if(resLocs.length % 2 === 1){
                rowsCt = (resLocs.length + 1) / 2;
            }
            else{
                rowsCt = resLocs.length / 2;
            }
            for(var i = 0; i < resLocs.length; i++){
                colRes.push(resLocs[i]);
                if(ctr===resLocs.length){
                    break;
                }
                ctr++;
                colRes.push(resLocs[rowsCt + i]);
                if(ctr===resLocs.length){
                    break;
                }
                ctr++;
            }
        }
        
        cannotSave = qf === null || (!colRes || colRes.length === 0);
        
        return (
            <MDBModal 
                centered
                size="lg"
                toggle={() => this.toggleSaveFiltersModal()}
                isOpen={this.state.saveFiltersToggle}>
                    <MDBModalHeader style={{backgroundColor:'#FFFFFF'}}>
                        Save My Filters
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size="12">
                                <label><b>Date Range:</b></label>
                                {qf ? <label style={{marginLeft: '4px'}}>{qf.label}</label> : <label style={{color:'red', marginLeft: '4px'}}>No Dates Selected</label>}
                            </MDBCol>
                            <MDBCol size="12">
                                <hr style={{marginBottom: '4px', marginTop: '4px'}}/>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="12">
                                <b>Locations:</b>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{maxHeight:'200px', overflowY: 'scroll'}}>
                            {/* {resLocs && resLocs.length > 0 ? resLocs.map(x => {
                                return <MDBCol size='6'>{x.text}</MDBCol>
                            }) : <MDBCol size="12"><label style={{color: 'red'}}>No Locations Selected</label></MDBCol>} */}
                            {colRes && colRes.length > 0 ? colRes.map(x => {
                                return <MDBCol key={x.value} size='6'>{x.text}</MDBCol>
                            }) : <MDBCol size="12"><label style={{color: 'red'}}>No Locations Selected</label></MDBCol>}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="12">
                                <hr style={{marginBottom: '4px', marginTop: '4px'}}/>
                            </MDBCol>
                            <MDBCol size="12">
                                <label><b>Filter Name:</b></label>
                                <MDBInput 
                                    containerClass={"noMargin"}
                                    outline
                                    value={this.state.myFilterName || "myFilter"}
                                    onChange={(e) => {
                                        this.setState({myFilterName: e.target.value})
                                    }} />
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter color={'indigo'}>
                        <Button color={'success'}
                                floating
                                size={'sm'}
                                disabled={cannotSave}
                                onClick={() => { this.saveMyFilters() }}
                                data-tip={"Save Filters"}>
                            <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                        </Button>
                        <Button color={'red'}
                                floating
                                size={'sm'}
                                data-tip={"Cancel"}
                                onClick={() => { this.toggleSaveFiltersModal() }}>
                            <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                        </Button>
                        <ReactTooltip/>
                    </MDBModalFooter>
            </MDBModal>
        );
    }

    toggleProductionModal = () => {
        let res = !this.state.productionMonthlyToggle;
        if (!res) {
            this.setState({
                selectedMonth: null,
                selectedYear: null,
                selectedRole: null,
            });
        }
        this.setState({
            productionMonthlyToggle: res
        })
    };

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: 'center', verticalAlign: 'center'}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        )
    }

    renderViewToolbar() {
        const {currentUser} = this.context;

        let ap = this.state.allProducts,
            bgColor = ap ? 'white' : 'orange',
            text = ap ? 'All Products' : 'Points-Only Products';

        let cannotUseMyFilters = (!this.state.allInternalLocations || this.state.allInternalLocations.length === 0) &&
            (!this.state.allSalesReps || this.state.allSalesReps.length === 0) &&
            (!this.state.allTechs || this.state.allTechs.length === 0);
            
        return (
            <MDBCardHeader
                style={{
                    paddingTop: 12,
                    paddingBottom: 8,
                    marginBottom: 10,
                    fontSize: 18,
                    backgroundColor: '#1976D2'
                }}>
                <MDBRow>
                    <MDBCol size={'8'}>
                        <MDBRow>
                            <MDBCol size={'6'}>
                                {this.renderLocationSelect()}
                            </MDBCol>

                            <MDBCol size={'6'}>
                                {this.renderRepTechSelect()}
                            </MDBCol>
                            
                            <MDBCol size={'12'}>
                                {this.renderDateRow()}
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>

                    <MDBCol size={'4'}>

                        <Button className="px-2 toolbarButton"
                                outline
                                rounded
                                size="sm"
                                color={bgColor}
                                data-place={'bottom'}
                                data-tip={text}
                                onClick={() => {
                                    this.setState({
                                        allProducts: !ap
                                    })
                                }}>
                            <GiCarWheel data-place={'bottom'} style={{color: bgColor, height: 20, width: 20}}/>
                        </Button>

                        <Button className="px-2 toolbarButton"
                                outline
                                rounded
                                size="sm"
                                color={'success'}
                                data-place={'bottom'}
                                data-tip={"Generate Reports"}
                                onClick={() => {
                                    let gc = this.state.generateClicked;
                                    this.setState({
                                        generateClicked: gc + 1
                                    })
                                }}>
                            <MDBIcon icon="chart-line" style={{fontSize: "2em"}}/>
                        </Button>

                        <Button className="px-2 toolbarButton"
                                outline
                                rounded
                                size="sm"
                                color={"secondary"}
                                data-place={'bottom'}
                                data-tip={"Download Dashboard CSV"}
                                onClick={() => {
                                    this.getDashboardInformation()
                                }}>
                            <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                        </Button>

                        {currentUser.role === 'ADMIN' || currentUser.role === 'SYSTEM_ADMIN'
                        || currentUser.role === 'EXECUTIVE_MANAGEMENT' || currentUser.role === 'SALES_MANAGER' ?
                            <Button className="px-2 toolbarButton"
                                    outline
                                    rounded
                                    size="sm"
                                    color={"warning"}
                                    data-place={'bottom'}
                                    data-tip={"View Daily Added Orders"}
                                    onClick={ () => this.props.history.push({
                                        pathname: '/dailyOrderCounts/',
                                    })}>
                                <MDBIcon icon="chart-line" style={{fontSize: "2em"}}/>
                            </Button>
                            : ''}

                        {currentUser.role === 'ADMIN' || currentUser.role === 'SYSTEM_ADMIN' || currentUser.role === 'AREA_MANAGER'
                        || currentUser.role === 'EXECUTIVE_MANAGEMENT' || currentUser.role === 'SALES_MANAGER' ?
                            <Button className="px-2 toolbarButton"
                                    outline
                                    rounded
                                    size="sm"
                                    color={"orange"}
                                    data-place={'bottom'}
                                    data-tip={"View Production Monthly Report"}
                                    onClick={() => {
                                        this.toggleProductionModal()
                                    }}>
                                <MDBIcon icon="calendar-alt" style={{fontSize: "2em"}}/>
                            </Button>
                            : ''}
                                   
                        <Button className="px-2 toolbarButton"
                                outline
                                rounded
                                size="sm"
                                color={"black"}
                                data-place={'bottom'}
                                data-tip={"Save My Filters"}
                                onClick={() => { this.toggleSaveFiltersModal() }}>
                            <MDBIcon icon="save" style={{fontSize: "2em"}}/>
                        </Button>
                 
                        <Button className="px-2 toolbarButton"
                                outline
                                rounded
                                size="sm"
                                color={"purple"}
                                data-place={'bottom'}
                                data-tip={"View My Filters"}
                                onClick={() => { this.toggleListFiltersModal() }}>
                            <MDBIcon icon="list" style={{fontSize: "2em"}}/>
                        </Button>

                        <Select
                            options={this.state.userReportFilters}
                            placeholder='My Filters'
                            disabled={cannotUseMyFilters}
                            onChange={(e) => { this.loadSelectedFilter(e.value) }}
                        />
                        
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
        )
    }

    renderListFiltersModal(){
        const {currentUser} = this.context;
        return(
            <MDBModal
                centered
                size="lg"
                toggle={() => this.toggleListFiltersModal()}
                isOpen={this.state.listFiltersToggle}>
                <MDBModalHeader style={{backgroundColor:'#FFFFFF'}}>
                    My Filters
                </MDBModalHeader>
                <MDBModalBody>
                    <div style={{maxHeight: '200px', overflowY: 'scroll', overflowX: 'hidden'}}>
                         {
                            currentUser.userReportFilters && currentUser.userReportFilters.length > 0 ? 
                                currentUser.userReportFilters.map(x => {
                                    return (
                                        <MDBRow key={x.name}>
                                            <MDBCol size={'2'}>
                                                <Button color={'red'}
                                                        floating
                                                        size={'sm'}
                                                        data-tip={"Delete"}
                                                        onClick={() => { this.deleteMyFilter(x.id) }}>
                                                    <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                                                </Button>
                                            </MDBCol>
                                            <MDBCol size={'10'}>
                                                <label style={{marginTop:'16px'}}>{x.name}</label>
                                            </MDBCol>
                                        </MDBRow>
                                    )
                                })
                            : <MDBRow><MDBCol size={'12'} style={{color:'red'}}>No saved filters</MDBCol></MDBRow>
                        } 
                    </div>
                </MDBModalBody>
                <MDBModalFooter>
                    <Button color={'red'}
                            floating
                            size={'sm'}
                            data-tip={"Close"}
                            onClick={() => { this.toggleListFiltersModal() }}>
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>
                    <ReactTooltip/>
                </MDBModalFooter>
            </MDBModal>
        )
    }

    //load the data in the table
    render() {
        let {currentUser} = this.context,
            filters = {},
            st = this.state;

        let topFilters = {
            quickFilter: st.quickFilter,
            showDatePicker: st.showDatePicker,
            salesRepsSelected: st.salesRepsSelected,
            startDate: st.startDate,
            endDate: st.endDate,
            pointsOnlyProducts: st.pointsOnlyProducts,
            techsSelected: st.techsSelected,
            locationsSelected: st.locationsSelected
        };

        if (currentUser && currentUser.userPreferences) {
            filters = currentUser.userPreferences;
        }

        if (this.state.processing) {
            return (this.renderLoadingSpinner);
        }


        return (
            <div>
                {this.renderViewToolbar()}
                <div style={{padding: '2rem'}} className="classic-tabs">
                    <ToastContainer
                        hideProgressBar={false}
                        newestOnTop={true}
                        autoClose={5000}
                        style={{marginTop: "75px"}}
                    />
                    {this.renderProductionMonthlyModal()}
                    {this.renderSaveFiltersModal()}
                    {this.renderListFiltersModal()}

                    <Row>
                        <Col md="12">
                            <Nav classicTabs color="blue" className="nav-justified">
                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "1"})}
                                             onClick={() => {
                                                 this.toggle("1");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="chart-line" size="2x"/><br/> Sales Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "2"})}
                                             onClick={() => {
                                                 this.toggle("2");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="tasks" size="2x"/><br/> Activity Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "3"})}
                                             onClick={() => {
                                                 this.toggle("3");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="check" size="2x"/><br/> Approval Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "4"})}
                                             onClick={() => {
                                                 this.toggle("4");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="money-bill" size="2x"/><br/> Expense Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "5"})}
                                             onClick={() => {
                                                 this.toggle("5");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="users" size="2x"/><br/> Account Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "6"})}
                                             onClick={() => {
                                                 this.toggle("6");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="cogs" size="2x"/><br/> Services Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "7"})}
                                             onClick={() => {
                                                 this.toggle("7");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="truck" size="2x"/><br/> Delivery Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "8"})}
                                             onClick={() => {
                                                 this.toggle("8");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="comment" size="2x"/><br/> Complaint Report
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink link to="#" className={classnames({active: this.state.activeItem === "9"})}
                                             onClick={() => {
                                                 this.toggle("9");
                                             }}
                                             role="tab"
                                    >
                                        <MDBIcon icon="comment" size="2x"/><br/> Service Order Reason Report
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent className="card" activeItem={this.state.activeItem}>
                                <TabPane tabId="1" role="tabpanel">
                                    <SalesReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                 filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="2" role="tabpanel">
                                    <ActivitiesReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                      filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="3" role="tabpanel">
                                    <ApprovalReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                    filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="4" role="tabpanel">
                                    <ExpensesReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                    filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="5" role="tabpanel">
                                    <AccountReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                   filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="6" role="tabpanel">
                                    <ServicesReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                    filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="7" role="tabpanel">
                                    <DeliveryReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                    filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                                <TabPane tabId="8" role="tabpanel">
                                    <ComplaintReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                     filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>

                                <TabPane tabId="9" role="tabpanel">
                                    <ServiceOrderReasonReport csvFunction={this.clientCSV.bind(this)} topFilters={topFilters}
                                                     filters={filters} generateClicked={this.state.generateClicked}/>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
