import React from "react";
import { Button, DataTable, Container, Spinner, MDBIcon } from "mdbreact";
import ReportsService from "../Security/ReportsService/reportsService";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class expensesReport extends React.Component {
	constructor(props) {
		super(props);

		let filters = this.determineFilters(props.filters);
		let topFilters = props.topFilters;

		//table headers & columns
		const data = {
			columns: [
				{
					label: "Created At",
					field: "createdAt",
					sort: "asc",
					width: 200,
				},
				{
					label: "Expense Date",
					field: "expenseDate",
					sort: "asc",
					width: 200,
				},
				{
					label: "Sales Rep",
					field: "user",
					sort: "asc",
					width: 200,
				},
				{
					label: "Office",
					field: "group",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 200,
				},
				{
					label: "Vendor",
					field: "vendor",
					sort: "asc",
					width: 200,
				},
				{
					label: "Description",
					field: "description",
					sort: "asc",
					width: 200,
				},
				{
					label: "Amount",
					field: "amount",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			expensesData: data,
			allExpenses: [],
			startDate: new Date(filters.startDate),
			endDate: new Date(filters.endDate),
			isLoaded: true,
			csvFunction: props.csvFunction,
			topFilters: topFilters,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		salesReps: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Get the most up-to-date filters passed down from parent
		if (
			JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
		) {
			this.setState({
				topFilters: this.props.topFilters,
			});
		}
		// Checks if the generate reports button has been clicked...JK
		if (prevProps.generateClicked !== this.props.generateClicked) {
			this.getExpenseReport();
		}
	}

	determineFilters(userFilters) {
		let d = new Date(),
			filters = {};

		if (userFilters) {
			if (userFilters.startDate) {
				filters.startDate = new Date(userFilters.startDate);
			} else {
				filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			}

			if (userFilters.endDate) {
				filters.endDate = new Date(userFilters.endDate);
			} else {
				filters.endDate = d;
			}
		} else {
			filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			filters.endDate = d;
		}

		return filters;
	}

	//format data received from api call for display
	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "2-digit",
		}).format(date);
	}

	getExpenseReport() {
		const { currentUser, salesReps } = this.context;
		let st = this.state,
			reps = [],
			tp = st.topFilters;

		let filters = {
			startDate: tp.startDate,
			endDate: tp.endDate,
		};

		try {
			filters = {
				startDate: new Date(tp.startDate).toLocaleDateString(),
				endDate: new Date(tp.endDate).toLocaleDateString(),
			};
		} catch {
			return;
		}

		// Have to check len since showDatePicker defaults to false at startup
		if (!tp.showDatePicker && tp.quickFilter.length > 0) {
			filters.startDate = tp.quickFilter[0].toLocaleDateString();
			filters.endDate = tp.quickFilter[1].toLocaleDateString();
		} else {
			// Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
			if (
				filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
			) {
				return;
			}
		}
		// Get users in the sales rep dropdown and add to the filter
		if (tp.salesRepsSelected.length > 0) {
			filters.users = tp.salesRepsSelected;
		}
		// Only default to getting all users if they have the correct permissions
		else if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT" ||
			currentUser.role === "FINANCE"
		) {
			salesReps.forEach((rep) => {
				reps.push(rep.id);
			});

			filters.users = reps;
		}
		this.setState({ isLoaded: false });

		return ReportsService.getExpensesReport(filters)
			.then((res) => {
				this.setState({
					allExpenses: res,
				});

				this.formatRows();
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	transformDate(date) {
		let result = "";
		if (date) {
			let parts = date.split("-");
			if (parts.length > 1) {
				result = `${parts[1]}/${parts[2]}/${parts[0]}`;
			}
			return result;
		}
	}

	//fetch report
	formatRows() {
		let expenses = this.state.allExpenses,
			ary = [],
			dt = this.state.expensesData;

		expenses.forEach((expense) => {
			ary.push({
				createdAt: this.transformDate(expense.createdAt.split(" ")[0]) || " ",
				expenseDate:
					this.transformDate(expense.expenseDate.split(" ")[0]) || " ",
				user: expense.username,
				group: expense.office ? expense.office.replace(/,/g, " ") : "No Office",
				account: expense.account
					? expense.account.replace(/,/g, " ")
					: "No Account",
				vendor: expense.vendor
					? expense.vendor.replace(/,/g, " ")
					: "No Vendor",
				description: expense.expenseDesc
					? expense.expenseDesc.replace(/,/g, " ")
					: "No Description",
				amount: parseFloat(expense.amount),
			});
		});
		dt.rows = ary;

		this.setState({
			expensesData: dt,
			isLoaded: true,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//load the data in table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<DataTable
					entriesOptions={[25, 50, 100]}
					pagesAmount={10}
					data={this.state.expensesData}
					responsive
					fixed
					striped
					bordered
				></DataTable>

				<Button
					style={{ float: "right" }}
					color={"blue"}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.state.csvFunction.bind(
						this,
						this.state.expensesData,
						"expensesExport.csv"
					)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
