import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import {
	Grid,
	Table,
	TableHeaderRow,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
} from "@devexpress/dx-react-grid-bootstrap4";
import { GroupingState, IntegratedGrouping } from "@devexpress/dx-react-grid";
import {
	Button,
	Card,
	CardGroup,
	CardBody,
	CardHeader,
	Container,
	Col,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
	Spinner,
	toast,
	ToastContainer,
	MDBIcon,
	Fa,
	MDBSelect,
	MDBInput,
	MDBCol,
	MDBRow
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import GroupService from "../Security/UserService/groupService";
import AccountService from "../Security/AccountService/accountService";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import InputMask from "react-input-mask";

export default class patientList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					title: "Name",
					name: "name",
				},
			],
			rows: [],
		};

		let results = this.determineResults(props.location);

		this.state = {
			data: data,
			isLoaded: true,
			filtersOpen: false,
			results: results,
			patientModal: false,
			mergeModal: false,
			nPatient: {
				firstName: "",
				lastName: "",
				dateOfBirth: null,
				smsMessages: true,
			},
			allLocs: [],
			allSalesReps: [],
			locationsSelect: [
				{
					text: "Loading...",
					value: "loading",
				},
			],
			salesRepsSelect: [
				{
					text: "Loading...",
					value: "loading",
				},
			],
			accountListSelect: [
				{
					text: "Choose Sales Rep First...",
					value: "Choose Sales Rep First...",
				},
			],
			locationsSelected: {},
			salesRepsSelected: {},
			accountSelected: {},
			showDatePicker: false,
			quickFilter: [],
			quickFilterSelected: null,
			selectedPatients: [],
			masterPatient: null,
			selectMode: false,
			fName: "",
			lName: "",
			dateOfBirth: "",
		};

		this.changeGrouping = (grouping) => this.setState({ grouping });
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		accounts: PropTypes.array,
		internalLocations: PropTypes.array,
		allLocations: PropTypes.array,
		salesReps: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { salesReps, currentUser, allLocations } = this.context;
		let st = this.state;
		if (
			st.allLocs.length !== allLocations.length &&
			st.currUser !== currentUser
		) {
			this.renderLocationsOptions();
		}
		if (st.allSalesReps.length !== salesReps.length) {
			this.renderSalesRepsOptions();
		}
	}

	determineResults(propsData) {
		let results = [];
		if (propsData.results) {
			results = propsData.results;
		}
		return results;
	}

	renderSalesRepsOptions(e) {
		const { salesReps, currentUser } = this.context;
		let options = [],
			np = this.state.nPatient;

		np.account = null;

		if (e === undefined || e.length === 0) {
			if (currentUser.role !== "SALES") {
				salesReps.map((rep) => {
					return options.push({
						text: rep.username,
						value: rep.id,
					});
				});
			}
			this.setState({
				salesRepsSelect: options,
				allSalesReps: salesReps,
				nPatient: np,
			});
		} else {
			let salesList = [];

			return GroupService.getAllSalesRepsByLocations(e)
				.then((res) => {
					res.forEach((t) => {
						if (salesList.indexOf(t.id) === -1) {
							options.push({
								text: t.username,
								value: t.id,
							});
						}
						salesList.push(t.id);
					});

					this.setState({
						salesRepsSelect: options,
						salesRepsSelected: [],
						nPatient: np,
					});
				})
				.catch((e) => {});
		}
	}

	renderAccountOptions(e) {
		let accountList = [],
			np = this.state.nPatient;

		np.account = null;

		return AccountService.getAccountsByUser(e)
			.then((res) => {
				res.forEach((t) => {
					accountList.push({
						text: t.name || "NO NAME",
						value: {
							label: t.name,
							value: t.id,
						},
					});
				});

				this.setState({
					accountListSelect: accountList,
					accountSelected: {},
					nPatient: np,
				});
			})
			.catch((e) => {});
	}

	renderLocationsOptions() {
		const { allLocations, currentUser } = this.context;
		let cu = currentUser;
		let select = [],
			locations = [];

		if (cu.role === "SALES") {
			locations = cu.locations;
		} else {
			locations = allLocations;
		}

		locations.map((location) => {
			// Populates the locations dropdown depending on which locations the user is in...JK
			if (location.type === "Internal") {
				return select.push({
					text: location.name,
					value: location.id,
				});
			}
			return null;
		});
		this.setState({
			locationsSelect: select,
			allLocs: allLocations,
		});
	}

	handleLocationChange = (e) => {
		let np = this.state.nPatient;
		np.account = null;

		this.setState({
			locationsSelected: e,
			nPatient: np,
			accountSelected: {},
		});
		this.renderSalesRepsOptions(e);
	};

	handleSaleRepChange = (e) => {
		this.setState({
			salesRepsSelected: e,
		});

		this.renderAccountOptions(e);
	};

	selectLocation(location) {
		let nFilters = this.state.filters;
		nFilters.locationId = location.value.id;
		this.setState({ filters: nFilters });
	}

	renderLocationsFilter() {
		return (
			<Row>
				<Col md={6} style={{ marginTop: 5 }}>
					<MDBSelect
						search
						outline
						label="Select Location"
						className={"smallMargin searchField"}
						options={this.state.locationsSelect}
						getValue={this.handleLocationChange.bind(this)}
						value={this.state.locationsSelected}
					/>
				</Col>
				<Col md={6} style={{ marginTop: 5 }}>
					<MDBSelect
						search
						outline
						className={"smallMargin searchField"}
						label="Select Sales Rep"
						options={this.state.salesRepsSelect}
						getValue={this.handleSaleRepChange.bind(this)}
						value={this.state.salesRepsSelected}
					/>
				</Col>
				<Col md="12" style={{ marginTop: 5 }}>
					<MDBSelect
						search
						outline
						className={"smallMargin searchField"}
						label="Select Account"
						options={this.state.accountListSelect}
						getValue={(e) => {
							if (e && e.length > 0) {
								this.handlePatientAccountSelected(e[0]);
							}
						}}
						value={this.state.accountSelected}
					/>
				</Col>
			</Row>
		);
	}

	formatDate(date) {
		if (date == null || date === "") {
			return "No Birthday Listed";
		}

		//MUST USE THIS FORMAT because of localdate/timezone issues pushing the DOB earlier...BC
		return moment.utc(new Date(date), "MM/DD/YYYY").format("MMMM DD, YYYY");
	}

	togglePatientModal() {
		let currentState = this.state.patientModal;
		this.setState({ patientModal: !currentState });
	}

	toggleMergeModal() {
		let currentState = this.state.mergeModal;
		this.setState({ mergeModal: !currentState });
	}

	clearPatientObject() {
		let ac = {};

		this.setState({
			nPatient: ac,
			accountSelected: {},
			salesRepsSelected: {},
			locationsSelected: {},
		});
	}

	handleChange = (property, event) => {
		//update the property on the object that the user has edited in the order...BC
		let nPatient = this.state.nPatient;
		nPatient[property] = event.target.value;
		this.setState({ nPatient: nPatient });
	};

	handleDatePickerChange = (value) => {
		//update the date property on the object that the user has edited in the order...BC
		let nPatient = this.state.nPatient;
		nPatient.dateOfBirth = value;
		this.setState({ nPatient: nPatient });
	};

	handlePatientAccountSelected = (e) => {
		let account = { name: e.label, id: e.value },
			selectBoxVal = { text: e.label, id: e.value },
			nP = this.state.nPatient;

		nP.account = account;

		this.setState({
			nPatient: nP,
			accountSelected: selectBoxVal,
		});
	};

	checkPatientDetails() {
		let patient = this.state.nPatient;
		return !(
			patient.firstName &&
			patient.lastName &&
			patient.dateOfBirth &&
			patient.account
		);
	}

	createPatient(history) {
		let patient = this.state.nPatient;

		//Set DOB to 'MM-DD-YYYY' to strip time from the value...BC
		patient.dateOfBirth = new Date(patient.dateOfBirth).toLocaleDateString();

		return OrderService.createPatient(patient)
			.then((res) => {
				this.togglePatientModal();
				history.push({
					pathname: "/patientInformation/" + res.id,
					state: { patient: res },
				});
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	getPatientsByInformation(fName, lName, dob) {
		return OrderService.getPatientsByInformation(fName, lName, dob)
			.then((res) => {
				this.buildListResults(res);
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	buildListResults(res) {
		let t = this;

		let ary = [],
			dt = this.state.data;

		if (res.length === 0) {
			toast.warn("No Patients Found");
		}

		res.forEach((patient, index) => {
			ary.push({
				name: patient.firstName + " " + patient.lastName,
				patient: patient,
			});
		});

		dt.rows = ary;

		t.setState({
			data: dt,
			results: res,
			isLoaded: true,
		});
	}

	checkSearch(key) {
		let t = this.state,
			fName = t.fName.trim(),
			lName = t.lName.trim();
		if (key.key === "Enter") {
			this.setState({ isLoaded: false });
			if (!t.dateOfBirth) {
				this.getPatientsByInformation(fName, lName);
			} else {
				this.getPatientsByInformation(fName, lName, t.dateOfBirth);
			}
		}
	}

	addPatientToList(p) {
		let patientList = this.state.selectedPatients,
			idx = patientList.indexOf(p.id);

		if (idx > -1) {
			patientList.splice(idx, 1);
		} else {
			patientList.push(p.id);
		}

		this.setState({
			patientList: patientList,
		});
	}

	renderCreatePatientRoute() {
		return (
			<Route
				render={({ history }) => (
					<div
						className={"ptBtn saveBtn"}
						disabled={this.checkPatientDetails()}
						data-tip={"Add New Patient"}
						onClick={() => this.createPatient(history)}
					>
						<Fa size="2x" icon={"check"}>
							{" "}
						</Fa>
					</div>
				)}
			/>
		);
	}

	renderRouteHeader(patient) {
		let pl = this.state.selectedPatients,
			bgColor = pl.indexOf(patient.id) > -1 ? "red" : "#5881C1";
		return (
			<Route
				render={({ history }) => (
					<CardHeader
						className={"cardGroupCardHeader"}
						style={{ backgroundColor: bgColor, color: "white" }}
						onClick={() => {
							if (this.state.selectMode === true) {
								this.addPatientToList(patient);
							} else {
								history.push({
									pathname: "/patientInformation/" + patient.id,
									state: { patient: patient },
									results: this.state.results,
								});
							}
						}}
					>
						<span style={{ fontSize: 14 }}>
							{patient.firstName + " " + patient.lastName}
						</span>
						<span style={{ fontSize: 14, float: "right" }}>
							{" "}
							{this.formatDate(patient.dateOfBirth)}
						</span>
					</CardHeader>
				)}
			/>
		);
	}

	determineSelectModeButton() {
		let sm = this.state.selectMode;
		const { currentUser } = this.context;

		//if the user is not one of those roles, they are not allowed to merge patients...BC
		if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT"
		) {
			if (sm === true) {
				return (
					<Button
						floating
						size="sm"
						color={"success"}
						data-place={"bottom"}
						data-tip={"Complete Merge"}
						onClick={() => this.toggleMergeModal()}
					>
						<MDBIcon far icon="object-group" style={{ fontSize: "2em" }} />
					</Button>
				);
			} else {
				return (
					<Button
						floating
						size="sm"
						color={"orange"}
						data-place={"bottom"}
						data-tip={"Merge Patients"}
						onClick={() =>
							this.setState({
								selectMode: true,
							})
						}
					>
						<MDBIcon icon="object-group" style={{ fontSize: "2em" }} />
					</Button>
				);
			}
		}
	}

	masterSelected(pid) {
		this.setState({
			masterPatient: pid,
		});
	}

	renderCardGroup(theme) {
		return (
			<div className={"cardGroupListContainer"}>
				<CardGroup className={"cardGroupList"} deck>
					{this.state.results.map((item, idx) => {
						return this.renderCard(item, idx, theme);
					})}
				</CardGroup>
			</div>
		);
	}

	renderCard(item, idx) {
		return (
			<Card key={idx} className={"cardGroupCard"}>
				{this.renderRouteHeader(item)}
				<CardBody style={{ padding: 4 }}>
					<MDBRow>
						<MDBCol si="12">
							<span style={{ fontSize: 14 }}>
								{" "}
								{(item.city || "No City") + " , " + (item.state || "No State")}
							</span>
						</MDBCol>
						<MDBCol size="12" style={{ fontSize: 14 }}>
							<p style={{ marginBottom: 0 }}>
								{item.phone || "No Phone Number"}{" "}
							</p>
						</MDBCol>
					</MDBRow>

					<p
						style={{
							fontSize: 12,
							position: "absolute",
							bottom: 4,
							right: 4,
							marginBottom: 0,
						}}
					>
						#{item.id}{" "}
					</p>
				</CardBody>
			</Card>
		);
	}

	renderPatientModal() {
		return (
			<Modal
				centered
				size="md"
				isOpen={this.state.patientModal}
				toggle={() => {
					return;
				}}
			>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<ModalBody style={{ backgroundColor: "#FFFFFF" }}>
						<CardHeader
							style={{
								color: "white",
								backgroundColor: "#5881C1",
								marginBottom: 0,
							}}
							className="form-header text-center font-weight-bold"
						>
							<h3>Create New Patient</h3>
						</CardHeader>
						<Row style={{ paddingTop: 10 }}>
							<Col md="6">
								<MDBInput
									outline
									containerClass={"smallMargin"}
									label="First Name"
									onChange={this.handleChange.bind(this, "firstName")}
								/>
							</Col>
							<Col md="6">
								<MDBInput
									outline
									containerClass={"smallMargin"}
									label="Last Name"
									onChange={this.handleChange.bind(this, "lastName")}
								/>
							</Col>

							<MDBCol size="6">
								<InputMask
									mask="(999)-999-9999"
									value={this.state.nPatient.phone}
									onChange={this.handleChange.bind(this, "phone")}
								>
									{(inputProps) => (
										<MDBInput
											outline
											containerClass={"smallMargin"}
											{...inputProps}
											label="Phone Number"
											style={{ paddingTop: 8 }}
										/>
									)}
								</InputMask>
							</MDBCol>

							<Col md="6" className={"inputDateWrapper uiDateOutline"}>
								<KeyboardDatePicker
									value={this.state.nPatient.dateOfBirth}
									label={"Date of Birth"}
									size="small"
									inputVariant={"outlined"}
									autoOk={true}
									format={"MM/DD/YYYY"}
									onChange={(date) => this.handleDatePickerChange(date)}
								/>
							</Col>
						</Row>
						<Row>
							<Col md="12">{this.renderLocationsFilter()}</Col>
						</Row>
					</ModalBody>
				</MuiPickersUtilsProvider>
				<ModalFooter color={"indigo"}>
					{this.renderCreatePatientRoute()}

					<div
						className={"ptBtn cancelBtn"}
						data-tip={"Cancel"}
						onClick={() => {
							this.clearPatientObject();
							this.togglePatientModal();
						}}
					>
						<Fa size="2x" icon={"times"}>
							{" "}
						</Fa>
					</div>
					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	mergePatients() {
		if (this.state.selectedPatients.length < 2) {
			toast.warn("You do not have enough patients selected to perform a merge");
			return;
		}

		if (this.state.masterPatient == null) {
			toast.warn("You must select a patient to be the master");
			return;
		}

		let masterIdx = this.state.selectedPatients.indexOf(
			this.state.masterPatient
		);

		this.state.selectedPatients.splice(masterIdx, 1);

		//do merge stuff
		let p = {
			masterID: this.state.masterPatient,
			mergedIDs: this.state.selectedPatients.toString(),
		};

		OrderService.mergePatients(p)
			.then(() => {
				toast.success("Merge Successful");

				this.setState({
					masterPatient: null,
					selectedPatients: [],
					selectMode: false,
					isLoaded: false,
				});

				//redo the search to get updated patient list
				this.checkSearch({ key: "Enter" });
			})
			.catch((e) => {
				toast.error("There was an error merging the patients.");
			});

		this.toggleMergeModal();
	}

	renderMergeModal() {
		let ids = this.state.selectedPatients;

		return (
			<Modal
				centered
				size="md"
				isOpen={this.state.mergeModal}
				toggle={() => {
					return;
				}}
			>
				<ModalBody style={{ backgroundColor: "#FFFFFF" }}>
					<CardHeader
						style={{
							color: "white",
							backgroundColor: "#5881C1",
							marginBottom: 20,
						}}
						className="form-header text-center font-weight-bold"
					>
						<h3>Select Master Patient Record</h3>
					</CardHeader>

					<Row>
						{ids.map((p, idx) => {
							return (
								<Col key={idx} size="3">
									<Input
										label={p}
										checked={this.state.masterPatient === p}
										onChange={() => this.masterSelected(p)}
										type="radio"
										id={"checkbox" + p}
									/>
								</Col>
							);
						})}
					</Row>
				</ModalBody>
				<ModalFooter style={{ paddingTop: 0, paddingBottom: 0 }}>
					<Button
						color={"success"}
						floating
						size="sm"
						data-tip={"Merge"}
						onClick={() => {
							this.mergePatients();
						}}
					>
						<Fa size="2x" icon={"check"}>
							{" "}
						</Fa>
					</Button>

					<Button
						color={"red"}
						floating
						size="sm"
						data-tip={"Cancel"}
						onClick={() => {
							this.setState({
								masterPatient: null,
								selectedPatients: [],
								selectMode: false,
							});
							this.toggleMergeModal();
						}}
					>
						<Fa size="2x" icon={"times"}>
							{" "}
						</Fa>
					</Button>

					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	renderTable() {
		const TableRow = ({ row, ...restProps }) => (
			<Table.Row
				{...restProps}
				// eslint-disable-next-line no-alert
				onClick={() => {
					this.props.history.push({
						pathname: "/patientInformation/" + row.patient.id,
						state: { patient: row.patient },
					});
				}}
				style={{
					cursor: "pointer",
				}}
			/>
		);

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<GroupingState onGroupingChange={this.changeGrouping} />
					<IntegratedGrouping />
					<Table rowComponent={TableRow} />
					<TableHeaderRow />
					<TableGroupRow />
					<Toolbar />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner(theme) {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return this.renderCardGroup(theme);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<Container>
					<Row>
						<Col size="3" style={{ paddingTop: "10px" }}>
							<div>
								<input
									className="form-control my-0 py-1 listSearchInputBackground"
									type="text"
									placeholder="First Name...."
									aria-label="Search"
									onChange={(event) =>
										this.setState({ fName: event.target.value })
									}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
						</Col>

						<Col size="3" style={{ paddingTop: "10px" }}>
							<div>
								<input
									className="form-control my-0 py-1 listSearchInputBackground"
									type="text"
									placeholder="Last Name...."
									aria-label="Search"
									onChange={(event) =>
										this.setState({ lName: event.target.value })
									}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
						</Col>
						<Col size="3" style={{ paddingTop: "10px" }}>
							<div className="input-group">
								{/*month--day--year trying to save seperately*/}
								<input
									className="form-control"
									type="date"
									placeholder="Date of Birth...."
									aria-label="Search"
									onChange={(event) =>
										this.setState({ dateOfBirth: event.target.value })
									}
									onKeyPress={(key) => this.checkSearch(key)}
								/>
							</div>
						</Col>
						<Col size="1">
							<Button
								floating
								size="sm"
								color={"indigo"}
								data-place={"bottom"}
								data-tip={"Search"}
								onClick={() => this.checkSearch({ key: "Enter" })}
							>
								<MDBIcon icon="search" style={{ fontSize: "2em" }} />
							</Button>
						</Col>

						<Col size="1">
							<Button
								floating
								size="sm"
								color={"secondary"}
								data-place={"bottom"}
								data-tip={"Add New Patient"}
								onClick={() => this.togglePatientModal()}
							>
								<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
							</Button>
						</Col>
						<Col size="1">{this.determineSelectModeButton()}</Col>
					</Row>
					<ReactTooltip />
				</Container>
				{this.renderTableOrSpinner()}
				{this.renderPatientModal()}
				{this.renderMergeModal()}
			</div>
		);
	}
}
