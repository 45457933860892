import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	Input,
	toast,
	MDBIcon,
} from "mdbreact";
import Select from "react-select";
import ExpenseTypeService from "./expenseTypeService";
import { ToastContainer } from "react-toastify";
import CreditCardService from "./creditCardTypesService";
import ReactTooltip from "react-tooltip";

export default class addExpenseType extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			errorMessage: "",
			isChecked: false,
			description: "",
			isLoaded: false,
			creditCards: [],
			creditCardsSelected: [],
		};
	}

	componentDidMount() {
		this.retrieveCCTypes();
	}

	retrieveCCTypes() {
		let t = this;
		return CreditCardService.getAllCreditCardTypes()
			.then((res) => {
				let arr = [];

				// Have to do ccVal b/c it will complain about matching keys
				// if you try to pass an obj as the value instead of a string
				res.forEach((cc) => {
					arr.push({
						value: cc.name,
						label: cc.name,
						ccVal: cc,
					});
				});

				t.setState({
					creditCards: arr,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	nameChange = (e) => {
		this.setState({
			name: e.target.value,
		});
	};

	checkBoxChange = (e) => {
		this.setState({
			isChecked: !this.state.isChecked,
		});
	};

	descriptionChange = (e) => {
		this.setState({
			description: e.target.value,
		});
	};

	handleSelectChange = (e) => {
		this.setState({
			creditCardsSelected: e,
		});
	};

	renderAddExpButton() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					label={"Expense Type Name"}
					onChange={this.nameChange.bind(this)}
					value={this.state.name}
				/>
				<div className="form-check my-4">
					<input
						className="form-check-input"
						checked={this.state.isChecked}
						type="checkbox"
						id="defaultCheck12"
						onChange={this.checkBoxChange.bind(this)}
					/>
					<label htmlFor="defaultCheck12" className="grey-text">
						Receipt Required
					</label>
				</div>
				<Input
					label={"Description"}
					onChange={this.descriptionChange.bind(this)}
					value={this.state.description}
				/>
				<Select
					placeholder={"Credit Card Type"}
					closeMenuOnSelect={false}
					isMulti
					options={this.state.creditCards}
					onChange={this.handleSelectChange.bind(this)}
					value={this.state.creditCardsSelected}
				/>
			</div>
		);
	}

	addSaveButtonClick = () => {
		if (this.validate()) {
			let n = this.state.name,
				receipt = this.state.isChecked,
				d = this.state.description,
				cc = this.state.creditCardsSelected,
				ccList = [];

			cc.forEach((credit) => {
				ccList.push(credit.ccVal);
			});

			let obj = {
				name: n,
				description: d,
				cardTypes: ccList,
				receiptRequired: receipt,
			};
			return ExpenseTypeService.createExpenseType(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						name: "",
						description: "",
						creditCardsSelected: [],
						isChecked: false,
					});
					toast.success(obj.name + " Expense Type has been Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred adding a new Expense Type");
				});
		}
	};

	validate() {
		let n = this.state.name,
			d = this.state.description;
		if (n === "") {
			this.setError("Please enter an Expense Name");
			return false;
		} else if (d === "") {
			this.setError("Please enter a Description");
		}
		return true;
	}
	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container className="mt-5">
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							<Button
								style={{ float: "left" }}
								floating
								size="sm"
								color={"warning"}
								data-tip={"Back to Expense Types"}
								onClick={() => this.props.history.push("/expenseTypes")}
							>
								<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
							</Button>
							Add New Expense Type
							<Button
								style={{ float: "right" }}
								floating
								size="sm"
								color={"success"}
								data-tip={"Save New Expense Type"}
								onClick={this.addSaveButtonClick}
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
						</CardHeader>

						<CardBody>{this.renderAddExpButton()}</CardBody>
					</Card>
					<ReactTooltip />
				</Container>
			</div>
		);
	}
}
