import api from "../api";

class AccountService {
	async getAllAccounts(filters) {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 1000,
				users: filters && filters.users ? filters.users : [],
				q: filters && filters.q ? filters.q : "",
			};
			api
				.get("/accounts", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async mergeAccounts(params) {
		return new Promise((res, reject) => {
			return api
				.get("/accounts/mergeAccounts", params)
				.then(() => {
					res();
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAccountsByUser(userId) {
		return new Promise((res, reject) => {
			api
				.get("/users/" + userId + "/accounts")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	//to get all account types
	async getAllAccountTypes() {
		return new Promise((res, reject) => {
			api
				.get("/accountTypes")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAccount(id) {
		return new Promise((res, reject) => {
			api
				.get("/sources/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createAccount(account) {
		return api.post("/sources", account);
	}

	async updateAccount(account) {
		return new Promise((res, reject) => {
			api
				.put("/sources/" + account.id, account)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const accountService = new AccountService();

export default accountService;
