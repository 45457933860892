import api from "../api";

class ReasonService {
	//This endpoint does not actually exist on the server...BC
	async findOneReason(id) {
		return new Promise((res, reject) => {
			return api
				.get("/reasons/" + id)
				.then((reason) => {
					res(reason);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async createReason(reason) {
		return new Promise((res, reject) => {
			return api
				.post("/reasons", { data: reason })
				.then((reason) => {
					res(reason);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async updateReason(reason) {
		return new Promise((res, reject) => {
			return api
				.put("/reasons/" + reason.id, { data: reason })
				.then((reason) => {
					res(reason);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async deleteReason(reason) {
		return new Promise((res, reject) => {
			return api
				.delete("/reasons/" + reason.id)
				.then((reason) => {
					res(reason);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	async findAllReasons() {
		return new Promise((res, reject) => {
			return api
				.get("/reasons")
				.then((reasons) => {
					res(reasons);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}

const reasonService = new ReasonService();

export default reasonService;
